import { isLangEnHash, isLangRuHash } from './booking-urls'

const localizationObj: object = {
  months_arr_normal: {
    ru: [
      'Январь',
      'Февраль',
      'Март',
      'Апрель',
      'Май',
      'Июнь',
      'Июль',
      'Август',
      'Сентябрь',
      'Октябрь',
      'Ноябрь',
      'Декабрь',
    ],
    en: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
  },
  months_arr: {
    ru: [
      'Января',
      'Февраля',
      'Марта',
      'Апреля',
      'Мая',
      'Июня',
      'Июль',
      'Августа',
      'Сентября',
      'Октября',
      'Ноября',
      'Декабря',
    ],
    en: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
  },

  weeks_arr: {
    ru: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
    en: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  },

  weeks_abbr_arr: {
    ru: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    en: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  },

  guest_count_label: {
    ru: 'Количество гостей', // .
    en: 'Number of guests', // .
  },
  guest_count_for: {
    ru: 'для',
    en: 'for',
  },
  guest_count_for_cap: {
    ru: 'Для',
    en: 'For',
  },
  guest_count_persons: {
    ru: ['персоны', 'персон', 'персон'], // ???
    en: ['person', 'persons', 'persons'], // ???
  },
  guest_count_guests: {
    ru: ['гость', 'гостя', 'гостей'], // .
    en: ['guest', 'guests', 'guests'], // .
  },

  booking_date_validation: {
    ru: 'Необходимо указать',
    en: 'Required to specify', // .
  },
  booking_time_validation: {
    ru: 'Необходимо указать',
    en: 'Required to specify', // .
  },
  guest_name_validation: {
    ru: 'Необходимо указать',
    en: 'Required to specify', // .
  },
  guest_phone_validation: {
    ru: 'Номер телефона указан неверно', // .
    en: 'The phone number is incorrect', // .
  },

  booking_date_label: {
    ru: 'Дата', // .
    en: 'Date', // .
  },

  booking_date_today: {
    ru: 'Сегодня', // .
    en: 'Today', // .
  },

  booking_date_tomorrow: {
    ru: 'Завтра', // .
    en: 'Tomorrow', // .
  },

  booking_time_label: {
    ru: 'Время', // .
    en: 'Time', // .
  },

  booking_date_placeholder: {
    ru: 'Когда', // .
    en: 'When', // .
  },

  booking_time_placeholder: {
    ru: 'Во сколько',
    en: 'Select time',
  },

  guest_name_placeholder: {
    ru: 'Имя гостя', // .
    en: 'Guest name', // .
  },

  guest_full_name_placeholder: {
    ru: 'Имя и фамилия гостя', // .
    en: "Guest's full name",
  },

  guest_phone_placeholder: {
    ru: 'Телефон', // .
    en: 'Phone number', // .
  },

  guest_comment_placeholder: {
    ru: 'Пожелания по бронированию', // .
    en: 'Add a special request', // .
  },

  form_submit_button_text: {
    ru: 'Забронировать столик', // .
    en: 'Reserve', // .
  },

  form_header_title_booking: {
    ru: 'Бронирование столика', // .
    en: 'Reservation', // .
  },

  form_header_title: {
    ru: 'Заявка создана', // .
    en: 'Request has been created', // .
  },

  booking_complete_description: {
    ru: 'Ожидайте подтверждения по sms в\u00A0ближайшее\u00A0время', // .
    en: 'Please wait for SMS confirmation', // .
  },

  form_complete_info_rest: {
    ru: 'Ресторан',
    en: 'Restaurant',
  },
  form_complete_info_on: {
    ru: 'на',
    en: 'on',
  },
  form_complete_info_at: {
    ru: 'в',
    en: 'at',
  },

  booking_add_to_calendar_title: {
    ru: 'Добавить в календарь:',
    en: 'Add to calendar:',
  },

  form_close_confirm_title: {
    ru: 'Хотите прервать бронирование?', // .
    en: 'Do you want to cancel reservation?', // .
  },
  form_close_confirm_abort: {
    ru: 'Да', // .
    en: 'Yes', // .
  },
  form_close_confirm_continue: {
    ru: 'Ой, я случайно', // .
    en: 'Oops, accidentally', // .
  },
  form_close_confirm_close: {
    ru: 'Отлично', // .
    en: 'Close', // ?
  },
}

// detect language
const getCurrentLanguage = () => {
  let hashLang = null
  if (isLangRuHash) {
    hashLang = 'ru'
  }
  if (isLangEnHash) {
    hashLang = 'en'
  }
  // const userLang = navigator.language || navigator.userLanguage
  const userLang = 'ru'
  return hashLang || userLang.split(/-|_/)[0]
}

const targetLang = getCurrentLanguage()

// get language
const l10n = (text: string) => {
  // @ts-ignore
  return localizationObj[text][targetLang]
}

export { targetLang }
export default l10n
