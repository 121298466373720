/**
 * @deprecated
 * @swagger http://rests.afisha.ru/rests/api/lk/v1/swagger/
 */

import { apiLkMock } from './apiLkMock'

import * as Types from '@shared/api/types/apiLkParameters'
import { apiRequest } from '@shared/api/apiRequest'
import { mappingAPIlk } from '@shared/api/middleware/mappingAPI'
import { middlewareError, textErrorsApiLk } from '@shared/api/middleware/middlewareError'

const BASE_DOMAIN = process.env.IS_STAGE === 'true' ? 'http://artemis.dev.rests.afisha.ru' : 'https://www.afisha.ru'
const BASE_URL = `${BASE_DOMAIN}/rests/api/lk/v1`
const GITLAB_STATUS = process.env.GITLAB_STATUS
const IS_PROD = GITLAB_STATUS === '0'

export const API_LK = {
  // BOOKING

  deprecated_booking_list: (props: Types.PropsBookingList) => {
    const showErrorUser = props?.error?.showUser || false
    const textError = props?.error?.text || textErrorsApiLk['deprecated_booking_list']
    return (
      mappingAPIlk
        // @ts-ignore
        .deprecated_booking_list(apiRequest.get({ url: `${BASE_URL}/booking/` }))
        .catch(() => middlewareError(showErrorUser, textError, false))
    )
  },

  deprecated_booking_read: (props: Types.PropsBookingRead) => {
    const showErrorUser = props?.error?.showUser || false
    const textError = props?.error?.text || textErrorsApiLk['deprecated_booking_read']
    return mappingAPIlk
      .deprecated_booking_read(apiRequest.get({ url: `${BASE_URL}/booking/${props.id}/` }))
      .catch(() => middlewareError(showErrorUser, textError, false))
  },

  deprecated_booking_partial_update: (props: Types.PropsBookingPartialUpdate) => {
    const showErrorUser = props?.error?.showUser || false
    const textError = props?.error?.text || textErrorsApiLk['deprecated_booking_partial_update']
    return mappingAPIlk
      .deprecated_booking_partial_update(apiRequest.patch({ url: `${BASE_URL}/booking/${props.id}/` }))
      .catch(() => middlewareError(showErrorUser, textError, false))
  },

  deprecated_booking_guest_not_come: (props: Types.PropsBookingGuestNotCome) => {
    const showErrorUser = props?.error?.showUser || false
    const textError = props?.error?.text || textErrorsApiLk['deprecated_booking_guest_not_come']
    return mappingAPIlk
      .deprecated_booking_guest_not_come(apiRequest.patch({ url: `${BASE_URL}/booking/${props.id}/guest_not_come/` }))
      .catch(() => middlewareError(showErrorUser, textError, false))
  },

  deprecated_booking_set_total: (props: Types.PropsBookingSetTotal) => {
    const showErrorUser = props?.error?.showUser || false
    const textError = props?.error?.text || textErrorsApiLk['deprecated_booking_set_total']
    return mappingAPIlk
      .deprecated_booking_set_total(apiRequest.patch({ url: `${BASE_URL}/booking/${props.id}/set_total/` }))
      .catch(() => middlewareError(showErrorUser, textError, false))
  },
}
