import MenuImage1 from '@images/menu-items/desktop/menu-image-1.png'
import MenuImage2 from '@images/menu-items/desktop/menu-image-2.png'
import MenuImage4 from '@images/menu-items/desktop/menu-image-4.png'
import MenuImage7 from '@images/menu-items/desktop/menu-image-7.png'
import MenuImage8 from '@images/menu-items/desktop/menu-image-8.png'
import MenuImage3 from '@images/menu-items/desktop/menu-image-3.png'
import MenuImage6 from '@images/menu-items/desktop/menu-image-6.png'
import MenuImage9 from '@images/menu-items/desktop/menu-image-9.png'
import MenuImage5 from '@images/menu-items/desktop/menu-image-5.png'

import {
  TypeMappingRestDish,
  TypeMappingScheduleSlot,
  TypeMappingScheduleWeek,
} from '@shared/api/middleware/mappingAPI/restaurant/type'

/**
  Маппинг данных блюд
*/
export const generateNewDishes = (data): TypeMappingRestDish[] => {
  try {
    return data?.items?.map((dishItem, i) => ({
      id: dishItem.id || i,
      title: dishItem.title,
      price: dishItem.price,
      img: dishItem.photo,
      description: dishItem.description,
    }))
  } catch (e) {
    console.log(e)
    return null
  }
}

/**
 Преобразование из "+8(111) 111 11 11, +8(222) 222 22 22" в ['+8(111) 111 11 11', '+8(222) 222 22 22']
 */
export const genPhoneArray = (phoneData): string[] => {
  try {
    return !(typeof phoneData === 'string') ? phoneData : phoneData.split(',').map((phone) => phone.trim())
  } catch (e) {
    console.log(e)
    return null
  }
}

/**
  Проверка на наличие текста, удаление пробелов
*/
export const isDescriptionExist = (data: string): string | null => {
  try {
    const regExp = /[а-яА-ЯёЁa-zA-Z]/g
    const textExist = !!data && regExp.test(data)
    return textExist ? data.trim() : null
  } catch (e) {
    console.log(e)
    return null
  }
}

export const getValueDiscount = (string) => {
  try {
    return string ? string.match(/\d+/i)[0] : null
  } catch (e) {
    console.log(e)
    return null
  }
}

export const generateMenuPDF = (menuArray) => {
  try {
    const menuTemplate = {
      breakfast: {
        img: MenuImage1.src,
        keyWords: ['Завтрак', 'breakfast'],
      },
      bar: {
        img: MenuImage2.src,
        keyWords: ['Бар', 'Барная карта', 'bar'],
      },
      child: {
        img: MenuImage4.src,
        keyWords: ['Детское', 'child'],
      },
      main: {
        img: MenuImage7.src,
        keyWords: ['Основное меню', 'Главное', 'Main'],
      },
      lunch: {
        img: MenuImage8.src,
        keyWords: ['Обед', 'Ланч', 'lunch'],
      },
      cocktails: {
        img: MenuImage3.src,
        keyWords: ['Коктейл', 'cocktail'],
      },
      wine: {
        img: MenuImage6.src,
        keyWords: ['Вино', 'Винная карта', 'Винное', 'wine'],
      },
      tea: {
        img: MenuImage9.src,
        keyWords: ['Чай', 'tea'],
      },
      other: {
        img: MenuImage5.src,
        keyWords: null,
      },
    }

    if (!menuArray?.length) return null

    let countMainMenu = 0
    for (let i = 0; i < menuArray.length; i++) {
      const getMenuTemplate =
        Object.values(menuTemplate).find((el) => {
          return el.keyWords && el.keyWords.some((word) => menuArray[i].type.toLowerCase().includes(word.toLowerCase()))
        }) || menuTemplate.other

      menuArray[i] = {
        id: i,
        type: menuArray[i].type,
        href: menuArray[i].href?.replace('//develop.rests', '//rests'),
        img: getMenuTemplate.img,
      }

      // Выносим основное меню в начало списка
      // Если в списке есть несколько основных меню (напр. ру. и англ.) то выносятся вперед оба
      const isMainMenu = menuTemplate.main.keyWords.some((word) =>
        menuArray[i].type.toLowerCase().includes(word.toLowerCase()),
      )

      if (isMainMenu) {
        const temporary = menuArray[countMainMenu]
        menuArray[countMainMenu] = menuArray[i]
        menuArray[i] = temporary
        countMainMenu += 1
      }
    }

    return menuArray
  } catch (e) {
    console.log(e)
    return null
  }
}

export const getFormattedMetro = (metroList) => {
  try {
    const prepareMetroColors = (colorValue) => {
      const defaultMetroColor = '262626'
      return Array.isArray(colorValue)
        ? [...colorValue.map((thisColor) => `#${thisColor || defaultMetroColor}`)]
        : [`#${colorValue || defaultMetroColor}`]
    }

    if (metroList?.length) {
      return metroList.map((item) => {
        const { hex_color, ...other } = item
        return { ...other, color: prepareMetroColors(hex_color) }
      })
    } else return null
  } catch (e) {
    console.log(e)
    return null
  }
}

export const getStatus = (data) => {
  try {
    const names = {
      0: 'Открыт',
      1: 'Ресторан временно закрыт',
      2: 'Ресторан закрыт',
      10: 'Ресторан удален в Афише',
    }

    const restaurantIsOpenSoon = (open) => {
      const openDate = new Date(open)
      const currentDate = new Date(Date.now())
      const nextDayOpenDate = new Date(openDate)
      nextDayOpenDate.setDate(openDate.getDate() + 1)

      return openDate > currentDate && nextDayOpenDate > currentDate
    }

    const statusOpenDate = restaurantIsOpenSoon(data.open_date)

    if (statusOpenDate) {
      return {
        code: 3,
        name: `Открытие
            ${new Date(data.open_date).toLocaleDateString('Ru', {
              month: 'long',
              day: 'numeric',
            })}
            ${new Date(data.open_date).getFullYear()}`,
        open: false,
        temporarily: false,
        openingSoon: true,
      }
    }

    return {
      code: data.state,
      name: names[data.state],
      open: [0, 1].includes(data.state),
      temporarily: data.state === 1,
      openingSoon: false,
    }
  } catch (e) {
    console.log(e)
    return null
  }
}

/**
 * @function mappingScheduleWeek
 *    Возвращает объект с расписанием работы в дни работы ресторана
 *    Ключи - дни недели
 *    Значение - расписание работы в указанный день
 */
export const mappingScheduleWeek = (workingHours) => {
  const result = {}
  const week = generateScheduleWeek(workingHours)
  Object.keys(week).forEach((key) => {
    result[key] = week[key]
  })
  return result
}

/**
 * @function generateScheduleWeek
 *    Возвращает объект с 7 элементами
 *    Ключи - дни недели
 *    Значение - расписание работы в указанный день
 */
const generateScheduleWeek = (workingHours, slotDuration: 5 | 10 | 20 | 30 | 60 = 30): TypeMappingScheduleWeek => {
  const weekDayTemplate = ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота']
  const result = {}

  weekDayTemplate.forEach((day, index) => {
    const sourceDay = workingHours.find((el) => el.day === day)
    const cycleResult = {
      weekdayIndex: index,
      weekday: day,
      slotDuration: null,
      open: null,
      close: null,
      slots: null,
    }

    if (sourceDay) {
      const open = sourceDay.time_start?.slice(0, 5) || null
      // null может прийти с бека как обозначение о работе ресторана до последнего клиента
      // в этом случае работа ресторана условно заканчивается в 22:00
      const close = sourceDay.time_end ? sourceDay.time_end.slice(0, 5) : '22:00'

      cycleResult.open = sourceDay.time_start?.slice(0, 5) || null
      cycleResult.close = sourceDay.time_end ? sourceDay.time_end.slice(0, 5) : '22:00'
      cycleResult.slots = generateSlotsByScheduleDay(open, close, slotDuration)
      cycleResult.slotDuration = cycleResult.slots?.length ? slotDuration : null
    }

    result[index] = cycleResult
  })
  return result
}

/**
 * @function generateSlotsByScheduleDay
 * Генерация слотов на основе времени открытия и закрытия.
 * формат аргументов open,close = 'ЧЧ:ММ', gapMinutes = MM
 * */
const generateSlotsByScheduleDay = (open, close = '22:00', gapMinutes = 30): TypeMappingScheduleSlot[] => {
  try {
    if (!open || !close) return null
    const gapMillisecond = gapMinutes * 60 * 1000

    const [openingHour, openingMinutes] = open.split(':').map((s) => Number(s))
    const [closingHour, closingMinutes] = close.split(':').map((s) => Number(s))

    let openingDate = new Date(null, null, null, openingHour, openingMinutes)
    const closingDate = new Date(null, null, null, closingHour, closingMinutes)

    if (closingDate.getTime() <= openingDate.getTime()) {
      closingDate.setDate(closingDate.getDate() + 1)
    }

    // Корректируем время открытия (если время открытия не стандартное)
    // например если время открытия 8:25, то будет первый слот будет назначен на 18:30 при gapMinutes = 30
    Array.from(Array(60 / gapMinutes).keys()).forEach((el, index) => {
      // Если минуты открытия попадают в слот, то ничего не делаем (напр: для слота в 30 минут это 12:00 и 12:30)
      if (openingDate.getMinutes() % gapMinutes === 0) return

      const comparisonDate = new Date(openingDate)
      comparisonDate.setMinutes(gapMinutes * (index + 1))

      // Если минуты открытия не попадают в слот, то проверяем является ли ближайший слот поля comparisonDate больше чем openingDate
      // Если да, то comparisonDate становится датой для первой брони
      if (comparisonDate > openingDate) openingDate = comparisonDate
    })

    const result = []
    const temporaryDate = new Date(openingDate)
    // проверка прошло ли 24 для избежания ошибок и правильной обработки круглосуточных расписаний
    const hasBeen24Hours = () => temporaryDate.getTime() >= openingDate.getTime() + 1000 * 60 * 60 * 24

    while (!hasBeen24Hours() && closingDate.getTime() >= temporaryDate.getTime() + gapMillisecond) {
      const setHours = `00${temporaryDate.getHours()}`.slice(-2)
      const setMinutes = `00${temporaryDate.getMinutes()}`.slice(-2)

      // Если время слота переходит на след день (напр: 00:30), то указываем это флагом isNextDay
      const isNextDay = Number(temporaryDate.getHours()) < Number(openingDate.getHours())

      result.push({ time: `${setHours}:${setMinutes}`, isNextDay: isNextDay })
      temporaryDate.setMinutes(temporaryDate.getMinutes() + gapMinutes)
      // На всякий случай)
      if (result.length > 200) break
    }

    return result
  } catch (e) {
    console.log(e)
    return null
  }
}
