import React, { FC } from 'react'

import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import ContentLoader from 'react-content-loader'
import {
  SKELETON_FOREGROUND_COLOR,
  SKELETON_MAIN_COLOR,
  SKELETON_SPEED,
  SKELETON_STYLES,
} from '@shared/consts/skeleton'
import s from '@pages/Listing/ui/ListingDescriptionPage/ListingDescriptionPage.module.sass'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
// ЗАГЛУШКА/ПРИМЕР КОМПОНЕНТА (УДАЛИТЬ КОММЕНТАРИЙ ПОСЛЕ ЗАВЕРШЕНИЯ РАБОТЫ)
const Skeleton: FC = () => {
  const { isMobile, isTablet } = useClientResponsive()

  return (
    <ErrorBoundary>
      <div className={s['main-title-skeleton']}>
        <ContentLoader
          speed={SKELETON_SPEED}
          backgroundColor={SKELETON_MAIN_COLOR}
          foregroundColor={SKELETON_FOREGROUND_COLOR}
          style={SKELETON_STYLES}
          className={s['main-title-skeleton__svg']}>
          <rect rx='12' ry='12' className={s['main-title-skeleton__rect--1']} />
          {isMobile && <rect rx='12' ry='12' className={s['main-title-skeleton__rect--2']} />}
        </ContentLoader>
      </div>
      <div className={s['main-description-skeleton']}>
        <ContentLoader
          speed={SKELETON_SPEED}
          backgroundColor={SKELETON_MAIN_COLOR}
          foregroundColor={SKELETON_FOREGROUND_COLOR}
          style={SKELETON_STYLES}
          className={s['main-description-skeleton__svg']}>
          <rect rx='10' ry='10' className={s['main-description-skeleton__rect--1']} />
          <rect rx='10' ry='10' className={s['main-description-skeleton__rect--2']} />
          <rect rx='10' ry='10' className={s['main-description-skeleton__rect--3']} />
          {isMobile && <rect rx='10' ry='10' className={s['main-description-skeleton__rect--4']} />}
        </ContentLoader>
      </div>
    </ErrorBoundary>
  )
}

export default Skeleton
