import React, { FC } from 'react'
import s from './BookingInput.module.sass'
import l10n from '@widgets/Restaurant/Booking/lib/booking-localization'
import { ErrorBoundary } from '../../../../../../6_shared/lib/components/ErrorBoundary'

interface Props {
  field: {
    id: string
    value: string
    valid: boolean
  }
  placeholder: string
  maxLength?: number
  isErrorsVisible: boolean
}

const BookingInput: FC<
  Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>
> = ({ isErrorsVisible, field, placeholder, maxLength = 200, ...props }) => {
  const isFieldErrorVisible = isErrorsVisible && !field.valid

  return (
    <ErrorBoundary>
      <div className={s.wrapper}>
        <div className={`${s.block} ${isFieldErrorVisible ? s.error : ''}`}>
          <textarea
            name={field.id}
            id={`input_${field.id}`}
            className={`${s.input} ${field.value ? s.focused : ''}`}
            style={{ backgroundColor: '#f6f6f6' }}
            inputMode='text'
            autoComplete='off'
            autoCorrect='off'
            spellCheck={false}
            placeholder={placeholder}
            maxLength={maxLength}
            rows={1}
            value={field.value}
            data-is-valid={field.valid}
            data-is-valid-text={isFieldErrorVisible}
            {...props}
          />

          <label className={s.label} htmlFor={`input_${field.id}`}>
            {placeholder}
          </label>
        </div>

        {!field.valid && isErrorsVisible && <span className={s.error}>{l10n('guest_name_validation')}</span>}
      </div>
    </ErrorBoundary>
  )
}

export default BookingInput
