import React, { FC } from 'react'

import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import ContentLoader from 'react-content-loader'
import {
  SKELETON_FOREGROUND_COLOR,
  SKELETON_MAIN_COLOR,
  SKELETON_SPEED,
  SKELETON_STYLES,
} from '@shared/consts/skeleton'
import { SectionTitleSkeleton } from '@shared/ui/Layout/SectionTitle'

const Skeleton: FC = () => {
  return (
    <ErrorBoundary>
      <div>
        <SectionTitleSkeleton />
        <ContentLoader
          speed={SKELETON_SPEED}
          backgroundColor={SKELETON_MAIN_COLOR}
          foregroundColor={SKELETON_FOREGROUND_COLOR}
          style={SKELETON_STYLES}
          width={'100%'}
          height={'135px'}>
          <rect x='0' y='0' rx='16' ry='16' width='100%' height='135px' />
        </ContentLoader>
      </div>
    </ErrorBoundary>
  )
}

export default Skeleton
