import React, { FC } from 'react'

import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import ContentLoader from 'react-content-loader'
import {
  SKELETON_FOREGROUND_COLOR,
  SKELETON_MAIN_COLOR,
  SKELETON_SPEED,
  SKELETON_STYLES,
} from '@shared/consts/skeleton'
import { useCurrentSize } from '@shared/lib/helpers/classes'
import s from '@shared/ui/Media/Avatar/Avatar.module.sass'

interface Props {
  sizes: string
  className?: string
}

const Skeleton: FC<Props> = ({ sizes, className = '' }) => {
  const [size] = useCurrentSize(sizes)

  return (
    <ErrorBoundary>
      <ContentLoader
        speed={SKELETON_SPEED}
        backgroundColor={SKELETON_MAIN_COLOR}
        foregroundColor={SKELETON_FOREGROUND_COLOR}
        style={SKELETON_STYLES}
        className={`${s.avatar} ${className}`}
        data-size={size}>
        <rect x='0' y='0' rx='34' ry='34' width='100%' height='100%' />
      </ContentLoader>
    </ErrorBoundary>
  )
}

export default Skeleton
