import * as React from 'react'
import s from './TooltipContent.module.sass'
import { useTooltipContext } from '@shared/ui/Overlays/Tooltip/hooks'
import { FloatingPortal, useMergeRefs, useTransitionStatus } from '@floating-ui/react'

const TooltipContent = React.forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(function TooltipContent(
  { style, className = '', ...props },
  propRef,
) {
  const { context: floatingContext, ...context } = useTooltipContext()
  const ref = useMergeRefs([context.refs.setFloating, propRef])
  // задержка для переходов стилей CSS
  const { isMounted, status } = useTransitionStatus(floatingContext, { duration: 200 })

  if (!context.open && !isMounted) return null

  return (
    <FloatingPortal>
      <div
        ref={ref}
        style={{
          ...context.floatingStyles,
          ...style,
        }}
        className={s.wrapper}
        data-status={status}
        {...context.getFloatingProps(props)}>
        <div className={`${s.tooltip} ${className}`} data-status={status} style={style}>
          {props.children}
        </div>
      </div>
    </FloatingPortal>
  )
})

export default TooltipContent
