import React, { FC } from 'react'
import s from './AdvertSidebarSticky.module.sass'
import { AdvertTemplate } from '@shared/ui/Layout/AdvertTemplate'

interface Props {
  children: React.ReactNode
}

const AdvertSidebarSticky: FC<Props> = ({ children }) => {
  return (
    <div className={s.wrapper}>
      <div className={s.sticky}>{children}</div>
    </div>
  )
}

export default AdvertSidebarSticky
