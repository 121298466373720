import { Dispatch } from 'react'
import { AnyAction } from '@reduxjs/toolkit'
import { addDishSection, setRestaurant } from '@pages/OwnerPersonalPage/model/OwnerPostsModel/reducer'
import { loadRestWithPosts, postUpdate } from '@pages/OwnerPersonalPage/model/OwnerPostsModel/createAsyncThunk'
import { globalStore } from '@app/model/store'
import { addTemporaryNotice } from '@widgets/Common/Notices'

export const initPostsPage = (restaurant: { id: number; name: string }) => async (dispatch: Dispatch<AnyAction>) => {
  dispatch(setRestaurant(restaurant))
  // @ts-ignore
  await dispatch(loadRestWithPosts())
}

export const addDishSectionAC = () => async (dispatch: Dispatch<AnyAction>) => {
  const statePosts = globalStore.getState().pages.owner_personal.posts
  // узнаем какая форма открыта сейчас (создание или редактирование)
  const typeForm = statePosts.forms.selectedForm

  // Если элементов уже 10, то больше не добавляем
  // @ts-ignore
  const currentDishes = statePosts.forms[typeForm].categories.menu.fields.dishes
  if (currentDishes.length >= 3) return

  // Если поля последнего элемента не заполнены, то не даем создать новый
  const allFieldsIsValid = Object.values(currentDishes[currentDishes.length - 1]).every((el) => {
    const valueIsObject = typeof el === 'object' && !Array.isArray(el) && el !== null
    // @ts-ignore
    if (valueIsObject && !el.value) return false
    return true
  })

  // @ts-ignore
  allFieldsIsValid ? dispatch(addDishSection()) : dispatch(addTemporaryNotice('Поля у предыдущего блюда не заполнены'))
}

export const postCreate = () => async (dispatch: Dispatch<AnyAction>) => {
  // @ts-ignore
  await dispatch(postUpdate())
}
export const postEdit = () => async (dispatch: Dispatch<AnyAction>) => {
  // @ts-ignore
  await dispatch(postUpdate())
}
