import s from './RestSelectionItem.module.sass'

import React, { FC } from 'react'
import Link from 'next/link'

import { Text } from '@shared/ui/Typography/Text'

interface Props {
  name: string
  address: string
  slug: string
}

const RestSelectionItem: FC<Props> = ({ name, address, slug }) => {
  return (
    <Link className={s.link} href={slug}>
      <Text sizes={'ML'} className={s.name}>
        {name}
      </Text>
      <Text sizes={'S'} className={s.address}>
        {address}
      </Text>
    </Link>
  )
}

export default RestSelectionItem
