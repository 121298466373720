/**
 * @fsd_page OwnerPersonalPage - ЛК, Личный кабинет ресторатора
 *   - ЛК бронирования / owner_booking
 *   - ЛК с рекламой / owner_marketing
 */

import s from './OwnerPersonalPage.module.sass'

import React, { useState, useEffect } from 'react'

import { useAppDispatch, useAppSelector } from '@app/model/store'

import { useHash } from '@pages/OwnerPersonalPage/hooks'
import { getTabFromHash } from '@pages/OwnerPersonalPage/helpers'

import { OwnerAccessRestricted } from '@pages/OwnerPersonalPage/ui/components/OwnerAccessRestricted'
import { OwnerAsideNav } from '@pages/OwnerPersonalPage/ui/components/OwnerAsideNav'
import { OwnerBookingSubpage } from '@pages/OwnerPersonalPage/ui/components/OwnerBookingSubpage'
import { OwnerHomePage } from '@pages/OwnerPersonalPage/ui/pages/OwnerHomePage'
import { OwnerPostsPage } from '@pages/OwnerPersonalPage/ui/pages/OwnerPostsPage'
import { OwnerRestCardPage } from '@pages/OwnerPersonalPage/ui/pages/OwnerRestCardPage'
import { OwnerReviewsPage } from '@pages/OwnerPersonalPage/ui/pages/OwnerReviewsPage'

import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { Button } from '@shared/ui/Actions/Button'
import { OwnerEditPostForm } from '@pages/OwnerPersonalPage/ui/components/OwnerPostForms/OwnerEditPostForm'
import { OwnerCreatePostForm } from '@pages/OwnerPersonalPage/ui/components/OwnerPostForms/OwnerCreatePostForm'
import { OwnerMobileWarning } from '@pages/OwnerPersonalPage/ui/components/OwnerMobileWarning'

interface OwnerPersonalPageProps {
  slug: string
  restId: string
  currentPage: string
  data: any
  global_data: any
}

const THE_TABS = [
  {
    label: 'Главная',
    icon: 'Home',
    content: 'HomeContent',
    has_side_button: false,
    has_status: false,
    status_count: null,
  },
  {
    label: 'Объявления',
    icon: 'Megaphone',
    content: 'MegaphoneContent',
    has_side_button: true,
    has_status: false,
    status_count: null,
  },
  {
    label: 'Карточка ресторана',
    icon: 'Restaurant',
    content: 'RestaurantContent',
    has_side_button: false,
    has_status: false,
    status_count: null,
  },
  {
    label: 'Отзывы',
    icon: 'Chatbubbles',
    content: 'ChatbubblesContent',
    has_side_button: false,
    has_status: true,
    status_count: null,
  },
]

const OwnerPersonalPage = (props: OwnerPersonalPageProps) => {
  const store = useAppSelector((state) => state)

  const currentPageComponent = props.currentPage
  const restId = props.data?.id
  const restSlugId = props.data?.url?.slug

  const userStore = store.global.user || null

  const isUserStoreLoaded = store.global.user.data
  const isGlobalStoreLoaded = store.global.storeLoaded
  const isPersonalPageReady = !!isUserStoreLoaded && !!isGlobalStoreLoaded

  const hasAccess = {
    booking: userStore?.data?.owner_perms?.has_booking_access || false, // ? TS: true | false
    description: userStore?.data?.owner_perms?.has_description_access || false, // ? TS: true | false
    special: userStore?.data?.owner_perms?.has_special_access || false, // ? TS: true | false
  }
  // const hasAnyAccess = hasAccess.booking || hasAccess.special || hasAccess.description
  const hasAccessMarketing = hasAccess.description || hasAccess.special
  const hasAccessBooking = hasAccess.booking || hasAccess.special

  const [hash, setHash] = useHash()

  useEffect(() => {
    // on load: use hash by default
    if (hash === '' && currentPageComponent === 'owner_marketing') {
      // @ts-ignore
      setHash(`#tab=home`)
    }
  }, [])

  const defaultTab = getTabFromHash(hash)
  const [activeTab, setActiveTab] = useState(defaultTab)

  const handleActiveTab = (tabName, ev) => {
    // 1. update hash
    //    - определяет есть ли хеш в урле
    //    - если есть, то добавляет к хешу один из четырех выбранных табов
    const hashName = tabName.indexOf('#') >= -1 ? tabName.split('#').pop().split('Content')[0].toLowerCase() : tabName
    // @ts-ignore
    setHash(`tab=${hashName}`)

    // 2. update tab state
    setActiveTab(tabName)
  }

  const handleInfoButton = (ev) => {
    const landingUrl = '/msk/restaurants/restorator/'
    const target = 'https://afisha.ru' + landingUrl
    window.location.href = target
  }

  if (store.global.client.responsive.isMobile) return <OwnerMobileWarning />

  return (
    <ErrorBoundary>
      {isPersonalPageReady && (
        <>
          {currentPageComponent === 'owner_marketing' && (
            <>
              <div className={s['page-wrap']} data-rest-id={restId}>
                {hasAccessMarketing && (
                  <OwnerAsideNav tabs={THE_TABS} activeTab={activeTab} handleActiveTab={handleActiveTab} />
                )}

                {hasAccessMarketing && (
                  <main className={s['page-marketing']}>
                    <h1 className='visually-hidden'>
                      Личный Кабинет Ресторатора / {restSlugId} / ({activeTab})
                    </h1>

                    <>
                      {activeTab === 'HomeContent' && <OwnerHomePage {...props} />}
                      {activeTab === 'MegaphoneContent' && <OwnerPostsPage {...props} />}
                      {activeTab === 'RestaurantContent' && <OwnerRestCardPage {...props} />}
                      {activeTab === 'ChatbubblesContent' && <OwnerReviewsPage {...props} />}
                    </>
                  </main>
                )}

                {!hasAccessMarketing && (
                  <>
                    <OwnerAccessRestricted handleInfoButton={handleInfoButton} />
                  </>
                )}
              </div>

              <OwnerEditPostForm />

              <OwnerCreatePostForm />
            </>
          )}

          {/* TODO: owner_booking делаем после полной реализации owner_marketing */}
          {currentPageComponent === 'owner_booking' && (
            <div className={s['page-wrap']} data-rest-id={restId}>
              {hasAccessBooking && (
                <main className={s['page-booking']}>
                  <OwnerBookingSubpage {...props} />
                </main>
              )}
            </div>
          )}
        </>
      )}
    </ErrorBoundary>
  )
}

export default OwnerPersonalPage
