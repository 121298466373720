import { sendClickHouseAnalytic } from '@shared/api/analytics/template'
import { PickAnalytic } from '@shared/api/analytics/types/types'
import { TypeMappingSelectionOutput } from '@shared/api/middleware/mappingAPI/selection/type'

const getDefaultData = (selection: TypeMappingSelectionOutput): PickAnalytic<'object_id'> => {
  const result: PickAnalytic<'object_id'> = {
    object_id: selection.id,
  }

  return result
}

export const EGGReviews = {
  send_review_message_click: () =>
    sendClickHouseAnalytic({
      event_name: 'element_click', // basic
      element_type: 'add_review',
      section_name: 'reviews',
    }),

  send_rating_click: (props: PickAnalytic<'rating_action'>) =>
    sendClickHouseAnalytic({
      event_name: 'element_click', // basic
      element_type: 'add_rating',
      section_name: 'reviews',
      rating_action: props.rating_action,
    }),

  delete_rating_click: (props: PickAnalytic<'rating_action'>) =>
    sendClickHouseAnalytic({
      event_name: 'element_click', // basic
      element_type: 'remove_rating',
      section_name: 'reviews',
      rating_action: props.rating_action,
    }),

  filter_reviews_click: (props: PickAnalytic<'filter_apply_value'>) =>
    sendClickHouseAnalytic({
      event_name: 'element_click', // basic
      element_type: 'filter',
      section_name: 'reviews',
      filter_apply_type: 'sort',
      filter_apply_value: props.filter_apply_value,
    }),

  review_view: (props: PickAnalytic<'section_index'>) =>
    sendClickHouseAnalytic({
      event_name: 'element_view',
      element_type: 'review',
      section_name: 'reviews',
      section_index: props.section_index,
    }),

  review_click: (props: PickAnalytic<'section_index'>) =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      element_type: 'review',
      section_name: 'reviews',
      section_index: props.section_index,
    }),

  review_disclose_click: (props: PickAnalytic<'section_index'>) =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      element_type: 'review',
      section_name: 'reviews',
      section_index: props.section_index,
    }),

  reviews_load_more_click: () =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      element_type: 'more',
      section_name: 'reviews',
    }),
}
