import React, { FC, useState } from 'react'
import s from './RestaurantSpecialOffer.module.sass'
import { Text } from '@shared/ui/Typography/Text'
import { CardService } from '@shared/ui/Layout/CardService'
import { Title } from '@shared/ui/Typography/Title'
import { Modal } from '@shared/ui/Overlays/Modal'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import Image from 'next/image'
import { getPlaceholderImage } from '@shared/lib/helpers/getPlaceholderImage'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { EGG } from '@shared/api/analytics'
import RestaurantSpecialOfferCard from './ui/RestaurantSpecialOfferCard/RestaurantSpecialOfferCard'
import { RestaurantSpecialOfferPopup } from '@widgets/Restaurant/RestaurantSpecialOffer/ui/RestaurantSpecialOfferPopup'

interface Props {
  title: string
  description: string
  img: string
  type_and_date: string
}

const RestaurantSpecialOffer: FC<Props> = ({ title, description, img, type_and_date }) => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const { isMobile } = useClientResponsive()

  const toggleVisibleModal = () => {
    !isOpenModal && EGG.pages.RestItem.special_offer_click()
    setIsOpenModal(!isOpenModal)
  }

  return (
    <ErrorBoundary>
      {isOpenModal && (
        <RestaurantSpecialOfferPopup
          title={title}
          type_and_date={type_and_date}
          description={description}
          img={img}
          handleClose={toggleVisibleModal}
        />
      )}

      <div className={s.wrapper}>
        {isMobile && (
          <Title tag={'h2'} sizes={'h4'}>
            Спецпредложение
          </Title>
        )}

        <RestaurantSpecialOfferCard
          title={title}
          type_and_date={type_and_date}
          description={description}
          img={img}
          onClick={toggleVisibleModal}
        />
      </div>
    </ErrorBoundary>
  )
}

export default RestaurantSpecialOffer
