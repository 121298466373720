// TODO: Логику форматирования для r_read в идеале перенести в файл src/6_shared/api/middleware/mappingAPI/index.ts
export const getFormattedPosts = (posts) => {
  const mappingType = {
    specialoffer: 'special',
    newsoffer: 'news',
    menuoffer: 'menu',
  }

  const result = []
  posts.forEach((post) => {
    if (!post.published || post.moderation === 'declined') return

    if (post.type === 'menuoffer') {
      result.push({
        id: post.id,
        type: mappingType[post.type],
        publicDate: post.published,
        modifiedDate: post.modified,
        status: post.status,
        moderation: post.moderation,
        dishes: post.items?.map((dish) => ({
          id: dish.id,
          title: dish.title,
          description: dish.description,
          price: dish.price,
          preview: dish.photo,
        })),
      })
    } else if (post.type === 'specialoffer' || post.type === 'newsoffer') {
      result.push({
        id: post.id,
        type: mappingType[post.type],
        title: post.title,
        publicDate: post.published,
        modifiedDate: post.modified,
        status: post.status,
        moderation: post.moderation,
        description: post.text,
        preview: post.photo,
      })
    }
  })

  return result
}

export const getPostsByCategories = (posts) => ({
  publicPosts: posts.filter((post) => post.status === 'published' && post.moderation !== 'declined'),
  oldPosts: posts.filter((post) => post.status === 'draft' && post.moderation !== 'declined' && !!post.title),
})
