import React, { FC } from 'react'

import l10n from '../../../lib/booking-localization'
import IconClose from '@icons/icon--x-thin.svg'
import { closeBooking, setIsOpenScreenConfirm } from '@widgets/Restaurant/Booking/model/reduce'
import { useAppDispatch } from '@app/model/store'
import { BookingButton } from '@widgets/Restaurant/Booking/ui/components/BookingButton'
import s from './BookingScreenConfirm.module.sass'
import { IconButton } from '@shared/ui/Actions/IconButton'
import { Title } from '@shared/ui/Typography/Title'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'

const BookingScreenConfirm: FC = () => {
  const dispatch = useAppDispatch()

  const handleContinueModal = (state: boolean) => {
    dispatch(setIsOpenScreenConfirm(state))
  }
  const handleAbortModal = () => {
    dispatch(closeBooking(null))
  }

  return (
    <ErrorBoundary>
      <header className={s['header-button']}>
        <IconButton
          sizes={'L M'}
          mode={'white'}
          icon={IconClose}
          onClick={handleAbortModal}
          className={s['button-close']}
        />
      </header>
      <main className={s.wrapper}>
        <div className={s.content}>
          <Title sizes={'h3'} tag={'h3'} className={s.title}>
            {l10n('form_close_confirm_title')}
          </Title>

          <div className={s.buttons}>
            <BookingButton onClick={handleAbortModal} color={'white'}>
              {l10n('form_close_confirm_abort')}
            </BookingButton>

            <BookingButton onClick={() => handleContinueModal(false)}>
              {l10n('form_close_confirm_continue')}
            </BookingButton>
          </div>
        </div>
      </main>
    </ErrorBoundary>
  )
}

export default BookingScreenConfirm
