import React, { FC } from 'react'

import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { CoverSkeleton } from '@shared/ui/Layout/Cover'

interface Props {
  classNameContainer?: string
  classNameContent?: string
}

const Skeleton: FC<Props> = (props) => {
  return (
    <ErrorBoundary>
      <CoverSkeleton {...props} />
    </ErrorBoundary>
  )
}

export default Skeleton
