import React, { FC, ReactNode } from 'react'
import s from './PopupMenuItem.module.sass'
import { Text } from '@shared/ui/Typography/Text'
import { ErrorBoundary } from '../../../lib/components/ErrorBoundary'

interface Props {
  children: ReactNode
  className?: string
  icon?: string
}

const PopupMenuItem: FC<Props & React.ButtonHTMLAttributes<any>> = ({ children, className = '', icon, ...props }) => {
  const Icon = icon

  return (
    <ErrorBoundary>
      <button type='button' className={`${s.link} ${className}`} {...props}>
        <Text tag={'span'} sizes={'M'}>
          {children}
        </Text>
        {/*@ts-ignore*/}
        {icon && <Icon className={s.icon} />}
      </button>
    </ErrorBoundary>
  )
}

export default PopupMenuItem
