import React, { FC } from 'react'

import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import ContentLoader from 'react-content-loader'
import {
  SKELETON_FOREGROUND_COLOR,
  SKELETON_MAIN_COLOR,
  SKELETON_SPEED,
  SKELETON_STYLES,
} from '@shared/consts/skeleton'
import s from '@pages/NewsItem/ui/NewsAuthor/NewsAuthor.module.sass'
import { AvatarSkeleton } from '@shared/ui/Media/Avatar'

interface Props {
  className?: string
}

const Skeleton: FC<Props> = ({ className = '' }) => {
  return (
    <ErrorBoundary>
      <div className={`${s.author} ${className}`}>
        <AvatarSkeleton sizes={'XL'} className={s['author-avatar']} />

        <div className={s['author-text']}>
          <ContentLoader
            speed={SKELETON_SPEED}
            backgroundColor={SKELETON_MAIN_COLOR}
            foregroundColor={SKELETON_FOREGROUND_COLOR}
            style={SKELETON_STYLES}
            className={s['text-skeleton']}>
            <rect x='0' y='0' rx='0' ry='0' width='100%' height='100%' />
          </ContentLoader>
        </div>
      </div>
    </ErrorBoundary>
  )
}

export default Skeleton
