import React, { FC, useRef, useState } from 'react'
import s from './ReviewItem.module.sass'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { Modal } from '@shared/ui/Overlays/Modal'
import { ReviewContent } from '@entities/Restaurant/Reviews/ui/ReviewItem/ui/ReviewContent'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { TypeMappingReviewOutput } from '@shared/api/middleware/mappingAPI/review/type'
import { PickAnalytic } from '@shared/api/analytics/types/types'
import { useIntersectionAnalytic } from '@shared/lib/hooks/useIntersectionAnalytic'
import { EGG } from '@shared/api/analytics'

interface ReviewItemProps {
  data: TypeMappingReviewOutput
  mode?: 'card' | 'row'
  analytic?: PickAnalytic<'section_index'>
}

const ReviewItem: FC<ReviewItemProps> = ({ data, mode = 'row', analytic }) => {
  const [isOpenReviewMobileModal, setIsOpenReviewMobileModal] = useState(false)
  const { isMobile } = useClientResponsive()
  const elementRef = useRef()
  useIntersectionAnalytic(elementRef, () => {
    analytic && EGG.entity.reviews.review_view({ section_index: analytic.section_index })
  })

  return (
    <ErrorBoundary>
      <div data-mode={mode} ref={elementRef}>
        <div className={s.review}>
          <ReviewContent
            data={data}
            mode={mode}
            setOpenReviewMobileModal={setIsOpenReviewMobileModal}
            analytic={analytic}
          />
        </div>

        {isOpenReviewMobileModal && isMobile && (
          <Modal
            size={'M'}
            mobileMode={'dropdown'}
            title={'Отзыв'}
            handleClose={() => setIsOpenReviewMobileModal(false)}
            className={s.modal}>
            <div className={s['modal-content']}>
              <ReviewContent data={data} mode={mode} isModal={true} analytic={analytic} />
            </div>
          </Modal>
        )}
      </div>
    </ErrorBoundary>
  )
}

export default ReviewItem
