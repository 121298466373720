import React, { FC, memo, ReactNode, useEffect, useState } from 'react'
import s from './Favorite.module.sass'
import { middlewareAuthorized } from '@shared/lib/helpers/auth'
import { useAppDispatch, useAppSelector } from '@app/model/store'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { EGG } from '@shared/api/analytics'
import { TypeMappingRestOutput } from '@shared/api/middleware/mappingAPI/restaurant/type'
import { PickAnalytic } from '@shared/api/analytics/types/types'
import { addFavoriteObject, deleteFavoriteObject } from '@app/model/reduce'
import { API_GRAPH } from '@shared/api/api.gql'
import { FavoritePropsToStore, TypeCategoryFavoriteObject } from '@app/model/types'
import { TypeMappingSelectionOutput } from '@shared/api/middleware/mappingAPI/selection/type'
import { TypeMappingNewsOutput } from '@shared/api/middleware/mappingAPI/news/type'
import { addTemporaryNotice } from '@widgets/Common/Notices'

interface Props {
  category: TypeCategoryFavoriteObject
  id: number
  children: ReactNode
  analytic: {
    data: TypeMappingRestOutput | TypeMappingSelectionOutput | TypeMappingNewsOutput
    other: PickAnalytic<'section_name'>
  }
  className?: string
}

enum CategoryForRequest {
  'restaurant' = 'Restaurant',
  'selection' = 'Selection',
  'concert' = 'Concert',
  'news' = 'Article',
}

const FavoriteContainer: FC<Props> = ({ children, ...props }) => {
  if (props.category === 'selection' && !process.env.IS_SHOW_FAVORITE_BUTTON_SELECTION) return
  if (props.category === 'news' && !process.env.SHOW_FAVORITE_BUTTON_NEWS) return

  return <Favorite {...props}>{children}</Favorite>
}

const Favorite: FC<Props> = ({ category, id, analytic, children, className = '' }) => {
  const dispatch = useAppDispatch()
  const stateUser = useAppSelector((state) => state.global.user.data)
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    stateUser && setIsActive(id in stateUser.favorites_objects[FavoritePropsToStore[category]])
  }, [id, stateUser])

  const handleClick = (e) => {
    e.stopPropagation()
    e.preventDefault()

    const getAnalyticMethod = () => {
      switch (category) {
        case 'restaurant':
          return isActive ? EGG.entity.restaurant.click_remove_favorite : EGG.entity.restaurant.click_add_favorite
        case 'selection':
          return isActive ? EGG.entity.selection.click_remove_favorite : EGG.entity.selection.click_add_favorite
        case 'concert':
          return null
        case 'news':
          return isActive ? EGG.entity.news.click_remove_favorite : EGG.entity.news.click_add_favorite
        default:
          return null
      }
    }

    const analyticMethod = getAnalyticMethod()

    const requestMethod = isActive ? API_GRAPH.deleteFavorite : API_GRAPH.addFavorite

    middlewareAuthorized(() => {
      const actionMethod = isActive ? deleteFavoriteObject : addFavoriteObject
      const revertActionMethod = isActive ? addFavoriteObject : deleteFavoriteObject
      dispatch(actionMethod({ object: category, id: id }))

      requestMethod
        .load({
          subjectId: id,
          subjectTypeName: CategoryForRequest[category],
        })
        .then((res) => {
          // @ts-ignore
          dispatch(addTemporaryNotice(isActive ? 'Удалено из избранного' : 'Добавлено в избранное'))

          if (analyticMethod) {
            // @ts-ignore
            analyticMethod(analytic.data, analytic.other)
          }
        })
        .catch(() => {
          // @ts-ignore
          dispatch(addTemporaryNotice('Что-то пошло не так, попробуйте позже'))
          dispatch(revertActionMethod({ object: category, id: id }))
        })
    })
  }

  return (
    <ErrorBoundary>
      <div className={`${s.button} ${className}`} onClick={handleClick} data-active={isActive}>
        {children}
      </div>
    </ErrorBoundary>
  )
}

export default memo(FavoriteContainer)
