import React, { FC, ReactNode } from 'react'
import s from './ContainerGlobal.module.sass'
import { ErrorBoundary } from '../../../../lib/components/ErrorBoundary'

interface Props {
  children: ReactNode
  className?: string
}

const ContainerGlobal: FC<Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = ({
  children,
  className = '',
  ...props
}) => {
  return (
    <ErrorBoundary>
      <div className={`${s.container} ${className}`} {...props}>
        {children}
      </div>
    </ErrorBoundary>
  )
}

export default ContainerGlobal
