import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  TypeFilterList,
  TypeFilterOnce,
  TypeFiltersByKeyAll,
  TypeFiltersByKeyList,
  TypeInitialState,
  TypeTag,
} from '@features/Restaurant/RestaurantFilters/model/types'
import { PropsRestaurantListParams } from '@shared/api/types/apiPublicParameters'
import { TypeMappingPaginationOutput } from '@shared/api/middleware/mappingAPI/pagination/type'
import { TypeMappingRestOutput } from '@shared/api/middleware/mappingAPI/restaurant/type'
import { Tag } from '@shared/api/types/swaggerTypes'

const initialState: TypeInitialState = {
  ready: false,
  visible: false,
  loading: true,
  isPreloaderResults: false,

  restaurants: [],
  pagination: null,
  paramsRequest: null,

  output: {
    loading: false,
    slugs: [],
    urlQuery: '',
    restaurants: [],
    pagination: null,
    paramsRequest: null,
  },
  filters: {
    hasBooking: {
      visible: true,
      checked: false,
      slug: 'has_booking',
    },
    afishaSelected: {
      visible: true,
      checked: false,
      slug: 'has_selection',
    },
    openNow: {
      visible: true,
      checked: false,
      slug: 'is_open_now',
    },
    metro: {
      visible: true,
      preCheckedList: [],
      checkedList: [],
      list: [],
    },
    rating: {
      visible: true,
      preCheckedList: [],
      checkedList: [],
      list: [],
    },
    price: {
      visible: true,
      preCheckedList: [],
      checkedList: [],
      list: [],
    },
    kitchen: {
      visible: true,
      preCheckedList: [],
      checkedList: [],
      list: [],
    },
    establishment: {
      visible: true,
      preCheckedList: [],
      checkedList: [],
      list: [],
    },
    ownerOffer: {
      visible: false,
      checked: false,
      slug: 'owner_offer',
    },
    map: {
      visible: false,
      longitude: null,
      latitude: null,
    },
  },
}

const restaurantFilters = createSlice({
  name: 'restaurantFilters',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },

    setIsPreloaderResults(state, action: PayloadAction<boolean>) {
      state.isPreloaderResults = action.payload
    },

    setFilterListByType(
      state,
      action: PayloadAction<{
        filterType: TypeFiltersByKeyList
        list: Tag[]
      }>,
    ) {
      const { filterType, list } = action.payload
      const formattedList = list.map((tag) => ({
        id: tag.id,
        slug: tag.slug,
        name: tag.name,
        checked: false,
        disabled: false,
      }))

      state.filters[filterType].list = formattedList.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
        return 0
      })
    },

    setFilterVisibleByType(
      state,
      action: PayloadAction<{
        filterType: TypeFiltersByKeyAll
        visible: boolean
      }>,
    ) {
      const { filterType, visible } = action.payload
      state.filters[filterType].visible = visible
    },

    setCheckedFilterItem(
      state,
      action: PayloadAction<{
        filterType: TypeFiltersByKeyAll
        checked: boolean
        itemId?: number
      }>,
    ) {
      try {
        const { filterType, itemId, checked } = action.payload
        // @ts-ignore
        const filter: TypeFilterList | TypeFilterOnce = state.filters[filterType]

        if ('list' in filter && !!filter.list.length) {
          const findIndex = filter.list.findIndex((tag: TypeTag) => tag.id === itemId)
          const findTag = filter.list[findIndex]
          findTag.checked = checked

          // @ts-ignore
          if (checked) filter.preCheckedList.push(findTag)
          // @ts-ignore
          else filter.preCheckedList = filter.preCheckedList.filter((tag) => tag.id !== itemId)
        } else if ('checked' in filter) {
          filter.checked = checked
        }
      } catch (error) {
        console.error(error)
      }
    },

    applyPrecheckedListFilters(state) {
      const { establishment, metro, kitchen, rating, price } = state.filters
      const filtersWithLists = [establishment, metro, kitchen, rating, price]

      filtersWithLists.forEach((filter: TypeFilterList) => {
        filter.checkedList = filter.preCheckedList.map((tag) => tag)
      })
    },

    removePrecheckedListFilters(state) {
      const { establishment, metro, kitchen, rating, price } = state.filters
      const filtersWithLists = [establishment, metro, kitchen, rating, price]

      filtersWithLists.forEach((filter: TypeFilterList) => {
        filter.preCheckedList = filter.checkedList.map((tag) => tag)
        filter.list = filter.list.map((tag) => {
          const isChecked = filter.checkedList.some((tagChecked) => tagChecked.id === tag.id)
          return { ...tag, checked: isChecked }
        })
      })
    },

    initCheckedFiltersItems(state, action: PayloadAction<string[]>) {
      const { establishment, metro, kitchen, rating, price, afishaSelected, hasBooking, openNow, ownerOffer, map } =
        state.filters
      const filtersWithLists = [establishment, metro, kitchen, price]
      const filtersOnces = [afishaSelected, hasBooking, openNow, ownerOffer]

      if (!action.payload) return

      // сброс выбранных фильтров и тегов
      filtersOnces.forEach((filter) => {
        filter.checked = action.payload.some((tagPayload) => new RegExp(tagPayload, 'i').test(filter.slug))
      })
      filtersWithLists.forEach((filter: TypeFilterList) => {
        filter.checkedList = []
        filter.preCheckedList = []
        if (filter.list?.length) {
          filter.list = filter.list.map((tag: TypeTag) => {
            const isSelectedTag = action.payload.some((tagPayload) => new RegExp(tagPayload, 'i').test(tag.slug))

            if (isSelectedTag) {
              // @ts-ignore
              filter.checkedList.push({ ...tag, checked: isSelectedTag })
              // @ts-ignore
              filter.preCheckedList.push({ ...tag, checked: isSelectedTag })
            }
            return { ...tag, checked: isSelectedTag }
          })
        }
      })

      // Обработка slug рейтинга
      const ratingFrom = action.payload.find((el) => el.includes('rating_from'))
      const getNumberRatingFrom = Number(ratingFrom?.match(/\d+/gm))

      const ratingTo = action.payload.find((el) => el.includes('rating_to'))
      const getNumberRatingTo = Number(ratingTo?.match(/\d+/gm))

      rating.checkedList = []
      rating.preCheckedList = []

      if (ratingFrom && ratingTo) {
        rating.list.forEach((tag: TypeTag, index) => {
          const [stateRatingFrom, stateRatingTo] = tag.name.split('-')
          const checked = getNumberRatingFrom <= Number(stateRatingFrom) && getNumberRatingTo >= Number(stateRatingTo)

          if (checked) {
            rating.list[index].checked = checked
            // @ts-ignore
            rating.checkedList.push({ ...tag, checked: checked })
            // @ts-ignore
            rating.preCheckedList.push({ ...tag, checked: checked })
          }
        })
      }
    },

    resetFilter(state, action: PayloadAction<TypeFiltersByKeyAll>) {
      // @ts-ignore
      const filter: TypeFilterList | TypeFilterOnce = state.filters[action.payload]

      if ('list' in filter && !!filter.list.length) {
        filter.preCheckedList = []
        filter.checkedList = []
        filter.list = filter.list.map((tag) => ({ ...tag, checked: false }))
      } else if ('checked' in filter) {
        filter.checked = false
      }
    },

    setRestaurantsData(
      state,
      action: PayloadAction<{
        restaurants: TypeMappingRestOutput[] | []
        pagination: TypeMappingPaginationOutput | null
      }>,
    ) {
      const { restaurants, pagination } = action.payload
      state.restaurants = restaurants
      state.pagination = pagination
    },

    updateParamsRequest(state, action: PayloadAction<{ cityId: string }>) {
      const { establishment, metro, kitchen, rating, price } = state.filters
      const { afishaSelected, hasBooking, openNow, ownerOffer } = state.filters
      const filtersForTagsIn = [establishment, kitchen, price]
      // @ts-ignore
      const cityId = action.payload.cityId

      const params: PropsRestaurantListParams = { page: 1, page_size: 24, city: String(cityId) }

      filtersForTagsIn.forEach((filter) => {
        if (filter.preCheckedList.length) {
          filter.preCheckedList.forEach((tag: TypeTag) => {
            params.tags_in = !params.tags_in ? String(tag.id) : `${params.tags_in},${tag.id}`
          })
        }
      })

      metro.preCheckedList.forEach((tag: TypeTag) => {
        params.metro_in = !params.metro_in ? String(tag.id) : `${params.metro_in},${tag.id}`
      })

      // rating_from and rating_to
      const sortPrecheckedList: TypeTag[] | [] = [...rating.preCheckedList].sort((tagA, tagB) => tagA.id - tagB.id)
      const firstCheckedRating = sortPrecheckedList.find((tag) => tag.checked)
      // @ts-ignore
      const lastCheckedRating = sortPrecheckedList.findLast((tag) => tag.checked)
      if (firstCheckedRating) params.rating_from = Number(firstCheckedRating.name.split('-')[0])
      if (lastCheckedRating) params.rating_to = Number(lastCheckedRating.name.split('-')[1])

      if (openNow.checked) params.is_open_now = true
      if (hasBooking.checked) params.has_booking = true
      if (afishaSelected.checked) params.has_selection = true
      if (ownerOffer.checked) params.has_special = true

      state.paramsRequest = params
    },

    // сбрасываем текущие данные по ресторанам до последних отданных данных из output
    revertRestaurantData(state) {
      state.restaurants = state.output.restaurants
      state.pagination = state.output.pagination
      state.paramsRequest = state.output.paramsRequest
    },

    setOutputLoading(state, action: PayloadAction<boolean>) {
      state.output.loading = action.payload
    },

    setOutput(state, action: PayloadAction<{ slugs: string[]; urlQuery: string }>) {
      state.output.slugs = action.payload.slugs
      state.output.urlQuery = action.payload.urlQuery
      state.output.restaurants = state.restaurants
      state.output.pagination = state.pagination
      state.output.paramsRequest = state.paramsRequest
    },

    setVisible(state, action: PayloadAction<boolean>) {
      state.visible = action.payload
    },

    setReady(state, action: PayloadAction<boolean>) {
      state.ready = action.payload
    },

    // RESET
    resetStateFilters() {
      return initialState
    },
  },
})

export const {
  setReady,
  setVisible,

  setLoading,

  updateParamsRequest,
  setRestaurantsData,
  revertRestaurantData,

  setIsPreloaderResults,
  initCheckedFiltersItems,
  setFilterListByType,
  setFilterVisibleByType,
  setCheckedFilterItem,

  applyPrecheckedListFilters,
  removePrecheckedListFilters,

  setOutputLoading,
  setOutput,

  resetFilter,
  resetStateFilters,
} = restaurantFilters.actions

export default restaurantFilters.reducer
