// @ts-ignore

import s from './error.module.sass'

interface FieldForError {
  error: string
  settings: {
    showError: boolean
    [key: string]: any
  }
  [key: string]: any
}

// ! TODO: перенести стили sass и весь компонент из @shared/lib в @shared/ui
export const getErrorData = (field: FieldForError) => {
  const isError: boolean = !!field.error && field.settings.showError

  return {
    isError: isError,
    class: isError ? s.error : '',
  }
}
