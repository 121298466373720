import React, { FC } from 'react'

import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import ContentLoader from 'react-content-loader'
import {
  SKELETON_FOREGROUND_COLOR,
  SKELETON_MAIN_COLOR,
  SKELETON_SPEED,
  SKELETON_STYLES,
} from '@shared/consts/skeleton'
import s from '@widgets/Restaurant/RestaurantExtraInfo/RestaurantExtraInfo.module.sass'
import { SectionTitleSkeleton } from '@shared/ui/Layout/SectionTitle'
import { useKeenSlider } from 'keen-slider/react'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'

const Skeleton: FC = () => {
  const { isMobile } = useClientResponsive()

  const listCellSkeleton = Array.from(Array(8).keys())
  const listTagsSkeleton = Array.from(Array(4).keys())

  const [sliderRef] = useKeenSlider({
    mode: 'free',
    slides: {
      perView: 'auto',
      spacing: 6,
    },
    selector: `.${s.tag}`,
  })

  return (
    <ErrorBoundary>
      <div className={`${s.wrapper}`}>
        <SectionTitleSkeleton className={s.address} />

        <ul className={s['metro-list']}>
          <ContentLoader
            speed={SKELETON_SPEED}
            backgroundColor={SKELETON_MAIN_COLOR}
            foregroundColor={SKELETON_FOREGROUND_COLOR}
            style={SKELETON_STYLES}
            className={s['metro-skeleton']}>
            <rect x='0' y='0' rx='8' ry='8' width='100%' height='100%' />
          </ContentLoader>
        </ul>

        <div className={s['map-skeleton']} data-loaded-map={false}>
          <ContentLoader
            speed={SKELETON_SPEED}
            backgroundColor={SKELETON_MAIN_COLOR}
            foregroundColor={SKELETON_FOREGROUND_COLOR}
            style={SKELETON_STYLES}
            width='100%'
            height='100%'>
            <rect x='0' y='0' rx='24px' ry='24px' width='100%' height='100%' />
          </ContentLoader>
        </div>

        {isMobile ? (
          <div className={s['table-skeleton-mobile']}>
            <ContentLoader
              speed={SKELETON_SPEED}
              backgroundColor={SKELETON_MAIN_COLOR}
              foregroundColor={SKELETON_FOREGROUND_COLOR}
              style={SKELETON_STYLES}
              width='100%'
              height='208px'>
              <rect x='0' y='0' rx='8' ry='8' width='258px' height='18px' />
              <rect x='0' y='38px' rx='8' ry='8' width='210px' height='18px' />
              <rect x='0' y='76px' rx='8' ry='8' width='184px' height='18px' />
              <rect x='0' y='114px' rx='8' ry='8' width='300px' height='18px' />
              <rect x='0' y='152px' rx='8' ry='8' width='226px' height='18px' />
              <rect x='0' y='190px' rx='8' ry='8' width='202px' height='18px' />
            </ContentLoader>
          </div>
        ) : (
          <div className={s['table']}>
            {listCellSkeleton.map((el, idx) => (
              <ContentLoader
                speed={SKELETON_SPEED}
                backgroundColor={SKELETON_MAIN_COLOR}
                foregroundColor={SKELETON_FOREGROUND_COLOR}
                style={SKELETON_STYLES}
                width='200px'
                height='42px'>
                <rect x='0' y='0' rx='8' ry='8' width='97px' height='18px' />
                <rect x='0' y='24px' rx='8' ry='8' width='144px' height='18px' />
              </ContentLoader>
            ))}
          </div>
        )}

        <div className={s['tag-wrapper']}>
          <ul className={`keen-slider`} ref={sliderRef} key={'tags-slider-skeleton'}>
            {listTagsSkeleton.map((el, index) => {
              return (
                <div className={`${s.tag} ${s['tag-skeleton']}`} key={index}>
                  <ContentLoader
                    speed={SKELETON_SPEED}
                    backgroundColor={SKELETON_MAIN_COLOR}
                    foregroundColor={SKELETON_FOREGROUND_COLOR}
                    style={SKELETON_STYLES}
                    width='100%'
                    height='100%'>
                    <rect x='0' y='0' rx='18' ry='18' width='100%' height='100%' />
                  </ContentLoader>
                </div>
              )
            })}
          </ul>
        </div>
      </div>
    </ErrorBoundary>
  )
}

export default Skeleton
