import React, { FC } from 'react'
import s from './ReviewPagination.module.sass'

import { Pagination } from '@features/Common/Pagination'

import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'

interface Props {
  className: string
  currentPage: number | null
  pageCount: number | null
  handleLoadMore: () => void
  hasPagination: boolean
  analytic?: {
    section_name: string
  }
}

const ReviewPagination: FC<Props> = ({
  currentPage,
  pageCount,
  analytic = null,
  handleLoadMore,
  hasPagination,
  className = '',
}) => {
  return (
    <ErrorBoundary>
      {hasPagination && (
        <Pagination
          mode={'load-more'}
          currentPage={currentPage}
          pageCount={pageCount}
          handleLoadMore={handleLoadMore}
          analytic={analytic}
          className={`${s.pagination} ${className}`}
        />
      )}
    </ErrorBoundary>
  )
}

export default ReviewPagination
