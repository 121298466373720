import React, { FC, useCallback, useId, useRef, useState } from 'react'
import s from './ImageCropping.module.sass'

import { TypeRulesImageUpload } from '@widgets/Common/ImageCropping/ui/ImagePreview/types'
import { ImageCroppingPopup } from '@widgets/Common/ImageCropping/ui/ImageCroppingPopup'
import { ImagePreview } from '@widgets/Common/ImageCropping/ui/ImagePreview'

interface Props {
  aspect?: number // 16 / 9
  img?: string
  handleSaveImage: (src: string) => void
  rules?: TypeRulesImageUpload
}

const ImageCropping: FC<Props> = ({ img: imgProps, aspect: aspectProps, rules: rulesProps, handleSaveImage }) => {
  const idComponent = 'crop-' + useId()
  const [imgSrcSource, setImgSrcSource] = useState<string>(imgProps)
  const [imgSrcResult, setImgSrcResult] = useState<string>(imgProps)
  const [openedCropper, setOpenedCropper] = useState(false)
  const inputRef = useRef<HTMLInputElement>()

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      inputRef.current = e.target
      const reader = new FileReader()
      reader.onload = (e) => {
        setImgSrcSource(reader.result?.toString() || '')
        // setImgSrcResult(reader.result?.toString() || '')
        setOpenedCropper(true)
      }
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const handleCancelCropping = () => {
    setOpenedCropper(false)
    handleSaveImage && handleSaveImage(imgSrcResult)
  }

  const handleSaveCropping = (value) => {
    setImgSrcResult(() => value)
    setOpenedCropper(false)
    handleSaveImage && handleSaveImage(value)
  }

  const handleDeletePreview = () => {
    if (inputRef.current) inputRef.current.value = ''

    setImgSrcSource(() => null)
    setImgSrcResult(() => null)
    handleSaveImage && handleSaveImage(null)
  }

  const onChangeCrop = () => {
    setOpenedCropper(true)
  }

  return (
    <>
      <ImagePreview
        id={idComponent}
        image={imgSrcResult}
        handleSelectImage={onSelectFile}
        handleChangeCrop={onChangeCrop}
        handleDelete={handleDeletePreview}
        rules={rulesProps}
      />
      {openedCropper && (
        <ImageCroppingPopup
          image={imgSrcSource}
          handleCancel={handleCancelCropping}
          handleSave={handleSaveCropping}
          aspect={aspectProps}
        />
      )}
    </>
  )
}

export default ImageCropping
