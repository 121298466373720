import React, { FC } from 'react'
import s from './RestaurantMainCards.module.sass'

import { RestaurantMainCard } from '@widgets/Restaurant/RestaurantMainCards/ui/RestaurantMainCard'
import { TypeMappingRestOutput } from '@shared/api/middleware/mappingAPI/restaurant/type'
import { SectionMainCarousel } from '@shared/ui/Layout/SectionMainCarousel'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { MainCardSkeleton } from '@shared/ui/Layout/Cards/MainCard'
import { PickAnalytic } from '@shared/api/analytics/types/types'
import { RestaurantMainCardsSkeleton } from '@widgets/Restaurant/RestaurantMainCards/index'

interface Props {
  list: TypeMappingRestOutput[]
  analytic?: PickAnalytic<null, 'section_name' | 'section_index' | 'section_type'>
  skeleton?: boolean
}

const RestaurantMainCards: FC<Props> = ({ list: listRest, analytic, skeleton = false }) => {
  if (!skeleton && !listRest?.length) return

  return (
    <ErrorBoundary>
      {skeleton ? (
        <RestaurantMainCardsSkeleton />
      ) : (
        <SectionMainCarousel>
          {listRest.map((coverRestItem, index) => (
            <RestaurantMainCard
              data={coverRestItem}
              analytic={{ section_index: index, ...analytic }}
              key={coverRestItem.id}
            />
          ))}
        </SectionMainCarousel>
      )}
    </ErrorBoundary>
  )
}

export default RestaurantMainCards
