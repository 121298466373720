import React, { FC, HTMLProps } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import s from './MediaCardCompilation.module.sass'

import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { getPlaceholderImage } from '@shared/lib/helpers/getPlaceholderImage'
import { Rating } from '@shared/ui/Feedback/Rating'
import { Title } from '@shared/ui/Typography/Title'
import { useCurrentSize } from '@shared/lib/helpers/classes'

import IconLike from '@icons/icon-like.svg'
import IconSelectionChevronBig from '@icons/icon-selection-chevron--big.svg'
import IconSelectionChevronMedium from '@icons/icon-selection-chevron--medium.svg'
import { ChevronWithTooltip } from '@shared/ui/Feedback/ChevronWithTooltip'
import { TypeMappingNewsOutput } from '@shared/api/middleware/mappingAPI/news/type'
import { TypeMappingSelectionOutput } from '@shared/api/middleware/mappingAPI/selection/type'
import { PickAnalytic } from '@shared/api/analytics/types/types'
import { IconButton } from '@shared/ui/Actions/IconButton'
import { Favorite } from '@features/Favorite'

// D-desktop - M-mobile
// type size = 'D1x1 D2x1 M1x1'
interface Props {
  id: string | number
  title: string
  sizes: string
  img: {
    horizontal: string
    vertical: string
  }
  favorite: {
    category: 'news' | 'selection'
    analytic: {
      data: TypeMappingNewsOutput | TypeMappingSelectionOutput
      other: PickAnalytic<'section_name'>
    }
  }
  promo?: boolean
  chevron?: boolean
  link: string
  className?: string
}

const MediaCardCompilation: FC<Props & HTMLProps<HTMLDivElement>> = React.forwardRef(
  ({ id, sizes, favorite, className = '', title, img, promo = false, chevron = false, link, ...props }, ref) => {
    const [size] = useCurrentSize(sizes)

    const firstNumbers = parseInt(title)
    const firstNumbersIsTrue = !!firstNumbers && firstNumbers > 2
    // @ts-ignore
    const titleFormatted = firstNumbersIsTrue ? title.replace(firstNumbers, '') : title

    return (
      <ErrorBoundary>
        <div data-id={id} className={`${s.card} ${className}`} data-size={size} title={title} {...props} ref={ref}>
          <Link href={link} scroll={false}>
            {promo && <Rating id={s.promo} className={s.promo} mode={'fill'} isPromo sizes={'S'} />}

            {chevron && size === 'M1x1' && (
              <ChevronWithTooltip icon={IconSelectionChevronMedium} className={s['afisha-selection-chevron--medium']} />
            )}
            {chevron && size !== 'M1x1' && (
              <ChevronWithTooltip icon={IconSelectionChevronBig} className={s['afisha-selection-chevron--big']} />
            )}

            {!!firstNumbersIsTrue && <span className={s.numbers}>{firstNumbers}</span>}

            <Title tag={'h5'} sizes={'h4'} className={s.title}>
              {titleFormatted}
            </Title>

            <Favorite
              category={favorite.category}
              id={Number(id)}
              analytic={{ data: favorite.analytic.data, other: favorite.analytic.other }}
              className={s.favorite}>
              <IconButton sizes={'S'} mode={'white'} icon={IconLike} />
            </Favorite>

            <div className={s['image-wrapper']}>
              <div className={s['image-inner']}>
                <Image
                  layout={'fill'}
                  src={
                    size === 'D2x1' ? img.horizontal || getPlaceholderImage() : img.vertical || getPlaceholderImage()
                  }
                  alt='card-main'
                  className={s.image}
                />
              </div>
            </div>
          </Link>
        </div>
      </ErrorBoundary>
    )
  },
)

export default MediaCardCompilation
