/**
 * @function scrollToElem - TODO описание
 * @usage - TODO
 */

export const scrollToElem = (idElem) => {
  const elem = document.getElementById(idElem)

  window.scrollTo({
    top: elem?.getBoundingClientRect().top + window.scrollY - 40,
    behavior: 'smooth',
  })
}
