import { sendClickHouseAnalytic } from '@shared/api/analytics/template'
import { PickAnalytic } from '@shared/api/analytics/types/types'
import { TypeMappingSelectionOutput } from '@shared/api/middleware/mappingAPI/selection/type'

const getDefaultData = (selection: TypeMappingSelectionOutput): PickAnalytic<'object_id'> => {
  const result: PickAnalytic<'object_id'> = {
    object_id: selection.id,
  }

  return result
}

type TypeDefaultProps = PickAnalytic<'section_index' | 'section_name'>
export const EGGSelection = {
  card_click: (selection: TypeMappingSelectionOutput, props: TypeDefaultProps) =>
    sendClickHouseAnalytic({
      event_name: 'element_click', // basic
      object_type: 'selection',
      element_type: 'selection_card',
      ...getDefaultData(selection),
      ...props,
    }),

  card_view: (selection: TypeMappingSelectionOutput, props: TypeDefaultProps) =>
    sendClickHouseAnalytic({
      event_name: 'element_view', // basic
      object_type: 'selection',
      element_type: 'selection_card',
      ...getDefaultData(selection),
      ...props,
    }),

  click_add_favorite: (selection: TypeMappingSelectionOutput, props: TypeDefaultProps) =>
    sendClickHouseAnalytic({
      event_name: 'element_click', // basic
      object_type: 'selection',
      element_type: 'selection_card',
      ...getDefaultData(selection),
      ...props,
    }),

  click_remove_favorite: (selection: TypeMappingSelectionOutput, props: TypeDefaultProps) =>
    sendClickHouseAnalytic({
      event_name: 'element_click', // basic
      object_type: 'selection',
      element_type: 'selection_card',
      ...getDefaultData(selection),
      ...props,
    }),
}
