import React, { FC } from 'react'

import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import ContentLoader from 'react-content-loader'
import {
  SKELETON_FOREGROUND_COLOR,
  SKELETON_MAIN_COLOR,
  SKELETON_SPEED,
  SKELETON_STYLES,
} from '@shared/consts/skeleton'
import s from '@shared/ui/Layout/Cards/MediaCardCover/MediaCardCover.module.sass'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'

interface Props {
  className?: string
}

const Skeleton: FC<Props> = (props) => {
  const { isMobile } = useClientResponsive()

  return (
    <ErrorBoundary>
      <section className={s.wrap} data-is-mobile={isMobile} {...props}>
        <aside className={`${s.aside} ${s['aside--skeleton']}`} data-is-mobile={isMobile}>
          <ContentLoader
            speed={SKELETON_SPEED}
            backgroundColor={SKELETON_MAIN_COLOR}
            foregroundColor={SKELETON_FOREGROUND_COLOR}
            style={SKELETON_STYLES}
            width={'100%'}
            height={'100%'}>
            <rect x='0' y='0' rx='32' ry='32' width='100%' height='100%' />
          </ContentLoader>
        </aside>

        {!isMobile && (
          <main className={`${s.main} ${s['main--skeleton']}`}>
            <ContentLoader
              speed={SKELETON_SPEED}
              backgroundColor={SKELETON_MAIN_COLOR}
              foregroundColor={SKELETON_FOREGROUND_COLOR}
              style={SKELETON_STYLES}
              width={'100%'}
              height={'100%'}>
              <rect x='0' y='0' rx='32' ry='32' width='100%' height='100%' />
            </ContentLoader>
          </main>
        )}
      </section>
    </ErrorBoundary>
  )
}

export default Skeleton
