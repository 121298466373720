import React, { FC } from 'react'
import s from './ListingDescriptionPage.module.sass'
import { Title } from '@shared/ui/Typography/Title'
import { Text } from '@shared/ui/Typography/Text'
import { useAppSelector } from '@app/model/store'
import { ListingDescriptionPageSkeleton } from '@pages/Listing/ui/ListingDescriptionPage/index'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'

const ListingDescriptionPage: FC = () => {
  const globalStore = useAppSelector((store) => store)
  const { title, description } = globalStore.pages.listing

  if (!title && !description) return <ListingDescriptionPageSkeleton />

  return (
    <>
      {title && (
        <Title className={s['main-title']} tag='h1' sizes={'h1 h3'}>
          {title}
        </Title>
      )}

      {description && (
        <Text sizes={'M S'} className={s['main-description']}>
          {description}
        </Text>
      )}
    </>
  )
}

export default ListingDescriptionPage
