import Images from './ImagesQuickFilters'

type TypeTagsKey =
  | 'С событиями'
  | 'Бары'
  | 'Европейская кухня'
  | 'Грузинская кухня'
  | 'Итальянская кухня'
  | 'Рыба'
  | 'Завтраки'
  | 'Бизнес-ланч'
  | 'Пивные рестораны'
  | 'Стейки'
  | 'Паназиатская кухня'
  | 'Кофейни'
  | 'Узбекская кухня'
  | 'Русская кухня'
  | 'Татарская кухня'
  | 'Американская кухня'
  | 'Японская кухня'
  | 'Кондитерские'
  | 'Башкирская кухня'
  | 'У воды'
  | 'Бургеры'

const TAGS = {
  ['С событиями']: {
    name: 'С событиями',
    slug: 'concerts',
    image: { desktop: Images.DesktopImage.src, mobile: Images.MobileImage },
  },
  ['Бары']: {
    name: 'Бары',
    slug: 'bars',
    image: { desktop: Images.DesktopImage1, mobile: Images.MobileImage1 },
  },
  ['Европейская кухня']: {
    name: 'Европейская кухня',
    slug: 'european_cuisine',
    image: { desktop: Images.DesktopImage2, mobile: Images.MobileImage2 },
  },
  ['Грузинская кухня']: {
    name: 'Грузинская кухня',
    slug: 'georgian_cuisine',
    image: { desktop: Images.DesktopImage3, mobile: Images.MobileImage3 },
  },
  ['Итальянская кухня']: {
    name: 'Итальянская кухня',
    slug: 'italian_kitchen',
    image: { desktop: Images.DesktopImage4, mobile: Images.MobileImage4 },
  },
  ['Рыба']: {
    name: 'Рыба',
    slug: 'fish_restaurants',
    image: { desktop: Images.DesktopImage5, mobile: Images.MobileImage5 },
  },
  ['Завтраки']: {
    name: 'Завтраки',
    slug: 'breakfast',
    image: { desktop: Images.DesktopImage6, mobile: Images.MobileImage6 },
  },
  ['Бизнес-ланч']: {
    name: 'Бизнес-ланч',
    slug: 'buisness_lunch',
    image: { desktop: Images.DesktopImage7, mobile: Images.MobileImage7 },
  },
  ['Пивные рестораны']: {
    name: 'Пивные рестораны',
    slug: 'beer_restaurants',
    image: { desktop: Images.DesktopImage8, mobile: Images.MobileImage8 },
  },
  ['Стейки']: {
    name: 'Стейки',
    slug: 'steak',
    image: { desktop: Images.DesktopImage9, mobile: Images.MobileImage9 },
  },
  ['Паназиатская кухня']: {
    name: 'Паназиатская кухня',
    slug: 'panasiatisch_cuisine',
    image: { desktop: Images.DesktopImage10, mobile: Images.MobileImage10 },
  },
  ['Кофейни']: {
    name: 'Кофейни',
    slug: 'coffee_houses',
    image: { desktop: Images.DesktopImage11, mobile: Images.MobileImage11 },
  },
  ['Узбекская кухня']: {
    name: 'Узбекская кухня',
    slug: 'uzbek_cuisine',
    image: { desktop: Images.DesktopImage12, mobile: Images.MobileImage12 },
  },
  ['Русская кухня']: {
    name: 'Русская кухня',
    slug: 'russian_cuisine',
    image: { desktop: Images.DesktopImage13, mobile: Images.MobileImage13 },
  },
  ['Татарская кухня']: {
    name: 'Татарская кухня',
    slug: 'tartarian_cuisine',
    image: { desktop: Images.DesktopImage14, mobile: Images.MobileImage14 },
  },
  ['Американская кухня']: {
    name: 'Американская кухня',
    slug: 'american_cuisine',
    image: { desktop: Images.DesktopImage15, mobile: Images.MobileImage15 },
  },
  ['Японская кухня']: {
    name: 'Японская кухня',
    slug: 'japanese_cuisine',
    image: { desktop: Images.DesktopImage16, mobile: Images.MobileImage16 },
  },
  ['Кондитерские']: {
    name: 'Кондитерские',
    slug: 'confectionery',
    image: { desktop: Images.DesktopImage17, mobile: Images.MobileImage17 },
  },
  ['Башкирская кухня']: {
    name: 'Башкирская кухня',
    slug: 'bashkir_cuisine',
    image: { desktop: Images.DesktopImage18, mobile: Images.MobileImage18 },
  },
  ['У воды']: {
    name: 'У воды',
    slug: 'shore_restaurants',
    image: { desktop: Images.DesktopImage19, mobile: Images.MobileImage19 },
  },
  ['Бургеры']: {
    name: 'Бургеры',
    slug: 'burger',
    image: { desktop: Images.DesktopImage20, mobile: Images.MobileImage20 },
  },
}

const getTags = (keys: TypeTagsKey[]) => {
  return keys.map((key) => {
    if (TAGS[key]) return TAGS[key]
    else throw Error('Quick фильтр не найден по ключу')
  })
}

export const QUICK_FILTERS_BY_CITIES = {
  msk: getTags([
    // 'С событиями', // TODO: Включить после добавления в листинг фильтра по событям\концертам в ресторанах
    'Бары',
    'Европейская кухня',
    'Итальянская кухня',
    'Рыба',
    'Завтраки',
    'Бизнес-ланч',
    'Грузинская кухня',
    'Русская кухня',
    'Паназиатская кухня',
  ]),

  spb: getTags([
    // 'С событиями', // TODO: Включить после добавления в листинг фильтра по событям\концертам в ресторанах
    'Бары',
    'Итальянская кухня',
    'Европейская кухня',
    'Грузинская кухня',
    'Русская кухня',
    'Бизнес-ланч',
    'Завтраки',
    'Рыба',
    'Пивные рестораны',
  ]),

  sochi: getTags([
    'Европейская кухня',
    'Грузинская кухня',
    'Итальянская кухня',
    'Бизнес-ланч',
    'У воды',
    'Бары',
    'Рыба',
    'Русская кухня',
    'Завтраки',
    'Стейки',
  ]),

  vladivostok: getTags([
    'Грузинская кухня',
    'Европейская кухня',
    'Паназиатская кухня',
    'Рыба',
    'Бары',
    'Русская кухня',
    'Бизнес-ланч',
    'Японская кухня',
    'Кофейни',
    'Завтраки',
  ]),

  ufa: getTags([
    'Европейская кухня',
    'Бары',
    'Башкирская кухня',
    'Бизнес-ланч',
    'Русская кухня',
    'Итальянская кухня',
    'Завтраки',
    'Пивные рестораны',
    'Японская кухня',
    'Грузинская кухня',
  ]),

  samara: getTags([
    'Европейская кухня',
    'Русская кухня',
    'Бары',
    'Грузинская кухня',
    'Стейки',
    'Пивные рестораны',
    'Японская кухня',
    'Итальянская кухня',
    'Кондитерские',
    'Бизнес-ланч',
  ]),

  ['rostov-na-donu']: getTags([
    'Европейская кухня',
    'Русская кухня',
    'Грузинская кухня',
    'Итальянская кухня',
    'Бары',
    'Стейки',
    'Бизнес-ланч',
    'Японская кухня',
    'Пивные рестораны',
    'Завтраки',
  ]),

  perm: getTags([
    'Европейская кухня',
    'Русская кухня',
    'Бары',
    'Грузинская кухня',
    'Бизнес-ланч',
    'Пивные рестораны',
    'Итальянская кухня',
    'Завтраки',
    'Паназиатская кухня',
  ]),

  novosibirsk: getTags([
    'Европейская кухня',
    'Русская кухня',
    'Бары',
    'Грузинская кухня',
    'Кофейни',
    'Итальянская кухня',
    'Пивные рестораны',
    'Бизнес-ланч',
    'Кондитерские',
    'Японская кухня',
  ]),

  nnovgorod: getTags([
    'Бары',
    'Русская кухня',
    'Европейская кухня',
    'Итальянская кухня',
    'Грузинская кухня',
    'Бизнес-ланч',
    'Завтраки',
    'Кофейни',
    'Стейки',
    'Бургеры',
  ]),

  krasnoyarsk: getTags([
    'Европейская кухня',
    'Русская кухня',
    'Бары',
    'Грузинская кухня',
    'Итальянская кухня',
    'Бизнес-ланч',
    'Паназиатская кухня',
    'Стейки',
    'Завтраки',
    'Рыба',
  ]),

  krasnodar: getTags([
    'Европейская кухня',
    'Бары',
    'Русская кухня',
    'Японская кухня',
    'Итальянская кухня',
    'Паназиатская кухня',
    'Американская кухня',
    'Бизнес-ланч',
    'Завтраки',
  ]),

  kazan: getTags([
    'Европейская кухня',
    'Татарская кухня',
    'Грузинская кухня',
    'Бары',
    'Узбекская кухня',
    'Итальянская кухня',
    'Бизнес-ланч',
    'Пивные рестораны',
    'У воды',
    'Русская кухня',
  ]),

  ekaterinburg: getTags([
    'Европейская кухня',
    'Бары',
    'Итальянская кухня',
    'Грузинская кухня',
    'Русская кухня',
    'Стейки',
    'Бизнес-ланч',
    'Пивные рестораны',
    'Узбекская кухня',
    'У воды',
  ]),

  kaliningrad: getTags([
    'Европейская кухня',
    'Узбекская кухня',
    'Пивные рестораны',
    'Итальянская кухня',
    'Бары',
    'Бизнес-ланч',
    'Русская кухня',
    'Завтраки',
    'Рыба',
    'Паназиатская кухня',
  ]),

  chelyabinsk: getTags([
    'Европейская кухня',
    'Русская кухня',
    'Бары',
    'Японская кухня',
    'Итальянская кухня',
    'Бизнес-ланч',
    'Пивные рестораны',
    'Грузинская кухня',
  ]),
}
