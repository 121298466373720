import React, { memo, useCallback, FC } from 'react'
import s from './BuildSelect.module.sass'
import { Select } from '../../Single/Select'
import { InputHint } from '../../Single/InputHint'
import { InputTitle } from '../../Single/InputTitle'
import { useFieldHint } from '@shared/lib/fields/hooks/useFieldHint/useFieldHint'
import { FieldSelect, SelectOption } from '@shared/types/fields/fields'
import { ErrorBoundary } from '../../../../lib/components/ErrorBoundary'

interface Props {
  field: FieldSelect
  setField: React.Dispatch<React.SetStateAction<FieldSelect>>
  // eslint-disable-next-line
  handleChange: (value: SelectOption) => void
  className?: string
}

const BuildSelect: FC<Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = ({
  field,
  setField,
  handleChange,
  className = '',
  ...props
}) => {
  const [hint] = useFieldHint(field)

  const setValue = useCallback(
    (value: SelectOption): void => {
      setField((prev) => ({
        ...prev,
        value: value,
        error: '',
      }))

      handleChange && handleChange(value)
    },
    [handleChange, setField],
  )

  const classes = {
    disabled: !field.value && field.disabled ? s.disabled : '',
    disabledValue: !!field.value && field.disabled ? s.disabled__value : '',
  }

  return (
    <ErrorBoundary>
      {field.visible && (
        <div className={`${classes.disabled} ${classes.disabledValue} ${className}`} {...props}>
          <InputTitle
            nameField={field.name}
            title={field.settings.label}
            showRequired={field.required && field.settings.showRequired}
          />
          <Select field={field} handleChange={setValue} />
          <InputHint text={hint.text} status={hint.status} />
        </div>
      )}
    </ErrorBoundary>
  )
}

export default memo(BuildSelect)
