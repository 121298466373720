import { undefinedObjectToNull } from '@shared/api/middleware/mappingAPI/helpers'
import { TypeMappingFavoritesGQLOutput } from '@shared/api/middleware/mappingAPI/gql_favorites/type'

export const mappingFavoritesGQL = (object: any): TypeMappingFavoritesGQLOutput => {
  const result = {
    restaurants: {},
    selections: {},
    concerts: {},
    news: {},
  }
  try {
    const favorites = object.data.user.activity.favorites.items

    favorites.forEach((item) => {
      const { id: idSubject, name: nameSubject } = item.subject

      const isRest = idSubject.toLowerCase().includes('restaurant')
      const isNews = idSubject.toLowerCase().includes('article')
      const isSelection = idSubject.toLowerCase().includes('selection')
      const isConcert = idSubject.toLowerCase().includes('concert')

      if (isRest) result.restaurants[parseInt(idSubject.match(/\d+/))] = nameSubject || true
      if (isNews) result.news[parseInt(idSubject.match(/\d+/))] = nameSubject || true
      if (isSelection) result.selections[parseInt(idSubject.match(/\d+/))] = nameSubject || true
      if (isConcert) result.concerts[parseInt(idSubject.match(/\d+/))] = nameSubject || true
    })

    return undefinedObjectToNull(result)
  } catch (error) {
    console.error(error)
    return result
  }
}
