import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'

import { FavoritePropsToStore, globalState, TypeCategoryFavoriteObject, TypeDesignEnv } from '@app/model/types'
import { COOKIE_CITY_ID, SESSION_STORAGE_CITY_CURRENT } from '@shared/consts/storage'

const initialState: globalState = {
  // отображать страницу только когда storeLoaded true (т.е. основные данные загружены)
  storeLoaded: false,

  client: {
    responsive: {
      designEnv: 'default',
      isDesktopLarge: false,
      isDesktop: false,
      isTablet: false,
      isMobile: false,
    },
    service: {
      device_uuid: null,
      session_uuid: null,
    },
  },
  user: {
    loading: false,
    data: null,
  },
  needAskCity: false,
  currentPage: null,
  currentCity: null,
  cityRead: null,
  cityList: null,
  topline: null,
}

const global = createSlice({
  name: 'global',
  initialState,

  reducers: {
    /**
     * @todo: add useragent matching:
        map $http_user_agent $afisha_mobile_ua {
          default     '';
          '~*ipad'    '';
          '~*android|avantgo|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge\ |maemo|midp|mmp|opera\ m(ob|in)i|palm(\ os)?|phone|p(ixi|re)\/|plucker|pocket|psp|symbian|treo|up\.(browser|link)|vodafone|wap|windows\ (ce|phone)|xda|xiino' 1;
        }
     * @param state
     * @param action
     */
    setDeviceType(state, action: PayloadAction<{ width?: number; type?: TypeDesignEnv }>) {
      const { width, type } = action.payload
      let result

      if (type) {
        result = type
        return
      }

      if (width) {
        if (width >= 1280) result = 'desktop'
        if (width <= 1279 && width >= 768) result = 'tablet'
        if (width <= 767) result = 'mobile'
      }

      if (result !== state.client.responsive.designEnv) {
        state.client.responsive.designEnv = result

        state.client.responsive.isDesktop = result === 'desktop'
        state.client.responsive.isTablet = result === 'tablet'
        state.client.responsive.isMobile = result === 'mobile'
      }

      if (typeof window !== 'undefined') {
        // @ts-ignore
        if (window?.app?.isMobile) state.client.responsive.isMobile = true
      }

      if (result === 'desktop' && state.client.responsive.designEnv === 'desktop') {
        const isDesktopLarge = width >= 1592

        if (state.client.responsive.isDesktopLarge !== isDesktopLarge) {
          state.client.responsive.isDesktopLarge = isDesktopLarge
        }
      }
    },

    setCurrentPage(state, action) {
      state.currentPage = action.payload
    },

    setCityList(state, action) {
      state.cityList = action.payload
    },

    setCurrentCity(state, action) {
      sessionStorage.setItem(SESSION_STORAGE_CITY_CURRENT, `${action.payload.id}-${action.payload.slug}`)

      // Cookie city_id нужен исключительно для запросов к беку
      let date: Date | string = new Date(Date.now())
      date.setDate(date.getDate() + 1)
      date = date.toUTCString()
      document.cookie = `${COOKIE_CITY_ID}=${action.payload.id}; expires=${date}; domain=.afisha.ru; path=/`

      state.currentCity = action.payload
    },

    setCityRead(state, action) {
      state.cityRead = action.payload
    },

    resetCityRead(state) {
      state.cityRead = initialState.cityRead
    },

    setUserData(state, action) {
      state.user.data = action.payload
    },

    setUserLoading(state, action: PayloadAction<boolean>) {
      state.user.loading = action.payload
    },

    setTopline(state, action) {
      state.topline = action.payload
    },

    resetTopline(state) {
      state.topline = initialState.topline
    },

    setNeedAskCity(state, action: PayloadAction<boolean>) {
      state.needAskCity = action.payload
    },

    addFavoriteObject(state, action: PayloadAction<{ object: TypeCategoryFavoriteObject; id: number }>) {
      const { object, id } = action.payload
      const categoryNameForStore = FavoritePropsToStore[object]

      state.user.data.favorites_objects[categoryNameForStore][id] = true
    },

    deleteFavoriteObject(state, action: PayloadAction<{ object: TypeCategoryFavoriteObject; id: number }>) {
      const { object, id } = action.payload
      const categoryNameForStore = FavoritePropsToStore[object]

      const getObject = state.user.data.favorites_objects[categoryNameForStore][id]
      if (getObject) delete state.user.data.favorites_objects[categoryNameForStore][id]
    },

    setStoreLoaded(state, action) {
      state.storeLoaded = action.payload
    },

    // Special reducer for hydrating the state
    extraReducers: {
      // @ts-ignore
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.global,
        }
      },
    },
  },
})

export const getState = (state: globalState) => state
export const {
  setStoreLoaded,
  setDeviceType,
  setCurrentPage,
  setCityList,
  setCurrentCity,
  setCityRead,
  resetCityRead,
  setUserData,
  setUserLoading,
  setTopline,
  resetTopline,
  setNeedAskCity,
  addFavoriteObject,
  deleteFavoriteObject,
} = global.actions

export default global.reducer
