import { useState, useEffect, useRef } from 'react'

const useOutsideClick = (initialIsVisible) => {
  const [isVisible, setIsVisible] = useState(initialIsVisible)
  const ref = useRef(null)

  const handleHideComponent = (event) => {
    if (event.key === 'Escape') {
      setIsVisible(false)
    }
  }

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsVisible(false)
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleHideComponent, true)
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('keydown', handleHideComponent, true)
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  return { ref, isVisible, setIsVisible }
}

export default useOutsideClick
