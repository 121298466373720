import { globalStore } from '@app/model/store'
import { TypeFilterOnce } from '@features/Restaurant/RestaurantFilters/model/types'
import { getDefaultRequestDataOnServer } from '@shared/api/getDefaultRequestDataOnServer'
import { API } from '@shared/api'
import { PropsTagList } from '@shared/api/types/apiPublicParameters'
import { mappingTagItem } from '@shared/api/middleware/mappingAPI/tag/tag'

/**
 * @function getFormattedUrlRestFilter - TODO описание
 */
type PropsFormattedUrl = {
  slugs: string[]
  city_id: string | number
}
export const getFormattedUrlRestFilter = async (data: PropsFormattedUrl): Promise<string> => {
  // Обрезаем массив slug-ов и чистим от дубликатов
  if (data.slugs.length > 50) data.slugs.length = 50
  const uniqueValuesFilter = new Set(data.slugs)

  const constArrSlugs = Array.from(uniqueValuesFilter)
  const variableArrSlugs = Array.from(uniqueValuesFilter)

  let resultPartUrl = ``

  // порядок выстраивания url по индексам групп
  const priority = [
    'has_selection', // Выбор Афиши
    'is_open_now', // Открыто сейчас
    'has_booking', // Бронирование
    'owner_offer', // Спецпредложения
    'kitchen', // Тип кухни
    'restaurants', // Тип ресторана
    'placed', // Тип расположения
    'children', // Услуги для детей
    'detailed', // Подробные услуги (доставка, завтраки, парковка)
    'prices', // Цена
    'rating', // Рейтинг
    'metro', // Метро
    'all', // Остальные теги которые не попали под выборку выше
  ]

  // Сопоставление типа группы и индексов для отправки на бек
  const idCategoryByType = {
    kitchen: '1173',
    restaurants: '1174',
    placed: '1175',
    children: '1176',
    detailed: '1177',
    prices: '1178',
  }

  // Поиск slug-а по названию и удаление из variableArrSlugs
  const deleteSlugFromVariableArr = (slug: string) => {
    const deletionIndex = variableArrSlugs.findIndex((varSlug) => slug === varSlug)
    variableArrSlugs.splice(deletionIndex, 1)
  }

  const getRatingSlugs = () => {
    const slugs = []

    for (let i = 0; i <= 10; i++) {
      slugs.push(`rating_from_${i}`)
      slugs.push(`rating_to_${i}`)
    }
    return slugs
  }

  // Запрос тега по типу с постоянным возвратом успешного промиса
  const fetchTag = (type) => {
    return new Promise((resolve, reject) => {
      // метро можно получить только через API.metro_list
      const apiMethod = type === 'metro' ? API.metro_list : API.tag_list

      const requestParams: PropsTagList = {
        params: { page: 1, page_size: 1000 },
        cache: true,
      }
      if (idCategoryByType[type]) requestParams.params.catalogs = idCategoryByType[type]

      apiMethod(requestParams)
        .then((res) => resolve({ ...res, results: res.results.map((tag) => mappingTagItem(tag)), group: type }))
        .catch(() => resolve({ results: [], group: type }))
    })
  }

  const tagsGroup = {
    has_selection: { list: ['has_selection'], selected: [] },
    is_open_now: { list: ['is_open_now'], selected: [] },
    has_booking: { list: ['has_booking'], selected: [] },
    owner_offer: { list: ['owner_offer'], selected: [] },
    kitchen: { list: [], selected: [] },
    restaurants: { list: [], selected: [] },
    placed: { list: [], selected: [] },
    children: { list: [], selected: [] },
    detailed: { list: [], selected: [] },
    prices: { list: [], selected: [] },
    metro: { list: [], selected: [] },
    rating: { list: getRatingSlugs(), selected: [] },
    all: { list: [], selected: [] },
  }

  await Promise.all([
    fetchTag('all'), // tags kitchen
    fetchTag('kitchen'), // tags kitchen
    fetchTag('restaurants'), // tags restaurants
    fetchTag('placed'), // tags placed
    fetchTag('children'), // tags for children
    fetchTag('detailed'), // tags detailed
    fetchTag('prices'), // tags prices
    fetchTag('metro'), // tags metro
  ]).then((responseArray) => {
    responseArray.forEach((responseGroup) => {
      // @ts-ignore
      if (responseGroup?.results?.length) {
        // @ts-ignore
        tagsGroup[responseGroup.group].list = responseGroup?.results.map((tag) => tag.slug)
      }
    })
  })

  // Сопоставляем переданные в функцию slug-и с группами тегов (кроме all).
  // При нахождении подходящей группы записываем slug в поле selected соответствующей группы
  // Удаляем slug-и из variableArrSlugs которые были найдены
  constArrSlugs.forEach((tag) => {
    Object.entries(tagsGroup).forEach(([key, group]) => {
      if (key !== 'all') {
        const hasTagInList = group.list?.some((tagByGroup) => new RegExp(tagByGroup, 'i').test(tag))

        if (hasTagInList) {
          group.selected.push(tag.toLowerCase())
          deleteSlugFromVariableArr(tag)
        }
      }
    })
  })

  if (tagsGroup.rating.selected.length) {
    const types: { from: number[]; to: number[] } = {
      from: [],
      to: [],
    }

    tagsGroup.rating.selected.forEach((tag) => {
      const value = Number(tag.match(/\d+/gm))
      tag.includes('from') ? types.from.push(value) : types.to.push(value)
    })

    tagsGroup.rating.selected = [
      `rating_from_${types.from.length ? Math.min(...types.from) : 0}`,
      `rating_to_${types.to.length ? Math.max(...types.to) : 10}`,
    ]
  }

  // Только если в variableArrSlugs остались slug-и которые, не совпали с slug-ами из групп,
  // то используем поиск по всей базе тегов из группы all
  if (variableArrSlugs.length) {
    variableArrSlugs.forEach((tag) => {
      const hasTagInList = tagsGroup.all.list?.includes(tag)

      if (hasTagInList) {
        tagsGroup.all.selected.push(tag)
        deleteSlugFromVariableArr(tag)
      }
    })
  }

  // добавляем слеш для каждого slug-а в поле selected в каждый группе
  Object.entries(tagsGroup).forEach(([key, group]) => {
    group.selected.forEach((tag, index) => (group.selected[index] += '/'))
  })

  // Собираем url по порядку приоритета полей групп
  priority.forEach((tagGroupName) => {
    resultPartUrl += tagsGroup[tagGroupName].selected.join('')
  })

  return resultPartUrl
}

/**
 * @function getFilterSelected - TODO описание
 */
export const getFilterSelected = () => {
  const getStateFilters = globalStore.getState().features.restaurant_filters

  const result = []
  Object.entries(getStateFilters.filters).forEach(([key, value]) => {
    // @ts-ignore
    if (value.checked) {
      result.push(`${key}: checked;`)
      // @ts-ignore
    } else if (value.preCheckedList?.length) {
      // @ts-ignore
      return value.preCheckedList.forEach((el) => result.push(`${key}: ${el.slug};`))
    }
  })
  return result.join(' ')
}

/**
 * @function getFilterByType - TODO описание
 */
export const getFilterByType = (key) => {
  const filter = globalStore.getState().features.restaurant_filters.filters[key]

  const result = []
  // @ts-ignore
  if (filter.checked) {
    result.push(`checked;`)
    // @ts-ignore
  } else if (filter.preCheckedList?.length) {
    // @ts-ignore
    filter.preCheckedList.forEach((el) => result.push(`${el.slug};`))
  }
  return result.join(' ')
}

/**
 * @function findDataTagForAnalyticBySlug - TODO описание
 */
export const findDataTagForAnalyticBySlug = (foundSlug) => {
  const filters = globalStore.getState().features.restaurant_filters.filters

  const result = {
    type: null,
    value: null,
  }

  Object.entries(filters).forEach(([type, object]) => {
    const isFilterOnce = 'checked' in object
    const isFilterList = 'list' in object
    if (isFilterOnce && object.slug === foundSlug) {
      result.type = type
      result.value = object.slug
      return result
    } else if (isFilterList) {
      // @ts-ignore
      const findTag = object.list.find((tag) => tag.slug === foundSlug)
      if (findTag) {
        result.type = type
        result.value = findTag.slug
        return result
      }
    }
  })

  return result
}
