import { createAsyncThunk } from '@reduxjs/toolkit'
import { API } from '@shared/api'
import { addTemporaryNotice } from '@widgets/Common/Notices'
import {
  setList,
  setLoading,
  setPaginationItems,
  setPaginationPage,
  setSpecialSection,
} from '@pages/NewsGallery/model/reducer'

export const loadMorePage = createAsyncThunk('newsGallery/loadMorePage', async (action, { dispatch, getState }) => {
  // @ts-ignore
  const state = getState().pages.news_gallery

  const { items_addition, items_full } = state.loading
  if (items_addition || items_full || !state.pagination.page.next.number) return

  dispatch(setLoading({ ...state.loading, items_addition: true }))

  const params = {
    page: state.pagination.page.next.number,
    page_size: state.pagination.page.size,
  }

  const sortTags = state.sort.selected.map((item) => item.slug).join(',')
  // @ts-ignore
  if (sortTags) params.variety_in = sortTags

  API.news_list({ params })
    .then((response) => {
      const { results, pagination } = response
      dispatch(setList([...state.list, ...results]))
      dispatch(setPaginationPage(pagination))
    })
    .catch(() => {
      // @ts-ignore
      dispatch(addTemporaryNotice('Ошибка загрузки новостей'))
    })
    .finally(() => {
      dispatch(setLoading({ ...state.loading, items_addition: false }))
    })
})

export const loadNewPage = createAsyncThunk('newsGallery/loadNewPage', async (action, { dispatch, getState }) => {
  // @ts-ignore
  const state = getState().pages.news_gallery

  const { items_addition, items_full } = state.loading
  if (items_addition || items_full) return

  dispatch(setLoading({ ...state.loading, items_full: true }))

  const params = {
    page: 1,
    page_size: 35,
  }

  const sortTags = state.sort.selected.map((item) => item.slug).join(',')
  // @ts-ignore
  if (sortTags) params.variety_in = sortTags

  API.news_list({ params })
    .then((response) => {
      const { results, pagination } = response
      const getItemForSpecialIndex = 18
      // Вытаскиваем новость для диспатча в specialSection для отображения в большой карточке
      const getItemForSpecial = results.find((el, index) => index == getItemForSpecialIndex)
      if (getItemForSpecial) dispatch(setSpecialSection(getItemForSpecial))

      if (getItemForSpecial) {
        const listWithoutSpecialItem = results.filter((el, index) => index != getItemForSpecialIndex)
        dispatch(setList(listWithoutSpecialItem))
      } else {
        dispatch(setList(results))
      }

      dispatch(setPaginationPage(pagination))
      dispatch(setPaginationItems(pagination.count))
    })
    .catch(() => {
      // @ts-ignore
      dispatch(addTemporaryNotice('Ошибка загрузки новостей'))
    })
    .finally(() => {
      dispatch(setLoading({ ...state.loading, items_full: false }))
    })
})
