import React, { FC, useEffect, useRef, useState, useMemo } from 'react'
import s from './RestaurantExtraInfo.module.sass'
import { useRouter } from 'next/router'
import { useKeenSlider } from 'keen-slider/react'
import { load } from '@2gis/mapgl'

// @ts-ignore
import IconMapPin from '@icons/icon-pin.svg'
// @ts-ignore
import IconMapZoomPlus from '@icons/map/zoom-plus.svg'
// @ts-ignore
import IconMapZoomMinus from '@icons/map/zoom-minus.svg'
// @ts-ignore
import IconMapUserLocation from '@icons/map/user-location.svg'
// @ts-ignore
import MapBackground from '@images/map-background.png'

import { useAppSelector } from '@app/model/store'

import { RestaurantExtraInfoSkeleton } from '@widgets/Restaurant/RestaurantExtraInfo/index'

import { findDataTagForAnalyticBySlug } from '@features/Restaurant/RestaurantFilters/helpers/helper'

import { BtnFilterAfishaTag } from '@shared/ui/Form/Single/Filters/AfishaTag'
import { BtnFilterTag } from '@shared/ui/Form/Single/Filters/DefaultTag'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { LoadingSpinner } from '@shared/ui/Feedback/LoadingSpinner'
import { SectionTitle } from '@shared/ui/Layout/SectionTitle'
import { Text } from '@shared/ui/Typography/Text'
import { Metro } from '@shared/ui/Feedback/Metro'
import { EGG } from '@shared/api/analytics'

interface Props {
  token2gis: string
}

const RestaurantExtraInfoWrapper: FC<Props> = (props) => {
  const restaurantData = useAppSelector((state) => state.pages.rest_item.data)

  if (!restaurantData) return <RestaurantExtraInfoSkeleton />
  else return <RestaurantExtraInfo {...props} />
}

const RestaurantExtraInfo: FC<Props> = ({ token2gis }) => {
  const [loadedMap, setLoadedMap] = useState(false)
  const [loadingMap, setLoadingMap] = useState(false)
  const [mapglAPI, setMapglAPI] = useState(null)
  const mapElement = useRef()
  const router = useRouter()
  const stateGlobal = useAppSelector((state) => state.global)
  const stateRestaurant = useAppSelector((state) => state.pages.rest_item)
  const { address, tags, price, services, contacts } = stateRestaurant.data

  const fullAddress = `${stateGlobal.currentCity.name}, ${address.address}`
  const isVisibleMap = !!address.coordinates[0] && !!address.coordinates[1]

  useEffect(() => {
    setLoadedMap(false)
  }, [address.coordinates])

  const handleOpenTagPage = (tagElem) => {
    if (tagElem.url) {
      const dataTag = findDataTagForAnalyticBySlug(tagElem.slug)
      const urlSplit = tagElem.url.split('/')
      const getSlug = urlSplit[urlSplit.length - 2]
      EGG.common.tag_click({
        filter_apply_type: dataTag.type,
        filter_apply_value: dataTag.value || getSlug,
      })

      router.push(tagElem.url, tagElem.url, { scroll: false })
    }
  }

  const [sliderRef] = useKeenSlider({
    mode: 'free',
    slides: {
      perView: 'auto',
      spacing: 6,
    },
    selector: `.${s.tag}`,
  })

  const handleOpenMap = () => {
    EGG.pages.RestItem.map_click()
    if (mapglAPI) mapglAPI.destroy()

    setLoadingMap(true)

    try {
      load().then((mapglAPI) => {
        // container — id of the div element in your html

        const map = new mapglAPI.Map(mapElement.current, {
          lang: 'ru',
          controlsLayoutPadding: { top: 20, bottom: 20, left: 16, right: 16 },
          center: address.coordinates,
          zoom: 13,
          zoomControl: false,
          key: token2gis,
          style: '4eda4784-9adb-4c0f-8380-367cad53599c',
          enableTrackResize: true,
          // @ts-ignore
          copyright: true,
          trafficControl: false,
        })

        const marker = new mapglAPI.HtmlMarker(map, {
          coordinates: address.coordinates,
          interactive: false,
          html: '<svg style="transform: translate(-50%, calc(-50% - 10px));" width="42" height="51" viewBox="0 0 42 51" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(#filter0_f_583_66)"> <ellipse cx="21" cy="40" rx="12" ry="2" fill="#737373"/> </g> <path d="M35 15.082C35 17.6367 34.1304 20.4205 32.7592 23.2045C31.3924 25.9794 29.5595 28.69 27.7092 31.0809C25.861 33.469 24.0104 35.519 22.6209 36.9725C21.9571 37.667 21.3998 38.2239 21 38.6151C20.6002 38.2239 20.0429 37.667 19.3791 36.9725C17.9896 35.519 16.139 33.469 14.2908 31.0809C12.4405 28.69 10.6076 25.9794 9.24084 23.2045C7.86956 20.4205 7 17.6367 7 15.082C7 7.29957 13.2731 1 21 1C28.7269 1 35 7.29957 35 15.082Z" fill="#FF3118" stroke="white" stroke-width="2"/> <circle cx="21" cy="15" r="5" fill="white"/> <defs> <filter id="filter0_f_583_66" x="0" y="29" width="42" height="22" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/><feGaussianBlur stdDeviation="4.5" result="effect1_foregroundBlur_583_66"/></filter></defs></svg>',
        })

        setMapglAPI(() => map)
        setLoadedMap(true)
        setLoadingMap(false)
      })
    } catch (error) {
      console.error('Ошибка инициализации карты', error)
    }
  }

  const getMetaStateString = (value) => {
    if (typeof value === 'boolean') return value ? 'Есть' : 'Нет'
    else return value ? value : 'Нет'
  }

  return (
    <ErrorBoundary>
      <div className={`${s.wrapper}`}>
        <h3 data-comment='section-meta' className={'visually-hidden'}>
          Подробная информация
        </h3>

        <SectionTitle className={s.address} title={fullAddress} />

        <ul className={s['metro-list']}>
          {address.metro &&
            address.metro.map((metroItem) => (
              <Metro key={metroItem.id} name={metroItem.name} link={metroItem.url} colors={metroItem.color} />
            ))}
        </ul>

        {isVisibleMap && (
          <div
            className={s['map-container']}
            data-loaded-map={loadedMap}
            data-loading-map={loadingMap}
            style={{ backgroundImage: `url('${MapBackground.src}')` }}>
            <LoadingSpinner loading={loadingMap} size={15} className={s['map-spinner']} />

            <button onClick={handleOpenMap} className={s['map-button']} type='button'>
              <div className={s['map-button-label']}>
                <IconMapPin className={s['map-button-pin']} />
                <Text sizes={'S'}>Посмотреть на карте</Text>
              </div>
            </button>

            <div className={s['map-wrapper']}>
              <div ref={mapElement} className={s.map}></div>
              <div className={s.interface}>
                <div className={s.zoom}>
                  <button onClick={() => mapglAPI.setZoom(mapglAPI.getZoom() + 1)}>
                    <IconMapZoomPlus />
                  </button>
                  <button onClick={() => mapglAPI.setZoom(mapglAPI.getZoom() - 1)}>
                    <IconMapZoomMinus />
                  </button>
                </div>
                <button className={s['user-location']} disabled>
                  <IconMapUserLocation />
                </button>
              </div>
            </div>
          </div>
        )}

        {/* // TODO: check is all data accessible in DB */}
        <div className={s['table']}>
          {/* Средний чек */}
          <div className={s['table-cell']}>
            <Text tag={'span'} sizes={'S'} className={s['cell-title']}>
              Средний чек
            </Text>
            <div className={s['cell-data']}>
              <Text tag={'span'} sizes={'M S'}>
                {getMetaStateString(price.name.short)}
              </Text>
            </div>
          </div>

          {/* Время работы */}
          {/* <div className={s['table-cell']}>
          <Text tag={'span'} sizes={'S'} className={s['cell-title']}>
            Время работы
          </Text>
          <div className={s['cell-data']}>
            <Text tag={'span'} sizes={'M S'}>
              {getMetaStateString(state.working_hours)}
            </Text>
          </div>
        </div> */}

          {/* Завтраки */}
          <div className={s['table-cell']}>
            <Text tag={'span'} sizes={'S'} className={s['cell-title']}>
              Завтраки
            </Text>
            <div className={s['cell-data']}>
              <Text tag={'span'} sizes={'M S'}>
                {getMetaStateString(services.breakfast)}
              </Text>
            </div>
          </div>

          {/* Бизнес-ланч */}
          <div className={s['table-cell']}>
            <Text tag={'span'} sizes={'S'} className={s['cell-title']}>
              Бизнес-ланч
            </Text>
            <div className={s['cell-data']}>
              <Text tag={'span'} sizes={'M S'}>
                {getMetaStateString(services.lunch)}
              </Text>
            </div>
          </div>

          {/* Доставка */}
          <div className={s['table-cell']}>
            <Text tag={'span'} sizes={'S'} className={s['cell-title']}>
              Доставка
            </Text>
            <div className={s['cell-data']}>
              <Text tag={'span'} sizes={'M S'}>
                {getMetaStateString(services.delivery)}
              </Text>
            </div>
          </div>

          {/* Парковка */}
          <div className={s['table-cell']}>
            <Text tag={'span'} sizes={'S'} className={s['cell-title']}>
              Парковка
            </Text>
            <div className={s['cell-data']}>
              <Text tag={'span'} sizes={'M S'}>
                {getMetaStateString(services.parking)}
              </Text>
            </div>
          </div>

          {/* Кейтеринг */}
          <div className={s['table-cell']}>
            <Text tag={'span'} sizes={'S'} className={s['cell-title']}>
              Кейтеринг
            </Text>
            <div className={s['cell-data']}>
              <Text tag={'span'} sizes={'M S'}>
                {getMetaStateString(services.catering)}
              </Text>
            </div>
          </div>

          {/* Банкеты */}
          <div className={s['table-cell']}>
            <Text tag={'span'} sizes={'S'} className={s['cell-title']}>
              Банкеты
            </Text>
            <div className={s['cell-data']}>
              <Text tag={'span'} sizes={'M S'}>
                {getMetaStateString(services.banquet)}
              </Text>
            </div>
          </div>

          {/* Телефон */}
          <div className={s['table-cell']}>
            <Text tag={'span'} sizes={'S'} className={s['cell-title']}>
              Телефон
            </Text>
            <div className={s['cell-data']}>
              {contacts.phone.list.length > 0
                ? contacts.phone.list.map((phoneItem, i) => (
                    <Text tag={'span'} sizes={'M S'} key={i}>
                      {phoneItem ? (
                        <a className={s['cell-data-link']} href={`tel:${phoneItem}`}>
                          {phoneItem}
                        </a>
                      ) : (
                        'Нет'
                      )}
                    </Text>
                  ))
                : 'Нет'}
            </div>
          </div>

          {/* Сайт */}
          <div className={s['table-cell']}>
            <Text tag={'span'} sizes={'S'} className={s['cell-title']}>
              Сайт
            </Text>
            <div className={s['cell-data']}>
              <Text tag={'span'} sizes={'M S'}>
                {contacts.site ? (
                  <a className={s['cell-data-link']} href={contacts.site} rel='nofollow' target='_blank'>
                    {contacts.site}
                  </a>
                ) : (
                  'Нет'
                )}
              </Text>
            </div>
          </div>

          {/* Социальные сети */}
          {/* <div className={s['table-cell']}>
          <Text tag={'span'} sizes={'S'} className={s['cell-title']}>
            Социальные сети
          </Text>
          <div className={s['cell-data']}>
            <Text tag={'span'} sizes={'M S'}>
              Vkontakte
            </Text>
          </div>
        </div> */}
        </div>

        <div className={s['tag-wrapper']}>
          <ul className={`keen-slider`} ref={sliderRef} key={stateRestaurant.data.id}>
            {!!tags.searchable.length &&
              tags.searchable.map((el, i) => {
                return (
                  <div className={s.tag} key={i}>
                    {/*// @ts-ignore*/}
                    {el.has_selection ? (
                      <BtnFilterAfishaTag key={el.id} onClick={() => handleOpenTagPage(el)}>
                        {el.name}
                      </BtnFilterAfishaTag>
                    ) : (
                      <BtnFilterTag key={el.id} onClick={() => handleOpenTagPage(el)}>
                        {el.name}
                      </BtnFilterTag>
                    )}
                  </div>
                )
              })}
          </ul>
        </div>
      </div>
    </ErrorBoundary>
  )
}

export default RestaurantExtraInfoWrapper
