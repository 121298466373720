import React, { FC, useRef, useState } from 'react'
import s from './ReviewItem.module.sass'

import { ReviewContent } from '@entities/Restaurant/ReviewsNew/ui/ReviewItem/ui/ReviewContent'

import { TypeMappingReviewOutput } from '@shared/api/middleware/mappingAPI/review/type'
import { useIntersectionAnalytic } from '@shared/lib/hooks/useIntersectionAnalytic'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { Modal } from '@shared/ui/Overlays/Modal'
import merge from 'lodash/merge'

interface ReviewItemProps {
  sourceData: {
    restName: string
    reviewData: TypeMappingReviewOutput
  }
  commonProps: {
    options?: {
      showSettings?: boolean // Возможность открыть настройки (редактирование, удаление) отзыва
      loading?: boolean // показатель загрузки в модалках (при отправке или редактировании отзыва)
      style?: 'card' | 'row'
    }
    events: {
      intersectionAnalyticMethod?: () => void
      onClickDeleteReview?: (data: TypeMappingReviewOutput) => void
      onClickEditReview?: (data: TypeMappingReviewOutput) => void
      onClickFullReview?: () => void
      onClickFullAnswer?: () => void
      onInputValueReview?: (value: string) => void
    }
  }
  ownerProps?: {
    options: {
      loading: boolean // показатель загрузки в модалках (при отправке или редактировании отзыва)
      answerPossible: boolean // возможность ответить на отзыв (кнопка "ответить" и поле для ответа)
      showSettings: boolean // Возможность открыть настройки (редактирование, удаление) ответа на отзыв
    }

    events: {
      onClickOpenSendAnswer?: () => void
      onClickSendAnswer?: (data: TypeMappingReviewOutput, body: string) => void
      onClickDeleteAnswer?: (data: TypeMappingReviewOutput) => void
      onClickEditAnswer?: (data: TypeMappingReviewOutput) => void
      onInputValueAnswer?: (data: TypeMappingReviewOutput, body: string) => void
    }
  }
}

const defaultProps = {
  sourceData: {
    restName: '',
    reviewData: null,
  },
  commonProps: {
    options: {
      loading: false,
      showSettings: false,
      style: 'row',
    },
    events: {},
  },
  ownerProps: null,
}

const ReviewItem: FC<ReviewItemProps> = (props) => {
  const propsReady: ReviewItemProps = merge({}, defaultProps, props)
  const { sourceData, ownerProps, commonProps } = propsReady
  const [isOpenReviewMobileModal, setIsOpenReviewMobileModal] = useState(false)
  const { isMobile } = useClientResponsive()
  const elementRef = useRef()
  useIntersectionAnalytic(elementRef, () => {
    commonProps.events.intersectionAnalyticMethod && commonProps.events.intersectionAnalyticMethod()
  })

  const handleClickOpenReview = () => {
    commonProps.events.onClickFullReview()
    setIsOpenReviewMobileModal(true)
  }

  return (
    <ErrorBoundary>
      <div data-mode={commonProps.options.style} ref={elementRef}>
        <div className={s.review}>
          <ReviewContent
            sourceData={sourceData}
            // @ts-ignore
            commonProps={merge({}, commonProps, {
              events: {
                onClickFullReview: handleClickOpenReview,
              },
              options: {
                isModal: false,
                disclose: true,
              },
            })}
            ownerProps={ownerProps}
          />
        </div>

        {isOpenReviewMobileModal && isMobile && (
          <Modal
            size={'M'}
            mobileMode={'dropdown'}
            title={'Отзыв'}
            handleClose={() => setIsOpenReviewMobileModal(false)}
            className={s.modal}>
            <div className={s['modal-content']}>
              <ReviewContent
                sourceData={sourceData}
                // @ts-ignore
                commonProps={merge({}, commonProps, {
                  events: {
                    onClickFullReview: handleClickOpenReview,
                  },
                  options: {
                    isModal: true,
                    disclose: true,
                  },
                })}
                ownerProps={ownerProps}
              />
            </div>
          </Modal>
        )}
      </div>
    </ErrorBoundary>
  )
}

export default ReviewItem
