import { mappingRestaurantItem } from '@shared/api/middleware/mappingAPI/restaurant/restaurant'

/**
 * @function undefinedObjectToNull
 *    TODO: необходимо добавить описание функции (особенности, описание, назначение, примеры, итп)
 */
export const undefinedObjectToNull = (object) => {
  Object.keys(object).forEach((key) => {
    const isUndefined = object[key] === undefined
    const isNull = object[key] === null
    const isArray = Array.isArray(object[key])
    if (typeof object[key] === 'object' && !isArray && !isNull) {
      undefinedObjectToNull(object[key])
    } else if (isUndefined) {
      object[key] = null
    }
  })
  return object
}

/**
 * @function getMappingRestaurantListSort
 *    TODO: необходимо добавить описание функции (особенности, описание, назначение, примеры, итп)
 */
export const getMappingRestaurantListSort = (list) => {
  try {
    const mappingList = list.map((rest) => mappingRestaurantItem(rest))
    const sortByAdvert = mappingList.sort((a) => (a.categories.advert ? -1 : 0))
    return sortByAdvert
  } catch (e) {
    console.error(e)
    return null
  }
}
