import React, { FC } from 'react'
import { TypeQuestionAnswer } from '@widgets/Common/QuestionsAnswers/types/QuestionAnswer'
import { QuestionAnswerItem } from '@widgets/Common/QuestionsAnswers/ui/QuestionAnswerItem'
import s from './QuestionsAnswers.module.sass'
import { SectionTitle } from '@shared/ui/Layout/SectionTitle'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { QuestionsAnswersSkeleton } from '@widgets/Common/QuestionsAnswers/index'

interface Props {
  data: TypeQuestionAnswer[]
  className?: string
  skeleton?: boolean
}

const QuestionsAnswers: FC<Props> = ({ data, className = '', skeleton = false }) => {
  if (skeleton) return <QuestionsAnswersSkeleton />

  return (
    <ErrorBoundary>
      <div className={`${s.wrapper} ${className}`}>
        <SectionTitle title={'Вопросы и ответы'} className={s.title} />

        <div className={s.items}>
          {data.map((el, index) => (
            <QuestionAnswerItem key={index} data={el} analytic={{ section_index: index }} />
          ))}
        </div>
      </div>
    </ErrorBoundary>
  )
}

export default QuestionsAnswers
