import s from './MediaCardCover.module.sass'

import React, { FC, HTMLProps } from 'react'

import { Favorite } from '@features/Favorite'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { Button } from '@shared/ui/Actions/Button'
import { Title } from '@shared/ui/Typography/Title'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { TypeMappingNewsOutput } from '@shared/api/middleware/mappingAPI/news/type'
import { TypeMappingSelectionOutput } from '@shared/api/middleware/mappingAPI/selection/type'
import { PickAnalytic } from '@shared/api/analytics/types/types'
import { IconButton } from '@shared/ui/Actions/IconButton'

import IconLike from '@icons/icon-like.svg'

interface Props {
  idObject: number
  title: string
  img: {
    desktop: string
    mobile: string
  }
  handleClick: () => void
  favorite: {
    category: 'news' | 'selection'
    analytic: {
      data: TypeMappingNewsOutput | TypeMappingSelectionOutput
      other: PickAnalytic<'section_name'>
    }
  }
  subtitles?: string[]
  buttonText?: string
  className?: string
}

const MediaCardCover: FC<Props & HTMLProps<HTMLDivElement>> = React.forwardRef(
  ({ idObject, title, img, handleClick, favorite, subtitles, buttonText, className = '', ...props }, ref) => {
    const { isMobile } = useClientResponsive()
    const imgLink = isMobile ? img.mobile : img.desktop

    // const placeholderImage = null
    // const thisImage = data?.cover?.cover1?.replace('//develop.rests', '//rests') || placeholderImage

    return (
      <ErrorBoundary>
        <section className={s.wrap} data-is-mobile={isMobile} title={title} {...props} ref={ref}>
          <aside className={s.aside} data-is-mobile={isMobile}>
            <div className={s['aside-content']} data-is-mobile={isMobile}>
              <Title
                className={s['aside-title']}
                tag='h3'
                sizes={'h2 h3 h4'}
                data-is-mobile={isMobile}
                onClick={handleClick}>
                {title}
              </Title>

              {subtitles?.length && (
                <div className={s['aside-description']} data-is-mobile={isMobile}>
                  {subtitles.map((text, index) => (
                    <div key={index}>
                      {index > 0 && <span className={s['aside-dot']}>·</span>}
                      <span className={s['aside-subtitle']}>{text}</span>
                    </div>
                  ))}
                </div>
              )}

              {buttonText && (
                <Button className={s['aside-open']} sizes='XL L M' mode={'white'} onClick={handleClick}>
                  {buttonText}
                </Button>
              )}

              {isMobile && (
                <Favorite
                  category={favorite.category}
                  id={idObject}
                  analytic={{ data: favorite.analytic.data, other: favorite.analytic.other }}
                  className={s.favorite}>
                  <IconButton sizes={'M'} mode={'transparent'} icon={IconLike} />
                </Favorite>
              )}
            </div>

            <div
              className={s['aside-background']}
              style={{ backgroundImage: `url(${imgLink})` }}
              data-is-mobile={isMobile}
            />
          </aside>

          {!isMobile && (
            <main className={s.main} style={{ backgroundImage: `url(${imgLink})` }}>
              <Favorite
                category={favorite.category}
                id={idObject}
                analytic={{ data: favorite.analytic.data, other: favorite.analytic.other }}
                className={s.favorite}>
                <IconButton sizes={'XL'} mode={'transparent'} icon={IconLike} />
              </Favorite>
            </main>
          )}
        </section>
      </ErrorBoundary>
    )
  },
)

export default MediaCardCover
