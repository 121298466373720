import { RestaurantReview } from '@shared/api/types/swaggerTypes'
import { generateDataString } from '@shared/lib/helpers/date'
import { undefinedObjectToNull } from '@shared/api/middleware/mappingAPI/helpers'
import { TypeMappingReviewOutput } from '@shared/api/middleware/mappingAPI/review/type'

export const mappingReviewItem = (object: RestaurantReview): TypeMappingReviewOutput | null => {
  try {
    const authorName = (review) => {
      const { last_name, first_name, afisha_id } = review.author_object

      if (!!last_name || !!first_name) {
        return `${first_name} ${last_name}`.trim()
      } else {
        return `Пользователь Афиши №${afisha_id}`
      }
    }

    return undefinedObjectToNull({
      id: object.id,
      date: generateDataString(object.created),
      like: object.is_positive_like,
      body: object.body,
      answer: object.owner_comment_author_object
        ? {
            body: object.owner_comment_text,
            date: generateDataString(object.owner_comment_created),
            answerTo: `${object.author_object.last_name} ${object.author_object.first_name}`,
            author: {
              id: object.owner_comment_author_object.id,
              name: 'Отзыв заведения',
              avatar: object.owner_comment_author_object.photo,
              afishaId: object.owner_comment_author_object.afisha_id,
            },
          }
        : null,
      author: {
        id: object.author_object.id,
        name: authorName(object),
        avatar: object.author_object.photo,
        afishaId: object.author_object.afisha_id,
      },
    })
  } catch (error) {
    console.error(error)
    return null
  }
}
