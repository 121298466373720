import React, { FC } from 'react'
import s from './ReviewPagination.module.sass'
import { loadMoreReviews } from '@entities/Restaurant/Reviews/model/createAsyncThunk'
import { useAppDispatch, useAppSelector } from '@app/model/store'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { Pagination } from '@features/Common/Pagination'

interface Props {
  className: string
}

const ReviewPagination: FC<Props> = ({ className = '' }) => {
  const dispatch = useAppDispatch()
  const stateReviews = useAppSelector((state) => state.entities.restaurant_reviews)
  const { pagination } = stateReviews

  return (
    <ErrorBoundary>
      {pagination?.page?.next.number && (
        <Pagination
          mode={'load-more'}
          currentPage={pagination?.page.current.number}
          pageCount={pagination?.page.count}
          handleLoadMore={() => dispatch(loadMoreReviews())}
          analytic={{ section_name: 'reviews' }}
          className={`${s.pagination} ${className}`}
        />
      )}
    </ErrorBoundary>
  )
}

export default ReviewPagination
