import s from './BookingDateTime.module.sass'
import React, { FC, useEffect, useState } from 'react'
import l10n from '@widgets/Restaurant/Booking/lib/booking-localization'
import { BookingCalendar } from '@widgets/Restaurant/Booking/ui/components/BookingCalendar'
import { TypeExtendedScheduleDay } from '@shared/types/schedule/types'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { EGG } from '@shared/api/analytics'
import { TypeDateField } from '@widgets/Restaurant/Booking/model/types'
import { TypeMappingScheduleWeek } from '@shared/api/middleware/mappingAPI/restaurant/type'

interface Props {
  dateField: TypeDateField
  onSelectDate: (selectDate: Date) => void
  error: boolean
  schedule: TypeMappingScheduleWeek
}

/**
 * @function SectionDate - stateless presentational component for booking Date selection
 * @todo 86400000 - магическое число
 */

// Текущая дата пользователя
const todayDate = new Date()
const todayDateFormatted = todayDate.toLocaleDateString()
const tomorrowDateFormatted = new Date(todayDate.getTime() + 86400000).toLocaleDateString()

const SectionDate: FC<Props> = ({ dateField, onSelectDate, error, schedule }) => {
  const [selectedDateTarget, setSelectedDateTarget] = useState<null | 'calendar_target' | 'buttons_target'>(null)

  useEffect(() => {
    getSelectedTarget()
  }, [dateField.selected])

  function getSelectedTarget() {
    if (!dateField.selected?.thisDate) return null

    const selectedDateFormatted = new Date(dateField.selected.thisDate).toLocaleDateString()
    const isToday = selectedDateFormatted == todayDateFormatted
    const isTomorrow = selectedDateFormatted == tomorrowDateFormatted

    if (isToday || isTomorrow) setSelectedDateTarget('buttons_target')
    else return setSelectedDateTarget('calendar_target')
  }

  // Получить расписание только на сегодня и завтра (если в эти дни ресторан работает)
  const getTwoDayOptions = dateField.options.filter((option: TypeExtendedScheduleDay) => {
    if (!option?.thisDate) return

    const optionDateFormatted = new Date(option.thisDate).toLocaleDateString()
    return optionDateFormatted === todayDateFormatted || optionDateFormatted === tomorrowDateFormatted
  })

  const getButtonClassName = (dateObj: TypeExtendedScheduleDay): string => {
    const selected = dateField.selected?.value === dateObj.value && selectedDateTarget === 'buttons_target'
    let nameTemplate = `${s.button} ${s['button--date']} ${selected ? s['button--selected'] : ''}`
    if (!dateObj.isRestOpened) {
      nameTemplate += s['button--disabled']
    }
    return nameTemplate
  }

  const getButtonText = (dateString: string) => {
    const isToday = new Date(dateString).toLocaleDateString() === todayDateFormatted
    const isTomorrow = new Date(dateString).toLocaleDateString() === tomorrowDateFormatted

    if (isToday) return l10n('booking_date_today')
    if (isTomorrow) return l10n('booking_date_tomorrow')
  }

  const handleChangeDateCalendar = (selectedDate: Date) => {
    EGG.entity.booking.change_reserve_date({ input: selectedDate.toLocaleDateString() })

    onSelectDate(selectedDate)

    setSelectedDateTarget('calendar_target')
  }

  const handleSetDate = (selectedDate: TypeExtendedScheduleDay, id: string) => {
    onSelectDate(new Date(selectedDate.value))
    setSelectedDateTarget('buttons_target')

    if (id === 'target_id_0') {
      EGG.entity.booking.click_reserve_date_today()
    } else if (id === 'target_id_1') {
      EGG.entity.booking.click_reserve_date_tomorrow()
    }
  }

  const isDateErrorVisible = error

  return (
    <ErrorBoundary>
      <div className={`${s.block} ${s['block-date']}`}>
        <label htmlFor='select_booking-data' className={s['booking-label']}>
          {l10n('booking_date_label')}
        </label>

        <div className={s.content}>
          {getTwoDayOptions.map((dateObj: TypeExtendedScheduleDay, i: number) => (
            <button
              onClick={() => {
                dateObj.isRestOpened && handleSetDate(dateObj, 'target_id_' + i)
              }}
              className={getButtonClassName(dateObj)}
              key={i}
              type='button'>
              {getButtonText(dateObj.thisDate)}
            </button>
          ))}

          <BookingCalendar
            selectedDate={dateField.selected}
            calendarTarget={selectedDateTarget === 'calendar_target'}
            schedule={schedule}
            datesOptions={dateField.options}
            handleChangeDate={handleChangeDateCalendar}
          />
        </div>

        {isDateErrorVisible && <span className={s.error}>{l10n('booking_date_validation')}</span>}
      </div>
    </ErrorBoundary>
  )
}

export default SectionDate
