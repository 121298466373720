import s from './PageLayout.module.sass'

import React, { FC, memo } from 'react'

import { HeaderAdvert } from '@app/PageLayout/components/HeaderAdvert'
import { useAppSelector } from '@app/model/store'
import { useErrorCatcher } from '@app/PageLayout/handlers/useErrorCatcher'
import { useResizeWindow } from '@app/PageLayout/handlers/useResizeWindow'

import { Notices } from '@widgets/Common/Notices'
import { Footer } from '@widgets/Common/Footer'
import { Header } from '@widgets/Common/Header'

import { ContainerGlobal } from '@shared/ui/Layout/Containers/ContainerGlobal'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { getCurrentRouteData, routeMapping } from '@app/service/routing/routing'

interface Props {
  route: string
}

const Skeleton: FC<Props> = ({ route }) => {
  const globalState = useAppSelector((state) => state.global)
  const { pageName: currentPage, component } = getCurrentRouteData(route, routeMapping)
  useResizeWindow()

  useErrorCatcher()

  // У лендинга ресторатора своя шапка
  const isShowHeader = route !== '/[city_slug]/restaurants/restorator'

  const isShowFooter = currentPage !== 'not_found' && currentPage !== 'service_err'

  const responsiveLoaded = globalState.client.responsive.designEnv !== 'default'

  const SkeletonPage = component

  const isNotOwnerPage = currentPage !== 'owner_marketing' && currentPage !== 'owner_booking'

  return (
    <ErrorBoundary>
      {/* Modals Layer */}
      <Notices />

      {/* Page Layer */}
      <div className={s['body']}>
        {/* Banner Data */}
        <HeaderAdvert />

        {/* Content Data */}
        <div className={s['body-white']}>
          <ContainerGlobal>
            {responsiveLoaded && (
              <>
                {isShowHeader && <Header />}

                <div className={s['wrapper']} data-is-loading={false}>
                  {component ? <SkeletonPage /> : <div></div>}
                </div>

                {isShowFooter && <Footer />}
              </>
            )}
          </ContainerGlobal>
        </div>
      </div>
    </ErrorBoundary>
  )
}

export default memo(Skeleton)
