import React from 'react'

const IconRestCardSkeleton = () => {
  return (
    <>
      <svg xmlns='http://www.w3.org/2000/svg' width='156' height='304' fill='none' viewBox='0 0 156 304'>
        <g clipPath='url(#clip0_18_1761)'>
          <rect width='297' height='202' fill='#EBEBEB' opacity='0.56' rx='16'></rect>
        </g>
        <rect width='274' height='20' y='212' fill='#EBEBEB' opacity='0.56' rx='6'></rect>
        <rect width='189' height='20' y='240' fill='#EBEBEB' opacity='0.56' rx='6'></rect>
        <rect width='148' height='14' y='268' fill='#EBEBEB' opacity='0.56' rx='6'></rect>
        <rect width='148' height='14' y='290' fill='#EBEBEB' opacity='0.56' rx='6'></rect>
        <defs>
          <clipPath id='clip0_18_1761'>
            <path fill='#fff' d='M0 0H297V202H0z'></path>
          </clipPath>
        </defs>
      </svg>
    </>
  )
}
export default IconRestCardSkeleton
