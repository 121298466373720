import React, { FC } from 'react'
import s from './QuickBookingDots.module.sass'
import IconDots from '@icons/icon-dots.svg'
export interface PropsQuickBookingDots {
  onClick: () => void
  className?: string
}
const QuickBookingDots: FC<PropsQuickBookingDots> = ({ onClick, className = '' }) => {
  return (
    <button className={`${s.dots} ${className}`} onClick={onClick}>
      <IconDots />
    </button>
  )
}

export default QuickBookingDots
