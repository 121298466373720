import React, { FC, ReactNode, useContext, useEffect } from 'react'
import { DropdownContext } from '@shared/ui/Form/Single/Filters/Dropdown/Context'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import s from '@shared/ui/Form/Single/Filters/Dropdown/Dropdown.module.sass'
import { Modal } from '@shared/ui/Overlays/Modal'
import { Popup } from '@shared/ui/Overlays/Popup'
import { PopupClose } from '@shared/ui/Overlays/Popup/ui/PopupClose'
import { PopupHeader } from '@shared/ui/Overlays/Popup/ui/PopupHeader'
import { PopupContent } from '@shared/ui/Overlays/Popup/ui/PopupContent'

interface Props {
  title: string
  children: ReactNode
  className?: string
  width?: string
  height?: string
  handleClose?: () => void
}

const DropdownModal: FC<Props & React.ButtonHTMLAttributes<any>> = ({
  className = '',
  children,
  title,
  width,
  height,
  handleClose,
  ...props
}) => {
  // @ts-ignore
  const [context, setContext] = useContext(DropdownContext)
  const { isMobile } = useClientResponsive()
  const onOpenChangePopup = (value) => {
    !value && handleCloseInner()
  }

  const handleCloseInner = () => {
    handleClose ? handleClose() : setContext((prev) => ({ ...prev, opened: false }))
  }

  return (
    <ErrorBoundary>
      <Popup open={context.opened && !isMobile} triggerRef={context.floatingRelative} onOpenChange={onOpenChangePopup}>
        <PopupContent className={`${s['popup-city-select-wrapper']} ${className}`} style={{ width, height }}>
          <PopupClose />
          <PopupHeader>{title}</PopupHeader>
          {children}
        </PopupContent>
      </Popup>

      {context.opened && isMobile && (
        <Modal size={'XS'} title={title} className={`${s.modal} ${className}`} handleClose={handleCloseInner}>
          {children}
        </Modal>
      )}
    </ErrorBoundary>
  )
}

export default DropdownModal
