import React, { FC, useEffect, useState } from 'react'

import { CreateReviewModal } from '@entities/Restaurant/Reviews/ui/CreateReview/ui/CreateReviewModal'
import { CreateReviewBlock } from '@entities/Restaurant/Reviews/ui/CreateReview/ui/CreateReviewBlock'

import { getDefaultField } from '@shared/lib/fields/getDefaultField'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { useField } from '@shared/lib/fields/hooks/useField/useField'

// @ts-ignore
import iconThumb from '@icons/icon-thumb.svg'

interface Props {
  restName: string
  data: {
    loading: boolean
    body: string | null
    like: boolean
  }
  mode: 'block' | 'modal'
  onChange: (data: { like?: boolean; body?: string }) => void
  onSend: () => void
  onClose: () => void
}

const CreateReview: FC<Props> = ({ restName, data, mode, onChange, onSend, onClose }) => {
  const [rateError, setRateError] = useState(false)
  const [comment, setComment, validComment] = useField(
    // @ts-ignore
    getDefaultField('textarea', {
      name: 'comment',
      required: true,
      validations: {
        'min-symbols-50': 'Отзыв должен содержать более 50 символов',
        'max-symbols-1500': 'Отзыв должен содержать не более 1500 символов',
      },
      settings: {
        placeholder: 'Ваш отзыв',
        height: '135px',
        resize: true,
        label: '',
      },
    }),
  )

  const handleClickDislike = () => {
    onChange({ like: data.like === false ? null : false })
    setRateError(false)
  }
  const handleClickLike = () => {
    onChange({ like: data.like === true ? null : true })
    setRateError(false)
  }

  useEffect(() => {
    // @ts-ignore
    onChange({ body: comment.value })
    // @ts-ignore
  }, [comment.value])

  const validRate = () => {
    return data.like !== null
  }

  const handleSubmit = () => {
    // @ts-ignore
    const isValidComment = validComment().error === ''
    const isValidRate = validRate()

    if (isValidComment && isValidRate) {
      onSend()
    } else {
      setRateError(!isValidRate)
      console.error('new review is invalid')
    }
  }

  const CreateReviewComponent = mode === 'modal' ? CreateReviewModal : CreateReviewBlock
  return (
    <ErrorBoundary>
      <CreateReviewComponent
        restName={restName}
        data={data}
        rateError={rateError}
        handleClickDislike={handleClickDislike}
        handleClickLike={handleClickLike}
        handleSubmit={handleSubmit}
        handleClose={() => onChange({ like: null, body: '' })}
        // @ts-ignore
        comment={comment}
        // @ts-ignore
        setComment={setComment}
      />
    </ErrorBoundary>
  )
}

export default CreateReview
