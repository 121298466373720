import React, { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useKeenSlider } from 'keen-slider/react'
import { useRouter } from 'next/router'

import s from './City.module.sass'

import { changeCityAC, setCancelUserCity, setConfirmUserCity } from '@app/model/actionCreatorCity'
import { useAppDispatch, useAppSelector } from '@app/model/store'

import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { getDefaultField } from '@shared/lib/fields/getDefaultField'
import { Search } from '@shared/ui/Form/Build/Search'
import { Title } from '@shared/ui/Typography/Title'
import { Button } from '@shared/ui/Actions/Button'
import { Modal } from '@shared/ui/Overlays/Modal'
import { Text } from '@shared/ui/Typography/Text'
import { EGG } from '@shared/api/analytics'
import { Popup } from '@shared/ui/Overlays/Popup'
import { PopupClose } from '@shared/ui/Overlays/Popup/ui/PopupClose'
import { PopupContent } from '@shared/ui/Overlays/Popup/ui/PopupContent'
import { PopupHeader } from '@shared/ui/Overlays/Popup/ui/PopupHeader'
import { VirtualElement } from '@floating-ui/react'

function groupItemsByName(items) {
  const groups = {}
  for (let i = 0; i < items.length; i++) {
    const item = items[i]
    const firstLetter = item.name[0].toUpperCase()
    if (!groups[firstLetter]) {
      groups[firstLetter] = {
        letter: firstLetter,
        items: [],
      }
    }
    groups[firstLetter].items.push(item)
  }
  return Object.values(groups).sort((a, b) => {
    // @ts-ignore
    if (a.letter.toLowerCase() < b.letter.toLowerCase()) return -1
    // @ts-ignore
    if (a.letter.toLowerCase() > b.letter.toLowerCase()) return 1
    return 0
  })
}

const City: FC = () => {
  const stateGlobal = useAppSelector((state) => state.global)
  const dispatch = useAppDispatch()
  const { isMobile, isTablet } = useClientResponsive()
  const [isOpenCitySelectPopup, setIsOpenCitySelectPopup] = useState(false)
  const [isOpenCityCheckPopup, setIsOpenCityCheckPopup] = useState(false)
  const [refOpenCity, setRefOpenCity] = useState<VirtualElement>()
  const [isMarqueeEnabled, setIsMarqueeEnabled] = useState(false)

  const refCity = useRef(null)

  const handleClosePopup = useCallback(() => {
    setIsOpenCitySelectPopup(false)
    EGG.citySelect.button_popup_close_click()
  }, [])

  const handleChangeOpenPopup = useCallback((value) => {
    !value && handleClosePopup()
  }, [])

  const clickCurrentCity = () => {
    if (!isOpenCityCheckPopup) {
      setIsOpenCitySelectPopup(!isOpenCitySelectPopup)
      EGG.header.button_city_selection_click()
    }
  }

  const handleClickCheckCitySelect = (e) => {
    e.stopPropagation()
    setIsOpenCityCheckPopup(false)
    setIsOpenCitySelectPopup(true)
    EGG.citySelect.show_cities_list_view()
  }
  const handleClickCheckCityAgree = () => {
    setIsOpenCityCheckPopup(false)
    EGG.citySelect.button_right_city_click()
    dispatch(setConfirmUserCity(stateGlobal.currentCity))
  }

  const handleChangeOpenPopupCheckCity = useCallback((value) => {
    !value && dispatch(setCancelUserCity())
  }, [])

  const handleCloseAll = () => {
    setIsOpenCitySelectPopup(false)
    setIsOpenCityCheckPopup(false)
  }

  useEffect(() => {
    const city = refCity.current
    if (isMobile) {
      setIsMarqueeEnabled(false)
      return
    } else if (city && city.parentElement && city.offsetWidth > city.parentElement.parentElement.clientWidth) {
      setIsMarqueeEnabled(true)
    } else {
      setIsMarqueeEnabled(false)
    }
  }, [stateGlobal.currentCity.name, stateGlobal.client.responsive.designEnv])

  useEffect(() => {
    setIsOpenCityCheckPopup(stateGlobal.needAskCity)
  }, [stateGlobal.needAskCity])

  return (
    <>
      <button
        type='button'
        className={s['city-selected']}
        data-marquee={isMarqueeEnabled}
        // @ts-ignore
        ref={setRefOpenCity}
        onClick={clickCurrentCity}>
        <div className={s['city-text']}>
          <span ref={refCity}>{stateGlobal.currentCity?.name || 'Москва'}</span>
          {isMarqueeEnabled && <span>{stateGlobal.currentCity?.name || 'Москва'}</span>}
        </div>
      </button>

      <div>
        <Popup
          open={isOpenCitySelectPopup && !isMobile}
          arrow={true}
          triggerRef={refOpenCity}
          onOpenChange={handleChangeOpenPopup}>
          <PopupContent style={{ width: '420px' }}>
            <PopupHeader>Город</PopupHeader>
            <PopupClose />
            <div className={s['popup-city-select-content']}>
              <CityContent handleClose={handleCloseAll} />
            </div>
          </PopupContent>
        </Popup>

        {isOpenCitySelectPopup && isMobile && (
          <Modal size={'XS'} title={'Город'} className={s.modal} handleClose={handleClosePopup}>
            <div className={s['popup-city-select-content']}>
              <CityContent handleClose={handleCloseAll} />
            </div>
          </Modal>
        )}

        <Popup
          open={isOpenCityCheckPopup}
          arrow={true}
          triggerRef={refOpenCity}
          onOpenChange={handleChangeOpenPopupCheckCity}
          closeClickOutside={false}>
          <PopupContent style={{ width: '360px' }}>
            <PopupHeader headerSizes={'h4 h5'}>Ваш город {stateGlobal.currentCity?.name}?</PopupHeader>
            <PopupClose />
            <div className={s['popup-city-check-content']}>
              <Button sizes='M' mode='white-border' onClick={handleClickCheckCitySelect}>
                Другой
              </Button>
              <Button sizes='M' mode='black' onClick={handleClickCheckCityAgree}>
                Верно
              </Button>
            </div>
          </PopupContent>
        </Popup>
      </div>
    </>
  )
}

type PropsCityContent = {
  handleClose: () => void
}

const filterCity = (list) => list.filter((cityItem) => cityItem.visible)

const CityContent: FC<PropsCityContent> = ({ handleClose }) => {
  const dispatch = useAppDispatch()
  const router = useRouter()
  const { isMobile } = useClientResponsive()
  const stateGlobal = useAppSelector((state) => state.global)
  const thisCityList = filterCity(stateGlobal.cityList)
  const [cityGroups, setCityGroups] = useState(null)
  const [citySearchList, setCitySearchList] = useState([])
  const [sliderRef] = useKeenSlider({
    mode: 'free',
    slides: {
      perView: 'auto',
      spacing: 4,
    },
    selector: `.${s['button-city-slide']}`,
  })

  const [searchField, setSearchField] = useState(
    getDefaultField('search', {
      settings: {
        placeholder: '',
      },
    }),
  )

  useEffect(() => {
    thisCityList && setCityGroups(() => groupItemsByName(thisCityList))
  }, [thisCityList.length])

  const handleSearchCities = (value) => {
    const resultCitySearchList = !value.trim()
      ? []
      : thisCityList.filter((city) => {
          const cityNameLowerCase = city.name.toLowerCase()
          const valueLowerCase = value.toLowerCase()
          return cityNameLowerCase.includes(valueLowerCase)
        })

    if (value.trim() && !resultCitySearchList.length) {
      EGG.citySelect.event_cities_not_found_view()
    }

    setCitySearchList(() => resultCitySearchList)
  }

  const handleRedirect = (cityObject) => {
    handleClose()
    EGG.citySelect.button_city_another_click({ city_name: cityObject.name })

    dispatch(changeCityAC())
    router.push(`/${cityObject.slug}/restaurants/`, `/${cityObject.slug}/restaurants/`, { scroll: false })

    // Выполняется если:
    // - у пользователя появился попап с вопросом о городе
    // - нажал кнопку "Другой"
    // - выбрал город в попапе списка городов
    if (stateGlobal.needAskCity) dispatch(setConfirmUserCity(cityObject))
  }

  const getPopularCities = useMemo(() => {
    const listHighlightCities = thisCityList?.filter((city) => city.highlight)
    listHighlightCities.sort((cityA, cityB) => cityA.order - cityB.order)
    return listHighlightCities
  }, [thisCityList])

  return (
    <>
      <Search field={searchField} setField={setSearchField} handleChange={handleSearchCities} />

      <div className={s['popular-cities-wrapper']}>
        <ul className={`${s['popular-cities']} keen-slider`} ref={sliderRef}>
          {getPopularCities.map((city) => (
            <a key={city.id} className={s['button-city-slide']} onClick={() => handleRedirect(city)}>
              <Button sizes='M' mode='white-border'>
                {city.name}
              </Button>
            </a>
          ))}
        </ul>
      </div>

      {!isMobile && <hr className={s.separator} />}

      {!!searchField.value && (
        <div className={s.content}>
          <div className={`${s['content-shadow']} ${s['top']}`}></div>

          <ul className={s['search-city-list']}>
            {citySearchList.length ? (
              citySearchList.map((city) => {
                return (
                  <li className={s['city-element']} key={city.slug}>
                    <a className={s['city-element-link']} onClick={() => handleRedirect(city)}>
                      {city.name}
                    </a>
                  </li>
                )
              })
            ) : (
              <div className={s['nothing-found']}>
                <Text sizes={'M'}>Ничего не найдено</Text>
              </div>
            )}
          </ul>

          <div className={`${s['content-shadow']} ${s['bottom']}`}></div>
        </div>
      )}

      {!searchField.value && (
        <>
          <div className={s.content}>
            <div className={`${s['content-shadow']} ${s['top']}`}></div>
            <ul className={s['groups-list']}>
              {cityGroups?.map((group, index) => {
                return (
                  <li key={index} className={s['groups-element']}>
                    <Title tag='h3' className={s['groups-title']}>
                      {group.letter}
                    </Title>

                    {group.items.length > 0 && (
                      <ul className={s['city-list']}>
                        {group.items.map((city) => {
                          return (
                            <li className={s['city-element']} key={city.slug}>
                              <a className={s['city-element-link']} onClick={() => handleRedirect(city)}>
                                {city.name}
                              </a>
                            </li>
                          )
                        })}
                      </ul>
                    )}
                  </li>
                )
              })}
            </ul>
            <div className={`${s['content-shadow']} ${s['bottom']}`}></div>
          </div>
        </>
      )}
    </>
  )
}

export default memo(City)
