import { sendClickHouseAnalytic } from '@shared/api/analytics/template'

// Уникальные события для главной страницы
export const EGGAppHome = {
  // 1. Отобразился экран
  appear_screen_page: () =>
    sendClickHouseAnalytic({
      event_name: 'screen_appear',
    }),
}
