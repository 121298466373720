import React, { FC, memo } from 'react'
import s from './BuildTextarea.module.sass'
import { InputTitle } from '@shared/ui/Form/Single/InputTitle'
import { InputHint } from '@shared/ui/Form/Single/InputHint'
import { Textarea } from '@shared/ui/Form/Single/Textarea'
import { useFieldHint } from '@shared/lib/fields/hooks/useFieldHint/useFieldHint'
import { FieldBase, FieldTextarea } from '@shared/types/fields/fields'
import { ErrorBoundary } from '../../../../lib/components/ErrorBoundary'

interface Props {
  field: FieldTextarea
  setField: React.Dispatch<React.SetStateAction<FieldBase>>
  // eslint-disable-next-line
  handleChange?: (value: string) => void
  className?: string
}

const BuildTextarea: FC<Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = ({
  field,
  setField,
  handleChange,
  className = '',
  ...props
}) => {
  const [hint] = useFieldHint(field)

  const setValue = (value: string): void => {
    setField((prev) => ({
      ...prev,
      value: value,
      error: '',
    }))

    handleChange && handleChange(value)
  }

  const classes = {
    disabled: !field.value && field.disabled ? s.disabled : '',
    disabledValue: !!field.value && field.disabled ? s.disabled__value : '',
  }

  return (
    <ErrorBoundary>
      {field.visible && (
        <div className={`${classes.disabled} ${classes.disabledValue} ${className}`} {...props}>
          <InputTitle
            nameField={field.name}
            title={field.settings.label}
            showRequired={field.required && field.settings.showRequired}
          />
          <Textarea field={field} handleValue={setValue} />
          <InputHint text={hint.text} status={hint.status} />
        </div>
      )}
    </ErrorBoundary>
  )
}

export default memo(BuildTextarea)
