import React, { FC, useMemo, useState } from 'react'
import s from './SpecialOffer.module.sass'

import { RestaurantSpecialOfferPopup } from '@widgets/Restaurant/RestaurantSpecialOffer/ui/RestaurantSpecialOfferPopup'
import TypeAndPrice from '@widgets/Restaurant/ResturantCards/RestCard/ui/Components/TypeAndPrice/TypeAndPrice'
import { TemplateCardProps } from '@widgets/Restaurant/ResturantCards/RestCard/type/TemplateCardProps'
import { Template } from '@widgets/Restaurant/ResturantCards/RestCard/ui/CardTypes/Template'

import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { IconButton } from '@shared/ui/Actions/IconButton'
import { Text } from '@shared/ui/Typography/Text'

import IconDots from '@icons/icon-dots.svg'
import IconLabelArrow from '@icons/icon-arrow-dropdown.svg'

import { RestaurantMenuPopup } from '@widgets/Restaurant/RestaurantMenu/ui/RestaurantMenuPopup'

const SpecialOffer: FC<
  TemplateCardProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>
> = (props) => {
  const [isOpenModalOfferCommon, setIsOpenModalOfferCommon] = useState(false)
  const [isOpenModalOfferMenu, setIsOpenModalOfferMenu] = useState(false)

  const dataForLabel = useMemo(() => {
    const { special, news, dishes } = props.data.offers

    if (special) return { type: 'special', data: special }
    else if (news) return { type: 'news', data: news }
    else if (dishes) return { type: 'dishes', data: dishes }
    else return null
  }, [props.data.offers])

  const getDescription = (dataProps) => {
    const { type, data } = dataProps
    if (type === 'special' || type === 'news') return data.title
    else if (type === 'dishes')
      return (
        <>
          <span>Новые блюда: </span>
          {data.map((el, index) => (
            <span key={index}>
              {el.title}
              {index !== data.length - 1 ? ', ' : ''}
            </span>
          ))}
        </>
      )
  }

  const handleClickLabelSpecialOffer = () => {
    setIsOpenModalOfferCommon(() => true)
  }

  const handleClickLabelMenu = () => {
    setIsOpenModalOfferMenu(() => true)
  }

  const handleCloseModalCommon = () => {
    setIsOpenModalOfferCommon(() => false)
  }

  const handleCloseModalMenu = () => {
    setIsOpenModalOfferMenu(() => false)
  }

  const price = props.data.price

  return (
    <ErrorBoundary>
      <Template {...props}>
        {/*<TastyClub tastyClub={props.data.discounts.tastyclub} />*/}

        <TypeAndPrice
          restType={props.data.type}
          tag={props.data?.tags?.main_tag}
          priceRange={price.level}
          priceLink={price.tag.url}
        />
        {dataForLabel && (
          <>
            <div className={s['action-preview-label']}>
              <IconLabelArrow className={s['action-preview-label__arrow']} />
              <div className={s['action-preview-label__description-wrapper']}>
                <Text sizes={'S'} className={s['action-preview-label__description']}>
                  {getDescription(dataForLabel)}
                </Text>
              </div>

              <IconButton
                id={s['action-preview-label-button']}
                sizes={'XS'}
                mode={'white'}
                icon={IconDots}
                className={s['action-preview-label__button']}
                onClick={dataForLabel.type === 'dishes' ? handleClickLabelMenu : handleClickLabelSpecialOffer}
              />
            </div>

            {isOpenModalOfferCommon && (
              <RestaurantSpecialOfferPopup
                title={dataForLabel.data.title}
                type_and_date={dataForLabel.data.type_verbose}
                description={dataForLabel.data.text}
                img={dataForLabel.data.photo}
                handleClose={handleCloseModalCommon}
              />
            )}

            {isOpenModalOfferMenu && (
              <RestaurantMenuPopup
                restTitle={props.data.name}
                dishes={props.data.offers.dishes}
                handleClose={handleCloseModalMenu}
              />
            )}
          </>
        )}
      </Template>
    </ErrorBoundary>
  )
}

export default SpecialOffer
