import React, { FC, useState } from 'react'
import s from './MenuMobile.module.sass'
import { useAppSelector } from '@app/model/store'
import { MenuLink } from '@widgets/Common/Header/ui/MenuLink'
import { Carousel } from '@shared/ui/Layout/Carousel'

function removeURLParams(url) {
  const output = url.split('?')[0]
  return output
}

// TODO: add jsdoc, ? takeout thisName & lowercase to upper-fn, ? takeout func
const addUTM = (thisLink, thisName = '') => {
  const UTM_STR = '?utm_source=restaurants&utm_medium=referral&utm_campaign=headline'
  const isRestsLink = thisName.toLowerCase() === 'рестораны'

  let filteredLink
  filteredLink = thisLink.at(-1) === '/' ? thisLink : thisLink + '/'
  filteredLink = filteredLink.indexOf('?') >= 0 ? removeURLParams(filteredLink) : filteredLink

  return isRestsLink ? filteredLink : filteredLink + UTM_STR
}

const Skeleton: FC = () => {
  const stateGlobal = useAppSelector((state) => state.global)

  const [menuItems, setMenuItems] = useState(() => {
    const filterArray = stateGlobal.topline?.filter((item) => item.MainLink.Name !== 'ВСЕ') || []
    return [
      ...filterArray,
      {
        MainLink: {
          Name: 'daily',
          Url: addUTM('https://daily.afisha.ru/'),
        },
      },
    ]
  })

  return (
    <nav className={s.nav} role='navigation' aria-label='Разделы сайта'>
      {menuItems && (
        <Carousel>
          {/*@ts-ignore*/}
          <Carousel.Slider spacingMobile={4}>
            {menuItems.map((item, i) =>
              item.MainLink.Name === 'daily' ? (
                <MenuLink
                  key={item.MainLink.Name}
                  dataId={item.MainLink.Name}
                  link={addUTM(item.MainLink.Url, item.MainLink.Name)}
                  role='menuitem'
                  className={s['nav-item']}
                  style={{ minWidth: '89px' }}
                  daily={item.MainLink.Name === 'daily'}
                />
              ) : (
                <MenuLink
                  key={item.MainLink.Name}
                  dataId={item.MainLink.Name}
                  link={addUTM(item.MainLink.Url, item.MainLink.Name)}
                  text={item.MainLink.Name}
                  selected={item.MainLink.Name.toLowerCase() === 'рестораны'}
                  role='menuitem'
                  className={s['nav-item']}
                />
              ),
            )}
            {/*@ts-ignore*/}
          </Carousel.Slider>
        </Carousel>
      )}
    </nav>
  )
}

export default Skeleton
