import { sendClickHouseAnalytic, sendYandexMetricAnalytic } from '@shared/api/analytics/template'
import { TypeMappingRestOutput } from '@shared/api/middleware/mappingAPI/restaurant/type'
import { PickAnalytic } from '@shared/api/analytics/types/types'

type TypeAnalyticRestCard = PickAnalytic<
  'restaurant_name' | 'object_id' | 'has_reserve' | 'afisha_selected' | 'is_promo_card' | 'has_promo_offer',
  'rating'
>

const getDefaultData = (rest: TypeMappingRestOutput): TypeAnalyticRestCard => {
  const result: TypeAnalyticRestCard = {
    restaurant_name: rest.name,
    object_id: rest.id,
    has_reserve: rest.booking.afisha.active || rest.booking.tomesto.active,
    afisha_selected: rest.categories.selection,
    is_promo_card: rest.categories.advert,
    has_promo_offer: !!rest.offers.special || !!rest.offers.news,
  }

  if (rest.feedback.rating) {
    result.rating = rest.feedback.rating.toFixed(1)
  }

  return result
}

type TypePropsDefault = PickAnalytic<'section_name', 'section_index'>

export const EGGRestaurant = {
  card_click: (rest: TypeMappingRestOutput, props: TypePropsDefault) => {
    sendClickHouseAnalytic({
      event_name: 'element_click',
      object_type: 'restaurant',
      element_type: 'rest_card',
      ...getDefaultData(rest),
      ...props,
    })
  },

  card_view: (rest: TypeMappingRestOutput, props: TypePropsDefault) =>
    sendClickHouseAnalytic({
      event_name: 'element_view',
      object_type: 'restaurant',
      element_type: 'rest_card',
      ...getDefaultData(rest),
      ...props,
    }),

  click_booking: (
    rest: TypeMappingRestOutput,
    props: PickAnalytic<'section_name', 'section_index' | 'section_type'>,
  ) => {
    sendClickHouseAnalytic({
      event_name: 'element_click',
      object_type: 'restaurant',
      element_type: 'reserve',
      ...getDefaultData(rest),
      ...props,
    })

    if (props.section_type === 'main_slider') {
      sendYandexMetricAnalytic({ id: 27079337, type: 'reachGoal', event: 'reservation-slider' })
      sendYandexMetricAnalytic({ id: 27508533, type: 'reachGoal', event: 'reservation-slider' })
    } else {
      sendYandexMetricAnalytic({ id: 27079337, type: 'reachGoal', event: 'reservation' })
      sendYandexMetricAnalytic({ id: 27508533, type: 'reachGoal', event: 'reservation' })
    }
  },

  click_add_favorite: (rest: TypeMappingRestOutput, props: TypePropsDefault) =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      object_type: 'restaurant',
      element_type: 'add_to_fav',
      ...getDefaultData(rest),
      ...props,
    }),

  click_remove_favorite: (rest: TypeMappingRestOutput, props: TypePropsDefault) =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      object_type: 'restaurant',
      element_type: 'remove_from_fav',
      ...getDefaultData(rest),
      ...props,
    }),
}
