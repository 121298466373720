import React, { FC, ReactNode, useContext } from 'react'

import s from './Content.module.sass'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { CardTwoBlockSkeletonContext } from '@shared/ui/Layout/CardTwoBlock/Context'

interface Props {
  children?: ReactNode
  className?: string
}

const Skeleton: FC<Props> = ({ children, className = '' }) => {
  const [context, setContext] = useContext(CardTwoBlockSkeletonContext)

  const defaultAttributes = {
    rx: '8',
    ry: '8',
    'data-size': context.size,
  }
  return (
    <ErrorBoundary>
      {children || (
        <>
          <rect className={`${s['skeleton-item']} ${s['skeleton-item--1']}`} {...defaultAttributes} />
          <rect className={`${s['skeleton-item']} ${s['skeleton-item--2']}`} {...defaultAttributes} />
          <rect className={`${s['skeleton-item']} ${s['skeleton-item--3']}`} {...defaultAttributes} />
        </>
      )}
    </ErrorBoundary>
  )
}

export default Skeleton
