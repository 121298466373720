import React, { FC, memo, useMemo } from 'react'
import { Button } from '../../Actions/Button'
import s from './PopupNotice.module.sass'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { Text } from '@shared/ui/Typography/Text'
import { ErrorBoundary } from '../../../lib/components/ErrorBoundary'

interface Props {
  status?: 'send' | 'error'
  text: string
  id: number
  // eslint-disable-next-line
  handleClose: (id: number) => void
  className?: string
}

// TODO: Нужно использовать константы из STATUSES
const textForStatus = {
  send: 'Отправлено. Спасибо!',
  error: 'Что-то пошло не так. Форма не отправлена. Попробуйте позже еще раз.',
}

const PopupNotice: FC<Props> = ({ status, text, id, handleClose, className = '' }) => {
  const { isMobile } = useClientResponsive()

  const handleCloseInner = () => handleClose(id)

  const getText = useMemo(() => {
    if (text) return text
    else if (status) return textForStatus[status]
    else {
      console.error('Ошибка! Отсутствует text или неверно указан status')
      return 'Ошибка! Отсутствует text или неверно указан status'
    }
  }, [text, status])

  return (
    <ErrorBoundary>
      <div className={`${s.wrapper} ${s[`wrapper--${isMobile ? 'mobile' : 'desktop'}`]}`}>
        <div className={`${s.notice} ${className}`}>
          <Text sizes={'M'} className={s.text}>
            {getText}
          </Text>
          <Button sizes='S' mode='white' onClick={handleCloseInner} className={s.button}>
            Окей
          </Button>
        </div>
      </div>
    </ErrorBoundary>
  )
}

export default memo(PopupNotice)
