import { globalStore } from '@app/model/store'
import { setInitState, setOpened, setPrefilledData } from '@widgets/Common/ModalFoundMistake/model/reduce'

export const openModal = () => (dispatch) => {
  const { user } = globalStore.getState().global

  if (user.data) {
    dispatch(
      setPrefilledData({
        full_name: user.data.username,
        email: user.data.email,
      }),
    )
  }

  dispatch(setOpened(true))
}

export const closeModal = () => (dispatch) => {
  dispatch(setInitState())
}
