import React, { useEffect } from 'react'
import { ContainerLongCenter } from '@shared/ui/Layout/Containers/ContainerLongCenter'
import { useEffectOnce } from '@shared/lib/hooks/useEffectOnce'
import s from './RestReviews.module.sass'
import { useRouter } from 'next/router'
import { Reviews } from '@entities/Restaurant/Reviews'
import { useAppDispatch, useAppSelector } from '@app/model/store'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { Title } from '@shared/ui/Typography/Title'
import { LoadingSpinner } from '@shared/ui/Feedback/LoadingSpinner'
import { loadMoreReviews } from '@entities/Restaurant/Reviews/model/createAsyncThunk'
import { ErrorBoundary } from '../../6_shared/lib/components/ErrorBoundary'
import { TypeMappingReviewOutput } from '@shared/api/middleware/mappingAPI/review/type'
import { Pagination } from '@features/Common/Pagination'

interface RestItemProps {
  shouldOpenBooking: boolean
  slug: string
  restId: string
  url_slug: string
  global_data: object
  initial_data: object
  rest_item_data: object
  router_info: object
}

const RestReviews = (props: RestItemProps) => {
  const router = useRouter()
  const stateReviews = useAppSelector((state) => state.entities.restaurant_reviews)
  const { user_reviews, other_reviews } = stateReviews.lists
  const dispatch = useAppDispatch()
  const { isMobile, isTablet } = useClientResponsive()
  const selectFilter = stateReviews.filters.rates.find((filter) => filter.select)
  const userReviews = user_reviews.filter((review: TypeMappingReviewOutput) => {
    const isLike = selectFilter.type === 'positive' && review.like
    const isDislike = selectFilter.type === 'negative' && !review.like
    const isAll = selectFilter.type === 'all'

    return isLike || isDislike || isAll
  })

  useEffectOnce(() => {
    // @ts-ignore
    if (props?.hasRedirect?.state) {
      // @ts-ignore
      router.replace(props?.hasRedirect.path)
    }
    // @ts-ignore
  }, [])

  return (
    <ErrorBoundary>
      <main>
        <ContainerLongCenter classNameContainer={s.container}>
          {/*@ts-ignore*/}
          <Reviews restaurant={{ name: props.data.name, id: props.data.id }}>
            <Title sizes={'h1 h3'} className={s.title}>
              {/*// @ts-ignore*/}
              Отзывы о ресторане {props.data.name}
            </Title>
            {/*// @ts-ignore*/}
            {(isMobile || isTablet) && <Reviews.Create mode={'modal'} />}
            <div className={s.filters}>
              {/*// @ts-ignore*/}
              <Reviews.Filters />
            </div>

            {(!!other_reviews.length || !!userReviews.length) && (
              <div className={s.list}>
                {userReviews?.map((review) => (
                  // @ts-ignore
                  <Reviews.Item key={review.id} data={review} mode={'row'} />
                ))}
                {other_reviews?.map((item) => (
                  // @ts-ignore
                  <Reviews.Item key={item.id} data={item} mode={'row'} />
                ))}
              </div>
            )}

            <Pagination
              mode={'load-more'}
              autoloadingOnMobile={true}
              currentPage={stateReviews.pagination?.page.current.number}
              pageCount={stateReviews.pagination?.page.count}
              handleLoadMore={() => dispatch(loadMoreReviews())}
              analytic={{ section_name: 'Отзывы ресторана' }}
              className={s.pagination}
            />
          </Reviews>
        </ContainerLongCenter>
      </main>
    </ErrorBoundary>
  )
}

export default RestReviews
