import React, { FC } from 'react'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { MenuDesktop } from '@widgets/Common/Header/ui/Menu/ui/MenuDesktop'
import { MenuMobile } from '@widgets/Common/Header/ui/Menu/ui/MenuMobile'
import { useAppSelector } from '@app/model/store'
import { MenuSkeleton } from '@widgets/Common/Header/ui/Menu/index'

const Menu: FC = () => {
  const { isMobile } = useClientResponsive()
  const stateGlobal = useAppSelector((state) => state.global)

  if (stateGlobal.topline) {
    return <>{isMobile ? <MenuMobile /> : <MenuDesktop />}</>
  } else return <MenuSkeleton />
}

export default Menu
