import React, { FC, memo, useEffect } from 'react'
import s from './RestaurantFilters.module.sass'
import { globalStore, useAppDispatch, useAppSelector } from '@app/model/store'
import { applyFilters, initRestaurantFilters } from '@features/Restaurant/RestaurantFilters/model/createActions'
import { FilterListTemplate } from '@features/Restaurant/RestaurantFilters/ui/FilterListTemplate'
import { FilterOnceTemplate } from '@features/Restaurant/RestaurantFilters/ui/FilterOnceTemplate'
import {
  TypeFilterByKeyListCheckboxes,
  TypeFiltersByKeyAll,
  TypeFiltersByKeyListButtons,
  TypeFiltersByKeyOnce,
  TypeOutput,
} from '@features/Restaurant/RestaurantFilters/model/types'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { resetStateFilters } from '@features/Restaurant/RestaurantFilters/model/reducer'
import { FilterButtonsTemplate } from '@features/Restaurant/RestaurantFilters/ui/FilterButtonsTemplate'
import { RestaurantFiltersSkeleton } from '@features/Restaurant/RestaurantFilters/index'

type TypeFilters = 'metro' | 'kitchen' | 'rate' | 'price' | 'afishaSelected' | 'hasBooking' | 'openNow'

interface Props {
  preloadingResults?: boolean
  handleLoading?: (value: boolean) => void
  handleOutputData: (data: TypeOutput) => void
  selected: string[] // slugs
  filters?: TypeFilters[] | 'all'
}

const RestaurantFilters: FC<Props> = ({
  preloadingResults = true,
  handleLoading,
  handleOutputData,
  selected,
  filters = 'all',
}) => {
  const globalState = useAppSelector((store) => store)
  const stateFilters = globalState.features.restaurant_filters
  const dispatch = useAppDispatch()

  useEffect(() => {
    handleLoading && handleLoading(stateFilters.output.loading)
  }, [stateFilters.output.loading])

  const handleSendOutputData = async () => {
    if (!stateFilters.ready) return
    await dispatch(applyFilters())
    handleOutputData(globalStore.getState().features.restaurant_filters.output)
  }

  useEffect(() => {
    if (globalState.global.cityRead) {
      dispatch(initRestaurantFilters({ tags: selected, preloading: preloadingResults }))
    } else {
      dispatch(resetStateFilters())
    }

    return () => {
      dispatch(resetStateFilters())
    }
  }, [globalState.global.cityRead, selected])

  if (!stateFilters.ready) {
    return <RestaurantFiltersSkeleton />
  }
  return (
    <ErrorBoundary>
      <div className={s.wrapper} data-is-visible={stateFilters.visible}>
        {Object.keys(stateFilters.filters).map((typeFilter: TypeFiltersByKeyAll) => {
          const filterTypeListTemplate: TypeFilterByKeyListCheckboxes[] = ['establishment', 'kitchen', 'metro']
          const filterTypeButtonsTemplate: TypeFiltersByKeyListButtons[] = ['price', 'rating']
          const filterTypeOnceTemplate: TypeFiltersByKeyOnce[] = ['afishaSelected', 'hasBooking', 'openNow']

          if (!stateFilters.filters[typeFilter].visible) return

          if (filterTypeOnceTemplate.includes(typeFilter as TypeFiltersByKeyOnce)) {
            return (
              <React.Fragment key={`${typeFilter}_wrap`}>
                <FilterOnceTemplate
                  key={typeFilter}
                  typeFilter={typeFilter as TypeFiltersByKeyOnce}
                  handleChange={handleSendOutputData}
                />
                {typeFilter === 'openNow' && <div className={s.divider}></div>}
              </React.Fragment>
            )
          }

          if (filterTypeListTemplate.includes(typeFilter as TypeFilterByKeyListCheckboxes)) {
            return (
              <FilterListTemplate
                key={typeFilter}
                typeFilter={typeFilter as TypeFilterByKeyListCheckboxes}
                handleSave={handleSendOutputData}
                handleRemove={handleSendOutputData}
              />
            )
          }

          if (filterTypeButtonsTemplate.includes(typeFilter as TypeFiltersByKeyListButtons)) {
            return (
              <FilterButtonsTemplate
                key={typeFilter}
                typeFilter={typeFilter as TypeFiltersByKeyListButtons}
                handleSave={handleSendOutputData}
                handleRemove={handleSendOutputData}
              />
            )
          }
        })}
      </div>
    </ErrorBoundary>
  )
}

export default memo(RestaurantFilters)
