import { sendClickHouseAnalytic } from '@shared/api/analytics/template'
import { PickAnalytic } from '@shared/api/analytics/types/types'

type TypeDefaultProps = PickAnalytic<'section_index'>
export const EGGPromoCard = {
  promo_card_click: (props: TypeDefaultProps) =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      element_type: 'promo_card',
      section_index: props.section_index,
    }),

  promo_card_view: (props: TypeDefaultProps) =>
    sendClickHouseAnalytic({
      event_name: 'element_view',
      element_type: 'promo_card',
      section_index: props.section_index,
    }),
}
