import React, { FC } from 'react'
import s from './QuickBookingRestShortB.module.sass'
import { useAppSelector } from '@app/model/store'
import { QuickBookingRestCSkeleton } from '@widgets/Restaurant/QuickBooking/variants/QuickBookingRestC/index'
import { TypeMappingRestOutput } from '@shared/api/middleware/mappingAPI/restaurant/type'
import { QuickBookingSelectors } from '@widgets/Restaurant/QuickBooking/components/QuickBookingSelectors'
import { Button } from '@shared/ui/Actions/Button'
import { PromoBadgeCTA } from '@shared/ui/Feedback/PromoBadges/PromoBadgeCTA'
import { EGG } from '@shared/api/analytics'

interface Props {
  className?: string
}

const QuickBookingRestShortBWrapper: FC<Props> = ({ className = '' }) => {
  const stateRestaurant = useAppSelector((state) => state.pages.rest_item.data)
  const hasBookingAfisha = stateRestaurant.booking.afisha.active
  if (!hasBookingAfisha) return

  return stateRestaurant ? <QuickBookingRestShortB className={className} /> : <QuickBookingRestCSkeleton />
}

const QuickBookingRestShortB: FC<Props> = ({ className = '' }) => {
  const stateRestaurant = useAppSelector((state) => state.pages.rest_item.data)

  const handleSelectGuest = (data, value) => {
    EGG.entity.booking.selectors_quick_booking_select_guest(data, { input: value })
  }

  const handleSelectDate = (data, value) => {
    EGG.entity.booking.selectors_quick_booking_select_date(data, { input: value })
  }
  const handleSelectTime = (data, value) => {
    EGG.entity.booking.selectors_quick_booking_select_time(data, { input: value })
  }

  const handleOpenBooking = (data) => {
    EGG.entity.booking.selectors_quick_booking_apply_click(data)
  }

  return (
    <QuickBookingSelectors
      restData={stateRestaurant as TypeMappingRestOutput}
      classNameWrapper={`${s.schedule} ${className}`}
      classNameSelector={s.selector}
      onSelectGuest={handleSelectGuest}
      onSelectDate={handleSelectDate}
      onSelectTime={handleSelectTime}
      onOpenBooking={handleOpenBooking}
      buttonElement={
        <div className={s.buttons}>
          {stateRestaurant.discounts.remarked && (
            <PromoBadgeCTA sizes={'XL'} className={`${s['promo-badge']}`}>
              -{stateRestaurant.discounts.remarked}₽
            </PromoBadgeCTA>
          )}
          <Button sizes={'XL'} mode={'black'}>
            Забронировать
          </Button>
        </div>
      }
    />
  )
}

export default QuickBookingRestShortBWrapper
