const fallbackCopyTextToClipboard = (text, callback) => {
  // create
  const textArea = document.createElement('textarea')
  textArea.value = text

  // avoid scrolling to bottom
  textArea.style.top = '0'
  textArea.style.left = '0'
  textArea.style.position = 'fixed'
  textArea.style.visibility = 'hidden'
  textArea.style.opacity = '0'

  // add to dom
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  // execCommand
  try {
    const successful = document.execCommand('copy')
    callback && successful && callback(text)
    const msg = successful ? 'successful' : 'unsuccessful'
    console.log('Fallback: Copying text command was ' + msg)
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err)
  }

  // clear
  document.body.removeChild(textArea)
}

const copyTextToClipboard = (text, callback) => {
  const isClipboardAvailable = navigator?.clipboard ? navigator.clipboard : null

  if (isClipboardAvailable) {
    navigator.clipboard.writeText(text).then(
      function () {
        console.info('async: copying to clipboard was successful!')
        callback && callback(text)
      },
      function (err) {
        console.error('async: could not copy text: ', err)
      },
    )
  } else {
    fallbackCopyTextToClipboard(text, callback)
  }
}

export default copyTextToClipboard
