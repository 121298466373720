import React, { FC } from 'react'
import s from './QuickBookingArticle.module.sass'
import { TypeMappingRestOutput } from '@shared/api/middleware/mappingAPI/restaurant/type'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { useGetABTests } from '@shared/lib/hooks/useGetABTests'
import { QuickBookingArticleB } from '@widgets/Restaurant/QuickBooking/variants/QuickBookingArticleB'
import { QuickBookingArticleC } from '@widgets/Restaurant/QuickBooking/variants/QuickBookingArticleC'
import { QuickBookingArticleD } from '@widgets/Restaurant/QuickBooking/variants/QuickBookingArticleD'

interface Props {
  restData: TypeMappingRestOutput
  className?: string
}

const QuickBookingArticle: FC<Props> = (props) => {
  const ABTests = useGetABTests()
  const ABTestBooking = ABTests.find((test) => test.name === 'AB_OPEN_BOOKING_1')
  if (!ABTestBooking || ABTestBooking?.group === 'A') return

  // AB enabled for group D
  if (ABTestBooking) {
    ABTestBooking.group = 'D'
  }

  return (
    <ErrorBoundary>
      {ABTestBooking.group === 'B' && <QuickBookingArticleB {...props} />}
      {ABTestBooking.group === 'C' && <QuickBookingArticleD {...props} />}
      {ABTestBooking.group === 'D' && <QuickBookingArticleC {...props} />}
    </ErrorBoundary>
  )
}

export default QuickBookingArticle
