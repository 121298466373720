import { createAsyncThunk } from '@reduxjs/toolkit'
import { API } from '@shared/api'
import { NextRouter } from 'next/router'

export const fetchSimilarRests = createAsyncThunk('restItem/fetchSimilarRests', async (router: NextRouter) => {
  // @ts-ignore
  return API.restaurant_similar_rests({ path: router.query.rest_slug, params: { page_size: 6, page: 1 } })
})

export const fetchNearestRests = createAsyncThunk('restItem/fetchNearestRests', async (router: NextRouter) => {
  // @ts-ignore
  return API.restaurant_nearest_rests({ path: router.query.rest_slug, params: { page_size: 6, page: 1 } })
})

export const fetchPopularRests = createAsyncThunk('restItem/fetchPopularRests', async (router: NextRouter) => {
  // @ts-ignore
  return API.restaurant_popular_rests({ path: router.query.rest_slug, params: { page_size: 6, page: 1 } })
})

export const fetchSelectionList = createAsyncThunk(
  'restItem/fetchSelectionList',
  async (data: { restId: number; cityId: number }) => {
    const response = await API.selection_list({
      params: {
        restaurant_id: String(data.restId),
        page_size: 6,
        page: 1,
        city_id: data.cityId,
      },
    })

    return response ? response.results : null
  },
)
