import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { ABGroups } from '@app/PageLayout/handlers/useQueryABTests'

type TypeABTest = { name: string; group: string; index: number }

const useGetABTests = () => {
  const [ABTests, setABTests] = useState<TypeABTest[]>([])
  const router = useRouter()

  useEffect(() => {
    const result = []
    Object.entries(ABGroups).forEach(([testName, groups]) => {
      const ABTestGroup = window.localStorage.getItem(testName)

      if (ABTestGroup && groups.includes(ABTestGroup)) {
        const ABTestIndex = Number(testName.slice(-1)) || null
        result.push({ name: testName, group: ABTestGroup, index: ABTestIndex })
      }
    })

    setABTests(result)
  }, [router])

  return ABTests
}

export { useGetABTests }
