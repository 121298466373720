import React, { memo } from 'react'
import { useEffectOnce } from '@shared/lib/hooks/useEffectOnce'

const IS_PROD = process.env.GITLAB_STATUS === '0'

/**
 * @function EggClickHouse - TODO описание
 */

const EggClickHouse = () => {
  const scriptDataEGG = `
    (function (window_, document_, script_, src_, ctor, callbacks_, apiKey_, counterID_) {
      (window_[callbacks_] = window_[callbacks_] || []).push(function () {
          try { window_.EGG = new window_[ctor](apiKey_, counterID_); } catch (e) { }
      });
      window_.EGG = (window_.EGG || {
          send: function () { var a = arguments; window_[callbacks_].push(function () { window_.EGG.send.apply(window_.EGG, a) }) },
      });
      n = document_.getElementsByTagName(script_)[0],
          script_ = document_.createElement(script_),
          script_.type = "text/javascript",
          script_.async = true,
          script_.src = src_,
          n.parentNode.insertBefore(script_, n);
  })(window, document, "script", "https://egg.afisha.ru/public/sdk-1.8.0.js", "_EggFisha", "eggfisha_callbacks_1", "iwKLN8NqoEeSDQOQ2VyjQ", 21)`

  useEffectOnce(() => {
    function addScriptEGG() {
      return new Promise((resolve, reject) => {
        const script_tag = document.createElement('script')
        script_tag.type = 'text/javascript'
        script_tag.text = scriptDataEGG

        document.body.appendChild(script_tag)
      })
    }

    setTimeout(() => {
      IS_PROD && addScriptEGG()
    }, 1000)
  }, [])

  return (
    <>
      {/* <noindex> */}
      <span className='visually-hidden'></span>
      {/* </noindex> */}
    </>
  )
}

export default memo(EggClickHouse)
