import React, { FC, ReactNode, useRef } from 'react'

import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import ContentLoader from 'react-content-loader'
import {
  SKELETON_FOREGROUND_COLOR,
  SKELETON_MAIN_COLOR,
  SKELETON_SPEED,
  SKELETON_STYLES,
} from '@shared/consts/skeleton'
import s from '@shared/ui/Layout/Cover/Cover.module.sass'
import { ContainerLong } from '@shared/ui/Layout/Containers/ContainerLong'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { useOnScreen } from '@widgets/Restaurant/Booking/lib/helper'

interface Props {
  classNameContainer?: string
  classNameContent?: string
}

const Skeleton: FC<Props> = ({ classNameContainer = '', classNameContent = '' }) => {
  const { isMobile } = useClientResponsive()
  const aside = useRef()
  const isVisible = useOnScreen(aside, { threshold: 0.15 })

  return (
    <ErrorBoundary>
      <ContainerLong
        tag={'section'}
        classNameContainer={`${s.container} ${classNameContainer}`}
        classNameContent={`${s.content} ${classNameContent}`}>
        <main className={`${s.main} ${s['main-skeleton']}`}>
          {!isMobile ? (
            <div className={s['absolute-skeleton']}>
              <ContentLoader
                speed={SKELETON_SPEED}
                backgroundColor={SKELETON_MAIN_COLOR}
                foregroundColor={SKELETON_FOREGROUND_COLOR}
                style={SKELETON_STYLES}
                className={s['svg-skeleton']}>
                <rect x='0' y='0' rx='16' ry='16' className={s['rect-skeleton']} />
              </ContentLoader>
            </div>
          ) : (
            <div className={s['absolute-skeleton']}>
              <ContentLoader
                speed={SKELETON_SPEED}
                backgroundColor={SKELETON_MAIN_COLOR}
                foregroundColor={SKELETON_FOREGROUND_COLOR}
                style={SKELETON_STYLES}
                className={s['svg-skeleton']}>
                <rect rx='8' ry='8' className={s['line-skeleton--1']} />
                <rect rx='12' ry='12' className={s['line-skeleton--2']} />
                <rect rx='8' ry='8' className={s['line-skeleton--3']} />
                <rect rx='8' ry='8' className={s['line-skeleton--4']} />
              </ContentLoader>
            </div>
          )}
        </main>

        <aside
          className={`${s['aside-wrapper']} ${s['aside-wrapper-skeleton']}`}
          data-visible={isMobile ? isVisible : true}
          ref={aside}>
          <div className={s['aside-container']}>
            <div className={`${s['aside']} ${s['aside-skeleton']}`}>
              <ContentLoader
                speed={SKELETON_SPEED}
                backgroundColor={SKELETON_MAIN_COLOR}
                foregroundColor={SKELETON_FOREGROUND_COLOR}
                style={SKELETON_STYLES}
                className={s['svg-skeleton']}>
                <rect x='0' y='0' className={s['rect-skeleton']} />
              </ContentLoader>
            </div>
          </div>
        </aside>
      </ContainerLong>
    </ErrorBoundary>
  )
}

export default Skeleton
