import React, { FC, ReactNode } from 'react'

import { DeleteMessagePopup } from '@entities/Restaurant/ReviewsNew/ui/DeleteMessagePopup'
import { ReviewPagination } from '@entities/Restaurant/ReviewsNew/ui/ReviewPagination'
import { EditMessagePopup } from '@entities/Restaurant/ReviewsNew/ui/EditMessagePopup'
import { FiltersReview } from '@entities/Restaurant/ReviewsNew/ui/FiltersReview'
import { CreateReview } from '@entities/Restaurant/ReviewsNew/ui/CreateReview'
import { ReviewItem } from '@entities/Restaurant/ReviewsNew/ui/ReviewItem'

import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'

type Props = {
  children: ReactNode[] | ReactNode
}

type Components = {
  Item: typeof ReviewItem
  Create: typeof CreateReview
  Filters: typeof FiltersReview
  Pagination: typeof ReviewPagination
  EditPopup: typeof EditMessagePopup
  DeletePopup: typeof DeleteMessagePopup
}

const Reviews: FC<Props> & Components = ({ children }) => {
  return (
    <ErrorBoundary>
      <>{children}</>
    </ErrorBoundary>
  )
}

export default Reviews

Reviews.Item = ReviewItem
Reviews.Create = CreateReview
Reviews.Filters = FiltersReview
Reviews.Pagination = ReviewPagination
Reviews.EditPopup = EditMessagePopup
Reviews.DeletePopup = DeleteMessagePopup
