import React, { FC } from 'react'
import s from './QuickBookingRestShort.module.sass'
import { useGetABTests } from '@shared/lib/hooks/useGetABTests'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { QuickBookingRestShortC } from '@widgets/Restaurant/QuickBooking/variants/QuickBookingRestShortC'
import { QuickBookingRestShortB } from '@widgets/Restaurant/QuickBooking/variants/QuickBookingRestShortB'
import { TypeDateTimeSlot } from '@shared/types/schedule/types'

interface Props {
  slotsData: {
    slots: TypeDateTimeSlot[]
    loading: boolean
    error: boolean
  }
  className?: string
}

const QuickBookingRestShort: FC<Props> = (props) => {
  const ABTests = useGetABTests()
  const ABTestBooking = ABTests.find((test) => test.name === 'AB_OPEN_BOOKING_1')
  if (!ABTestBooking || ABTestBooking?.group === 'A') return

  // AB enabled for group D
  if (ABTestBooking) {
    ABTestBooking.group = 'D'
  }

  return (
    <ErrorBoundary>
      {ABTestBooking.group === 'C' && <QuickBookingRestShortC {...props} />}
      {ABTestBooking.group === 'D' && <QuickBookingRestShortB className={props.className} />}
    </ErrorBoundary>
  )
}

export default QuickBookingRestShort
