import React, { FC } from 'react'

import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import ContentLoader from 'react-content-loader'
import {
  SKELETON_FOREGROUND_COLOR,
  SKELETON_MAIN_COLOR,
  SKELETON_SPEED,
  SKELETON_STYLES,
} from '@shared/consts/skeleton'
import { useCurrentSize } from '@shared/lib/helpers/classes'
import s from './MediaCardCompilation.module.sass'

interface Props {
  className?: string
  sizes: string
}

const Skeleton: FC<Props> = ({ className = '', sizes, ...props }) => {
  const [size] = useCurrentSize(sizes)

  const defaultAttributes = {
    rx: '16',
    ry: '16',
    'data-size': size,
  }

  return (
    <ErrorBoundary>
      <div className={`${s['skeleton-card']} ${className}`} data-size={size} {...props}>
        <ContentLoader
          speed={SKELETON_SPEED}
          backgroundColor={SKELETON_MAIN_COLOR}
          foregroundColor={SKELETON_FOREGROUND_COLOR}
          style={SKELETON_STYLES}
          width={'100%'}
          height={'100%'}
          data-size={size}>
          <rect className={s['skeleton-inner']} {...defaultAttributes} />
        </ContentLoader>
      </div>
    </ErrorBoundary>
  )
}

export default Skeleton
