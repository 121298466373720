import { sendClickHouseAnalytic } from '@shared/api/analytics/template'
import { UAParser } from 'ua-parser-js'
import { EGG } from '@shared/api/analytics'

// Глобальные события
export const EGGGlobal = {
  // Пользователь попал на версию с редизайном
  new_session_experiment: () =>
    sendClickHouseAnalytic({
      event_name: 'experiment',
      ab_group: 'b',
      ab_name: 'redesign',
    }),

  // Отправка базовых данных о пользователе при загрузке каждой страницы
  send_global_user_data: (count = 0) => {
    if (count >= 2) return

    const getSessionId = () => {
      const cookieData = document.cookie.match(/_egg21_SessionUUID=[^;]+/)
      return cookieData !== null ? cookieData[0].split('=')[1] : ''
    }
    const getDeviceId = () => {
      const cookieData = document.cookie.match(/_eggDeviceUUID=[^;]+/)
      return cookieData !== null ? cookieData[0].split('=')[1] : ''
    }

    async function detectAdBlock() {
      let adBlockEnabled = false
      const googleAdUrl = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js'
      try {
        await fetch(new Request(googleAdUrl)).catch((_) => (adBlockEnabled = true))
      } catch (e) {
        adBlockEnabled = true
      } finally {
        console.log(`AdBlock Enabled: ${adBlockEnabled}`)
      }
    }
    const isAdBlock = detectAdBlock() ? 1 : 0

    const parser = new UAParser()

    const sendData = {
      session_uuid: getSessionId(), // id сессии
      device_uuid: getDeviceId(), // id девайса
      url: window.location.href, // url просматриваемой страницы

      browser_adblock: isAdBlock, // наличие блокировщика рекламы (0 или 1)
      browser_platform: parser.getResult().engine.name, // платформа браузера
      browser_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone, // часовой пояс браузера
      browser_timezone_offset: new Date().getTimezoneOffset() / 60,
      browser_langs: window.navigator.language, // язык браузера
      browser_geolocation: !!navigator.geolocation, // разрешение браузера отслеживать геолокацию
      browser_notification: !!window.Notification, // разрешение браузера отправлять уведомления
      browser_resolution_x: window.screen.availWidth, // разрешение экрана
      browser_resolution_y: window.screen.availHeight, // разрешение экрана

      ua_class: parser.getResult().device.type, // тип девайса
      ua_device: parser.getResult().device.model, // девайс,
      ua_name: parser.getResult().browser.name, // браузер,
      ua_ver: parser.getResult().browser.version, // версия браузера,
      ua_ver_major: parser.getResult().browser.major, // мажорная версия браузера,
      ua_os: parser.getResult().os.version, // версия операционной системы
    }

    const sendRequest = () => {
      const eggGlobalVar = 'EGG'
      window[eggGlobalVar].send('pageview', {
        project_id: 21, // id проекта

        ip: '{{ A_USER_IP }}', // IP,
        referer: '{{ request.META.HTTP_REFERER }}', // страница, с которой перешли на просматриваемую страницу, включая внешние источники
        // @ts-ignore
        // eslint-disable-next-line no-constant-condition
        mobile: '{{ IS_MOBILE_REQUEST }}' === 'True' ? 1 : 0, // мобильная версия (0 и 1)
        city_id: '{{ request.city_id }}', // id города

        ...sendData,
      })
    }

    const eggGlobalVar = 'EGG'
    if (window[eggGlobalVar]) sendRequest()
    else {
      setTimeout(() => EGG.global.send_global_user_data(count + 1), 2000)
    }
  },
}
