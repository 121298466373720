import Link from 'next/link'
import React from 'react'
import s from './../../Header.module.sass'
import IconLogoRestSvg from '@icons/icon-afisha-restaurants-logo.svg'
import IconLogoTextSvg from '@icons/icon-afisha-logo-text.svg'
import { useAppSelector } from '@app/model/store'
import { useRouter } from 'next/router'
import { EGG } from '@shared/api/analytics'

const UTM_STR = '?utm_source=restaurants&utm_medium=referral&utm_campaign=headline'
const AFISHA_LINK = 'https://www.afisha.ru/' + UTM_STR

const IconLogoRest = React.forwardRef(function CustomComponent() {
  const router = useRouter()
  const stateGlobal = useAppSelector((state) => state.global)

  const slug = stateGlobal.currentCity?.slug || router.query?.city_slug
  const link = slug ? `/${slug}/restaurants/` : '/'
  const handleClick = () => {
    EGG.header.button_tab_click({ navigation_name: 'logo' })
  }

  return (
    <Link href={link} className={s['logo-main-link']} replace={true} onClick={handleClick} scroll={false}>
      <IconLogoRestSvg className={s['logo-main-image']} />
    </Link>
  )
})

const IconLogoText = React.forwardRef(function CustomComponent() {
  const handleClick = () => {
    EGG.header.button_tab_click({ navigation_name: 'logo' })
  }
  return (
    <a href={AFISHA_LINK} className={s['logo-text-link']} onClick={handleClick}>
      <IconLogoTextSvg className={s['logo-text-image']} />
    </a>
  )
})

export { IconLogoRest, IconLogoText }
