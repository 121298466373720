import React, { FC, forwardRef } from 'react'
import s from './Button.module.sass'
import { useCurrentSize } from '@shared/lib/helpers/classes'
import { ErrorBoundary } from '../../../lib/components/ErrorBoundary'

interface Props {
  sizes: string
  mode: 'white' | 'white-border' | 'white-shadow' | 'yellow-border' | 'black' | 'red-link'
  className?: string
  children: React.ReactNode
}

const Button = forwardRef<HTMLButtonElement, React.ButtonHTMLAttributes<HTMLButtonElement> & Props>(
  ({ sizes, mode, className = '', children, ...props }, ref) => {
    const [size] = useCurrentSize(sizes)

    return (
      <ErrorBoundary>
        <button
          type='button'
          data-size={size}
          data-mode={mode}
          className={`${s.button} ${className}`}
          // @ts-ignore
          ref={ref}
          {...props}>
          <div className={s.button__content}>{children}</div>
        </button>
      </ErrorBoundary>
    )
  },
)

export default Button
