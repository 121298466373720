import React, { FC } from 'react'
import s from './DeleteMessagePopup.module.sass'

import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { LoadingSpinner } from '@shared/ui/Feedback/LoadingSpinner'
import { Title } from '@shared/ui/Typography/Title'
import { Button } from '@shared/ui/Actions/Button'
import { Modal } from '@shared/ui/Overlays/Modal'

type Props = {
  type: 'review' | 'answer'
  onClose: () => void
  onDelete: () => void
  loading?: boolean
}

const DeleteMessagePopup: FC<Props> = ({ type, onClose, onDelete, loading }) => {
  const { isMobile } = useClientResponsive()

  const handleClose = () => onClose()
  const handleDelete = () => onDelete()

  return (
    <ErrorBoundary>
      <Modal
        size={'S'}
        buttonClose={false}
        handleClose={handleClose}
        title={isMobile && 'Отзыв на ресторан удалится с оценкой безвозвратно. Удалить отзыв и оценку?'}
        className={s.modal}>
        <div className={s.wrapper} data-loading={loading}>
          {!isMobile && (
            <Title tag={'h5'} sizes={'h3'} className={s.title}>
              Отзыв на ресторан удалится с оценкой безвозвратно. Удалить отзыв и оценку?
            </Title>
          )}

          <div className={s.buttons}>
            <Button sizes={'XL L'} mode={'white-border'} onClick={handleDelete} className={s.button}>
              Удалить отзыв
            </Button>

            <Button sizes={'XL L'} mode={'black'} onClick={handleClose} className={s.button}>
              Отменить
            </Button>
          </div>
        </div>
        <LoadingSpinner loading={loading} />
      </Modal>
    </ErrorBoundary>
  )
}

export default DeleteMessagePopup
