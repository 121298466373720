import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'
import { TypeState } from '@widgets/Common/Notices/model/types'

const initialState: TypeState = {
  notices: [],
  maxNotices: 10,
}

export const addTemporaryNotice = createAsyncThunk('notices/addTemporaryNotice', async (text, { dispatch }) => {
  const id = Math.random().toFixed(4)

  dispatch(addNotice({ id, text }))
  setTimeout(() => dispatch(removeNotice(id)), 6000)
})

const noticesSlice = createSlice({
  name: 'notices',
  initialState,

  reducers: {
    addNotice(state, action) {
      if (state.notices.length >= state.maxNotices) {
        state.notices.splice(0, 1)
      }
      state.notices.push(action.payload)
    },

    removeNotice(state, action) {
      state.notices = state.notices.filter((item) => action.payload !== item.id)
    },

    // Special reducer for hydrating the state
    extraReducers: {
      // @ts-ignore
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.notices,
        }
      },
    },
  },
})

export const { addNotice, removeNotice } = noticesSlice.actions

export default noticesSlice.reducer
