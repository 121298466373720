import React, { FC } from 'react'
import s from './FiltersReview.module.sass'

import { BtnFilterTag } from '@shared/ui/Form/Single/Filters/DefaultTag'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { Carousel } from '@shared/ui/Layout/Carousel'

type Props = {
  filters: {
    types: {
      id: number
      type: 'all' | 'positive' | 'negative' | 'unanswered'
      count: number
      title: 'Все' | 'Положительные' | 'Отрицательные' | 'Без ответа'
      visible: boolean
      select: boolean
    }[]
    // На будущее если появится фильтры по дате
    dates?: {
      id: number
      count: number
      title: 'Новые' | 'Старые' | 'Популярные'
      visible: boolean
      select: boolean
    }[]
  }
  onSelect: (data: { type: 'types' | 'dates'; data: object }) => void
}

const FiltersReview: FC<Props> = ({ filters, onSelect }) => {
  return (
    <ErrorBoundary>
      <div className={s['filters']}>
        <Carousel>
          <Carousel.Slider spacingMobile={4} spacingDesktop={4}>
            {filters.types.map(
              (filter) =>
                filter.visible && (
                  <BtnFilterTag
                    key={filter.id}
                    active={filter.select}
                    counter={filter.count}
                    onClick={() => onSelect({ type: 'types', data: filter })}>
                    {filter.title}
                  </BtnFilterTag>
                ),
            )}
          </Carousel.Slider>
        </Carousel>
      </div>
    </ErrorBoundary>
  )
}

export default FiltersReview
