// 1. https://confluence.rambler-co.ru/pages/viewpage.action?pageId=21070392
// 1.1 секция 'Показы рекламных ресторанов клиенту и клики по ним'
// 2. https://confluence.rambler-co.ru/pages/viewpage.action?pageId=36661580
// 2.1 секция 'Отправить статистику'

import parseCookie from '@shared/lib/helpers/parseCookie'

export type TypeAdvertisingCardRestVariety = 'feature' | 'nearest' | 'similar' | 'search' | 'selection'
export type TypeAdvertisingCardRestEvent = 'click' | 'visible'
const IS_PROD = process.env.GITLAB_STATUS === '0'

type TypeProps = {
  type: TypeAdvertisingCardRestEvent
  city_id: number
  rest_id: number
  variety: TypeAdvertisingCardRestVariety
}

type TypeRequestBody = {
  click: 0 | 1
  city_id: number
  rid: number
  channel: 'st' | 'ms'
  variety: TypeAdvertisingCardRestVariety
}
export const sendAdvertisingRestAnalytic = (data: TypeProps) => {
  if (!IS_PROD) return
  const URL = 'https://www.afisha.ru/rests/stat/'
  const reqToken = parseCookie('csrftoken')
  const bodyObject: TypeRequestBody = {
    click: data.type === 'click' ? 1 : 0,
    city_id: data.city_id,
    rid: data.rest_id,
    channel: window.innerWidth > 768 ? 'st' : 'ms',
    variety: data.variety,
  }

  let body = ''

  Object.keys(bodyObject).forEach((key, index) => {
    body = index === 0 ? `${key}=${bodyObject[key]}` : `${body}&${key}=${bodyObject[key]}`
  })

  const headersObj = {
    'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
    'X-CSRFToken': reqToken,
  }

  fetch(URL, {
    method: 'POST',
    credentials: 'include',
    headers: headersObj,
    body: body,
  })
    .then((response) => response.status >= 200 && response.status < 300)
    .then(() => {
      // eslint-disable-next-line no-console
      console.info(`variety = [${data.variety}],\
        click = [${data.type === 'click'}],\
        Item ID = ${data.rest_id}.`)
    })
    .catch((error) => {
      console.error(error)
    })
}
