import s from './Modal.module.sass'

import Image from 'next/image'
import React, { FC, memo, MouseEvent, ReactNode, useCallback, useEffect } from 'react'
import { FloatingPortal } from '@floating-ui/react'

import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { getPlaceholderImage } from '@shared/lib/helpers/getPlaceholderImage'
import { IconButton } from '@shared/ui/Actions/IconButton'
import { Title } from '@shared/ui/Typography/Title'
import { useBlockScrollOverlay } from '@shared/lib/hooks/useBlockScrollOverlay'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'

import IconClose from '@icons/icon--x-big.svg'
import IconCloseThin from '@icons/icon--x-thin.svg'

interface ModalProps {
  size: 'XS' | 'S' | 'M' | 'L' | 'XL'
  mobileMode?: 'dropdown' | 'dropdown-image'
  dropdownImage?: string
  title?: string
  subtitle?: ReactNode
  titleSizes?: string
  handleClose: () => void
  buttonClose?: boolean
  children: ReactNode
  className?: string
}

const Modal: FC<ModalProps> = ({
  size,
  mobileMode = 'dropdown',
  dropdownImage,
  title = '',
  subtitle,
  titleSizes = 'h2 h3',
  className = '',
  handleClose,
  buttonClose = true,
  children,
  ...props
}) => {
  useBlockScrollOverlay()

  const { isMobile } = useClientResponsive()
  const checkTargetClick = (e: MouseEvent<HTMLDivElement>) => {
    e.currentTarget === e.target && handleClose()
  }

  const isShowButtonCloseNearTitle = isMobile ? mobileMode === 'dropdown' && buttonClose : buttonClose

  const handleEscClose = useCallback((event) => {
    if (event.key === 'Escape') {
      handleClose()
    }
  }, [])

  // используем handleEscClose при нажатии на Esc для закрытия модального окна
  useEffect(() => {
    document.addEventListener('keydown', handleEscClose, false)
    return () => {
      document.removeEventListener('keydown', handleEscClose, false)
    }
  }, [handleEscClose])

  return (
    <ErrorBoundary>
      <FloatingPortal>
        <div
          className={`${s.modal} ${isMobile ? s[`mobile-mode--${mobileMode}`] : s[`desktop-size-${size}`]}`}
          onClick={checkTargetClick}
          {...props}>
          <div className={s.inner}>
            {mobileMode === 'dropdown-image' && isMobile && (
              <>
                <div className={s['dropdown-image']}>
                  {/*@ts-ignore*/}
                  <Image layout={'fill'} src={dropdownImage || getPlaceholderImage() || ''} />
                </div>

                {handleClose && (
                  <IconButton
                    data-testid={'modal-button-close'}
                    id={s['button-close']}
                    className={s['button-close']}
                    icon={isMobile ? IconCloseThin : IconClose}
                    mode={'black'}
                    sizes={'M'}
                    onClick={handleClose}
                  />
                )}
              </>
            )}
            <div className={`${s.container} ${className}`}>
              {title ? (
                <div className={s.header}>
                  <div className={s['header-top']}>
                    <Title tag='h3' sizes={titleSizes} className={s['header-title']}>
                      {title}
                    </Title>

                    {isShowButtonCloseNearTitle && (
                      <IconButton
                        data-testid={'modal-button-close'}
                        id={s['button-close']}
                        className={`${s['button-close']} ${s['button-close--static']}`}
                        icon={isMobile ? IconCloseThin : IconClose}
                        mode={'white'}
                        sizes={'XL M'}
                        onClick={handleClose}
                      />
                    )}
                  </div>
                  {subtitle && subtitle}
                </div>
              ) : (
                isShowButtonCloseNearTitle && (
                  <IconButton
                    data-testid={'modal-button-close'}
                    id={s['button-close']}
                    className={`${s['button-close']} ${s['button-close--absolute']}`}
                    icon={IconClose}
                    mode={'white'}
                    sizes={'XL M'}
                    onClick={handleClose}
                  />
                )
              )}

              <div className={s.content} data-content>
                <div className={`${s['content-shadow']} ${s['top']}`} data-shadow-top></div>
                <div className={s['content-inner']} data-content-inner>
                  {children}
                </div>
                <div className={`${s['content-shadow']} ${s['bottom']}`} data-shadow-bottom></div>
              </div>
            </div>
          </div>
        </div>
      </FloatingPortal>
    </ErrorBoundary>
  )
}

export default memo(Modal)
