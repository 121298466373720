import React, { FC, ReactNode, useContext } from 'react'
import s from './SectionCarouselItems.module.sass'
import { Carousel } from '@shared/ui/Layout/Carousel'
import { SectionCarouselContext } from '@shared/ui/Layout/SectionCarousel/SectionCarouselContext'

interface Props {
  children: ReactNode[]
}

const SectionCarouselSkeletons: FC<Props> = ({ children }) => {
  const [context, setContext] = useContext(SectionCarouselContext)

  return (
    context.skeleton && (
      <Carousel.Slider
        // @ts-ignore
        countSlidesOnContainer={context.countSlidesOnContainer}
        className={s.slider}
        style={{ borderRadius: context.borderRadius }}>
        {children}
      </Carousel.Slider>
    )
  )
}

export default SectionCarouselSkeletons
