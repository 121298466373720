import React, { FC } from 'react'
import s from './NewsContent.module.sass'
import Image from 'next/image'
import Link from 'next/link'

import IconQuote from '@icons/icon-quote.svg'
import IconTelegram from '@icons/social-tg.svg'
import IconVK from '@icons/social-vk.svg'
import IconDzen from '@icons/social-zn.svg'

import { useAppSelector } from '@app/model/store'

import { Text } from '@shared/ui/Typography/Text'
import { Title } from '@shared/ui/Typography/Title'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { NewsContentSkeleton } from '@pages/NewsItem/ui/NewsContent/index'
import { QuickBookingArticle } from '@widgets/Restaurant/QuickBooking/variants/QuickBookingArticle'

const getPropsText = (tag = 'p') => {
  switch (tag) {
    case 'p':
      return {
        sizes: 'ML M',
        paragraph: true,
      }
    case 'i':
      return {
        sizes: 'M',
      }
    case 'b':
      return {
        sizes: 'ML M',
        paragraph: true,
      }
    default:
      return {
        sizes: 'ML M',
        paragraph: true,
      }
  }
}

interface Props {
  className?: string
}

const NewsContent: FC<Props> = ({ className = '' }) => {
  const { news, restaurants } = useAppSelector((store) => store.pages.news_item)
  const { isMobile } = useClientResponsive()

  if (!news) return <NewsContentSkeleton className={className} />

  const Social = () => (
    <div className={s.social}>
      <Text className={s['social-text']} sizes={'ML M'} paragraph={isMobile}>
        Скидки, подарки, акции и другие новости, которые приятно узнавать первыми, — в наших социальных сетях
      </Text>

      <div className={s['social-list']}>
        {/* Telegram */}
        <Link
          //@ts-ignore
          alt='news-item-img'
          href='https://t.me/afisharestaurants'
          className={`${s['social-link']}`}
          target='_blank'
          scroll={false}>
          <IconTelegram />
          Telegram канал Афиша Рестораны
        </Link>

        {/* VK */}
        <Link
          //@ts-ignore
          alt='news-item-img'
          href='https://vk.com/afisharestaurants'
          className={`${s['social-link']}`}
          target='_blank'
          scroll={false}>
          <IconVK />
          ВКонтакте группа Афиша Рестораны
        </Link>

        {/* Dzen */}
        <Link
          // @ts-ignore
          alt='news-item-img'
          href='https://dzen.ru/afisharests'
          className={`${s['social-link']}`}
          target='_blank'
          scroll={false}>
          <IconDzen />
          Яндекс.Дзен блог Афиша Рестораны
        </Link>
      </div>
    </div>
  )

  return (
    <div className={`${s.content} ${className}`}>
      <Text className={s['content-announce']} sizes={'XL ML'} paragraph>
        {news.description.announce}
      </Text>

      {news.markup &&
        news.markup.map((item, index) => {
          const isSocial =
            index == news.markup.length - 1 && item?.data?.text && item.data.text.toLowerCase().includes('скидки')

          const isQuote = item.type === 'quote'

          const isNextTagI = news.markup[index + 1]?.type === 'text' && news.markup[index + 1]?.data?.tag === 'i'

          const hasImage = item.type === 'image' && item?.data?.file?.url

          const hasHeading = item.type === 'heading' && item?.data?.text

          const restQuickBooking =
            item.type === 'quick-booking' && restaurants?.find((rest) => rest.id === item.data.id)
          const hasQuickBooking = !!restQuickBooking

          if (hasImage) {
            return (
              <div
                key={index}
                className={s['content-img']}
                data-has-alt={isNextTagI}
                style={{ width: '100%', aspectRatio: item?.data?.file?.ratio, position: 'relative' }}>
                <Image layout={'fill'} alt={`Фотография ${index}`} src={item.data.file.url || ''} />
              </div>
            )
          }
          // @ts-ignore
          else if (hasHeading)
            return (
              <>
                {/*@ts-ignore*/}
                <Title
                  key={index}
                  data-has-alt={isNextTagI}
                  className={s['content-header']}
                  tag={'h2'}
                  sizes={'h3 h5'}
                  dangerouslySetInnerHTML={{ __html: item.data.text }}
                />
              </>
            )
          else if (hasQuickBooking)
            return <QuickBookingArticle restData={restQuickBooking} className={s['quick-booking']} />
          else if (isSocial) return <Social key={index} />
          else if (isQuote)
            return (
              <blockquote key={index} className={s['content-blockquote']}>
                <IconQuote />
                <Text sizes={'XL ML'} medium paragraph={!isMobile}>
                  {item.data.text}
                </Text>
                <Text tag={'cite'} sizes={'M S'}>
                  {item.data.cite}
                </Text>
              </blockquote>
            )
          else
            return (
              <>
                {/*@ts-ignore*/}
                <Text
                  key={index}
                  className={s['content-text']}
                  // @ts-ignore
                  tag={item.data.tag || 'p'}
                  {...getPropsText(item.data.tag)}
                  dangerouslySetInnerHTML={{ __html: item.data.text }}
                />
              </>
            )
        })}
    </div>
  )
}

export default NewsContent
