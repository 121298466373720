/**
 * @function generateNewsTitle — функция получает заголовок,
 *    и если в нем есть "Планы на неделю:",
 *    то данная строка удалятся (во избежание дублирования в интерфейсе),
 * @returns возвращается новая строка с большой буквы
 */
const generateNewsTitle = (inputTitle) => {
  const wrongString = 'Планы на неделю:'
  const hasWrongData = inputTitle.indexOf(wrongString) >= 0
  let filteredString = null
  if (hasWrongData) {
    filteredString = inputTitle.replace(wrongString, '').trim()
    filteredString = filteredString.charAt(0).toUpperCase() + filteredString.substring(1)
  }
  return hasWrongData ? filteredString : inputTitle
}

export default generateNewsTitle
