import s from './SubformMenu.module.sass'

import React, { FC, useEffect, useState } from 'react'

import { useAppDispatch, useAppSelector } from '@app/model/store'

import {
  addDishSection,
  removeDishSection,
  setFieldValid,
  setFieldValue,
} from '@pages/OwnerPersonalPage/model/OwnerPostsModel/reducer'

import { OwnerCalendar } from '@pages/OwnerPersonalPage/ui/components/OwnerCalendar'
import { SubformMenuItem } from '@pages/OwnerPersonalPage/ui/components/OwnerPostForms/OwnerCreatePostForm/ui/SubformMenu/ui/SubformMenuItem'

import { Text } from '@shared/ui/Typography/Text'
import { Button } from '@shared/ui/Actions/Button'
import { InputTitle } from '@shared/ui/Form/Single/InputTitle'

import IconPlus from '@icons/icon-owner-plus.svg'
import { addDishSectionAC } from '@pages/OwnerPersonalPage/model/OwnerPostsModel/createActions'

const SubformMenu: FC = () => {
  const dispatch = useAppDispatch()
  const storeMenu = useAppSelector((store) => store?.pages?.owner_personal?.posts?.forms?.create?.categories?.menu)
  const [fieldSelectedDate, setFieldSelectedDate] = useState(null)

  const handleAddDish = () => {
    dispatch(addDishSectionAC())
  }

  // установка данных полей из store при первом\повторных отображений компонента(value и valid)
  useEffect(() => {
    setFieldSelectedDate(() => (storeMenu.fields.date.value ? new Date(storeMenu.fields.date.value) : null))
  }, [])

  useEffect(() => {
    // @ts-ignore
    const isValidDate = (d: any) => d instanceof Date && !isNaN(d)

    dispatch(
      setFieldValue({ typeField: 'date', value: isValidDate(fieldSelectedDate) ? fieldSelectedDate.toJSON() : null }),
    )
    // dispatch(setFieldValid({ typeField: 'date', valid: isValidDate(fieldSelectedDate) }))
    dispatch(setFieldValid({ typeField: 'date', valid: true }))
  }, [fieldSelectedDate])

  const handleDeleteDish = (index: number) => {
    dispatch(removeDishSection(index))
  }

  return (
    <>
      <div className={s.wrapper}>
        <Text className={s['main-description']} sizes='S'>
          Можно ввести до четырех позиций из меню. Публикуется 1 раз в сутки.
        </Text>

        {/*<div className={s['data-wrap']}>*/}
        {/*  <InputTitle nameField={'owner-date-spec'} title={'Дата публикации'} showRequired={false} />*/}
        {/*  <OwnerCalendar selectedDate={fieldSelectedDate} onChangeDate={(date) => setFieldSelectedDate(date)} />*/}
        {/*</div>*/}

        {storeMenu?.fields?.dishes?.length > 0 &&
          storeMenu.fields.dishes.map((dish, index) => (
            <SubformMenuItem
              data={dish}
              key={dish.id}
              index={index}
              handleDelete={handleDeleteDish}
              isShowButtonDelete={storeMenu?.fields?.dishes?.length > 1}
            />
          ))}

        {storeMenu?.fields?.dishes.length < 3 && (
          <Button
            sizes={'XL'}
            mode={'white-border'}
            onClick={handleAddDish}
            className={s['button-add']}
            data-testid={'add-dish'}>
            <div className={s['button-add__content']}>
              <IconPlus />
              <Text sizes={'M'}>Добавить блюдо</Text>
            </div>
          </Button>
        )}
      </div>
    </>
  )
}

export default SubformMenu
