import React, { FC, ReactNode, useEffect, useState } from 'react'
import s from './CardTwoBlock.module.sass'
import { Img } from '@shared/ui/Layout/CardTwoBlock/ui/Img'
import { Content } from '@shared/ui/Layout/CardTwoBlock/ui/Content'
import { useCurrentSize } from '@shared/lib/helpers/classes'
import { CardTwoBlockContext } from './Context'
import Link from 'next/link'
import { ErrorBoundary } from '../../../lib/components/ErrorBoundary'

type size = 'L' | 'ML' | 'M' | 'S'

type Extension = {
  Img: typeof Img
  Content: typeof Content
}

interface Props {
  children: ReactNode
  sizes: string
  href: string
  className?: string
}

const CardTwoBlock: FC<Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>> =
  React.forwardRef(({ children, sizes = 'L ML', className = '', href, ...props }, ref) => {
    const [size] = useCurrentSize(sizes)

    const [context, setContext] = useState({
      href: href,
      size: size,
    })

    useEffect(() => {
      setContext((prev) => ({
        ...prev,
        size,
      }))
    }, [size])

    return (
      <ErrorBoundary>
        <CardTwoBlockContext.Provider value={[context, setContext]}>
          {/*@ts-ignore*/}
          <div className={`${s.card} ${className}`} data-size={size} {...props} ref={ref}>
            {children}
          </div>
        </CardTwoBlockContext.Provider>
      </ErrorBoundary>
    )
  })

// @ts-ignore
CardTwoBlock.Img = Img
// @ts-ignore
CardTwoBlock.Content = Content

export default CardTwoBlock
