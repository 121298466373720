/**
 * @global search-keyword: "StickersSelections"
 * @function patchStickersSelections - временный хак, который обновляет списки подборок по слагу,
 *    и добавляет к ним ключ isStickersSelections отображающий шеврон;
 *    а также для таких подборок встраиваются кастомные обложки в page: selection_item,
 *    данные названия слагов TARGET_SELECTIONS также указываются в компонентах,
 *    и при переносе картинок на сторону админки все необходимо вычистить
 *    (временно использована icon_image, без кропа)
 * @todo данный признак необходимо определять в админке, на беке
 */

const targetStickersSelection = {
  sel01: 'luchshie-restorany-moskvy-2023',
  sel02: 'luchshie-otkrytiya-2023-goda',
  sel03: 'luchshie-bary-moskvy-2023',
  sel04: 'vybor-polzovatelej-afishi-restorany-2023',
  sel05: 'luchshie-restorany-sankt-peterburga-2023',
  sel06: 'luchshie-otkrytiya-2023-goda-spb',
  sel07: 'luchshie-bary-sankt-peterburga-2023',
  sel08: 'vybor-pol-zovatelej-afishi-restorany-2023',
}

const TARGET_SELECTIONS = [
  targetStickersSelection.sel01,
  targetStickersSelection.sel02,
  targetStickersSelection.sel03,
  targetStickersSelection.sel04,
  targetStickersSelection.sel05,
  targetStickersSelection.sel06,
  targetStickersSelection.sel07,
  targetStickersSelection.sel08,
]

const patchStickersSelections = (selectionsList) =>
  Array.isArray(selectionsList) && selectionsList.length > 0
    ? selectionsList.map((item) => ({
        ...item,
        chevron: TARGET_SELECTIONS.includes(item.slug),
      }))
    : selectionsList

export { patchStickersSelections, targetStickersSelection, TARGET_SELECTIONS }
