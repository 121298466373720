import React, { FC, FocusEvent, MouseEvent, useCallback, useEffect } from 'react'

import { useAppDispatch, useAppSelector } from '@app/model/store'

import { BookingInput } from '@widgets/Restaurant/Booking/ui/components/BookingInput'
import { handlePhoneFormatting } from '@widgets/Restaurant/Booking/lib/booking-phone-formatting'
import { setPhoneFieldValue } from '@widgets/Restaurant/Booking/model/reduce'
import l10n from '@widgets/Restaurant/Booking/lib/booking-localization'

import { EGG } from '@shared/api/analytics'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { checkDiscountByNumber } from '@widgets/Restaurant/Booking/model/createActions'

const DEFAULT_PHONE_VALUE = '+7 '

const BookingPhone: FC = () => {
  const dispatch = useAppDispatch()
  const { restaurantSource, form } = useAppSelector((store) => store.booking)

  // const storeGlobal = useAppSelector((store) => store.global)
  // const userData = {
  //   fullname: `${storeGlobal.user.data.first_name} ${storeGlobal.user.data.last_name}`,
  //   phone: `${storeGlobal.user.data.phone}`,
  // }
  // console.log('userData', userData)

  const { phoneField, isErrorsVisible } = form

  // Первая проверка при рендере
  useEffect(() => {
    if (phoneField.value?.length > 7) {
      dispatch(checkDiscountByNumber({ phone: phoneField.value, restId: restaurantSource.id }))
    }
  }, [])

  const handlePhoneFocus = () => {
    EGG.entity.booking.click_reserve_field_phone()
  }

  const handlePhoneTypingCallback = useCallback(
    (enteredPhoneString: string) => {
      const isPhoneRemoving = enteredPhoneString.length < phoneField.value.length
      const thisPhoneNumber = handlePhoneFormatting(enteredPhoneString, isPhoneRemoving ? 'remove' : 'set')

      dispatch(setPhoneFieldValue(thisPhoneNumber))

      if (enteredPhoneString.length === 4) {
        EGG.entity.booking.input_reserve_field_phone()
      }
    },
    [dispatch, phoneField.value.length, restaurantSource.id, restaurantSource.name],
  )

  const handlePhoneBlur = (event: FocusEvent<HTMLTextAreaElement>) => {
    const value = event.target.value
    dispatch(setPhoneFieldValue(value))
    dispatch(checkDiscountByNumber({ phone: value, restId: restaurantSource.id }))
  }

  const setDefaultPhoneValue = (event: MouseEvent<HTMLTextAreaElement> & { target: HTMLTextAreaElement }) => {
    const value = event.target.value
    if (value === '') {
      dispatch(setPhoneFieldValue(DEFAULT_PHONE_VALUE))
    }
  }

  return (
    <ErrorBoundary>
      <BookingInput
        field={phoneField}
        placeholder={l10n('guest_phone_placeholder')}
        isErrorsVisible={isErrorsVisible}
        maxLength={23}
        onClick={setDefaultPhoneValue}
        onFocus={handlePhoneFocus}
        onChange={(e) => {
          // @ts-ignore
          handlePhoneTypingCallback(e.target.value)
        }}
        onBlur={handlePhoneBlur}
      />
    </ErrorBoundary>
  )
}

export default BookingPhone
