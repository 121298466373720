import { useEffect, useState } from 'react'
import { TypeGlobalArgsNearestSlots } from '@widgets/Restaurant/QuickBooking/types/types'
import { getNearestSlots } from '@shared/lib/helpers/schedule/schedule'
import { TypeDateTimeSlot } from '@shared/types/schedule/types'

type TypeUseNearestSlotsOutput = { slots: TypeDateTimeSlot[] | []; loading: boolean; error: boolean }

export function useNearestSlots(data: TypeGlobalArgsNearestSlots): TypeUseNearestSlotsOutput {
  const [loading, setLoading] = useState(true)
  const [slots, setSlots] = useState<TypeDateTimeSlot[]>([])
  const [error, setError] = useState(false)

  useEffect(() => {
    setError(false)
    setLoading(true)
    getNearestSlots(data)
      .then((res) => setSlots(res))
      .finally(() => setLoading(false))
  }, [data])

  return { slots, loading, error }
}
