import React, { createContext } from 'react'

export const SectionCarouselContext = createContext<TypeSectionCarouselContext>(null)

type TypeSectionCarouselContext = [TypeCarouselState, React.Dispatch<React.SetStateAction<TypeCarouselState>>] | null

type TypeCarouselState = {
  borderRadius: number
  countSlidesOnContainer: number
  skeleton: boolean
}
