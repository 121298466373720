import React, { FC, ReactNode } from 'react'
import s from './PromoBadgeLabel.module.sass'
import { Text } from '@shared/ui/Typography/Text'

interface Props {
  children: ReactNode
  className?: string
  label?: string
}

const PromoBadgeLabel: FC<Props> = ({ className = '', label = 'Скидка', children }) => {
  return (
    <div className={`${s.wrapper} ${className} promo-badge-label`}>
      <div className={s.main}>
        <span className={s['main-text']}>{children}</span>
      </div>
      <div className={s.label}>
        <Text sizes={'S'} className={s['label-text']}>
          {label}
        </Text>
      </div>
    </div>
  )
}

export default PromoBadgeLabel
