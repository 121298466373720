export const LIST_METRO_BY_CITY = {
  msk: [
    {
      id: 4357,
      name: 'Бульвар Рокоссовского',
      slug: 'ulitsa_podbelskogo',
    },
    {
      id: 4358,
      name: 'Черкизовская',
      slug: 'cherkizovskaya',
    },
    {
      id: 4359,
      name: 'Преображенская площадь',
      slug: 'preobrazhenskaya_ploshchad',
    },
    {
      id: 4360,
      name: 'Сокольники',
      slug: 'sokolniki',
    },
    {
      id: 4361,
      name: 'Красносельская',
      slug: 'krasnoselskaya',
    },
    {
      id: 4363,
      name: 'Красные Ворота',
      slug: 'krasnye_vorota',
    },
    {
      id: 4364,
      name: 'Чистые пруды',
      slug: 'chistye_prudy',
    },
    {
      id: 4365,
      name: 'Лубянка',
      slug: 'lubyanka',
    },
    {
      id: 4366,
      name: 'Охотный Ряд',
      slug: 'okhotnyi_ryad',
    },
    {
      id: 4367,
      name: 'Библиотека им. Ленина',
      slug: 'biblioteka_im._lenina',
    },
    {
      id: 4368,
      name: 'Кропоткинская',
      slug: 'kropotkinskaya',
    },
    {
      id: 4369,
      name: 'Парк культуры',
      slug: 'park_kultury',
    },
    {
      id: 4370,
      name: 'Фрунзенская',
      slug: 'frunzenskaya',
    },
    {
      id: 4371,
      name: 'Спортивная',
      slug: 'sportivnaya',
    },
    {
      id: 4372,
      name: 'Воробьевы горы',
      slug: 'vorobyevy_gory',
    },
    {
      id: 4373,
      name: 'Университет',
      slug: 'universitet',
    },
    {
      id: 4374,
      name: 'Проспект Вернадского',
      slug: 'prospekt_vernadskogo',
    },
    {
      id: 4375,
      name: 'Юго-Западная',
      slug: 'yugo-zapadnaya',
    },
    {
      id: 13045,
      name: 'Саларьево',
      slug: 'salaryevo',
    },
    {
      id: 13044,
      name: 'Румянцево',
      slug: 'rumyancevo',
    },
    {
      id: 13046,
      name: 'Тропарево',
      slug: 'msk_troparevo',
    },
    {
      id: 24465,
      name: 'Филатов луг',
      slug: 'filatov_lug',
    },
    {
      id: 24466,
      name: 'Прокшино',
      slug: 'prokshino',
    },
    {
      id: 24467,
      name: 'Ольховая',
      slug: 'olkhovaya',
    },
    {
      id: 24470,
      name: 'Коммунарка',
      slug: 'kommunarka1',
    },
    {
      id: 4376,
      name: 'Речной вокзал',
      slug: 'rechnoy_vokzal',
    },
    {
      id: 4377,
      name: 'Водный стадион',
      slug: 'vodnyi_stadion',
    },
    {
      id: 4378,
      name: 'Войковская',
      slug: 'voykovskaya',
    },
    {
      id: 4379,
      name: 'Сокол',
      slug: 'sokol',
    },
    {
      id: 4380,
      name: 'Аэропорт',
      slug: 'msk_aeroport',
    },
    {
      id: 4381,
      name: 'Динамо',
      slug: 'msk_dinamo',
    },
    {
      id: 4382,
      name: 'Белорусская',
      slug: 'belorusskaya',
    },
    {
      id: 4383,
      name: 'Маяковская',
      slug: 'mayakovskaya',
    },
    {
      id: 4384,
      name: 'Тверская',
      slug: 'tverskaya',
    },
    {
      id: 4385,
      name: 'Театральная',
      slug: 'teatralnaya',
    },
    {
      id: 4386,
      name: 'Новокузнецкая',
      slug: 'novokuznetskaya',
    },
    {
      id: 4388,
      name: 'Автозаводская',
      slug: 'avtozavodskaya',
    },
    {
      id: 4389,
      name: 'Коломенская',
      slug: 'kolomenskaya',
    },
    {
      id: 4390,
      name: 'Каширская',
      slug: 'msk_kashirskaya',
    },
    {
      id: 4391,
      name: 'Кантемировская',
      slug: 'kantemirovskaya',
    },
    {
      id: 4392,
      name: 'Царицыно',
      slug: 'tsaritsyno',
    },
    {
      id: 4393,
      name: 'Орехово',
      slug: 'orehovo',
    },
    {
      id: 4394,
      name: 'Домодедовская',
      slug: 'domodedovskaya',
    },
    {
      id: 4395,
      name: 'Красногвардейская',
      slug: 'krasnogvardeyskaya',
    },
    {
      id: 12831,
      name: 'Алма-Атинская',
      slug: 'alma_atinskaya',
    },
    {
      id: 13193,
      name: 'Технопарк',
      slug: 'tehnopark',
    },
    {
      id: 24354,
      name: 'Ховрино',
      slug: 'khovrino',
    },
    {
      id: 24373,
      name: 'Беломорская',
      slug: 'belomorskaya',
    },
    {
      id: 4396,
      name: 'Щелковская',
      slug: 'shchelkovskaya',
    },
    {
      id: 4397,
      name: 'Первомайская',
      slug: 'pervomayskaya',
    },
    {
      id: 4398,
      name: 'Измайловская',
      slug: 'izmaylovskaya',
    },
    {
      id: 4399,
      name: 'Партизанская',
      slug: 'partizanskaya',
    },
    {
      id: 4400,
      name: 'Семеновская',
      slug: 'semenovskaya',
    },
    {
      id: 4401,
      name: 'Электрозаводская',
      slug: 'elektrozavodskaya',
    },
    {
      id: 4402,
      name: 'Бауманская',
      slug: 'baumanskaya',
    },
    {
      id: 4404,
      name: 'Площадь Революции',
      slug: 'ploshchad_revolyutsii',
    },
    {
      id: 4405,
      name: 'Арбатская',
      slug: 'arbatskaya',
    },
    {
      id: 4406,
      name: 'Смоленская',
      slug: 'smolenskaya',
    },
    {
      id: 4408,
      name: 'Парк Победы',
      slug: 'park_pobedy',
    },
    {
      id: 4411,
      name: 'Молодежная',
      slug: 'molodezhnaya',
    },
    {
      id: 4409,
      name: 'Строгино',
      slug: 'strogino',
    },
    {
      id: 4410,
      name: 'Крылатское',
      slug: 'krylatskoe',
    },
    {
      id: 12394,
      name: 'Славянский бульвар',
      slug: 'msk_slavanskij_bulvar',
    },
    {
      id: 12395,
      name: 'Мякинино',
      slug: 'msk_makinino',
    },
    {
      id: 12396,
      name: 'Волоколамская',
      slug: 'msk_volokolamskaya',
    },
    {
      id: 12397,
      name: 'Митино',
      slug: 'msk_mitino',
    },
    {
      id: 12830,
      name: 'Пятницкое шоссе',
      slug: 'msk_patnickoe_shosse',
    },
    {
      id: 4412,
      name: 'Кунцевская',
      slug: 'kuntsevskaya',
    },
    {
      id: 4413,
      name: 'Пионерская',
      slug: 'pionerskaya',
    },
    {
      id: 4414,
      name: 'Филевский парк',
      slug: 'filevskiy_park',
    },
    {
      id: 4415,
      name: 'Багратионовская',
      slug: 'bagrationovskaya',
    },
    {
      id: 4416,
      name: 'Фили',
      slug: 'fili',
    },
    {
      id: 4417,
      name: 'Кутузовская',
      slug: 'kutuzovskaya',
    },
    {
      id: 4418,
      name: 'Студенческая',
      slug: 'studencheskaya',
    },
    {
      id: 4419,
      name: 'Выставочная',
      slug: 'vystavochnaya',
    },
    {
      id: 4423,
      name: 'Александровский сад',
      slug: 'aleksandrovskiy_sad',
    },
    {
      id: 12393,
      name: 'Международная',
      slug: 'msk_mezhdunarodnaya',
    },
    {
      id: 13009,
      name: 'Деловой центр',
      slug: 'business_centre',
    },
    {
      id: 4425,
      name: 'Октябрьская',
      slug: 'msk_oktyabrskaya',
    },
    {
      id: 4426,
      name: 'Добрынинская',
      slug: 'dobryninskaya',
    },
    {
      id: 4427,
      name: 'Павелецкая',
      slug: 'msk_paveletskaya',
    },
    {
      id: 4428,
      name: 'Таганская',
      slug: 'msk_taganskaya',
    },
    {
      id: 4429,
      name: 'Курская',
      slug: 'msk_kurskaya',
    },
    {
      id: 4430,
      name: 'Комсомольская',
      slug: 'msk_komsomolskaya',
    },
    {
      id: 4432,
      name: 'Новослободская',
      slug: 'novoslobodskaya',
    },
    {
      id: 4434,
      name: 'Краснопресненская',
      slug: 'krasnopresnenskaya',
    },
    {
      id: 4435,
      name: 'Киевская',
      slug: 'msk_ms_kievskaya',
    },
    {
      id: 4436,
      name: 'Медведково',
      slug: 'medvedkovo',
    },
    {
      id: 4437,
      name: 'Бабушкинская',
      slug: 'babushklinskaya',
    },
    {
      id: 4438,
      name: 'Свиблово',
      slug: 'sviblovo',
    },
    {
      id: 4439,
      name: 'Ботанический сад',
      slug: 'botanicheskiy_sad',
    },
    {
      id: 4440,
      name: 'ВДНХ',
      slug: 'VDNKH',
    },
    {
      id: 4441,
      name: 'Алексеевская',
      slug: 'alekseevskaya',
    },
    {
      id: 4442,
      name: 'Рижская',
      slug: 'rizhskaya',
    },
    {
      id: 4443,
      name: 'Проспект Мира',
      slug: 'msk_prospekt_mira',
    },
    {
      id: 4444,
      name: 'Сухаревская',
      slug: 'suharevskaya',
    },
    {
      id: 4445,
      name: 'Тургеневская',
      slug: 'turgenevskaya',
    },
    {
      id: 4447,
      name: 'Третьяковская',
      slug: 'tretyakovskaya',
    },
    {
      id: 4449,
      name: 'Шаболовская',
      slug: 'shabolovskaya',
    },
    {
      id: 4450,
      name: 'Ленинский проспект',
      slug: 'leninskiy_prospekt',
    },
    {
      id: 4451,
      name: 'Академическая',
      slug: 'akademicheskaya',
    },
    {
      id: 4452,
      name: 'Профсоюзная',
      slug: 'profsoyuznaya',
    },
    {
      id: 4453,
      name: 'Новые Черемушки',
      slug: 'novye_cheremushki',
    },
    {
      id: 4454,
      name: 'Калужская',
      slug: 'kaluzhskaya',
    },
    {
      id: 4455,
      name: 'Беляево',
      slug: 'belyaevo',
    },
    {
      id: 4456,
      name: 'Коньково',
      slug: 'konkovo',
    },
    {
      id: 4457,
      name: 'Теплый Стан',
      slug: 'teplyi_stan',
    },
    {
      id: 4458,
      name: 'Ясенево',
      slug: 'yasenevo',
    },
    {
      id: 4459,
      name: 'Новоясеневская',
      slug: 'novoyasenevskaya',
    },
    {
      id: 4460,
      name: 'Планерная',
      slug: 'planernaya',
    },
    {
      id: 4461,
      name: 'Сходненская',
      slug: 'skhodnenskaya',
    },
    {
      id: 4462,
      name: 'Тушинская',
      slug: 'tushinskaya',
    },
    {
      id: 4463,
      name: 'Щукинская',
      slug: 'shchukinskaya',
    },
    {
      id: 4464,
      name: 'Октябрьское Поле',
      slug: 'oktyabrskoe_pole',
    },
    {
      id: 4465,
      name: 'Полежаевская',
      slug: 'polezhaevskaya',
    },
    {
      id: 4466,
      name: 'Беговая',
      slug: 'begovaya',
    },
    {
      id: 4467,
      name: 'Улица 1905 года',
      slug: 'ulitsa_1905_goda',
    },
    {
      id: 4468,
      name: 'Баррикадная',
      slug: 'barrikadnaya',
    },
    {
      id: 4469,
      name: 'Пушкинская',
      slug: 'pushkinskaya',
    },
    {
      id: 4470,
      name: 'Кузнецкий Мост',
      slug: 'kuznetskiy_most',
    },
    {
      id: 4471,
      name: 'Китай-город',
      slug: 'msk_kitay-gorod',
    },
    {
      id: 4473,
      name: 'Пролетарская',
      slug: 'proletarskaya',
    },
    {
      id: 4474,
      name: 'Волгоградский проспект',
      slug: 'volgogradskiy_prospekt',
    },
    {
      id: 4475,
      name: 'Текстильщики',
      slug: 'tekstilschiki',
    },
    {
      id: 4476,
      name: 'Кузьминки',
      slug: 'kuzminki',
    },
    {
      id: 4477,
      name: 'Рязанский проспект',
      slug: 'ryazanskiy_prospekt',
    },
    {
      id: 4478,
      name: 'Выхино',
      slug: 'vykhino',
    },
    {
      id: 12940,
      name: 'Лермонтовский проспект',
      slug: 'lermontovskij_prospekt',
    },
    {
      id: 12941,
      name: 'Жулебино',
      slug: 'zhulebino',
    },
    {
      id: 13042,
      name: 'Спартак',
      slug: 'spartak',
    },
    {
      id: 13194,
      name: 'Котельники',
      slug: 'kotelniki',
    },
    {
      id: 4479,
      name: 'Новогиреево',
      slug: 'novogireevo',
    },
    {
      id: 4480,
      name: 'Перово',
      slug: 'perovo',
    },
    {
      id: 4481,
      name: 'Шоссе Энтузиастов',
      slug: 'shosse_entuziastov',
    },
    {
      id: 4482,
      name: 'Авиамоторная',
      slug: 'aviamotornaya',
    },
    {
      id: 4483,
      name: 'Площадь Ильича',
      slug: 'ploschad_ilyicha',
    },
    {
      id: 4484,
      name: 'Марксистская',
      slug: 'marksistskaya',
    },
    {
      id: 12829,
      name: 'Новокосино',
      slug: 'novokosino',
    },
    {
      id: 13533,
      name: 'Минская',
      slug: 'minskaya',
    },
    {
      id: 13535,
      name: 'Ломоносовский проспект',
      slug: 'lomonosovskij_prospekt',
    },
    {
      id: 13534,
      name: 'Раменки',
      slug: 'ramenki',
    },
    {
      id: 24347,
      name: 'Мичуринский проспект',
      slug: 'michurinskiy_prospekt',
    },
    {
      id: 24348,
      name: 'Озерная',
      slug: 'ozernaya',
    },
    {
      id: 24349,
      name: 'Говорово',
      slug: 'govorovo',
    },
    {
      id: 24350,
      name: 'Солнцево',
      slug: 'solntsevo',
    },
    {
      id: 24351,
      name: 'Боровское шоссе',
      slug: 'borovskoe_shosse',
    },
    {
      id: 24352,
      name: 'Новопеределкино',
      slug: 'novoperedelkino',
    },
    {
      id: 24353,
      name: 'Рассказовка',
      slug: 'rasskazovka',
    },
    {
      id: 4486,
      name: 'Алтуфьево',
      slug: 'altufevo',
    },
    {
      id: 4487,
      name: 'Бибирево',
      slug: 'bibirevo',
    },
    {
      id: 4488,
      name: 'Отрадное',
      slug: 'otradnoe',
    },
    {
      id: 4489,
      name: 'Владыкино',
      slug: 'vladykino',
    },
    {
      id: 4490,
      name: 'Петровско-Разумовская',
      slug: 'petrovsko-razumovskaya',
    },
    {
      id: 4491,
      name: 'Тимирязевская',
      slug: 'timiryazevskaya',
    },
    {
      id: 4492,
      name: 'Дмитровская',
      slug: 'dmitrovskaya',
    },
    {
      id: 4493,
      name: 'Савеловская',
      slug: 'savelovskaya',
    },
    {
      id: 4494,
      name: 'Менделеевская',
      slug: 'mendeleevskaya',
    },
    {
      id: 4495,
      name: 'Цветной бульвар',
      slug: 'tsvetnoy_bulvar',
    },
    {
      id: 4496,
      name: 'Чеховская',
      slug: 'chehovskaya',
    },
    {
      id: 4497,
      name: 'Боровицкая',
      slug: 'borovitskaya',
    },
    {
      id: 4498,
      name: 'Полянка',
      slug: 'polyanka',
    },
    {
      id: 4499,
      name: 'Серпуховская',
      slug: 'serpukhovskaya',
    },
    {
      id: 4500,
      name: 'Тульская',
      slug: 'tulskaya',
    },
    {
      id: 4501,
      name: 'Нагатинская',
      slug: 'nagatinskaya',
    },
    {
      id: 4502,
      name: 'Нагорная',
      slug: 'nagornaya',
    },
    {
      id: 4503,
      name: 'Нахимовский проспект',
      slug: 'nahimoskiy_prospekt',
    },
    {
      id: 4504,
      name: 'Севастопольская',
      slug: 'sevastopolskaya',
    },
    {
      id: 4505,
      name: 'Чертановская',
      slug: 'chertanovskaya',
    },
    {
      id: 4506,
      name: 'Южная',
      slug: 'yuzhnaya',
    },
    {
      id: 4507,
      name: 'Пражская',
      slug: 'prazhskaya',
    },
    {
      id: 4508,
      name: 'Улица Академика Янгеля',
      slug: 'ulitsa_akademika_yangelya',
    },
    {
      id: 4509,
      name: 'Аннино',
      slug: 'annino',
    },
    {
      id: 4510,
      name: 'Бульвар Дмитрия Донского',
      slug: 'bulvar_dmitriya_donskogo',
    },
    {
      id: 4511,
      name: 'Марьина Роща',
      slug: 'maryina_roshcha',
    },
    {
      id: 4512,
      name: 'Сретенский бульвар',
      slug: 'sretenskiy_bulvar',
    },
    {
      id: 4513,
      name: 'Чкаловская',
      slug: 'chkalovskaya',
    },
    {
      id: 4514,
      name: 'Римская',
      slug: 'rimskaya',
    },
    {
      id: 4515,
      name: 'Крестьянская Застава',
      slug: 'krestyanskaya_zastava',
    },
    {
      id: 4516,
      name: 'Дубровка',
      slug: 'dubrovka',
    },
    {
      id: 4517,
      name: 'Кожуховская',
      slug: 'kozhuhovskaya',
    },
    {
      id: 4518,
      name: 'Печатники',
      slug: 'pechatniki',
    },
    {
      id: 4519,
      name: 'Волжская',
      slug: 'volzhskaya',
    },
    {
      id: 4520,
      name: 'Люблино',
      slug: 'lyublino',
    },
    {
      id: 4521,
      name: 'Братиславская',
      slug: 'bratislavskaya',
    },
    {
      id: 4522,
      name: 'Марьино',
      slug: 'maryino',
    },
    {
      id: 4523,
      name: 'Шипиловская',
      slug: 'shipilovskaya',
    },
    {
      id: 12407,
      name: 'Трубная',
      slug: 'msk_trubnaya',
    },
    {
      id: 12469,
      name: 'Достоевская',
      slug: 'msk_dostoevskaya',
    },
    {
      id: 12743,
      name: 'Зябликово',
      slug: 'msk_m_zyablikovo',
    },
    {
      id: 12742,
      name: 'Борисово',
      slug: 'msk_m_borisovo',
    },
    {
      id: 13313,
      name: 'Бутырская',
      slug: 'butyrskaya',
    },
    {
      id: 13314,
      name: 'Фонвизинская',
      slug: 'fonvizinskaya',
    },
    {
      id: 24344,
      name: 'Верхние Лихоборы',
      slug: 'verhnie_lihobory',
    },
    {
      id: 24345,
      name: 'Селигерская',
      slug: 'seligerskaya',
    },
    {
      id: 24737,
      name: 'Яхромская',
      slug: 'yakhromskaya',
    },
    {
      id: 24738,
      name: 'Лианозово',
      slug: 'lianozovo',
    },
    {
      id: 24739,
      name: 'Физтех',
      slug: 'fiztekh',
    },
    {
      id: 4525,
      name: 'Варшавская',
      slug: 'varshavskaya',
    },
    {
      id: 4526,
      name: 'Каховская',
      slug: 'kahovskaya',
    },
    {
      id: 4527,
      name: 'Улица Старокачаловская',
      slug: 'ulitsa_starokachalovskaya',
    },
    {
      id: 4528,
      name: 'Улица Скобелевская',
      slug: 'ulitsa_skobelevskaya',
    },
    {
      id: 4529,
      name: 'Бульвар Адмирала Ушакова',
      slug: 'bulvar_admirala_ushakova',
    },
    {
      id: 4530,
      name: 'Улица Горчакова',
      slug: 'ulitsa_gorchakova',
    },
    {
      id: 4531,
      name: 'Бунинская аллея',
      slug: 'buninskaya_alleya',
    },
    {
      id: 13010,
      name: 'Битцевский парк',
      slug: 'Bitsevskij_park',
    },
    {
      id: 13011,
      name: 'Лесопарковая',
      slug: 'lesoparkovaya',
    },
    {
      id: 13262,
      name: 'Хорошево',
      slug: 'horoshevo',
    },
    {
      id: 13263,
      name: 'Зорге',
      slug: 'zorge',
    },
    {
      id: 13264,
      name: 'Панфиловская',
      slug: 'panfilovskya',
    },
    {
      id: 13265,
      name: 'Стрешнево',
      slug: 'streshnevo',
    },
    {
      id: 13266,
      name: 'Балтийская',
      slug: 'baltijskaya',
    },
    {
      id: 13267,
      name: 'Коптево',
      slug: 'koptevo',
    },
    {
      id: 13268,
      name: 'Лихоборы',
      slug: 'lihobory',
    },
    {
      id: 13269,
      name: 'Окружная',
      slug: 'okruzhnaya',
    },
    {
      id: 13270,
      name: 'Ростокино',
      slug: 'rostokino',
    },
    {
      id: 13271,
      name: 'Белокаменная',
      slug: 'belokamennaya',
    },
    {
      id: 13272,
      name: 'Локомотив',
      slug: 'lokomotiv',
    },
    {
      id: 13273,
      name: 'Измайлово',
      slug: 'izmailovo',
    },
    {
      id: 13274,
      name: 'Соколиная Гора',
      slug: 'sokolinaya_gora',
    },
    {
      id: 13275,
      name: 'Андроновка',
      slug: 'andronovka',
    },
    {
      id: 13276,
      name: 'Нижегородская',
      slug: 'nizhegorodskaya',
    },
    {
      id: 13277,
      name: 'Новохохловская',
      slug: 'novohohlovskaya',
    },
    {
      id: 13278,
      name: 'Угрешская',
      slug: 'ugreshskaya',
    },
    {
      id: 13279,
      name: 'ЗИЛ',
      slug: 'zil',
    },
    {
      id: 13280,
      name: 'Верхние Котлы',
      slug: 'verhnie_kotly',
    },
    {
      id: 13281,
      name: 'Крымская',
      slug: 'krymskaya',
    },
    {
      id: 13282,
      name: 'Площадь Гагарина',
      slug: 'ploshchad_gagarina',
    },
    {
      id: 13283,
      name: 'Лужники',
      slug: 'luzhniki',
    },
    {
      id: 13284,
      name: 'Шелепиха',
      slug: 'shelepiha',
    },
    {
      id: 24314,
      name: 'Хорошевская',
      slug: 'horoshevskaya',
    },
    {
      id: 24315,
      name: 'ЦСКА',
      slug: 'csca',
    },
    {
      id: 24316,
      name: 'Петровский парк',
      slug: 'petrovsky_park',
    },
    {
      id: 24607,
      name: 'Народное Ополчение',
      slug: 'narodnoe_opolchenie',
    },
    {
      id: 24608,
      name: 'Мневники',
      slug: 'mnevniki',
    },
    {
      id: 24609,
      name: 'Терехово',
      slug: 'terekhovo',
    },
    {
      id: 24610,
      name: 'Давыдково',
      slug: 'davydkovo',
    },
    {
      id: 24611,
      name: 'Аминьевская',
      slug: 'aminevskaya',
    },
    {
      id: 24612,
      name: 'Новаторская',
      slug: 'novatorskaya',
    },
    {
      id: 24613,
      name: 'Воронцовская',
      slug: 'vorontsovskaya',
    },
    {
      id: 24614,
      name: 'Зюзино',
      slug: 'zjuzino',
    },
    {
      id: 24670,
      name: 'Нагатинский затон',
      slug: 'nagatinskij_zaton',
    },
    {
      id: 24671,
      name: 'Кленовый бульвар',
      slug: 'klenovyj_boulevard',
    },
    {
      id: 24453,
      name: 'Косино',
      slug: 'kosino',
    },
    {
      id: 24454,
      name: 'Улица Дмитриевского',
      slug: 'ulitsa_dmitrievskogo',
    },
    {
      id: 24455,
      name: 'Лухмановская',
      slug: 'lukhmanovskaya',
    },
    {
      id: 24456,
      name: 'Некрасовка',
      slug: 'nekrasovka',
    },
    {
      id: 24553,
      name: 'Лефортово',
      slug: 'lefortovo',
    },
    {
      id: 24602,
      name: 'Юго-Восточная',
      slug: 'south_east',
    },
    {
      id: 24603,
      name: 'Окская',
      slug: 'okskaya',
    },
    {
      id: 24604,
      name: 'Стахановская',
      slug: 'stakhanovskaya',
    },
  ],
  spb: [
    {
      id: 4532,
      name: 'Девяткино',
      slug: 'devyatkino',
    },
    {
      id: 4533,
      name: 'Гражданский проспект',
      slug: 'grazhdanskiy_prospekt',
    },
    {
      id: 4534,
      name: 'Академическая',
      slug: 'spb_akademicheskaya',
    },
    {
      id: 4535,
      name: 'Политехническая',
      slug: 'politehnicheskaya',
    },
    {
      id: 4536,
      name: 'Площадь Мужества',
      slug: 'ploshchad_muzhestva',
    },
    {
      id: 4537,
      name: 'Лесная',
      slug: 'lesnaya',
    },
    {
      id: 4538,
      name: 'Выборгская',
      slug: 'vyborgskaya',
    },
    {
      id: 4539,
      name: 'Площадь Ленина',
      slug: 'spb_ploshchad_lenina',
    },
    {
      id: 4540,
      name: 'Чернышевская',
      slug: 'chernyshevskaya',
    },
    {
      id: 4541,
      name: 'Площадь Восстания',
      slug: 'ploshchad_vosstaniya',
    },
    {
      id: 4542,
      name: 'Владимирская',
      slug: 'vladimirskaya',
    },
    {
      id: 4543,
      name: 'Пушкинская',
      slug: 'spb_pushkinskaya',
    },
    {
      id: 4545,
      name: 'Балтийская',
      slug: 'baltiyskaya',
    },
    {
      id: 4546,
      name: 'Нарвская',
      slug: 'narvskaya',
    },
    {
      id: 4547,
      name: 'Кировский завод',
      slug: 'kirovskiy_zavod',
    },
    {
      id: 4548,
      name: 'Автово',
      slug: 'avtovo',
    },
    {
      id: 4549,
      name: 'Ленинский проспект',
      slug: 'spb_leninskiy_prospekt',
    },
    {
      id: 4550,
      name: 'Проспект Ветеранов',
      slug: 'prospekt_veteranov',
    },

    {
      id: 4551,
      name: 'Парнас',
      slug: 'parnas',
    },
    {
      id: 4552,
      name: 'Проспект Просвещения',
      slug: 'prospekt_prosveshcheniya',
    },
    {
      id: 4553,
      name: 'Озерки',
      slug: 'ozerki',
    },
    {
      id: 4554,
      name: 'Удельная',
      slug: 'spb_udelnaya',
    },
    {
      id: 4555,
      name: 'Пионерская',
      slug: 'spb_pionerskaya',
    },
    {
      id: 4556,
      name: 'Черная речка',
      slug: 'chernaya_rechka',
    },
    {
      id: 4557,
      name: 'Петроградская',
      slug: 'petrogradskaya',
    },
    {
      id: 4558,
      name: 'Горьковская',
      slug: 'gorkovskaya',
    },
    {
      id: 4559,
      name: 'Невский проспект',
      slug: 'nevskiy_prospekt',
    },
    {
      id: 4560,
      name: 'Сенная площадь',
      slug: 'sennaya_ploshchad',
    },
    {
      id: 4561,
      name: 'Технологический институт',
      slug: 'spb_technologicheskiy_institut',
    },
    {
      id: 4562,
      name: 'Фрунзенская',
      slug: 'spb_frunzenskaya',
    },
    {
      id: 4563,
      name: 'Московские Ворота',
      slug: 'moskovskie_vorota',
    },
    {
      id: 4564,
      name: 'Электросила',
      slug: 'elektrosila',
    },
    {
      id: 4565,
      name: 'Парк Победы',
      slug: 'spb_park_pobedy',
    },
    {
      id: 4566,
      name: 'Московская',
      slug: 'spb_moskovskaya',
    },
    {
      id: 4567,
      name: 'Звездная',
      slug: 'zvezdnaya',
    },
    {
      id: 4568,
      name: 'Купчино',
      slug: 'kupchino',
    },

    {
      id: 24338,
      name: 'Беговая',
      slug: 'begovaya2',
    },
    {
      id: 24336,
      name: 'Зенит',
      slug: 'zenit',
    },
    {
      id: 12356,
      name: 'Обводный канал',
      slug: 'obvodnoy_kanal',
    },
    {
      id: 12355,
      name: 'Звенигородская',
      slug: 'zvenigorodskaya',
    },
    {
      id: 4584,
      name: 'Садовая',
      slug: 'sadovaya',
    },
    {
      id: 12353,
      name: 'Адмиралтейская',
      slug: 'admiralteyskaya',
    },
    {
      id: 4583,
      name: 'Спортивная',
      slug: 'spb_sportivnaya',
    },
    {
      id: 4582,
      name: 'Чкаловская',
      slug: 'spb_chkalovskaya',
    },
    {
      id: 4581,
      name: 'Крестовский остров',
      slug: 'krestovskyi_ostrov',
    },
    {
      id: 4580,
      name: 'Старая Деревня',
      slug: 'staraya_derevnya',
    },
    {
      id: 4579,
      name: 'Комендантский проспект',
      slug: 'komendantskiy_prospetk',
    },
    {
      id: 12321,
      name: 'Волковская',
      slug: 'Volkovskaya',
    },
    {
      id: 12357,
      name: 'Бухарестская',
      slug: 'buharestskaya',
    },
    {
      id: 12358,
      name: 'Международная',
      slug: 'mezhdunarodnaya',
    },
    {
      id: 24479,
      name: 'Проспект Славы',
      slug: 'prospekt_slavy',
    },
    {
      id: 24480,
      name: 'Дунайская',
      slug: 'dunajskaya',
    },
    {
      id: 24482,
      name: 'Шушары',
      slug: 'shushary',
    },

    {
      id: 4591,
      name: 'Улица Дыбенко',
      slug: 'ulitsa_dybenko',
    },
    {
      id: 12364,
      name: 'Спасская',
      slug: 'spasskaya',
    },
    {
      id: 4587,
      name: 'Площадь Александра Невского',
      slug: 'ploshchad_aleksandra_nevskogo_2',
    },
    {
      id: 4588,
      name: 'Новочеркасская',
      slug: 'novocherkasskaya',
    },
    {
      id: 4589,
      name: 'Ладожская',
      slug: 'ladozhskaya',
    },
    {
      id: 4590,
      name: 'Проспект Большевиков',
      slug: 'prospekt_bolshevikov',
    },
    {
      id: 4585,
      name: 'Достоевская',
      slug: 'dostoevskaya',
    },
    {
      id: 4586,
      name: 'Лиговский проспект',
      slug: 'ligovskiy_prospekt',
    },
    {
      id: 4569,
      name: 'Приморская',
      slug: 'primorskaya',
    },
    {
      id: 4570,
      name: 'Василеостровская',
      slug: 'vasileostrovskaya',
    },
    {
      id: 4571,
      name: 'Гостиный Двор',
      slug: 'gostinyi_dvor',
    },
    {
      id: 4572,
      name: 'Маяковская',
      slug: 'spb_mayakovskaya',
    },
    {
      id: 4574,
      name: 'Елизаровская',
      slug: 'elizarovskaya',
    },
    {
      id: 4575,
      name: 'Ломоносовская',
      slug: 'lomonosovskaya',
    },
    {
      id: 4576,
      name: 'Пролетарская',
      slug: 'spb_proletarskaya',
    },
    {
      id: 4577,
      name: 'Обухово',
      slug: 'obukhovo',
    },
    {
      id: 4578,
      name: 'Рыбацкое',
      slug: 'rybatskoe',
    },
  ],
  novosibirsk: [
    {
      id: 4606,
      name: 'Заельцовская',
      slug: 'zaeltsovskaya',
    },
    {
      id: 4607,
      name: 'Гагаринская',
      slug: 'gagarinskaya',
    },
    {
      id: 4608,
      name: 'Красный проспект',
      slug: 'krasnyi_prospekt',
    },
    {
      id: 4609,
      name: 'Площадь Ленина',
      slug: 'ploshchad_lenina',
    },
    {
      id: 4610,
      name: 'Октябрьская',
      slug: 'nvs_oktyabrskaya',
    },
    {
      id: 4611,
      name: 'Речной вокзал',
      slug: 'nvs_rechnoy_vokzal',
    },
    {
      id: 4612,
      name: 'Студенческая',
      slug: 'nvs_studencheskaya',
    },
    {
      id: 4613,
      name: 'Площадь Маркса',
      slug: 'ploshchad_marksa',
    },
    {
      id: 4614,
      name: 'Площадь Гарина-Михайловского',
      slug: 'ploshchad_garina-mikhaylovskogo',
    },
    {
      id: 4615,
      name: 'Сибирская',
      slug: 'sibirskaya',
    },
    {
      id: 4616,
      name: 'Маршала Покрышкина',
      slug: 'nvs_marshala_pokryshkina',
    },
    {
      id: 4617,
      name: 'Березовая роща',
      slug: 'berezovaya_roshcha',
    },
    {
      id: 12750,
      name: 'Золотая Нива',
      slug: 'zolotaya_niva',
    },
  ],
  nnovgorod: [
    {
      id: 12817,
      name: 'Горьковская',
      slug: 'nnv_gorkovskaya',
    },
    {
      id: 4601,
      name: 'Московская',
      slug: 'moskovskaya',
    },
    {
      id: 4600,
      name: 'Чкаловская',
      slug: 'nnv_chkalovskaya',
    },
    {
      id: 4592,
      name: 'Парк культуры',
      slug: 'nnv_park_kultury',
    },
    {
      id: 4594,
      name: 'Комсомольская',
      slug: 'nnv_komsomolskaya',
    },
    {
      id: 4595,
      name: 'Автозаводская',
      slug: 'nnv_avtozavodskaya',
    },
    {
      id: 4596,
      name: 'Пролетарская',
      slug: 'nnv_proletarskaya',
    },
    {
      id: 4599,
      name: 'Ленинская',
      slug: 'leninskaya',
    },
    {
      id: 4598,
      name: 'Заречная',
      slug: 'zarechnaya',
    },
    {
      id: 4597,
      name: 'Двигатель Революции',
      slug: 'dvigatel_revolutsii',
    },
    {
      id: 4593,
      name: 'Кировская',
      slug: 'kirovskaya',
    },
    {
      id: 24342,
      name: 'Стрелка',
      slug: 'strelka',
    },
    {
      id: 4603,
      name: 'Канавинская',
      slug: 'kanavinskaya',
    },
    {
      id: 4604,
      name: 'Бурнаковская',
      slug: 'burnakovskaya',
    },
    {
      id: 4605,
      name: 'Буревестник',
      slug: 'burevestnik',
    },
  ],
  ekaterinburg: [
    {
      id: 4618,
      name: 'Проспект Космонавтов',
      slug: 'prospekt_kosmonavtov',
    },
    {
      id: 4619,
      name: 'Уралмаш',
      slug: 'ekt_uralmash',
    },
    {
      id: 4620,
      name: 'Машиностроителей',
      slug: 'mashinostroiteley',
    },
    {
      id: 4621,
      name: 'Уральская',
      slug: 'uralskaya',
    },
    {
      id: 4622,
      name: 'Динамо',
      slug: 'dinamo',
    },
    {
      id: 4623,
      name: 'Площадь 1905 года',
      slug: 'ploshchad_1905_goda',
    },
    {
      id: 4624,
      name: 'Геологическая',
      slug: 'geologicheskaya',
    },
    {
      id: 4625,
      name: 'Ботаническая',
      slug: 'botanicheskaya',
    },
    {
      id: 12948,
      name: 'Чкаловская',
      slug: 'ekb_chkalovskaya',
    },
  ],
}
