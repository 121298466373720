import React from 'react'

const IconBookingIsNotEnabled = () => {
  return (
    <>
      <svg width='418' height='260' viewBox='0 0 418 260' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clip-path='url(#clip0_196_2456)'>
          <g clip-path='url(#clip1_196_2456)'>
            <path
              d='M316.508 23.7989L379.726 39.6133C379.726 39.6133 386.035 44.3705 386.55 48.2277C386.936 52.0849 387.58 249.316 387.58 249.316L396.593 247.258L409.597 243.916C409.597 243.916 413.717 241.473 415.133 237.23C416.549 232.987 416.678 230.287 416.678 226.173C416.678 222.058 416.678 47.3277 416.678 47.3277C416.678 47.3277 416.42 40.7705 413.974 38.9705C411.528 37.1705 407.923 34.0847 407.923 34.0847L343.675 18.7845L319.212 21.2274C319.212 21.2274 316.637 21.0989 316.637 22.5132C316.637 23.9275 316.508 23.7989 316.508 23.7989Z'
              fill='url(#paint0_linear_196_2456)'
            />
            <path
              d='M16.5147 205.215C16.5147 205.215 19.7335 221.801 23.3386 227.072C26.9437 232.215 33.2526 236.973 33.2526 236.973L45.7416 238.773L72.7796 240.958L83.4661 240.83L113.98 237.873L139.602 233.244L160.46 227.072L171.662 221.801L162.907 207.529L152.735 193.901L144.366 184.643C144.366 184.643 132.778 190.686 129.56 191.844C126.341 192.872 101.878 199.429 98.5302 199.944C95.1826 200.458 77.286 201.615 77.286 201.615L60.6769 200.972L48.4454 200.072L36.7289 200.329L25.9137 202.001L17.5447 204.058L16.5147 205.215Z'
              fill='url(#paint1_linear_196_2456)'
            />
            <path
              d='M125.954 193.901C125.954 193.901 128.916 199.686 130.718 200.715C132.521 201.744 137.542 202.515 137.542 202.515L146.426 200.715L154.28 196.344L143.98 184.643L132.135 191.329L125.954 193.901Z'
              fill='url(#paint2_linear_196_2456)'
            />
            <path
              d='M179.258 240.187C179.258 240.187 190.331 233.115 194.322 229.258C198.313 225.401 239.9 178.601 239.9 178.601L238.87 157.386L235.78 139.514L227.025 139.386L220.459 136.943L217.755 133.214L201.017 148.515C201.017 148.515 179.387 162.786 171.79 167.158C164.065 171.4 141.405 183.486 141.405 183.486C141.405 183.486 152.864 195.186 161.104 206.758C169.344 218.458 174.88 231.058 174.88 231.058L179.258 240.187Z'
              fill='url(#paint3_linear_196_2456)'
            />
            <path
              d='M249.557 131.286L253.677 168.829L255.48 167.415L257.54 158.415L258.312 150.057L258.055 147.229L264.364 140.286L276.466 121.771L294.878 85.8996L292.689 77.7995L288.312 70.9851L284.191 68.928L272.217 81.0138L249.815 109.043L252.261 114.7L253.548 121.129L251.746 127.814L249.557 131.286Z'
              fill='url(#paint4_linear_196_2456)'
            />
            <path
              d='M314.448 108.528L327.967 125.371L327.71 154.429C327.71 154.429 327.71 160.729 330.671 162.786C333.761 164.843 361.572 177.058 361.572 177.058C361.572 177.058 368.911 178.086 369.168 172.943C369.426 167.672 369.426 80.8852 369.426 80.8852L369.297 70.3423C369.297 70.3423 368.396 65.1993 362.73 64.1708C357.065 63.2708 332.86 54.6564 332.86 54.6564C332.86 54.6564 329.899 54.6564 328.997 55.9421C328.096 57.2278 327.581 70.4708 327.581 70.4708L316.508 64.4279L313.933 62.1136L314.191 27.3989L314.577 23.4132L315.607 22.6417L376.893 37.6847C376.893 37.6847 382.558 39.7419 384.232 43.7277C385.906 47.7134 385.906 48.4849 386.292 52.0849C386.678 55.685 386.292 248.03 386.292 248.03L377.279 244.944L321.658 214.087C321.658 214.087 316.894 210.487 315.993 208.044C315.092 205.601 314.319 201.487 314.191 198.915C314.062 196.344 314.448 108.528 314.448 108.528Z'
              fill='url(#paint5_linear_196_2456)'
            />
            <path
              d='M306.208 80.6281L357.838 144.015L358.224 87.8282L317.023 63.785L306.208 80.6281Z'
              fill='url(#paint6_linear_196_2456)'
            />
            <path
              d='M258.07 25.8954L266.053 32.9669V22.9382C266.053 22.9382 270.173 21.7811 271.976 19.4668C273.649 17.1524 275.066 14.1953 274.937 11.2381C274.808 8.40949 273.263 4.80945 269.529 3.00943C265.795 1.33799 261.675 1.72371 251.89 5.58089C242.105 9.43807 199.23 24.2239 199.23 24.2239C199.23 24.2239 192.02 28.5954 194.853 36.4384C197.685 44.2813 206.054 43.2527 206.054 43.2527L258.07 25.8954Z'
              fill='url(#paint7_linear_196_2456)'
            />
          </g>
          <path
            d='M142.718 185.042C129.663 193.811 100.268 199.519 100.268 199.519C100.268 199.519 80.8138 203.004 56.2736 200.612C31.7333 198.221 15.6779 204.186 15.6779 204.186C15.6779 204.186 16.9912 228.975 33.5745 237.294C33.5745 237.294 65.1446 241.061 77.4534 240.83C89.7622 240.598 145.988 236.69 170.645 221.711'
            stroke='black'
            stroke-width='1.5'
            stroke-miterlimit='10'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M25.5918 201.024C25.5918 201.024 25.5918 197.475 26.5832 194.055C26.5832 194.055 50.6342 193.875 67.694 201.024'
            stroke='black'
            stroke-width='1.5'
            stroke-miterlimit='10'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M218.27 132.7C218.27 132.7 210.7 140.53 206.399 144.259C202.099 147.987 186.507 158.492 186.507 158.492L166.962 169.613L141.843 182.895C141.843 182.895 164.361 203.209 179.799 239.776C179.799 239.776 186.777 235.687 192.198 231.2C197.618 226.713 210.391 213.315 212.669 210.898C214.948 208.481 240.493 178.202 240.493 178.202'
            stroke='black'
            stroke-width='1.5'
            stroke-miterlimit='10'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M125.401 193.695C125.401 193.695 129.688 211.348 153.585 195.829'
            stroke='black'
            stroke-width='1.5'
            stroke-miterlimit='10'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M25.2571 242.27C25.2571 242.27 37.7461 244.597 41.0293 251.746C42.3812 255.011 42.1237 257.429 42.1237 257.429C30.8836 260.424 20.3645 258.303 20.3645 258.303C20.3645 258.303 9.04712 257.069 5.54505 252.838C2.04298 248.608 2.48074 242.193 2.48074 242.193L1.46359 212.299C1.46359 212.299 10.7467 208.507 15.7036 207.851'
            stroke='black'
            stroke-width='1.5'
            stroke-miterlimit='10'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M6.83258 210.924C6.83258 210.924 6.30469 224.115 6.83258 232.357C7.36046 240.598 6.12444 256.49 19.9267 250.074C33.729 243.658 34.5788 245.6 34.5788 245.6'
            stroke='black'
            stroke-width='1.5'
            stroke-miterlimit='10'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M15.0985 237.384C15.0985 237.384 22.6692 235.378 26.2613 236.677'
            stroke='black'
            stroke-width='1.5'
            stroke-miterlimit='10'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M14.4419 227.69C14.4419 227.69 19.3216 227.69 26.2614 234.247'
            stroke='black'
            stroke-width='1.5'
            stroke-miterlimit='10'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M28.7978 243.208C28.7978 243.208 26.2614 237.898 26.2614 231.997'
            stroke='black'
            stroke-width='1.5'
            stroke-miterlimit='10'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M36.1496 245.6L50.7372 245.831C50.7372 245.831 48.8832 240.907 47.2094 239.364'
            stroke='black'
            stroke-width='1.5'
            stroke-miterlimit='10'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M236.373 139.064C230.927 139.669 223.665 139.154 220.253 135.837C216.841 132.52 218.27 123.404 218.27 123.404C218.27 123.404 239.399 120.948 245.849 107.063C245.849 107.063 260.836 112.72 250.098 130.707'
            stroke='black'
            stroke-width='1.5'
            stroke-miterlimit='10'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M233.824 124.381C233.824 124.381 237.261 139.206 239.308 155.213C241.356 171.22 240.828 208.327 236.862 228.14L250.111 226.378C262.394 192.846 247.729 118.48 247.729 118.48'
            stroke='black'
            stroke-width='1.5'
            stroke-miterlimit='10'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M240.48 198.825C240.48 198.825 249.081 198.169 254.54 192.319'
            stroke='black'
            stroke-width='1.5'
            stroke-miterlimit='10'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M239.875 208.095C239.875 208.095 242.501 210.654 254.154 201.396'
            stroke='black'
            stroke-width='1.5'
            stroke-miterlimit='10'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M239.81 192.319C239.81 192.319 205.24 234.748 194.116 239.827L190.756 232.357'
            stroke='black'
            stroke-width='1.5'
            stroke-miterlimit='10'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M250.33 108.451C250.33 108.451 274.857 75.5623 284.745 68.3365C284.745 68.3365 289.329 66.2922 295.998 83.9581C295.998 83.9581 273.544 133.741 258.557 146.65C260.141 155.895 254.553 171.478 254.553 171.478'
            stroke='black'
            stroke-width='1.5'
            stroke-miterlimit='10'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M298.56 88.3938C298.56 88.3938 276.621 139.952 259.484 153.465'
            stroke='black'
            stroke-width='1.5'
            stroke-miterlimit='10'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M226.51 79.9851C226.51 79.9851 228.982 69.905 219.442 68.2721C209.901 66.6393 210.236 74.4822 210.854 77.1694C211.472 79.8565 210.996 82.0809 208.614 84.5495C206.232 87.0309 204.275 88.3552 204.854 93.4981C205.421 98.641 206.27 98.731 204.751 103.257C203.232 107.783 201.326 113.273 204.275 115.883C207.236 118.493 211.871 121.861 220.021 121.09C220.021 121.09 222.12 120.756 218.27 112.823C214.42 104.877 211.91 100.762 212.103 95.401C212.296 90.0395 213.069 86.6966 217.111 84.2537C221.141 81.8109 223.665 80.7951 226.51 79.9851Z'
            fill='black'
            stroke='black'
            stroke-width='1.5'
            stroke-miterlimit='10'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M204.082 102.691C204.082 102.691 197.863 100.261 197.026 93.4081C196.189 86.5552 199.974 82.3894 202.163 80.8594C204.339 79.3165 206.657 77.3622 210.313 79.6508'
            stroke='black'
            stroke-width='1.5'
            stroke-miterlimit='10'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M227.244 79.5608C227.244 79.5608 245.27 85.0894 242.798 110.393'
            stroke='black'
            stroke-width='1.5'
            stroke-miterlimit='10'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M225.181 99.1993C225.949 98.8271 225.816 96.9718 224.884 95.0554C223.952 93.139 222.575 91.8872 221.807 92.2594C221.039 92.6316 221.173 94.4869 222.104 96.4033C223.036 98.3197 224.414 99.5715 225.181 99.1993Z'
            fill='black'
          />
          <path
            d='M234.967 94.0054C235.735 93.6332 235.602 91.778 234.67 89.8616C233.738 87.9452 232.36 86.6934 231.593 87.0656C230.825 87.4378 230.958 89.293 231.89 91.2094C232.822 93.1258 234.199 94.3776 234.967 94.0054Z'
            fill='black'
          />
          <path
            d='M219.21 108.194C219.21 108.194 222.287 106.355 224.36 103.013'
            stroke='black'
            stroke-width='1.5'
            stroke-miterlimit='10'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M235.974 107.423C235.974 107.423 235.678 110.225 230.991 110.38C226.304 110.534 222.12 106.728 222.12 106.728'
            stroke='black'
            stroke-width='1.5'
            stroke-miterlimit='10'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M230.746 98.9754C230.746 98.9754 233.553 98.5254 235.523 97.6897C237.364 96.9054 237.866 97.8697 237.866 98.564C237.866 99.9397 234.879 102.048 232.304 103.013'
            stroke='black'
            stroke-width='1.5'
            stroke-miterlimit='10'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M295.998 83.9709L296.526 84.6909L336.813 136.493L359.023 146.252V89.5252L317.41 65.1606L306.646 82.0551L297.157 80.4608'
            stroke='black'
            stroke-width='1.5'
            stroke-miterlimit='10'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M306.646 82.0552L359.023 146.252'
            stroke='black'
            stroke-width='1.5'
            stroke-miterlimit='10'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M287.076 68.928C287.076 68.928 304.38 44.6534 316.56 65.5208'
            stroke='black'
            stroke-width='1.5'
            stroke-miterlimit='10'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M295.998 82.1966C295.998 82.1966 308.513 60.4678 315.852 66.665'
            stroke='black'
            stroke-width='1.5'
            stroke-miterlimit='10'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M294.711 60.5707C294.711 60.5707 304.251 46.6334 310.831 58.3207'
            stroke='black'
            stroke-width='1.5'
            stroke-miterlimit='10'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M196.472 214.228C198.371 214.228 199.91 212.691 199.91 210.795C199.91 208.899 198.371 207.362 196.472 207.362C194.574 207.362 193.035 208.899 193.035 210.795C193.035 212.691 194.574 214.228 196.472 214.228Z'
            fill='white'
            stroke='black'
            stroke-width='1.5'
            stroke-miterlimit='10'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M210.828 201.384C212.727 201.384 214.266 199.847 214.266 197.951C214.266 196.055 212.727 194.518 210.828 194.518C208.93 194.518 207.391 196.055 207.391 197.951C207.391 199.847 208.93 201.384 210.828 201.384Z'
            fill='white'
            stroke='black'
            stroke-width='1.5'
            stroke-miterlimit='10'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M221.901 188.475C223.8 188.475 225.339 186.938 225.339 185.042C225.339 183.146 223.8 181.609 221.901 181.609C220.002 181.609 218.463 183.146 218.463 185.042C218.463 186.938 220.002 188.475 221.901 188.475Z'
            fill='white'
            stroke='black'
            stroke-width='1.5'
            stroke-miterlimit='10'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M199.06 213.637C199.06 213.637 201.429 217.288 205.665 218.188'
            stroke='black'
            stroke-width='1.5'
            stroke-miterlimit='10'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M213.905 200.458C213.905 200.458 216.596 202.695 219.094 203.466'
            stroke='black'
            stroke-width='1.5'
            stroke-miterlimit='10'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M225.068 187.665C225.068 187.665 227.36 190.275 230.012 190.609'
            stroke='black'
            stroke-width='1.5'
            stroke-miterlimit='10'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M315.131 109.158V202.168C315.131 207.478 318.041 212.377 322.702 214.922L381.207 246.86C384.503 248.66 388.365 249.11 391.996 248.133L405.786 244.404C412.133 242.694 416.536 236.934 416.536 230.377V46.9548C416.536 40.3847 412.12 34.6246 405.773 32.9146L345.555 19.1316C343.637 18.6173 341.641 18.5016 339.684 18.7845L320.796 20.4559C320.796 20.4559 315.118 20.4559 315.118 26.3445V64.1064'
            stroke='black'
            stroke-width='1.5'
            stroke-miterlimit='10'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M315.865 23.2845L376.147 37.7618C382.52 39.4719 386.936 45.2448 386.936 51.8277V248.647'
            stroke='black'
            stroke-width='1.5'
            stroke-miterlimit='10'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M328.482 71.6537V59.915C328.482 56.8164 331.508 54.6178 334.456 55.5692L365.151 65.4179C368.19 66.395 370.25 69.2236 370.25 72.4122V171.773C370.25 176.299 365.589 179.321 361.443 177.495L334.495 165.576C330.838 163.956 328.469 160.33 328.469 156.345V125.783'
            stroke='black'
            stroke-width='1.5'
            stroke-miterlimit='10'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M257.141 24.6731L265.844 32.4132L265.201 22.3331C265.059 21.7931 265.728 21.6645 265.728 21.6645L267.492 21.0474C272.861 19.1702 275.72 13.243 273.84 7.86866C271.96 2.50717 266.025 -0.347141 260.643 1.53002L200.644 22.526C195.275 24.4031 192.417 30.3303 194.296 35.7047C196.176 41.0662 202.112 43.9205 207.494 42.0433L257.141 24.6731Z'
            stroke='black'
            stroke-width='1.5'
            stroke-miterlimit='10'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M206.618 34.1876C208.517 34.1876 210.056 32.6506 210.056 30.7547C210.056 28.8587 208.517 27.3218 206.618 27.3218C204.719 27.3218 203.18 28.8587 203.18 30.7547C203.18 32.6506 204.719 34.1876 206.618 34.1876Z'
            stroke='black'
            stroke-width='1.5'
            stroke-miterlimit='10'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M217.253 29.9703C219.152 29.9703 220.691 28.4333 220.691 26.5374C220.691 24.6415 219.152 23.1045 217.253 23.1045C215.355 23.1045 213.815 24.6415 213.815 26.5374C213.815 28.4333 215.355 29.9703 217.253 29.9703Z'
            stroke='black'
            stroke-width='1.5'
            stroke-miterlimit='10'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M227.875 25.7659C229.774 25.7659 231.313 24.229 231.313 22.333C231.313 20.4371 229.774 18.9001 227.875 18.9001C225.977 18.9001 224.437 20.4371 224.437 22.333C224.437 24.229 225.977 25.7659 227.875 25.7659Z'
            stroke='black'
            stroke-width='1.5'
            stroke-miterlimit='10'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
        </g>
        <defs>
          <linearGradient
            id='paint0_linear_196_2456'
            x1='366.593'
            y1='18.7845'
            x2='430.935'
            y2='221.339'
            gradientUnits='userSpaceOnUse'>
            <stop stop-color='#35888E' />
            <stop offset='0.0364583' stop-color='#356F91' />
            <stop offset='0.177083' stop-color='#3B4C9C' />
            <stop offset='0.963542' stop-color='white' />
          </linearGradient>
          <linearGradient
            id='paint1_linear_196_2456'
            x1='165.224'
            y1='204.572'
            x2='21.0312'
            y2='227.778'
            gradientUnits='userSpaceOnUse'>
            <stop stop-color='#A5B5D9' />
            <stop offset='1' stop-color='#FCFCFC' />
          </linearGradient>
          <linearGradient
            id='paint2_linear_196_2456'
            x1='147.199'
            y1='194.929'
            x2='133.683'
            y2='199.441'
            gradientUnits='userSpaceOnUse'>
            <stop stop-color='#BCC8E2' />
            <stop offset='1' stop-color='white' />
          </linearGradient>
          <linearGradient
            id='paint3_linear_196_2456'
            x1='234.107'
            y1='135.786'
            x2='167.251'
            y2='204.023'
            gradientUnits='userSpaceOnUse'>
            <stop offset='0.140625' stop-color='#FAB793' />
            <stop offset='0.619792' stop-color='#F4E83C' />
            <stop offset='0.989583' stop-color='white' />
          </linearGradient>
          <linearGradient
            id='paint4_linear_196_2456'
            x1='246.982'
            y1='135.786'
            x2='309.342'
            y2='74.6267'
            gradientUnits='userSpaceOnUse'>
            <stop stop-color='#FBB496' />
            <stop offset='0.661458' stop-color='#FDFCF8' />
          </linearGradient>
          <linearGradient
            id='paint5_linear_196_2456'
            x1='350.198'
            y1='22.6417'
            x2='350.198'
            y2='248.03'
            gradientUnits='userSpaceOnUse'>
            <stop stop-color='white' />
            <stop offset='1' stop-color='#D0D8EB' />
          </linearGradient>
          <linearGradient
            id='paint6_linear_196_2456'
            x1='384.104'
            y1='115.214'
            x2='300.453'
            y2='77.8411'
            gradientUnits='userSpaceOnUse'>
            <stop offset='0.223958' stop-color='#B9C4E2' />
            <stop offset='1' stop-color='white' stop-opacity='0' />
          </linearGradient>
          <linearGradient
            id='paint7_linear_196_2456'
            x1='234.569'
            y1='2.07007'
            x2='234.569'
            y2='43.2971'
            gradientUnits='userSpaceOnUse'>
            <stop stop-color='#BFCBE5' />
            <stop offset='1' stop-color='white' />
          </linearGradient>
          <clipPath id='clip0_196_2456'>
            <rect width='417.03' height='260' fill='white' transform='translate(0.485077)' />
          </clipPath>
          <clipPath id='clip1_196_2456'>
            <rect width='400.807' height='247.76' fill='white' transform='translate(16.3216 0.784302)' />
          </clipPath>
        </defs>
      </svg>
    </>
  )
}
export default IconBookingIsNotEnabled
