import { useRouter } from 'next/router'
import { useEffect } from 'react'

export const ABGroups = {
  AB_OPEN_BOOKING_1: ['A', 'B', 'C', 'D'],
}

// Обработка query параметров которые относятся к AB тестам
// Данный хук нужен для принудительной установки устройства к той или иной группе AB тестов
// На основе переданных query параметров устанавливает в localStorage ключ и группу для тестирования
export const useQueryABTests = () => {
  const router = useRouter()

  const handleQueryParams = (router) => {
    if (!router.query) return

    Object.entries(router.query).forEach(([query, value]) => {
      if (typeof value !== 'string' && typeof value !== 'number') return

      if (query in ABGroups) {
        ABGroups[query].includes(value)
        window.localStorage.setItem(query, String(value))
      }
    })
  }

  // AB enabled for group D
  useEffect(() => {
    window.localStorage.setItem('AB_OPEN_BOOKING_1', String('D'))
  }, [])

  useEffect(() => {
    handleQueryParams(router)
  }, [router])
}
