import React, { FC } from 'react'
import s from './Header.module.sass'
import { IconLogoRest, IconLogoText } from '@widgets/Common/Header/ui/Logo'
import { ContainerLong } from '@shared/ui/Layout/Containers/ContainerLong'
import { Text } from '@shared/ui/Typography/Text'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import IconSubproductRest from '@icons/icon-logo-subproduct-restaurant.svg'

const Header: FC = () => {
  return (
    <ErrorBoundary>
      <ContainerLong tag={'header'} classNameContent={`${s.header}`}>
        <div className={s['logo']}>
          <IconLogoRest />

          <div className={s['logo-text']}>
            <IconLogoText />
            <IconSubproductRest />
          </div>
        </div>

        <Text sizes={'ML'}>
          <a href='mailto:restaurants@afisha.ru' className={s.mail}>
            restaurants@afisha.ru
          </a>
        </Text>
      </ContainerLong>
    </ErrorBoundary>
  )
}

export default Header
