import React, { FC } from 'react'
import s from './CalendarSlider.module.sass'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { CalendarArrow } from '@widgets/Restaurant/RestConcerts/ui/CalendarArrow'
import { Carousel } from '@shared/ui/Layout/Carousel'
import CarouselSlider from '@shared/ui/Layout/Carousel/CarouselSlider'
import { CalendarSliderCell } from '@widgets/Restaurant/RestConcerts/ui/CalendarSliderCell'
import { CarouselArrow } from '@shared/ui/Layout/Carousel/CarouselControls'

interface Props {
  days: object[]
  daySelect: string
  hasButtonPrev: boolean
  hasButtonNext: boolean
  hasOverlayPrev: boolean
  hasOverlayNext: boolean
  handleDaySelection: (data: any) => void
  handleDeselect: () => void
}

const CalendarSlider: FC<Props> = ({
  days,
  daySelect,
  hasButtonPrev,
  hasButtonNext,
  hasOverlayPrev,
  hasOverlayNext,
  handleDaySelection,
  handleDeselect,
}) => {
  const calendarClassName = `
    ${s['calendar']}
    ${hasButtonPrev ? s['calendar--has-prev'] : ''}
    ${hasButtonNext ? s['calendar--has-next'] : ''}
  `

  const calendarListClassName = `
    ${s['calendar-list']}
    ${hasButtonPrev ? s['calendar-list--has-prev'] : ''}
    ${hasButtonNext ? s['calendar-list--has-next'] : ''}
  `

  return (
    <ErrorBoundary>
      <div className={calendarClassName} aria-label='Календарь'>
        <Carousel>
          <Carousel.Arrow direction={'prev'}>
            <CalendarArrow state='prev' hasOverlay={hasOverlayPrev} hasButton={hasButtonPrev} />
          </Carousel.Arrow>
          <Carousel.Arrow direction={'next'}>
            <CalendarArrow state='next' hasOverlay={hasOverlayNext} hasButton={hasButtonNext} />
          </Carousel.Arrow>

          <Carousel.Slider className={calendarListClassName} spacingMobile={4} spacingDesktop={0} slideMoveCount={9}>
            {days?.length !== 0 &&
              days.map((day, index) => (
                <CalendarSliderCell
                  daySelect={daySelect}
                  dayData={day}
                  key={index}
                  handleDaySelection={handleDaySelection}
                  handleDeselect={handleDeselect}
                />
              ))}
          </Carousel.Slider>
        </Carousel>
      </div>
    </ErrorBoundary>
  )
}

export default CalendarSlider
