import React, { FC, useRef } from 'react'
import Image from 'next/image'
import Link from 'next/link'

import s from './FilterItem.module.sass'

import { useAppSelector } from '@app/model/store'
import { useOnScreen } from '@widgets/Restaurant/Booking/lib/helper'
import { findDataTagForAnalyticBySlug } from '@features/Restaurant/RestaurantFilters/helpers/helper'
import { TypeQuickFilter } from '@shared/api/middleware/mappingAPI/city/types'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { Text } from '@shared/ui/Typography/Text'
import { EGG } from '@shared/api/analytics'

interface Props {
  data: TypeQuickFilter
}

const FilterItem: FC<Props> = ({ data }) => {
  const { isMobile } = useClientResponsive()
  const globalStore = useAppSelector((store) => store.global)
  const wrapper = useRef()

  // @ts-ignore
  const rootForIntersectionObserver = wrapper.current?.parentElement.parentElement
  const visible60 = useOnScreen(wrapper, { threshold: 0.6, root: rootForIntersectionObserver })
  const visible70 = useOnScreen(wrapper, { threshold: 0.7, root: rootForIntersectionObserver })
  const visible80 = useOnScreen(wrapper, { threshold: 0.8, root: rootForIntersectionObserver })
  const visible90 = useOnScreen(wrapper, { threshold: 0.9, root: rootForIntersectionObserver })

  const getOpacityValue = () => {
    const isShowContent = window.innerWidth <= 970 || isMobile || visible90
    if (isShowContent) return 1
    else if (visible80) return 0.6
    else if (visible70) return 0.4
    else if (visible60) return 0.2
    else return 0
  }

  const handleClick = () => {
    const dataTag = findDataTagForAnalyticBySlug(data.slug)
    EGG.common.quick_filter_click({
      filter_apply_type: dataTag.type,
      filter_apply_value: data.slug,
    })
  }

  return (
    <Link
      href={`/${globalStore.cityRead.slug}/restaurants/restaurant_list/${data.slug}`}
      onClick={handleClick}
      className={s.filter}
      key={data.slug}
      ref={wrapper}
      title={data.name}
      scroll={false}>
      <div className={s['image-wrapper']}>
        <Image
          src={data.image[isMobile ? 'mobile' : 'desktop']}
          alt={data.name}
          width={isMobile ? 100 : 130}
          height={isMobile ? 100 : 130}
          className={s.image}
          style={{ opacity: getOpacityValue() }}
        />
      </div>

      <Text sizes={'S'} className={s.title} tag={'h4'} style={{ opacity: getOpacityValue() }}>
        {data.name}
      </Text>
    </Link>
  )
}

export default FilterItem
