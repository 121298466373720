import React, { createContext, FC, ReactNode } from 'react'
import s from './Cover.module.sass'
import CoverMain from '@shared/ui/Layout/Cover/CoverMain'
import CoverAside from '@shared/ui/Layout/Cover/CoverAside'
import { ContainerLong } from '@shared/ui/Layout/Containers/ContainerLong'
import { ErrorBoundary } from '../../../lib/components/ErrorBoundary'

export const ImgContext = createContext('')

interface Props {
  img: string
  children: ReactNode
  classNameContainer?: string
  classNameContent?: string
}

const Cover: FC<Props> = ({ children, img, classNameContainer = '', classNameContent = '' }) => {
  return (
    <ErrorBoundary>
      <ImgContext.Provider value={img}>
        <ContainerLong
          tag={'section'}
          classNameContainer={`${s.container} ${classNameContainer}`}
          classNameContent={`${s.content} ${classNameContent}`}>
          {children}
        </ContainerLong>
      </ImgContext.Provider>
    </ErrorBoundary>
  )
}

export default Cover

// @ts-ignore
Cover.Main = CoverMain
// @ts-ignore
Cover.Aside = CoverAside
