import { undefinedObjectToNull } from '@shared/api/middleware/mappingAPI/helpers'

import { TypeMappingDailyGQLOutput } from '@shared/api/middleware/mappingAPI/gql_daily/type'
import { getFullEntrySlug } from '@shared/api/middleware/mappingAPI/gql_daily/helpers/getEntryURL'
import { getResizedLink } from '@shared/api/middleware/mappingAPI/gql_daily/helpers/getImageURL'

export const mappingDailyGQL = (object: any): TypeMappingDailyGQLOutput => {
  try {
    return undefinedObjectToNull({
      id: object.id,
      title: object.title,
      url: getFullEntrySlug(object.id, object.slug, object.rubric.slug),
      img: object.photoModifications.map((photo) =>
        // @ts-ignore
        getResizedLink({
          photoModification: photo,
          width: photo.crop.size.width || '-',
          height: photo.crop.size.height || '-',
        }),
      ),
    })
  } catch (error) {
    console.error(error)
    return null
  }
}
