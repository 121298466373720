/**
 * @function getTabFromHash
 * @param thisHash - "#tab=restaurant&is_dev=true"
 * @description - из входящей строки берем параметр "tab",
 *      и его значение конвертируем в название компонента ключа
 *      для отображения нужного компонента
 * @returns функция позволяет получить ключ для отображения активного таба
 * @example HomeContent | MegaphoneContent | RestaurantContent | ChatbubblesContent
 */
export const getTabFromHash = (thisHash) => {
  /**
   * @constant hashArray - 1. разделяем хеш-строку на массив
   * @returns {array} - ["tab=restaurant", "is_dev=true"]
   */
  const hashArray = thisHash
    .split('#')
    .pop()
    .split('&')
    .filter((v) => !!v)

  /**
   * @constant hashTabName - 2. берем значение параметра "tab"
   * @returns {array} - "restaurant"
   */
  const hashTabName =
    hashArray.length > 0
      ? hashArray
          .filter((hashStr) => {
            return hashStr.indexOf('tab') > -1 ? hashStr : false
          })[0]
          .split('=')
          .pop()
      : null

  /**
   * @constant contentTabName - 3. форматируем значение активного таба
   * @returns {array} - "RestaurantContent"
   */
  const capFirstLetter = (thisString) => {
    return thisString.charAt(0).toUpperCase() + thisString.slice(1)
  }
  const contentTabName =
    thisHash !== '' && thisHash.indexOf('#') !== -1 && !!hashTabName ? capFirstLetter(hashTabName) + 'Content' : null

  const defaultTabName = 'HomeContent'
  return contentTabName || defaultTabName
}

/**
 * @function isObject - simple object check.
 * @param item
 * @returns {boolean}
 */
export const isObject = (item) => {
  return item && typeof item === 'object' && !Array.isArray(item)
}

/**
 * @function mergeDeep - deep merge two objects
 * @param target
 * @param ...sources
 */
export const mergeDeep = (target, ...sources) => {
  if (!sources.length) return target
  const source = sources.shift()

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} })
        mergeDeep(target[key], source[key])
      } else {
        Object.assign(target, { [key]: source[key] })
      }
    }
  }

  return mergeDeep(target, ...sources)
}
