import { sendClickHouseAnalytic } from '@shared/api/analytics/template'
import { PickAnalytic } from '@shared/api/analytics/types/types'
import { TypeMappingNewsOutput } from '@shared/api/middleware/mappingAPI/news/type'

type TypeDefaultDataOutput = PickAnalytic<'object_id' | 'object_type'>
const getDefaultData = (news: TypeMappingNewsOutput): TypeDefaultDataOutput => {
  const mappingType = {
    'plani-na-nedelu': 'selection',
    podborki: 'selection',
    'avtorskaia-kolonka': 'article',
    obzory: 'article',
    novosti: 'article',
    interviu: 'interviu',
  }

  const result: TypeDefaultDataOutput = {
    object_id: news.id,
    object_type: mappingType[news.type.slug] || 'article',
  }

  return result
}

type TypeDefaultProps = PickAnalytic<'section_index' | 'section_name'>
export const EGGNews = {
  card_click: (news: TypeMappingNewsOutput, props: TypeDefaultProps) =>
    sendClickHouseAnalytic({
      event_name: 'element_click', // basic
      element_type: 'news_card',
      ...getDefaultData(news),
      ...props,
    }),

  card_view: (news: TypeMappingNewsOutput, props: TypeDefaultProps) =>
    sendClickHouseAnalytic({
      event_name: 'element_view', // basic
      element_type: 'news_card',
      ...getDefaultData(news),
      ...props,
    }),

  click_add_favorite: (news: TypeMappingNewsOutput, props: TypeDefaultProps) =>
    sendClickHouseAnalytic({
      event_name: 'element_click', // basic
      element_type: 'news_card',
      ...getDefaultData(news),
      ...props,
    }),

  click_remove_favorite: (news: TypeMappingNewsOutput, props: TypeDefaultProps) =>
    sendClickHouseAnalytic({
      event_name: 'element_click', // basic
      element_type: 'news_card',
      ...getDefaultData(news),
      ...props,
    }),
}
