import { sendClickHouseAnalytic } from '@shared/api/analytics/template'
import { globalStore } from '@app/model/store'
import { PickAnalytic } from '@shared/api/analytics/types/types'

export const EGGHeader = {
  button_city_selection_click: () =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      element_type: 'city',
      section_name: 'header',
      city_name: globalStore.getState().global.currentCity.name,
    }),

  button_search_click: () =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      element_type: 'search',
      section_name: 'header',
    }),

  button_favorite_click: () =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      element_type: 'go_to_fav',
      section_name: 'header',
    }),

  button_login_click: () =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      element_type: 'come_in',
      section_name: 'header',
    }),

  button_tab_click: (props: PickAnalytic<'navigation_name'>) =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      element_type: 'tab',
      section_name: 'header',
      ...props,
    }),
}
