import { usePopup } from '@shared/ui/Overlays/Popup/hooks'
import * as React from 'react'
import { createContext } from 'react'

type ContextType =
  | (ReturnType<typeof usePopup> & {
      setLabelId: React.Dispatch<React.SetStateAction<string | undefined>>
      setDescriptionId: React.Dispatch<React.SetStateAction<string | undefined>>
    })
  | null

export const PopupContext = createContext<ContextType>(null)
