/* eslint-disable */

import { setContext } from '@apollo/client/link/context'

const getPageContext = () => {
  const ENVIRONMENT = 'data-environment';
  const CURRENT_CITY = 'data-current-city';
  const REQUEST_CITY = 'data-request-city';

  const attrEnvironment = document.querySelector(`[${ENVIRONMENT}]`)?.getAttribute(ENVIRONMENT);
  const attrCurrentCity = document.querySelector(`[${CURRENT_CITY}]`)?.getAttribute(CURRENT_CITY);
  const attrRequestCity = document.querySelector(`[${REQUEST_CITY}]`)?.getAttribute(REQUEST_CITY);


  return {
    // as 'Test' | 'Development' | 'Production'
    environment: attrEnvironment ? (attrEnvironment) : null,

    // 2
    currentCity: attrCurrentCity ? parseInt(attrCurrentCity) : null,

    // ???
    requestCity: attrRequestCity ? parseInt(attrRequestCity) : null,
  };
};

export const cityIDContext = setContext(
  (request, context) => {
    const cityID = getPageContext().currentCity

    if (cityID !== null) {
      return {
        ...context,
        headers: {
          ...context.headers,
          'X-Afisha-City-ID': cityID
        }
      }
    }

    return context
  }
)
