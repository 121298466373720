import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  TypeCommonPost,
  TypeInitialState,
  TypePayloadOpenEditMenuForm,
  TypePayloadOpenEditNewsForm,
  TypePayloadOpenEditSpecialForm,
  TypePostCategory,
} from '@pages/OwnerPersonalPage/model/OwnerPostsModel/types'

const initialCategories = {
  special: {
    id: null,
    fields: {
      date: { value: null, valid: false },
      title: { value: '', valid: false },
      description: { value: '', valid: false },
      preview: { value: '', valid: false },
    },
  },
  news: {
    id: null,
    fields: {
      date: { value: null, valid: false },
      title: { value: '', valid: false },
      description: { value: '', valid: false },
      preview: { value: '', valid: false },
    },
  },
  menu: {
    id: null,
    fields: {
      date: { value: null, valid: false },
      dishes: [
        {
          id: null,
          title: { value: '', valid: false },
          price: { value: '', valid: false },
          description: { value: '', valid: false },
          preview: { value: '', valid: false },
        },
      ],
    },
  },
}

const initialState: TypeInitialState = {
  canPublished: false, // takeout to owner-global

  published: null,

  previous: [], // предыдущие объявления (временное решение пока нет возможности сохранять несколько объявлений)
  saved: [],

  // Здесь хранятся изначальные данные r_read
  backendSource: null,

  restaurant: {
    id: null,
    name: null,
  },

  forms: {
    selectedForm: null,

    create: {
      opened: false,
      loading: false,
      selectedCategory: 'special',
      categories: initialCategories,
    },

    edit: {
      opened: false,
      loading: false,
      selectedCategory: null,
      publicDate: null,
      modifiedDate: null,
      categories: initialCategories,
    },

    delete: {
      opened: false,
      loading: false,
      selectedCategory: null,
    },
  },
}

export type TypeRestaurant = {
  id: number | null
  name: string | null
}

/**
 * @description OwnerPersonalPageReducer
 */
const OwnerPosts = createSlice({
  name: 'OwnerPosts',
  initialState,

  reducers: {
    setMainData(state, action: PayloadAction<boolean>) {
      state.canPublished = action.payload
    },

    setBackendSource(state, action: PayloadAction<object>) {
      state.backendSource = action.payload
    },

    setDescriptionData(state, action: PayloadAction<string>) {
      // state.description = action.payload
    },

    setPublishedPost(state, action: PayloadAction<TypeCommonPost>) {
      state.published = action.payload
    },

    setRestaurant(state, action: PayloadAction<TypeRestaurant>) {
      state.restaurant.id = action.payload.id
      state.restaurant.name = action.payload.name
    },

    setCategoryCreateForm: (state, action: PayloadAction<TypePostCategory>) => {
      state.forms.create.selectedCategory = action.payload
    },

    setFieldValue: (
      state,
      action: PayloadAction<{
        typeField: string
        value: string | Date
        id?: number // для массива полей menu -> dish
      }>,
    ) => {
      try {
        const { typeField, value, id } = action.payload

        // узнаем какая форма открыта сейчас (создание или редактирование)
        const typeForm = state.forms.selectedForm
        // узнаем какая категория сейчас активна (special, news, menu)
        // @ts-ignore
        const typeCategory = state.forms[typeForm].selectedCategory

        if (typeCategory === 'menu' && typeField !== 'date') {
          // @ts-ignore
          const findDishIndex = state.forms[typeForm].categories[typeCategory].fields.dishes.findIndex(
            (dish) => dish.id === id,
          )
          // @ts-ignore
          state.forms[typeForm].categories[typeCategory].fields.dishes[findDishIndex][typeField].value = value
        } else {
          // @ts-ignore
          state.forms[typeForm].categories[typeCategory].fields[typeField].value = value
        }
      } catch (e) {
        console.error('setFieldValue ошибка ввода данных поля в store', e)
      }
    },

    setFieldValid: (
      state,
      action: PayloadAction<{
        typeField: string
        id?: number // для массива полей menu -> dish
        valid: boolean
      }>,
    ) => {
      try {
        const { typeField, valid, id } = action.payload

        // узнаем какая форма открыта сейчас (создание или редактирование)
        const typeForm = state.forms.selectedForm
        // узнаем какая категория сейчас активна (special, news, menu)
        // @ts-ignore
        const typeCategory = state.forms[typeForm].selectedCategory

        if (typeCategory === 'menu' && typeField !== 'date') {
          // @ts-ignore
          const findDishIndex = state.forms[typeForm].categories[typeCategory].fields.dishes.findIndex(
            (dish) => dish.id === id,
          )
          // @ts-ignore
          state.forms[typeForm].categories[typeCategory].fields.dishes[findDishIndex][typeField].valid = valid
        } else {
          // @ts-ignore
          state.forms[typeForm].categories[typeCategory].fields[typeField].valid = valid
        }
      } catch (e) {
        console.error('setFieldValid ошибка записи данных валидации в store', e)
      }
    },

    addDishSection: (state) => {
      // узнаем какая форма открыта сейчас (создание или редактирование)
      const typeForm = state.forms.selectedForm
      // @ts-ignore
      const currentDishes = state.forms[typeForm].categories.menu.fields.dishes

      currentDishes.push({
        // @ts-ignore
        ...initialState.forms[typeForm].categories.menu.fields.dishes[0],
        id: Math.floor(Math.random() * 9999),
      })
    },

    removeDishSection: (state, action: PayloadAction<number>) => {
      // узнаем какая форма открыта сейчас (создание или редактирование)
      const typeForm = state.forms.selectedForm

      // @ts-ignore
      const findDishIndex = state.forms[typeForm].categories.menu.fields.dishes.findIndex(
        (dish) => dish.id === action.payload,
      )

      // @ts-ignore
      state.forms[typeForm].categories.menu.fields.dishes.splice(findDishIndex, 1)
    },

    openCreateForm: (state) => {
      state.forms.selectedForm = 'create'
      state.forms.create.opened = true
    },

    openEditSpecialForm: (state, action: PayloadAction<TypePayloadOpenEditSpecialForm>) => {
      const props = action.payload

      const form = state.forms.edit
      const fields = form.categories.special.fields

      state.forms.selectedForm = 'edit'

      form.modifiedDate = props.modifiedDate
      form.publicDate = props.publicDate
      form.opened = true
      form.selectedCategory = 'special'

      fields.date.value = props.publicDate ? null : props.modifiedDate
      fields.title.value = props.title
      fields.description.value = props.description
      fields.preview.value = props.preview
    },

    openEditNewsForm: (state, action: PayloadAction<TypePayloadOpenEditNewsForm>) => {
      const props = action.payload
      const form = state.forms.edit
      const fields = form.categories.news.fields

      state.forms.selectedForm = 'edit'

      form.modifiedDate = props.modifiedDate
      form.publicDate = props.publicDate
      form.opened = true
      form.selectedCategory = 'news'

      fields.date.value = props.publicDate ? null : props.modifiedDate
      fields.title.value = props.title
      // @ts-ignore
      fields.description.value = props.description || props.text
      fields.preview.value = props.preview
    },

    openEditMenuForm: (state, action: PayloadAction<TypePayloadOpenEditMenuForm>) => {
      const props = action.payload
      const form = state.forms.edit
      const fields = form.categories.menu.fields

      state.forms.selectedForm = 'edit'

      form.modifiedDate = props.modifiedDate
      form.publicDate = props.publicDate
      form.opened = true
      form.selectedCategory = 'menu'

      fields.date.value = props.publicDate ? null : props.modifiedDate

      fields.dishes = props.dishes.map((dish) => ({
        id: Math.floor(Math.random() * 9999),
        title: { value: dish.title, valid: false },
        price: { value: dish.price, valid: false },
        description: { value: dish.description, valid: false },
        preview: { value: dish.preview, valid: false },
      }))
    },

    setSavedPosts: (state, action: PayloadAction<TypeCommonPost[]>) => {
      state.saved = action.payload
    },

    setPreviousPosts: (state, action: PayloadAction<TypeCommonPost[]>) => {
      state.previous = action.payload
    },

    setLoadingForm: (state, action: PayloadAction<boolean>) => {
      try {
        const typeForm = state.forms.selectedForm
        state.forms[typeForm].loading = action.payload
      } catch (e) {
        console.error('setLoadingForm')
      }
    },

    closeForm: (state) => {
      state.forms = initialState.forms
    },
  },
})

export const {
  setMainData,
  setBackendSource,
  setDescriptionData,
  setPublishedPost,
  setRestaurant,
  setCategoryCreateForm,
  setFieldValue,
  setFieldValid,
  openCreateForm,
  openEditSpecialForm,
  openEditNewsForm,
  openEditMenuForm,
  closeForm,
  addDishSection,
  setPreviousPosts,
  removeDishSection,
  setLoadingForm,
} = OwnerPosts.actions

export default OwnerPosts.reducer
