import React, { FC } from 'react'
import s from './RestaurantNewDishes.module.sass'

import { TypeMappingRestDish } from '@shared/api/middleware/mappingAPI/restaurant/type'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { CardService } from '@shared/ui/Layout/CardService'
import { Title } from '@shared/ui/Typography/Title'
import { EGG } from '@shared/api/analytics'

interface Props {
  data: TypeMappingRestDish[]
  className?: string
  handleClick?: () => void
}

const RestaurantNewDishes: FC<Props> = ({ data, className = '', handleClick }) => {
  const handleClickCard = (index) => {
    EGG.pages.RestItem.new_menu_item_click({ section_index: index })
  }

  return (
    <ErrorBoundary>
      <div className={`${s.wrapper} ${className}`}>
        <Title tag={'h2'} sizes={'h2 h3 h3 h4'}>
          Новые блюда
        </Title>

        <div className={s.cards}>
          {data.map((el, index) => (
            <CardService
              key={el.id}
              title={el.title}
              subtitle={`${el.price} ₽`}
              description={el.description}
              img={el.img}
              className={s.card}
              handleClick={() => handleClickCard(index)}
            />
          ))}
        </div>
      </div>
    </ErrorBoundary>
  )
}

export default RestaurantNewDishes
