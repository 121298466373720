import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { StateNewsItem } from '@pages/NewsItem/model/types'
import { TypeMappingNewsOutput } from '@shared/api/middleware/mappingAPI/news/type'
import { TypeMappingRestOutput } from '@shared/api/middleware/mappingAPI/restaurant/type'

const initialState: StateNewsItem = {
  news: null,
  restaurants: null,
  others: null,
}

const newsItem = createSlice({
  name: 'newsItem',
  initialState,

  reducers: {
    setNews(state, action: PayloadAction<TypeMappingNewsOutput>) {
      state.news = action.payload
    },
    setRestaurantsList(state, action: PayloadAction<TypeMappingRestOutput[] | []>) {
      state.restaurants = action.payload?.length ? action.payload : null
    },
    setOthersNewsList(state, action: PayloadAction<TypeMappingNewsOutput[]>) {
      state.others = action.payload
    },
    resetState() {
      return initialState
    },
  },
})

export const getState = (state) => state
export const { setNews, setRestaurantsList, setOthersNewsList, resetState } = newsItem.actions
export default newsItem.reducer
