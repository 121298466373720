import React, { FC, memo, ReactNode, useState } from 'react'
// import s from './Carousel.module.sass'
import { CarouselContext } from './CarouselContext'
import CarouselSlider from '@shared/ui/Layout/Carousel/CarouselSlider'
import CarouselControls, { CarouselArrow } from '@shared/ui/Layout/Carousel/CarouselControls'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { PickAnalytic } from '@shared/api/analytics/types/types'

type Props = {
  analytic?: PickAnalytic<'section_name'>
  children: ReactNode
}

type CarouselExtensions = {
  Controls: typeof CarouselControls
  Slider: typeof CarouselSlider
  Arrow: typeof CarouselArrow
}

const Carousel: FC<Props> & CarouselExtensions = ({ analytic, children }) => {
  const [context, setContext] = useState({
    slides: null,
    options: null,
    sliderRef: null,
    instanceRef: null,
    slideMoveCount: null,
    details: {
      nextIndex: null,
      prevIndex: null,
      currentIndex: null,
      maxIndex: null,
      minIndex: null,
    },
    countSlidesOnContainer: null,
    analytic: analytic,
  })
  return (
    <ErrorBoundary>
      <CarouselContext.Provider value={[context, setContext]}>{children}</CarouselContext.Provider>
    </ErrorBoundary>
  )
}

export default Carousel

Carousel.Controls = CarouselControls
Carousel.Slider = CarouselSlider
Carousel.Arrow = CarouselArrow
