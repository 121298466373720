import React, { FC, useMemo } from 'react'
import s from './Rating.module.sass'
import { Text } from '@shared/ui/Typography/Text'
import { useCurrentSize } from '@shared/lib/helpers/classes'
import { ErrorBoundary } from '../../../lib/components/ErrorBoundary'

// type size = 'M' | 'S'

interface Props {
  rating?: number
  sizes?: string
  mode?: 'stroke' | 'fill'
  isPromo?: boolean
  className?: string
}

const Rating: FC<Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>> = ({
  rating,
  sizes = 'M S',
  mode = 'fill',
  className = '',
  isPromo = false,
  ...props
}) => {
  const [size] = useCurrentSize(sizes)
  const getColor = useMemo(() => {
    if (rating >= 6) return 'green'
    if ((rating < 6 && rating >= 4) || isPromo) return 'gray'
    else return 'gray'
  }, [rating, isPromo])

  return (
    <ErrorBoundary>
      <span
        className={`${s.rating} ${className}`}
        data-size={size}
        data-promo={isPromo}
        data-color={getColor}
        data-mode={mode}
        {...props}>
        {rating && (
          <Text sizes={sizes} numbers={true}>
            {rating.toFixed(1)}
          </Text>
        )}
        {isPromo && <Text sizes={sizes}>Промо</Text>}
      </span>
    </ErrorBoundary>
  )
}

export default Rating
