import DesktopImage from '@images/quick-filter/desktop/Image00.png'
import DesktopImage1 from '@images/quick-filter/desktop/Image01.png'
import DesktopImage2 from '@images/quick-filter/desktop/Image02.png'
import DesktopImage3 from '@images/quick-filter/desktop/Image03.png'
import DesktopImage4 from '@images/quick-filter/desktop/Image04.png'
import DesktopImage5 from '@images/quick-filter/desktop/Image05.png'
import DesktopImage6 from '@images/quick-filter/desktop/Image06.png'
import DesktopImage7 from '@images/quick-filter/desktop/Image07.png'
import DesktopImage8 from '@images/quick-filter/desktop/Image08.png'
import DesktopImage9 from '@images/quick-filter/desktop/Image09.png'
import DesktopImage10 from '@images/quick-filter/desktop/Image10.png'
import DesktopImage11 from '@images/quick-filter/desktop/Image11.png'
import DesktopImage12 from '@images/quick-filter/desktop/Image12.png'
import DesktopImage13 from '@images/quick-filter/desktop/Image13.png'
import DesktopImage14 from '@images/quick-filter/desktop/Image14.png'
import DesktopImage15 from '@images/quick-filter/desktop/Image15.png'
import DesktopImage16 from '@images/quick-filter/desktop/Image16.png'
import DesktopImage17 from '@images/quick-filter/desktop/Image17.png'
import DesktopImage18 from '@images/quick-filter/desktop/Image18.png'
import DesktopImage19 from '@images/quick-filter/desktop/Image19.png'
import DesktopImage20 from '@images/quick-filter/desktop/Image20.png'

import MobileImage from '@images/quick-filter/mobile/Image00.png'
import MobileImage1 from '@images/quick-filter/mobile/Image01.png'
import MobileImage2 from '@images/quick-filter/mobile/Image02.png'
import MobileImage3 from '@images/quick-filter/mobile/Image03.png'
import MobileImage4 from '@images/quick-filter/mobile/Image04.png'
import MobileImage5 from '@images/quick-filter/mobile/Image05.png'
import MobileImage6 from '@images/quick-filter/mobile/Image06.png'
import MobileImage7 from '@images/quick-filter/mobile/Image07.png'
import MobileImage8 from '@images/quick-filter/mobile/Image08.png'
import MobileImage9 from '@images/quick-filter/mobile/Image09.png'
import MobileImage10 from '@images/quick-filter/mobile/Image10.png'
import MobileImage11 from '@images/quick-filter/mobile/Image11.png'
import MobileImage12 from '@images/quick-filter/mobile/Image12.png'
import MobileImage13 from '@images/quick-filter/mobile/Image13.png'
import MobileImage14 from '@images/quick-filter/mobile/Image14.png'
import MobileImage15 from '@images/quick-filter/mobile/Image15.png'
import MobileImage16 from '@images/quick-filter/mobile/Image16.png'
import MobileImage17 from '@images/quick-filter/mobile/Image17.png'
import MobileImage18 from '@images/quick-filter/mobile/Image18.png'
import MobileImage19 from '@images/quick-filter/mobile/Image19.png'
import MobileImage20 from '@images/quick-filter/mobile/Image20.png'

export default {
  DesktopImage,
  DesktopImage1,
  DesktopImage2,
  DesktopImage3,
  DesktopImage4,
  DesktopImage5,
  DesktopImage6,
  DesktopImage7,
  DesktopImage8,
  DesktopImage9,
  DesktopImage10,
  DesktopImage11,
  DesktopImage12,
  DesktopImage13,
  DesktopImage14,
  DesktopImage15,
  DesktopImage16,
  DesktopImage17,
  DesktopImage18,
  DesktopImage19,
  DesktopImage20,

  MobileImage,
  MobileImage1,
  MobileImage2,
  MobileImage3,
  MobileImage4,
  MobileImage5,
  MobileImage6,
  MobileImage7,
  MobileImage8,
  MobileImage9,
  MobileImage10,
  MobileImage11,
  MobileImage12,
  MobileImage13,
  MobileImage14,
  MobileImage15,
  MobileImage16,
  MobileImage17,
  MobileImage18,
  MobileImage19,
  MobileImage20,
}
