import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import s from './MenuDesktop.module.sass'
import { useAppSelector } from '@app/model/store'
import { MenuLink } from '@widgets/Common/Header/ui/MenuLink'

function removeURLParams(url) {
  const output = url.split('?')[0]
  return output
}

// TODO: add jsdoc, ? takeout thisName & lowercase to upper-fn, ? takeout func
const addUTM = (thisLink, thisName = '') => {
  const UTM_STR = '?utm_source=restaurants&utm_medium=referral&utm_campaign=headline'
  const isRestsLink = thisName.toLowerCase() === 'рестораны'

  let filteredLink
  filteredLink = thisLink.at(-1) === '/' ? thisLink : thisLink + '/'
  filteredLink = filteredLink.indexOf('?') >= 0 ? removeURLParams(filteredLink) : filteredLink

  return isRestsLink ? filteredLink : filteredLink + UTM_STR
}

const MenuDesktop: FC = () => {
  const stateGlobal = useAppSelector((state) => state.global)

  const getFilteredTopline = () => {
    return stateGlobal.topline?.filter((item) => item.MainLink.Name !== 'ВСЕ') || []
  }

  const [menuItems, setMenuItems] = useState(getFilteredTopline())

  const ref = useRef<HTMLDivElement>(null)
  const [isMenuOpened, setIsMenuOpened] = useState(false)
  const [visibleIndexes, setVisibleIndexes] = useState<number[]>([])

  useEffect(() => {
    setMenuItems(getFilteredTopline())
  }, [stateGlobal.topline])

  const recalculateOffscreenItems = useCallback(() => {
    const menu = ref.current

    if (menu) {
      const visibleItems: typeof visibleIndexes = []

      let firstOffset: number | null = null

      for (const child of menu.children) {
        const rect = child.getBoundingClientRect()

        if (firstOffset === null) {
          firstOffset = rect.top
        }

        if (rect.top === firstOffset) {
          const rawIndex = (child as HTMLElement).dataset.index

          if (rawIndex !== undefined) {
            const index = parseInt(rawIndex)

            if (!Number.isNaN(index)) {
              visibleItems.push(index)
            }
          }
        } else {
          break
        }
      }

      if (visibleItems.length === menuItems.length) {
        setIsMenuOpened(false)
      }

      setVisibleIndexes(visibleItems)
    }
  }, [menuItems])

  useEffect(() => {
    recalculateOffscreenItems()
    window.addEventListener('resize', recalculateOffscreenItems)
    return () => window.removeEventListener('resize', recalculateOffscreenItems)
  }, [menuItems])

  return (
    <div className={s['nav-wrapper']}>
      <nav
        className={s.nav}
        data-opened={isMenuOpened}
        role='navigation'
        aria-label='Разделы сайта'
        onMouseLeave={() => setIsMenuOpened(false)}>
        <div>
          <div ref={ref} className={s['nav-top']}>
            {menuItems?.map((item, i) => (
              <MenuLink
                key={item.MainLink.Name}
                dataId={item.MainLink.Name}
                link={addUTM(item.MainLink.Url, item.MainLink.Name)}
                text={item.MainLink.Name}
                selected={item.MainLink.Name.toLowerCase() === 'рестораны'}
                role='menuitem'
                data-index={i}
                tabIndex={visibleIndexes.includes(i) ? 0 : -1}
                className={s['nav-item']}
              />
            ))}
          </div>

          {isMenuOpened && (
            <div className={s['nav-bottom']}>
              {menuItems?.map((item, i) =>
                visibleIndexes.includes(i) ? null : (
                  <MenuLink
                    key={item.MainLink.Name}
                    dataId={item.MainLink.Name}
                    link={addUTM(item.MainLink.Url, item.MainLink.Name)}
                    text={item.MainLink.Name}
                    selected={item.MainLink.Name.toLowerCase() === 'рестораны'}
                    role='menuitem'
                  />
                ),
              )}
            </div>
          )}
        </div>

        <MenuLink link={addUTM('https://daily.afisha.ru/')} daily dataId={'daily'} />

        {visibleIndexes.length !== menuItems.length && (
          <MenuLink dots onMouseEnter={() => setIsMenuOpened(true)} dataId={'dots'} selected={false} />
        )}
      </nav>
    </div>
  )
}

export default MenuDesktop
