import React, { FC, memo } from 'react'
import s from './Number.module.sass'
import { TextTag } from './types'
import { useCurrentSize } from '@shared/lib/helpers/classes'
import { ErrorBoundary } from '../../../lib/components/ErrorBoundary'

interface Props {
  className?: string
  children: React.ReactNode
  /** Размеры для разрешений large-desktop, desktop, tablet, mobile в формате строки -
   * 'N2'(для всех разрешений N2), 'N1 N2'(для десктопа и мобилки), 'N1 N2 N4'(для десктопа, планшета и мобилки)*/
  sizes: string
  uppercase?: boolean
  tag?: TextTag
}
const Text: FC<Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>> = ({
  className = '',
  tag = 'p',
  children,
  sizes,
  uppercase = false,
  ...props
}) => {
  const [size] = useCurrentSize(sizes)
  const TagText = tag

  return (
    <ErrorBoundary>
      {/*// @ts-ignore*/}
      <TagText className={`${s.number} ${className}`} data-size={size} {...props}>
        {children}
      </TagText>
    </ErrorBoundary>
  )
}

export default memo(Text)
