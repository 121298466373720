import React, { FC, ReactNode } from 'react'
import s from './PromoBadgeCTA.module.sass'
import { useCurrentSize } from '@shared/lib/helpers/classes'

// Бэйдж скидки рядом с CTA-кнопкой  XL-56px

interface Props {
  children: ReactNode
  className?: string
  sizes?: string | null // XL, L
}

const PromoBadgeCTA: FC<Props> = ({ sizes = 'XL', children, className = '' }) => {
  const [size] = useCurrentSize(sizes)

  return (
    <div className={`${s.wrapper} ${className}`} data-size={size}>
      <span className={`${s.text} promo-badge-cta-text`}>{children}</span>
    </div>
  )
}

export default PromoBadgeCTA
