import React, { FC, useEffect, useState } from 'react'
import s from './FilterListTemplate.module.sass'
import { BtnFilterDropdown } from '@shared/ui/Form/Single/Filters/Dropdown'
import { Search } from '@shared/ui/Form/Build/Search'
import { getDefaultField } from '@shared/lib/fields/getDefaultField'
import FilterButtons from '../FilterButtons/FilterButtons'
import { useAppDispatch, useAppSelector } from '@app/model/store'
import { Checkbox } from '@shared/ui/Form/Single/Checkbox'
import { CheckboxOption } from '@shared/types/fields/fields'
import { TypeFiltersByKeyList, TypeTag } from '@features/Restaurant/RestaurantFilters/model/types'
import { Text } from '@shared/ui/Typography/Text'
import {
  closeModalFilterList,
  resetFilterAsync,
  setCheckedFilterItemAsync,
} from '@features/Restaurant/RestaurantFilters/model/createAsyncThunk'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { EGG } from '@shared/api/analytics'
import { getFilterByType, getFilterSelected } from '@features/Restaurant/RestaurantFilters/helpers/helper'

interface Props {
  typeFilter: TypeFiltersByKeyList
  handleSave: () => void
  handleRemove: () => void
}

const title = {
  establishment: 'Тип заведения',
  metro: 'Метро',
  kitchen: 'Кухня',
}

const getFilterList = (value, list) => {
  return !value.trim()
    ? []
    : list.filter((tag: TypeTag) => {
        const cityNameLowerCase = tag.name.toLowerCase()
        const valueLowerCase = value.toLowerCase()
        return cityNameLowerCase.includes(valueLowerCase)
      })
}

const FilterListTemplate: FC<Props> = ({ typeFilter, handleSave, handleRemove }) => {
  const dispatch = useAppDispatch()
  const stateFilters = useAppSelector((store) => store.features.restaurant_filters)
  const { list, preCheckedList, checkedList } = stateFilters.filters[typeFilter]
  const [tagSearchList, setTagSearchList] = useState([])
  const [tagPreCheckedList, setTagPreCheckedList] = useState([])
  const [isOpenedPopup, setIsOpenedPopup] = useState(false)
  const { isMobile } = useClientResponsive()
  const [searchField, setSearchField] = useState(
    getDefaultField('search', {
      settings: {
        placeholder: '',
      },
    }),
  )

  const useList = searchField.value ? tagSearchList : list
  const usePrecheckedList = searchField.value ? tagPreCheckedList : preCheckedList

  useEffect(() => {
    handleSearchTag(searchField.value)
  }, [stateFilters.filters[typeFilter]])

  const handleSearchTag = (value) => {
    setTagSearchList(() => getFilterList(value, list))
    setTagPreCheckedList(() => getFilterList(value, preCheckedList))
  }

  const changeChecked = (option: CheckboxOption, checked) => {
    dispatch(
      setCheckedFilterItemAsync({
        filterType: typeFilter,
        itemId: Number(option.id),
        checked: checked,
      }),
    )
  }

  const handleRemoveInner = async () => {
    setIsOpenedPopup(false)
    setSearchField((prev) => ({ ...prev, value: '' }))
    await dispatch(resetFilterAsync(typeFilter))
    handleRemove()
  }

  const handleSaveInner = () => {
    EGG.common.filter_use_click({
      filter_apply_type: typeFilter,
      filters_apply: getFilterSelected(),
      filter_apply_value: getFilterByType(typeFilter),
    })
    setIsOpenedPopup(false)
    setSearchField((prev) => ({ ...prev, value: '' }))
    handleSave()
  }

  const handleOpenPopup = () => {
    setIsOpenedPopup(true)
  }
  const handleClosePopup = async () => {
    // @ts-ignore
    dispatch(closeModalFilterList())
    setIsOpenedPopup(false)
    setSearchField((prev) => ({ ...prev, value: '' }))
  }

  return (
    <BtnFilterDropdown openedPopup={isOpenedPopup}>
      {/*@ts-ignore*/}
      <BtnFilterDropdown.Button
        handleOpen={handleOpenPopup}
        handleClose={handleClosePopup}
        count={checkedList?.length || 0}
        handleRemove={handleRemoveInner}>
        {title[typeFilter]}
        {/*@ts-ignore*/}
      </BtnFilterDropdown.Button>
      {/*@ts-ignore*/}
      <BtnFilterDropdown.Popup
        title={title[typeFilter]}
        width={'420px'}
        className={s.popup}
        handleClose={handleClosePopup}>
        <Search field={searchField} setField={setSearchField} handleChange={handleSearchTag} />
        <div className={s['wrapper-lists']}>
          {!!usePrecheckedList.length && (
            <>
              <div className={s['list-checked-wrapper']}>
                <div className={`${s['list-checked-shadow']} ${s['list-checked-shadow--top']}`}></div>
                <div className={s['list-checked']}>
                  {usePrecheckedList.map((tag) => (
                    <Checkbox
                      key={`checked-${tag.id}`}
                      option={{
                        id: tag.id,
                        name: tag.slug,
                        label: tag.name,
                        disabled: tag.disabled,
                      }}
                      checked={tag.checked}
                      onChange={changeChecked}
                    />
                  ))}
                </div>
                <div className={`${s['list-checked-shadow']} ${s['list-checked-shadow--bottom']}`}></div>
              </div>

              <div className={s.divider}></div>
            </>
          )}

          <div className={s['list-wrapper']}>
            <div className={`${s['list-shadow']} ${s['list-shadow--top']}`}></div>
            <div className={s.list}>
              {useList.length ? (
                useList.map((tag) => {
                  const option = {
                    id: tag.id,
                    name: tag.slug,
                    label: tag.name,
                    disabled: tag.disabled,
                  }
                  return isMobile ? (
                    <Text
                      key={`default-${tag.id}`}
                      sizes={'M'}
                      className={s['tag-mobile']}
                      onClick={() => changeChecked(option, !tag.checked)}>
                      {tag.name}
                    </Text>
                  ) : (
                    <Checkbox
                      key={`default-${tag.id}`}
                      option={option}
                      checked={tag.checked}
                      onChange={changeChecked}
                    />
                  )
                })
              ) : (
                <Text sizes={'M'}>Ничего не найдено</Text>
              )}
            </div>
            <div className={`${s['list-shadow']} ${s['list-shadow--bottom']}`}></div>
          </div>
        </div>
        {/*@ts-ignore*/}
        <FilterButtons handleRemove={handleRemoveInner} handleSave={handleSaveInner} />
        {/*@ts-ignore*/}
      </BtnFilterDropdown.Popup>
    </BtnFilterDropdown>
  )
}

export default FilterListTemplate
