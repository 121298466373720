import React, { FC, memo, useCallback } from 'react'

import s from './BuildRadio.module.sass'
import { InputTitle } from '@shared/ui/Form/Single/InputTitle'
import { InputHint } from '@shared/ui/Form/Single/InputHint'
import { useFieldHint } from '@shared/lib/fields/hooks/useFieldHint/useFieldHint'
import { Radio } from '@shared/ui/Form/Single/Radio'
import { FieldRadio, RadioOption } from '@shared/types/fields/fields'
import { ErrorBoundary } from '../../../../lib/components/ErrorBoundary'

interface Props {
  field: FieldRadio
  setField: React.Dispatch<React.SetStateAction<FieldRadio>>
  // eslint-disable-next-line
  handleChange: (value: RadioOption) => void
  className?: string
  mode?: 'checkbox' | 'button'
}

const BuildRadio: FC<Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = ({
  field,
  setField,
  handleChange,
  className = '',
  mode = 'checkbox',
  ...props
}) => {
  const [hint] = useFieldHint(field)

  const handleClick = useCallback(
    (option: RadioOption) => {
      setField((prev) => ({
        ...prev,
        error: '',
        value: option.id,
      }))
      handleChange && handleChange(option)
    },
    [handleChange, setField],
  )

  return (
    <ErrorBoundary>
      {field.visible && (
        <div className={className} {...props}>
          <InputTitle
            nameField={field.name}
            title={field.settings.label}
            showRequired={field.required && field.settings.showRequired}
          />

          <div className={s.buttons}>
            {field.settings.options.map((option) => (
              <Radio
                key={option.id}
                option={option}
                handleClick={handleClick}
                checked={field.value === option.id}
                mode={mode}
              />
            ))}
          </div>

          <InputHint text={hint.text} status={hint.status} />
        </div>
      )}
    </ErrorBoundary>
  )
}

export default memo(BuildRadio)
