import React, { FC } from 'react'
import l10n from '../../../lib/booking-localization'
import { ErrorBoundary } from '../../../../../../6_shared/lib/components/ErrorBoundary'

interface Props {
  date: Date
  // eslint-disable-next-line no-unused-vars
  changeMonth: (value: number) => void
  decreaseMonth: () => void
  increaseMonth: () => void
  prevMonthButtonDisabled: boolean
  nextMonthButtonDisabled: boolean
}

const BookingCalendarHeader: FC<Props> = ({
  date,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => {
  return (
    <ErrorBoundary>
      <div className='react-datepicker__header-container'>
        <button
          type='button'
          onClick={() => changeMonth(new Date().getMonth())}
          className='react-datepicker__header-month-title'>
          {l10n('months_arr_normal')[date.getMonth()]}
        </button>

        <div className='react-datepicker__header-month-change-wrap'>
          <button
            className='react-datepicker__header-month-change react-datepicker__header-month-change--prev'
            onClick={decreaseMonth}
            disabled={prevMonthButtonDisabled}>
            <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <circle cx='16' cy='16' r='16' transform='rotate(-180 16 16)' fill='white' />
              <path
                d='M20.0588 8L11 16.4118L20.0588 24.8235'
                stroke='black'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </button>

          <button
            className='react-datepicker__header-month-change react-datepicker__header-month-change--next'
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}>
            <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <circle cx='16' cy='16' r='16' transform='rotate(-180 16 16)' fill='white' />
              <path
                d='M10.9412 25L20 16.5882L10.9412 8.17647'
                stroke='black'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </button>
        </div>
      </div>
    </ErrorBoundary>
  )
}

export default BookingCalendarHeader
