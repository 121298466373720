import React from 'react'

const IconBookingIsNotEnabled = () => {
  return (
    <>
      <svg xmlns='http://www.w3.org/2000/svg' width='198' height='276' fill='none' viewBox='0 0 198 276'>
        <g clipPath='url(#clip0_128_2373)'>
          <ellipse cx='63' cy='176.4' fill='url(#paint0_radial_128_2373)' rx='35.5' ry='28'></ellipse>
          <path
            fill='url(#paint1_linear_128_2373)'
            d='M109.5 66.4c-13.6-2.8-29 .167-35 2-1.5-5-4.2-16.9-3-24.5 1.5-9.5 3.5-12.5 7-19.5s12-14 23-20c8.8-4.8 22-4 27.5-3l6 1 12 5.5 5.5 4.5 9 9.5 6 11.5 2.5 12.5-.5 13-4 12.5-7 11-5.5 5.5c-2-2-7.4-6.8-13-10-7-4-13.5-8-30.5-11.5z'></path>
          <path
            fill='url(#paint2_linear_128_2373)'
            d='M86 251.9l.5-2.5-6.5-10.5v-1.5l22.5-9 8 15 1.5 4-1 3.5-26.5 23c-1 .667-3.7 1.6-6.5 0s-2.5-5.667-2-7.5l3-6 7-8.5z'></path>
          <path
            fill='url(#paint3_linear_128_2373)'
            d='M86 251.9l.5-2.5-6.5-10.5v-1.5l22.5-9 8 15 1.5 4-1 3.5-26.5 23c-1 .667-3.7 1.6-6.5 0s-2.5-5.667-2-7.5l3-6 7-8.5z'></path>
          <path
            fill='url(#paint4_linear_128_2373)'
            d='M47.038 239.032l1.574-2.006-1.065-12.303.676-1.339 24.14 2.116.375 16.996-.465 4.246-2.471 2.673-34.025 8.575c-1.194.144-4.024-.24-5.802-2.931-1.777-2.691.325-6.185 1.598-7.596l5.384-4.002 10.081-4.429z'></path>
          <ellipse cx='136.5' cy='183.4' fill='url(#paint5_radial_128_2373)' rx='22' ry='21'></ellipse>
          <path
            fill='url(#paint6_linear_128_2373)'
            d='M182 127.9c.4-18.4-13.167-40-20-48.5l-9.5 9-3 6.5-17.5 20 4 31.5 38 23 5-13c.833-1.833 2.6-10.1 3-28.5z'></path>
          <mask
            id='mask0_128_2373'
            style={{ maskType: 'alpha' }}
            width='84'
            height='110'
            x='98'
            y='78'
            maskUnits='userSpaceOnUse'>
            <path
              fill='#D9D9D9'
              d='M181.391 124.9c0-18.4-12.666-38.667-19-46.5l-46 3.5-17.5 37.5c-1 16-.7 48.5 8.5 50.5s31.167 12.833 41 18l17.5-5.5c2.5-4 7.9-13 9.5-17 2-5 6-17.5 6-40.5z'></path>
          </mask>
          <g filter='url(#filter0_f_128_2373)' mask='url(#mask0_128_2373)'>
            <circle cx='167.391' cy='130.4' r='52' fill='url(#paint7_radial_128_2373)'></circle>
          </g>
          <path
            fill='#000'
            d='M71.5 229.4l-23.5 1c-.333-2.167-1-7.7-1-12.5s2.667-11 4-13.5l-11-8.5c-3-3.333-10.103-10.206-11.5-13-.333 0 .4-.6 6-3s16-3.333 20.5-3.5c11.5-.667 36.5 1.5 54.5 7.5s32.5 14.167 37 19c-4.667 2.333-5.8 3.9-13 7.5s-29.667 8.333-35.5 8l5 11-23 9-7-16-1.5 7z'></path>
          <path
            fill='url(#paint8_linear_128_2373)'
            d='M152 204.9l-6-21.5 6-20.5v-24.5l16.5-3.5c2.833 8.333 8.2 26.3 7 31.5-1.2 5.2-6.833 12.833-9.5 16 .167 2.5.6 8.3 1 11.5.5 4 1.5 4.5 3 5.5s5 2 8.5 2.5 5.253 3 6 5.5c.598 2-.751 3.5-1.5 4-5.833.167-18.6.4-23 0-4.4-.4-7.167-4.5-8-6.5z'></path>
          <path
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.5'
            d='M14.825 117.28c5.97-29.97 27.81-54.21 56.38-63.62M51.295 204.46c-16.23-10.67-28.56-26.8-34.38-45.76M150.705 199.53c-14.5 11.74-32.97 18.78-53.09 18.78M161.375 78.52c12.88 14.82 20.68 34.18 20.68 55.35 0 18.48-5.93 35.57-16 49.47'></path>
          <path
            stroke='#000'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.5'
            d='M126.945 71.27c1.5.59 2.98 1.23 4.44 1.91'></path>
          <path
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.5'
            d='M126.945 71.27a84.263 84.263 0 00-31.01-5.88c-2.59 0-5.15.12-7.67.35M88.5 65.4a71.57 71.57 0 00-14 3M142.185 79.19a84.924 84.924 0 00-10.81-6.01M142.185 79.19c3.88 2.54 7.915 6.08 11.315 9.21'></path>
          <path
            stroke='#000'
            strokeLinecap='round'
            strokeMiterlimit='10'
            strokeWidth='1.5'
            d='M71.5 55.4a51.55 51.55 0 003 13'></path>
          <path
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.5'
            d='M82.545 18.8a49.607 49.607 0 00-6.85 11M71.505 55.16c-.44-4.37-.28-8.72.42-12.96M103.475 3.88c4.16-1.55 8.41-2.5 12.63-2.91M82.545 18.8a49.246 49.246 0 0120.44-14.74c.16-.06.33-.11.49-.18M116.105.97c4.37-.41 8.72-.24 12.96.48M75.685 29.8a49.54 49.54 0 00-3.77 12.4M165.595 71.17a49.495 49.495 0 01-12.48 16.54M74.595 68.11c-.01-.03-.03-.06-.04-.09-.03-.08-.06-.16-.09-.25M152.415 12.2a49.264 49.264 0 0114.54 20.27c.09.25.17.49.26.74M170.055 45.86c-.38-4.23-1.31-8.48-2.84-12.65M152.415 12.2c-3.35-2.78-7.04-5.1-10.97-6.91M129.065 1.45c4.29.73 8.45 2.03 12.38 3.84M165.595 71.17a49.571 49.571 0 004.46-25.31M150.195 29.49c-9.28-13.18-26.64-18.91-42.37-12.85-2.9 1.11-5.56 2.56-7.98 4.28M156.225 56.48c1.1-6.23.58-12.83-1.86-19.16-1.09-2.83-2.5-5.45-4.18-7.82'></path>
          <path
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.5'
            d='M99.845 20.92c-13.07 9.31-18.73 26.6-12.7 42.26.34.88.72 1.73 1.11 2.56M142.185 79.19c2.29-1.69 4.34-3.63 6.13-5.76M156.225 56.48a36.173 36.173 0 01-7.91 16.95M74.465 67.78h.01M85.275 55.16l-13.41 1.34M71.925 42.2h-.01M71.925 42.2l13.3 2.19M87.965 35.37l-12.28-5.57M82.545 18.8l-.01-.01M92.945 27.36l-10.4-8.56M92.015 9.95l7.83 10.97M99.845 20.92v.01M103.475 3.88l-.01-.01M108.175 16.5l-4.7-12.62M117.375 14.39L116.105.97M126.795 14.75l2.27-13.3M135.805 17.53l5.64-12.24M141.445 5.28v.01M143.785 22.56l8.63-10.36M150.195 29.49h-.01M150.195 29.49l11.01-7.77M154.565 37.85l12.65-4.64M167.225 33.21h-.01M156.625 47.05l13.43-1.19M169.505 58.81l-13.28-2.33M165.615 71.18l-.02-.01M165.595 71.17l-12.2-5.7M148.315 73.43l10.32 8.68'></path>
          <path
            fill='#000'
            d='M126.945 71.27l-15.22-37.73-.14.06-6.37 7.67-3.17-4.33 8.02-9.6 5.41-2.18 18.87 46.83-2.96 1.19'></path>
          <path
            stroke='#000'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.5'
            d='M126.945 71.27l-15.22-37.73-.14.06-6.37 7.67-3.17-4.33 8.02-9.6 5.41-2.18 18.87 46.83-2.96 1.19'></path>
          <path
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.6'
            d='M97.705 200.82c-4.47 1.93-8.96 3.88-13.02 6.57-4.06 2.69-7.71 6.17-9.88 10.52-2.33 4.67-2.82 10.04-2.78 15.26.03 3.74.31 7.48.3 11.21a5.744 5.744 0 01-4.26 5.52l-31.63 8.46c-4.21 1.13-8.27-2.71-7.06-6.89.06-.2.13-.41.21-.61 1.91-4.94 8.76-7.97 8.76-7.97l8.2-3.48s1.96-.72 1.67-2.45c-1.08-6.38-1.25-12.91-.77-19.36.17-2.31.44-4.63 1.09-6.86.56-1.92 1.41-3.74 2.35-5.5 4.6-8.57 11.71-15.77 20.22-20.48'></path>
          <path
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.5'
            d='M146.585 183.34l4.62 19.97c1.12 4.84 5.43 8.27 10.4 8.27h19.15c2.75 0 4.6-2.78 3.56-5.33-1.05-2.58-2.21-2.96-3.79-3.78-2.04-1.06-6.38-1.52-8.53-2.07-2.73-.7-4.52-3.47-4.89-6.27l-3.59-26.99'></path>
          <path
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.6'
            d='M97.625 218.3l8.46 16.94c1.67 3.34 3.58 6.58 5.22 9.93a5.73 5.73 0 01-1.39 6.84l-24.64 21.56c-3.28 2.87-8.62 1.22-9.38-3.07-.04-.21-.07-.42-.08-.64-.47-5.28 4.34-11.02 4.34-11.02l5.82-6.74s1.44-1.51.41-2.94c-3.79-5.25-6.82-11.03-9.24-17.03l-4.07-9.44'></path>
          <path
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.5'
            d='M129.165 211.58h68.58M-.995 211.58h47.51M15.015 116.37c-27.74 26.39-7.18 42.06 1.39 42.58 8.57.52 18.32-4.69 18.32-4.69l8.97-4.7c3-1.57 6.61-1.44 9.5.34.51.32 5.53 2.65 6.2 2.89 5.14 1.83 9.83 2.09 9.83 2.09s6.97-.32 8.05-4.27c1.08-3.94-2.99-5.55-5.96-6.51-3.78-1.23-5.6-1.63-8.78-3.04-3.17-1.41-6.34-2.65-10.39-5.89-4.04-3.23-5.39-13.28-5.39-13.28s-.92-3.07-2.83-2.08c-2.34 1.21-1.92 3.33-1.71 5.57.18 1.87-.57 10.58-.57 10.58s.34 1.45-2.06 2.64c-2.4 1.19-21.16 2.63-21.16 2.63'></path>
          <path
            stroke='#000'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit='10'
            strokeWidth='1.5'
            d='M68.555 100.47s-.1 3.87 5.07 3.69c5.75-.2 8.26-4.78 13.82-1.99 6.87 3.45 1.31 12.33 1.31 12.33M45.725 101.29s3.56-2.16 11.12.54'></path>
          <path
            fill='#000'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.5'
            d='M73.435 120.79c1.27.21 2.92-3.21 3.66-7.66.74-4.45.28-8.22-1-8.43-1.27-.21-2.92 3.21-3.66 7.66-.73 4.45-.3 8.21 1 8.43z'></path>
          <path
            stroke='#fff'
            strokeMiterlimit='10'
            strokeWidth='1.6'
            d='M97.705 200.82c-4.47 1.93-8.96 3.88-13.02 6.57-4.06 2.69-7.71 6.17-9.88 10.52C72.475 222.58 73 228.9 74 231.9m-28.555-1.5c-.445-2-.48-6.35 0-12.8.17-2.31.44-4.63 1.09-6.86.56-1.92 1.41-3.74 2.35-5.5 4.6-8.57 11.71-15.77 20.22-20.48'></path>
          <path
            fill='#000'
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.5'
            d='M50.985 117.25c1.27.21 2.92-3.21 3.66-7.66.74-4.45.28-8.22-1-8.43-1.27-.21-2.92 3.21-3.66 7.66-.74 4.45-.31 8.21 1 8.43z'></path>
          <path
            stroke='#000'
            strokeMiterlimit='10'
            strokeWidth='1.5'
            d='M48.215 128.79s3.95-5.66 11.34.57 22.53 11.64 24.96 7.53'></path>
          <path
            stroke='#000'
            strokeWidth='1.5'
            d='M25 176.4c.833-1.333 4.1-4.5 10.5-6.5 10.5-3.281 22.833-4.167 34-4.5 6.5 0 21 1 39.5 6 10.397 2.81 27.5 12 35 15.5l4 2.5M92.5 179.9l2.5-12.5M106.5 182.9l3.5-11.5'></path>
        </g>
        <defs>
          <filter
            id='filter0_f_128_2373'
            width='124'
            height='124'
            x='105.391'
            y='68.4'
            colorInterpolationFilters='sRGB'
            filterUnits='userSpaceOnUse'>
            <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
            <feBlend in='SourceGraphic' in2='BackgroundImageFix' result='shape'></feBlend>
            <feGaussianBlur result='effect1_foregroundBlur_128_2373' stdDeviation='5'></feGaussianBlur>
          </filter>
          <radialGradient
            id='paint0_radial_128_2373'
            cx='0'
            cy='0'
            r='1'
            gradientTransform='matrix(0 28 -35.5 0 63 176.4)'
            gradientUnits='userSpaceOnUse'>
            <stop offset='0.339' stopColor='#CBD2FE'></stop>
            <stop offset='1' stopColor='#FBFBFB' stopOpacity='0'></stop>
          </radialGradient>
          <linearGradient
            id='paint1_linear_128_2373'
            x1='156'
            x2='82.5'
            y1='68.9'
            y2='32.4'
            gradientUnits='userSpaceOnUse'>
            <stop stopColor='#A6A6A6'></stop>
            <stop offset='1' stopColor='#fff'></stop>
          </linearGradient>
          <linearGradient
            id='paint2_linear_128_2373'
            x1='81.5'
            x2='93.5'
            y1='266.9'
            y2='245.4'
            gradientUnits='userSpaceOnUse'>
            <stop stopColor='#FFB783'></stop>
            <stop offset='1' stopColor='#fff'></stop>
          </linearGradient>
          <linearGradient
            id='paint3_linear_128_2373'
            x1='81.5'
            x2='93.5'
            y1='266.9'
            y2='245.4'
            gradientUnits='userSpaceOnUse'>
            <stop stopColor='#FFB783'></stop>
            <stop offset='1' stopColor='#fff'></stop>
          </linearGradient>
          <linearGradient
            id='paint4_linear_128_2373'
            x1='36.256'
            x2='56.664'
            y1='250.39'
            y2='236.613'
            gradientUnits='userSpaceOnUse'>
            <stop stopColor='#FFB783'></stop>
            <stop offset='1' stopColor='#fff'></stop>
          </linearGradient>
          <radialGradient
            id='paint5_radial_128_2373'
            cx='0'
            cy='0'
            r='1'
            gradientTransform='matrix(0 21 -22 0 136.5 183.4)'
            gradientUnits='userSpaceOnUse'>
            <stop offset='0.224' stopColor='#F6DDD6'></stop>
            <stop offset='1' stopColor='#FFFDFC'></stop>
          </radialGradient>
          <linearGradient
            id='paint6_linear_128_2373'
            x1='191'
            x2='143'
            y1='124.4'
            y2='129.4'
            gradientUnits='userSpaceOnUse'>
            <stop stopColor='#CA9233'></stop>
            <stop offset='0.667' stopColor='#FFFCFC'></stop>
          </linearGradient>
          <radialGradient
            id='paint7_radial_128_2373'
            cx='0'
            cy='0'
            r='1'
            gradientTransform='matrix(0 52 -52 0 167.391 130.4)'
            gradientUnits='userSpaceOnUse'>
            <stop stopColor='#C58315'></stop>
            <stop offset='1' stopColor='#fff'></stop>
          </radialGradient>
          <linearGradient
            id='paint8_linear_128_2373'
            x1='189'
            x2='153'
            y1='214.9'
            y2='166.9'
            gradientUnits='userSpaceOnUse'>
            <stop offset='0.089' stopColor='#1512A8'></stop>
            <stop offset='0.285' stopColor='#B184A4'></stop>
            <stop offset='0.451' stopColor='#F0BCA7'></stop>
            <stop offset='0.932' stopColor='#fff' stopOpacity='0'></stop>
          </linearGradient>
          <clipPath id='clip0_128_2373'>
            <path fill='#fff' d='M0 0H197.49V275.8H0z' transform='translate(.255)'></path>
          </clipPath>
        </defs>
      </svg>
    </>
  )
}
export default IconBookingIsNotEnabled
