/**
 * AdvertTemplate - вставка баннера на страницу
 *    (AD_FOX advertising, рекламные баннеры)
 * @param type - используемый тип баннера, один из TypeAd
 * @param count - лучше передавать ID, I, INDEX
 *    для использования нескольких шаблонов с аттрибутами id={id} на одной странице
 * @param container
 * @param className
 */

import s from './AdvertTemplate.module.sass'
import React, { FC, memo, ReactComponentElement, useContext, useEffect, useState } from 'react'

import { useAppSelector } from '@app/model/store'

import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { useCurrentSize } from '@shared/lib/helpers/classes'
import { GlobalContext } from '../../../../../pages/context'
// import { useEffectOnce } from '@shared/lib/hooks/useEffectOnce'

const SHOW_ANY_ADV = process.env.SHOW_ANY_ADV

declare const window: Window &
  typeof globalThis & {
    Adf: any
    begun_new_callbacks: any
  }

export type TypeAd =
  | '100%x240'
  | '240x400'
  | '240x400_2nd'
  | 'Billboard'
  | 'Context_100%x70'
  | 'Content1'
  | 'Content2'
  | 'Content3'
  | 'Content4'
  | 'Footer'
  | 'Listing1'
  | 'Listing2'
  | 'Listing3'
  | 'Listing4'
  | 'TopBanner'
  | 'Center'
  | 'Parallax'

const PUID = {
  afisha_6: 'AFISHARU_MAIN',
  afisha_18: 'AFISHARU_MAIN_MAIN',
  rests_6: 'AFISHA_RESTAURANTS',
  rests_18: 'AFISHA_RESTAURANTS_MAIN',
}

const pad_1 = 432499134
const pad_2 = 545711485

const getCurrentCode = (typeCurrent, isMobile = null) => {
  const codes = {
    Billboard: [pad_1, 432499160], // desktop header
    TopBanner: [pad_2, 579500508], // mobile header
    '100%x240': [pad_1, 432499144],
    '240x400': [pad_1, 432499154],
    '240x400_2nd': [pad_1, 432499148],
    'Context_100%x70': [pad_1, 438040176],
    Context_full: [pad_1, 438040176],
    Content1: [pad_2, 545711495],
    Content2: [pad_2, 545711501],
    Content3: [pad_2, 545716147],
    Content4: [pad_2, 545722549],
    Footer: [pad_2, 545731421],
    Listing1: [pad_2, 545735133],
    Listing2: [pad_2, 545735171],
    Listing3: [pad_2, 545735399],
    Listing4: [pad_2, 545735901],
    Center: [pad_1, 579502505],
    Parallax: [pad_1, 579502503],
  }
  return codes[typeCurrent]
}

const styles = {
  '100%x240': { minWidth: '920px', maxWidth: '1232px', minHeight: '300px', maxHeight: '400px' },
  '240x400': { minWidth: '300px', maxWidth: '300px', minHeight: '600px', maxHeight: null },
  '240x400_2nd': { minWidth: '300px', maxWidth: '300px', minHeight: '600px', maxHeight: null },
  Billboard: { minWidth: '960px', maxWidth: '1600px', minHeight: '90px', maxHeight: '90px' },
  'Context_100%x70': { minWidth: '200px', maxWidth: '1232px', minHeight: '200px', maxHeight: '400px' },
  Context_full: { minWidth: '920px', maxWidth: '1232px', minHeight: '300px', maxHeight: null },
  Content1: { minWidth: '920px', maxWidth: '1232px', minHeight: '300px', maxHeight: null },
  Content2: { minWidth: '920px', maxWidth: '1232px', minHeight: '300px', maxHeight: null },
  Content3: { minWidth: '920px', maxWidth: '1232px', minHeight: '300px', maxHeight: null },
  Content4: { minWidth: '920px', maxWidth: '1232px', minHeight: '300px', maxHeight: null },
  Footer: { minWidth: '320px', maxWidth: '1600px', minHeight: '250px', maxHeight: null },
  Listing1: { minWidth: '320px', maxWidth: '1600px', minHeight: '250px', maxHeight: null },
  Listing2: { minWidth: '320px', maxWidth: '1600px', minHeight: '250px', maxHeight: null },
  Listing3: { minWidth: '320px', maxWidth: '1600px', minHeight: '250px', maxHeight: null },
  Listing4: { minWidth: '320px', maxWidth: '1600px', minHeight: '250px', maxHeight: null },
  TopBanner: { minWidth: '375px', maxWidth: '959px', minHeight: '90px', maxHeight: '90px' },
  Center: { minWidth: '200px', maxWidth: '200px', minHeight: '200px', maxHeight: '200px' },
  Parallax: { minWidth: '920px', maxWidth: '1232px', minHeight: '300px', maxHeight: null },
}

interface Props {
  type: string
  className?: string
  container?: ReactComponentElement<any>
  count?: any
}

const AdvertContainer = ({ children }) => {
  return <>{children}</>
}

const AdvertTemplate: FC<Props> = ({ type, count = null, container, className = '' }) => {
  const { isMobile } = useClientResponsive()
  const Container = container || AdvertContainer
  const globalData = useAppSelector((store) => store.global)
  const [globalContext, setGlobalContext] = useContext(GlobalContext)

  // * typeCurrent - определение типа
  const [typeCurrent] = useCurrentSize(type)

  const [visible, setVisible] = useState(false)

  const [begunAutoPad, begunBlockId] = getCurrentCode(typeCurrent, isMobile)

  // TODO: "|| 0" - изменить ноль на рандомный хеш, 4-5 символов
  const dynamicCount = parseInt(count || 0, 10)

  const nodeId = `block_pad_id_${begunAutoPad}_${begunBlockId}_${globalData.currentPage}__${dynamicCount}`

  const options_ssp = {
    'begun-auto-pad': begunAutoPad,
    'begun-block-id': begunBlockId,
  }

  const options_puid = {
    puid6: isMobile ? PUID.afisha_6 : PUID.rests_6,
    puid18: isMobile ? PUID.afisha_18 : PUID.rests_18,
  }

  // * isDev - отображает баннеры даже без контента, и не скрывает при неудачной загрузке adf
  const isDev = window.location.search.indexOf('is_dev=true') > 0

  useEffect(() => {
    // Если страница - скелетон, запрос на рекламу не делаем
    if (globalContext.isSkeletonPage) return

    // * nodeId - #id
    // * options_puid - AFISHARU_MAIN
    // * options_ssp - [432499134, 545711501]
    const initCallback = () => {
      window.Adf.banner.ssp(nodeId, options_puid, options_ssp).then((response) => {
        if (response.canHide) {
          if (!isDev) {
            setVisible(false)
          }
          console.warn(response)
        } else {
          setVisible(true)
        }
      })
    }

    // TODO: если не найдет ADf, добавить повторный запуск
    if (typeof window.Adf !== 'undefined') {
      initCallback()
    } else {
      window.begun_new_callbacks = window.begun_new_callbacks || []

      window.begun_new_callbacks.push({
        lib: {
          init: () => {
            initCallback()
          },
        },
      })
    }
  }, [globalContext.isSkeletonPage])

  const thisClassName = `${s.advert} ${className} ${!visible ? s['visually-hidden'] : ''} ${
    isDev ? s['advert--is-dev'] : ''
  }`

  return SHOW_ANY_ADV ? (
    // @ts-ignore
    <Container>
      <div className={s['advert-wrap']}>
        <div className={thisClassName} style={styles[typeCurrent]} id={nodeId} data-id={dynamicCount} />
        {isDev && <span className={s['advert-text']}>{typeCurrent}</span>}
      </div>
    </Container>
  ) : null
}

export default memo(AdvertTemplate)
