import { useEffect, useState } from 'react'
import { getErrorData } from '../../getErrorData'

interface FieldProps {
  value: string | object | null
  error: string
  validations: { [propName: string]: string }
  settings: {
    showError: boolean
    hint: string
  }
}

interface TypesUseFieldHint {
  text: string
  status: 'default' | 'error'
}

export const useFieldHint = (field: FieldProps) => {
  const [hint, setHint] = useState<TypesUseFieldHint>({
    text: field.settings.hint || '',
    status: 'default',
  })

  useEffect(() => {
    const isError = getErrorData(field).isError
    const textHintError = field.validations[field.error] || 'Неизвестная ошибка'
    const textHintDefault = field.settings.hint || ''

    setHint({
      text: isError ? textHintError : textHintDefault,
      status: isError ? 'error' : 'default',
    })
  }, [field.error, field.settings.showError, field.settings.hint, field.validations, field])

  useEffect(() => {
    setHint({
      text: field.settings.hint,
      status: 'default',
    })
  }, [field.settings.hint, field.value])

  return [hint, setHint] as const
}
