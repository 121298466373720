import React, { FC } from 'react'

import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { MediaCardCompilationSkeleton } from '@shared/ui/Layout/Cards/MediaCardCompilation'

interface Props {
  className?: string
  sizes: string
}

const Skeleton: FC<Props> = (props) => {
  return (
    <ErrorBoundary>
      <MediaCardCompilationSkeleton {...props} />
    </ErrorBoundary>
  )
}

export default Skeleton
