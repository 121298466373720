import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TypeLoading, TypeState } from '@pages/SelectionsGallery/model/types'
import { TypeMappingSelectionOutput } from '@shared/api/middleware/mappingAPI/selection/type'
import { TypeMappingPaginationOutput } from '@shared/api/middleware/mappingAPI/pagination/type'
import { TypeMappingRestOutput } from '@shared/api/middleware/mappingAPI/restaurant/type'

const initialState: TypeState = {
  loading: {
    page: true,
    items_full: false,
    items_addition: false,
  },
  pagination: null,
  list: null,
  sections: {
    recommendedRests: {
      loading: true,
      list: null,
    },
    special: null,
  },
}

const selectionsGallery = createSlice({
  name: 'selectionsGallery',
  initialState,

  reducers: {
    setLoading(state, action: PayloadAction<TypeLoading>) {
      state.loading = action.payload
    },

    setPagination(state, action: PayloadAction<TypeMappingPaginationOutput>) {
      state.pagination = action.payload
    },
    setPaginationItems(state, action: PayloadAction<number | null>) {
      state.pagination.total = action.payload
    },
    setPaginationPage(state, action: PayloadAction<TypeMappingPaginationOutput>) {
      state.pagination.page = action.payload.page
    },

    setList(state, action: PayloadAction<TypeMappingSelectionOutput[]>) {
      state.list = action.payload
    },

    setSpecialSection(state, action: PayloadAction<TypeMappingSelectionOutput>) {
      state.sections.special = action.payload
    },

    setRecommendedRests(state, action: PayloadAction<TypeMappingRestOutput[] | null>) {
      state.sections.recommendedRests.list = action.payload
      state.sections.recommendedRests.loading = false
    },

    setLoadingPage(state, action: PayloadAction<boolean>) {
      state.loading.page = action.payload
    },
    clearStateSelectionsGallery: () => initialState,
  },
})

export const getState = (state) => state

export const {
  setPagination,
  setPaginationItems,
  setPaginationPage,
  setList,
  setLoading,
  setSpecialSection,
  setLoadingPage,
  setRecommendedRests,
  clearStateSelectionsGallery,
} = selectionsGallery.actions

export default selectionsGallery.reducer
