import { undefinedObjectToNull } from '@shared/api/middleware/mappingAPI/helpers'
import { Tag } from '@shared/api/types/swaggerTypes'
import { TypeMappingTagOutput } from '@shared/api/middleware/mappingAPI/tag/type'

export const mappingTagItem = (object: Tag): TypeMappingTagOutput => {
  try {
    return undefinedObjectToNull({
      id: object.id,
      name: object.name,
      slug: object.slug ? object.slug.toLowerCase() : null,
      abs_url: object.abs_url ? object.abs_url.toLowerCase() : null,
    })
  } catch (error) {
    console.error(error)
    return null
  }
}
