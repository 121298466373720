import React, { FC } from 'react'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { MenuDesktopSkeleton } from '@widgets/Common/Header/ui/Menu/ui/MenuDesktop'
import { MenuMobileSkeleton } from '@widgets/Common/Header/ui/Menu/ui/MenuMobile'

const Menu: FC = () => {
  const { isMobile } = useClientResponsive()

  return <>{isMobile ? <MenuMobileSkeleton /> : <MenuDesktopSkeleton />}</>
}

export default Menu
