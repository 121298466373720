import React, { memo, FC } from 'react'
import s from './Title.module.sass'
import { useCurrentSize } from '@shared/lib/helpers/classes'
import { ErrorBoundary } from '../../../lib/components/ErrorBoundary'
import { TitleSkeleton } from '@shared/ui/Typography/Title/index'

type size = 'h1' | 'h2' | 'h3' | 'h4' | 'h5'

interface Props {
  className?: string
  tag?: size
  sizes?: string
  children: React.ReactNode
  isSuper?: boolean
  skeleton?: boolean
}

const TitleContainer: FC<
  Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>
> = ({ children, skeleton, ...props }) => {
  return (
    <ErrorBoundary>
      {skeleton ? (
        <TitleSkeleton sizes={props.sizes} className={props.className} />
      ) : (
        <Title {...props}>{children}</Title>
      )}
    </ErrorBoundary>
  )
}

const Title: FC<Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>> = ({
  tag = 'h3',
  sizes = 'h3',
  className = '',
  isSuper = false,
  children,
  ...props
}) => {
  const [size] = useCurrentSize(sizes)
  const TagTitle = tag

  return (
    <ErrorBoundary>
      <TagTitle className={`${s.header} ${className}`} data-size={size} data-super={isSuper} {...props}>
        {children}
      </TagTitle>
    </ErrorBoundary>
  )
}

export default memo(TitleContainer)
