import React, { FC } from 'react'
import s from './QuickBookingArticleB.module.sass'
import { useAppDispatch } from '@app/model/store'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { Button } from '@shared/ui/Actions/Button'
import Link from 'next/link'
import { TypeMappingRestOutput } from '@shared/api/middleware/mappingAPI/restaurant/type'
import { openBooking } from '@widgets/Restaurant/Booking'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { EGG } from '@shared/api/analytics'
import { useRouter } from 'next/router'

interface Props {
  restData: TypeMappingRestOutput
  className?: string
}

const QuickBookingArticleB: FC<Props> = ({ restData, className = '' }) => {
  const { isMobile } = useClientResponsive()
  const dispatch = useAppDispatch()

  const hasBookingAfisha = restData.booking.afisha.active || restData.booking.tomesto.active
  if (!hasBookingAfisha) return

  const handleBooking = () => {
    if (restData.booking.afisha.active) {
      dispatch(openBooking(restData))
    }

    EGG.entity.booking.buttons_quick_booking_booking_click(restData, { section_index: 0 })
  }
  const handleNavigate = () => {
    EGG.entity.booking.buttons_quick_booking_booking_click(restData, { section_index: 0 })
  }

  return (
    <ErrorBoundary>
      <div className={`${s.widget} ${className}`}>
        {restData.booking.tomesto.active ? (
          <Link href={restData.booking.tomesto.url} target={'_blank'} onClick={handleBooking}>
            <Button sizes={'XL L'} mode={'black'}>
              Забронировать
            </Button>
          </Link>
        ) : (
          <Button sizes={'XL L'} mode={'black'} onClick={handleBooking}>
            Забронировать
          </Button>
        )}

        <Link href={restData.url.path} target={'_blank'} className={s.link} onClick={handleNavigate}>
          <Button sizes={'XL L'} mode={'white-border'}>
            Подробнее {isMobile ? '' : 'о ресторане'}
          </Button>
        </Link>
      </div>
    </ErrorBoundary>
  )
}

export default QuickBookingArticleB
