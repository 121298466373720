import React, { FC } from 'react'
import s from './BookingAlerts.module.sass'
import { useAppSelector } from '@app/model/store'
import { Text } from '@shared/ui/Typography/Text'

const BookingAlerts: FC = () => {
  const storeBooking = useAppSelector((state) => state.booking)
  const { restaurantSource } = storeBooking

  return (
    !!restaurantSource.alerts.length && (
      <div className={s.wrapper}>
        {restaurantSource.alerts.map((alert) => (
          <div className={s.item} data-disabled={alert.disabled}>
            <div className={s.side} data-type={alert.type} style={{ backgroundColor: alert.color || '#E0EEFF' }}>
              <span>{alert.aside || '!'}</span>
            </div>
            <div className={s.main} style={{ backgroundColor: alert.color || '#E0EEFF' }}>
              <Text sizes={'M S'}>{alert.text}</Text>
            </div>
            <div></div>
          </div>
        ))}
      </div>
    )
  )
}

export default BookingAlerts
