import React, { cloneElement, FC, forwardRef, isValidElement, ReactNode } from 'react'
import s from './PopupClose.module.sass'
import { usePopupContext } from '@shared/ui/Overlays/Popup/hooks'
import IconClose from '@icons/icon--x-big.svg'
import { IconButton } from '@shared/ui/Actions/IconButton'

interface Props {
  children?: ReactNode
  asChild?: boolean
}

export const PopupClose = forwardRef<HTMLButtonElement, React.ButtonHTMLAttributes<HTMLButtonElement> & Props>(
  ({ children, asChild = false, ...props }, ref) => {
    const context = usePopupContext()

    const handleClick = (e) => {
      props.onClick?.(e)
      context.setOpen(false)
    }

    // `asChild` allows the user to pass any element as the anchor
    if (asChild && isValidElement(children)) {
      return cloneElement(
        children,
        context.getReferenceProps({
          ref,
          ...props,
          ...children.props,
          onClick: handleClick,
        }),
      )
    }

    return (
      <IconButton
        ref={ref}
        icon={IconClose}
        mode={'white'}
        sizes={'M'}
        className={`${s.close} popup-button-close`}
        onClick={handleClick}
        {...props}
      />
    )
  },
)

export default PopupClose
