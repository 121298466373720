/**
 * @swagger http://rests.afisha.ru/rests/api/owner/v1/swagger/
 */

import { apiOwnerMock } from './apiOwnerMock'

import * as Types from '@shared/api/types/apiOwnerParameters'
import { apiRequest } from '@shared/api/apiRequest'
import { mappingAPIowner } from '@shared/api/middleware/mappingAPI'
import { middlewareError, textErrorsApiOwner } from '@shared/api/middleware/middlewareError'
import { globalStore } from '@app/model/store'

const BASE_DOMAIN = process.env.IS_STAGE === 'true' ? 'http://artemis.dev.rests.afisha.ru' : 'https://www.afisha.ru'
const BASE_URL = `${BASE_DOMAIN}/rests/api/owner/v1`
const GITLAB_STATUS = process.env.GITLAB_STATUS
const IS_PROD = GITLAB_STATUS === '0'

export const API_OWNER = {
  // API FILES

  api_files_list: (props: Types.PropsApiFilesList) => {
    const showErrorUser = props?.error?.showUser || false
    const textError = props?.error?.text || textErrorsApiOwner['api_files_list']
    return mappingAPIowner
      .api_files_list(apiRequest.get({ url: `${BASE_URL}/api_files/`, ...props }))
      .catch(() => middlewareError(showErrorUser, textError, false))
  },

  api_files_create: async (props: Types.PropsApiFilesCreate) => {
    // Бекенд ждет файл в формате formData
    // Доп функции ниже также нужны для случая если в props.body.file будет передан url blob вида: blob:https://localhost:80/50992bdc-9385-4664-b63e-99525d20aee2

    const blob = await fetch(props.body.file).then((r) => r.blob())
    const formData = new FormData()
    formData.append('file', blob, 'Cropper.jpg')

    const modifiedProps = { ...props, body: formData }

    const showErrorUser = modifiedProps?.error?.showUser || false
    const textError = modifiedProps?.error?.text || textErrorsApiOwner['api_files_create']
    return mappingAPIowner
      .api_files_create(apiRequest.post({ url: `${BASE_URL}/api_files/`, ...modifiedProps }))
      .catch(() => middlewareError(showErrorUser, textError, true))
  },

  api_files_read: (props: Types.PropsApiFilesRead) => {
    const showErrorUser = props?.error?.showUser || false
    const textError = props?.error?.text || textErrorsApiOwner['api_files_read']
    return mappingAPIowner
      .api_files_read(apiRequest.get({ url: `${BASE_URL}/api_files/${props.id}/`, ...props }))
      .catch(() => middlewareError(showErrorUser, textError, false))
  },

  api_files_delete: (props: Types.PropsApiFilesDelete) => {
    const showErrorUser = props?.error?.showUser || false
    const textError = props?.error?.text || textErrorsApiOwner['api_files_delete']
    return mappingAPIowner
      .api_files_delete(apiRequest.delete({ url: `${BASE_URL}/api_files/${props.id}/`, ...props }))
      .catch(() => middlewareError(showErrorUser, textError, false))
  },

  // R

  r_read: (props: Types.PropsRRead) => {
    const showErrorUser = props?.error?.showUser || false
    const textError = props?.error?.text || textErrorsApiOwner['r_read']
    const thisMock = apiOwnerMock['r_read']
    return mappingAPIowner
      .r_read(
        IS_PROD
          ? apiRequest.get({ url: `${BASE_URL}/r/${props.rid}/`, ...props })
          : new Promise((resolve, reject) => {
              resolve(thisMock)
            }),
      )
      .catch(() => middlewareError(showErrorUser, textError, false))
  },

  r_info: (props: Types.PropsRInfo) => {
    const showErrorUser = props?.error?.showUser || false
    const textError = props?.error?.text || textErrorsApiOwner['r_info']
    const thisMock = apiOwnerMock['r_info']
    return mappingAPIowner
      .r_info(
        IS_PROD
          ? apiRequest.patch({ url: `${BASE_URL}/r/${props.rid}/info/?restaurant=${props.restaurant}`, ...props })
          : new Promise((resolve, reject) => {
              resolve(thisMock)
            }),
      )
      .catch(() => middlewareError(showErrorUser, textError, false))
  },

  r_menu_partial_update: (props: Types.PropsRMenuPartialUpdate) => {
    const showErrorUser = props?.error?.showUser || false
    const textError = props?.error?.text || textErrorsApiOwner['r_menu_partial_update']
    return mappingAPIowner
      .r_menu_partial_update(
        apiRequest.patch({
          url: `${BASE_URL}/r/${props.rid}/menu/${props.id}/`,
          ...props,
        }),
      )
      .catch(() => middlewareError(showErrorUser, textError, true))
  },

  r_menu_item_create: (props: Types.PropsRMenuItemCreate) => {
    const showErrorUser = props?.error?.showUser || false
    const textError = props?.error?.text || textErrorsApiOwner['r_menu_item_create']
    return mappingAPIowner
      .r_menu_item_create(
        apiRequest.post({
          url: `${BASE_URL}/r/${props.rid}/menu/${props.menu_offer_id}/item/`,
          ...props,
        }),
      )
      .catch(() => middlewareError(showErrorUser, textError, true))
  },

  r_menu_item_partial_update: (props: Types.PropsRMenuItemPartialUpdate) => {
    const showErrorUser = props?.error?.showUser || false
    const textError = props?.error?.text || textErrorsApiOwner['r_menu_item_partial_update']
    return mappingAPIowner
      .r_menu_item_partial_update(
        apiRequest.patch({
          url: `${BASE_URL}/r/${props.rid}/menu/${props.menu_offer_id}/item/${props.id}/?restaurant=${props.restaurant}`,
          ...props,
        }),
      )
      .catch(() => middlewareError(showErrorUser, textError, true))
  },

  r_menu_item_delete: (props: Types.PropsRMenuItemDelete) => {
    const showErrorUser = props?.error?.showUser || false
    const textError = props?.error?.text || textErrorsApiOwner['r_menu_item_delete']
    return mappingAPIowner
      .r_menu_item_delete(
        apiRequest.delete({
          url: `${BASE_URL}/r/${props.rid}/menu/${props.menu_offer_id}/item/${props.id}/`,
          ...props,
        }),
      )
      .catch(() => middlewareError(showErrorUser, textError, true))
  },

  r_news_partial_update: (props: Types.PropsRNewsPartialUpdate) => {
    const showErrorUser = props?.error?.showUser || false
    const textError = props?.error?.text || textErrorsApiOwner['r_news_partial_update']
    return mappingAPIowner
      .r_news_partial_update(
        apiRequest.patch({
          url: `${BASE_URL}/r/${props.rid}/news/${props.id}/`,
          ...props,
        }),
      )
      .catch(() => middlewareError(showErrorUser, textError, true))
  },

  r_special_partial_update: (props: Types.PropsRSpecialPartialUpdate) => {
    const showErrorUser = props?.error?.showUser || false
    const textError = props?.error?.text || textErrorsApiOwner['r_special_partial_update']
    return mappingAPIowner
      .r_special_partial_update(
        apiRequest.patch({
          url: `${BASE_URL}/r/${props.rid}/special/${props.id}/?restaurant=${props.restaurant}`,
          ...props,
        }),
      )
      .catch(() => middlewareError(showErrorUser, textError, true))
  },

  // OWNER REVIEWS

  owner_reviews_list: (props: Types.PropsOwnerReviewsList) => {
    const showErrorUser = props?.error?.showUser || false
    const textError = props?.error?.text || textErrorsApiOwner['owner_reviews_list']
    const thisMock = apiOwnerMock['owner_reviews_list'](props)

    return mappingAPIowner
      .owner_reviews_list(
        IS_PROD
          ? apiRequest.get({ url: `${BASE_URL}/reviews/`, ...props })
          : new Promise((resolve, reject) => {
              resolve(thisMock)
            }),
      )
      .catch(() => middlewareError(showErrorUser, textError, false))
  },

  owner_reviews_read: (props: Types.PropsOwnerReviewsRead) => {
    const showErrorUser = props?.error?.showUser || false
    const textError = props?.error?.text || textErrorsApiOwner['owner_reviews_read']
    return mappingAPIowner
      .owner_reviews_read(apiRequest.get({ url: `${BASE_URL}/reviews/${props.id}/`, ...props }))
      .catch(() => middlewareError(showErrorUser, textError, false))
  },

  owner_reviews_partial_update: (props: Types.PropsOwnerReviewsPartialUpdate) => {
    const showErrorUser = props?.error?.showUser || false
    const textError = props?.error?.text || textErrorsApiOwner['owner_reviews_partial_update']
    const thisMock = apiOwnerMock['owner_reviews_partial_update']
    return mappingAPIowner
      .owner_reviews_partial_update(
        IS_PROD
          ? apiRequest.patch({ url: `${BASE_URL}/reviews/${props.id}/?restaurant=${props.restaurant}`, ...props })
          : new Promise((resolve, reject) => resolve(thisMock)),
      )
      .catch(() => {
        middlewareError(showErrorUser, textError, true)
      })
  },
}
