import React, { FC } from 'react'
import s from './MenuLink.module.sass'

import ContentLoader from 'react-content-loader'
import { SKELETON_FOREGROUND_COLOR, SKELETON_MAIN_COLOR, SKELETON_SPEED } from '@shared/consts/skeleton'

interface Props {
  type: 'link' | 'button' | 'dots'
}

const MenuLinkSkeleton: FC<Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = ({
  type,
}) => {
  const isLink = type === 'link'
  const isDots = type === 'dots'
  const isButton = type === 'button'

  return (
    <>
      {isLink && (
        <ContentLoader
          speed={SKELETON_SPEED}
          backgroundColor={SKELETON_MAIN_COLOR}
          foregroundColor={SKELETON_FOREGROUND_COLOR}
          width={'100'}
          height={'48'}
          className={s.skeleton}>
          <rect x='0' y='0' rx='24' ry='24' width='100' height='48' />
        </ContentLoader>
      )}

      {isDots && (
        <ContentLoader
          speed={SKELETON_SPEED}
          backgroundColor={SKELETON_MAIN_COLOR}
          foregroundColor={SKELETON_FOREGROUND_COLOR}
          width={'48'}
          height={'48'}
          className={s.skeleton}>
          <rect x='0' y='0' rx='24' ry='24' width='48' height='48' />
        </ContentLoader>
      )}

      {isButton && (
        <ContentLoader
          speed={SKELETON_SPEED}
          backgroundColor={SKELETON_MAIN_COLOR}
          foregroundColor={SKELETON_FOREGROUND_COLOR}
          width={'48'}
          height={'48'}
          className={s.skeleton}>
          <rect x='0' y='0' rx='24' ry='24' width='48' height='48' />
        </ContentLoader>
      )}
    </>
  )
}

export default MenuLinkSkeleton
