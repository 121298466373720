import React, { FC, memo, useMemo, useRef } from 'react'
import s from './MenuItem.module.sass'
import Image from 'next/image'
import { Text } from '@shared/ui/Typography/Text'
import { TypeMenuItem } from '@widgets/Restaurant/RestaurantMenu/types/menu'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { useIntersectionAnalytic } from '@shared/lib/hooks/useIntersectionAnalytic'
import { EGG } from '@shared/api/analytics'

const MenuItem: FC<TypeMenuItem> = ({ id, type, img, href, analytic }) => {
  const { isMobile } = useClientResponsive()
  const wrapper = useRef()
  const extFile = useMemo(() => {
    const m = href.match(/\.([^.]+)$/)
    return m ? m[1] : 'Формат не определен'
  }, [href])

  useIntersectionAnalytic(wrapper, () =>
    EGG.pages.RestItem.menu_item_view({
      menu_type: type,
      object_id: id,
      object_type: 'menu',
      section_index: analytic?.section_index || 0,
    }),
  )

  const handleClickMenu = () => {
    EGG.pages.RestItem.menu_item_click({
      menu_type: type,
      object_id: id,
      object_type: 'menu',
      section_index: analytic?.section_index || 0,
    })
  }

  return (
    <ErrorBoundary>
      <a
        href={href}
        target='_blank'
        className={s.menu}
        rel='noreferrer'
        data-menu-item-id={id}
        ref={wrapper}
        onClick={handleClickMenu}>
        <Text sizes={'L ML'} className={s.title}>
          {type}
        </Text>

        <Text sizes={'S'} className={s.ext} uppercase>
          {extFile}
        </Text>

        <div className={!isMobile ? s['image-wrapper'] : `${s['image-wrapper']} ${s['image-wrapper--mobile']}`}>
          <Image
            src={img || ''}
            width={!isMobile ? 92 : 86}
            height={!isMobile ? 92 : 86}
            className={s['image-elem']}
            alt={`Ссылка на меню — ${type}`}
          />
        </div>
      </a>
    </ErrorBoundary>
  )
}

export default memo(MenuItem)
