import React, { FC } from 'react'

import { MenuItem } from '@widgets/Restaurant/RestaurantMenu/ui/MenuItem'
import { TypeMenuItem } from '@widgets/Restaurant/RestaurantMenu/types/menu'

import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { SectionCarousel } from '@shared/ui/Layout/SectionCarousel'

interface Props {
  data: TypeMenuItem[]
  className?: string
}

const RestaurantMenu: FC<Props> = ({ data, className = '' }) => {
  return (
    <ErrorBoundary>
      <SectionCarousel countSlidesOnContainer={3} title={'Меню'} className={className}>
        <SectionCarousel.Items>
          {data.map((el, index) => {
            return <MenuItem key={el.id} {...el} analytic={{ section_index: index }} />
          })}
        </SectionCarousel.Items>
      </SectionCarousel>
    </ErrorBoundary>
  )
}

export default RestaurantMenu
