import React, { FC } from 'react'
import s from './RestaurantMenuPopup.module.sass'
import { Text } from '@shared/ui/Typography/Text'
import Image from 'next/image'
import { getPlaceholderImage } from '@shared/lib/helpers/getPlaceholderImage'
import { Modal } from '@shared/ui/Overlays/Modal'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { TypeMappingRestDish } from '@shared/api/middleware/mappingAPI/restaurant/type'

interface Props {
  restTitle: string
  dishes: TypeMappingRestDish[]
  handleClose: () => void
}

const RestaurantMenuPopup: FC<Props> = ({ restTitle, dishes, handleClose }) => {
  const { isMobile } = useClientResponsive()

  return (
    <Modal
      size={'S'}
      mobileMode={'dropdown'}
      title={'Новые блюда'}
      titleSizes={'h2 h3'}
      subtitle={
        <Text sizes={'M S'} className={s.subtitle}>
          Обновление меню {restTitle}
        </Text>
      }
      handleClose={handleClose}>
      <ul className={s.dishes}>
        {dishes.map((dish) => (
          <li className={s.dish}>
            <Image
              src={dish.img || getPlaceholderImage()}
              width={isMobile ? 68 : 114}
              height={isMobile ? 68 : 114}
              alt={dish.title}
              className={s['dish-photo']}
            />
            <div className={s['dish-content']}>
              <Text sizes={'ML'}>{dish.title}</Text>
              <Text sizes={'S'} className={s['dish-price']}>
                {dish.price.toLocaleString()} ₽
              </Text>
              <Text sizes={'S'}>{dish.description}</Text>
            </div>
          </li>
        ))}
      </ul>
    </Modal>
  )
}

export default RestaurantMenuPopup
