/* eslint-disable no-unused-vars */

import { RefObject, useEffect, useMemo, useState } from 'react'
import l10n from '@widgets/Restaurant/Booking/lib/booking-localization'

// * section. custom hooks *

/**
 * @function useLocalStorage - TODO описание
 */
export const useLocalStorage = (key: string, initialValue: string) => {
  const isWindowAvailable = typeof window !== 'undefined'

  // eslint-disable-next-line camelcase
  const [storedValue, set_storedValue] = useState(() => {
    if (isWindowAvailable) {
      try {
        // --> get from localStorage <--
        const storageItem = window.localStorage.getItem(key)
        return storageItem ? JSON.parse(storageItem) : initialValue
      } catch (error) {
        console.error(error)
        return initialValue
      }
    } else {
      return initialValue
    }
  })

  type TypeSetValueFunction = (value: string) => string
  const setValue = (value: string | TypeSetValueFunction): void => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value
      set_storedValue(valueToStore)

      if (isWindowAvailable) {
        // --> save to localStorage <--
        window.localStorage.setItem(key, JSON.stringify(valueToStore))
      }
    } catch (error) {
      console.error(error)
    }
  }

  return [storedValue, setValue]
}

/**
 * @function useOnClickOutside - TODO описание
 */
export const useOnClickOutside = (ref: RefObject<any>, handler: (event: TouchEvent | MouseEvent) => void) => {
  useEffect(
    () => {
      const listener = (event: TouchEvent | MouseEvent) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return
        }
        handler(event)
      }
      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)
      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler],
  )
}

/**
 * @function useOnScreen - TODO описание
 */
export const useOnScreen = (ref: RefObject<any>, optionsProps?: object) => {
  const [isIntersecting, setIntersecting] = useState(false)

  const options = { threshold: 0.5, ...optionsProps }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting), options)

  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current)
    }
    return () => {
      observer.disconnect()
    }
  }, [observer, ref])
  return isIntersecting
}

/**
 * @function useOnScreen - TODO описание
 */
export const useOnScreenByPercentage = (ref: RefObject<any>, optionsProps?: object) => {
  const [percentageVisible, setPercentageVisible] = useState(0)

  const options = { ...optionsProps }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const observer = new IntersectionObserver(([entry]) => setPercentageVisible(entry.intersectionRatio), options)

  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current)
    }
    return () => {
      observer.disconnect()
    }
  }, [observer, ref])
  return percentageVisible
}

// * section. актуальный helper из desktop *

/**
 * @function parseCookie - TODO описание
 */
export default function parseCookie(name: string): string | null {
  let cookieValue: null | string = null

  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';')

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim()

      if (cookie.substring(0, name.length + 1) === name + '=') {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1))
        break
      }
    }
  }
  return cookieValue
}

/**
 * @function getCookie - TODO описание
 */
export const getCookie = (name: string) => {
  const match = document.cookie.match(new RegExp(name + '=([^;]+)'))
  if (!match) {
    return
  }
  return match[1]
}

/**
 * @function hash - генерирует случайный хэш, чтобы можно было использовать в key при генерации списков в react
 */
export const hash = () => {
  return (+new Date() + Math.random() * 100).toString(32).replace('.', '')
}

/**
 * @function setRubFormat - переводит число в денежный формат (XX XXX Р):
 */
export const setRubFormat = (num: number): string => {
  const result = new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',
  })
  // eslint-disable-next-line no-useless-escape
  return result.format(num).replace(/\,[0-9]+/, '')
}

/**
 * @function checkIfProduction - TODO описание
 */
export const checkIfProduction = () => location.hostname === 'www.afisha.ru'

/**
 * @function numWord - TODO описание
 * @example => valueInput - 8,  words - ['отзыв', 'отзыва', 'отзывов'] -> 8 отзывов
 */
export const numWord = (valueInput: number, words: string[]) => {
  const value = Math.abs(valueInput) % 100
  const num = value % 10
  if (value > 10 && value < 20) {
    return words[2]
  }
  if (num > 1 && num < 5) {
    return words[1]
  }
  if (num === 1) {
    return words[0]
  }
  return words[2]
}

/**
 * @function textNumberTemplate - в зависимости от числа ставит слово в нужную форму
 * магический алгоритм взят тут https://gist.github.com/realmyst/1262561
 * @param  {Number} number количество чего бы то ни было
 * @param  {Array}  arr    массив с формами слова
 * @return {String}        слово в нужной форме
 */
export const textNumberTemplate = (number: number, arr: any) => {
  let template = ''
  const cases = [2, 0, 1, 1, 1, 2]
  const index = number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]
  template = arr[index]
  return template
}

/**
 * @function shuffle - TODO описание
 */
export const shuffle = (inputArray: any) => {
  return inputArray && inputArray.length > 0
    ? inputArray
        .map((value) => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value)
    : inputArray
}

/**
 * @function getGuestCountString - TODO описание
 */
export const getGuestCountString = (guestValue) => {
  const numText = numWord(guestValue, [
    l10n('guest_count_guests')[0],
    l10n('guest_count_guests')[1],
    l10n('guest_count_guests')[2],
  ])

  return numText ? `${guestValue} ${numText}` : `${guestValue}`
}

/**
 * @function isPhonePatternValid - TODO описание
 */
export const isPhonePatternValid = (phoneValue: string): boolean => {
  const PHONE_API_REGEXP = /^[0-9\b]+$/
  const thisValue = phoneValue.replace(/\D/g, '')
  return !!thisValue && thisValue !== '' && thisValue.length >= 9 && PHONE_API_REGEXP.test(thisValue)
}
