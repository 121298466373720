import React, { FC } from 'react'

import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import ContentLoader from 'react-content-loader'
import {
  SKELETON_FOREGROUND_COLOR,
  SKELETON_MAIN_COLOR,
  SKELETON_SPEED,
  SKELETON_STYLES,
} from '@shared/consts/skeleton'
import s from '@shared/ui/Layout/SectionTitle/SectionTitle.module.sass'

interface Props {
  className?: string
}

const Skeleton: FC<Props> = ({ className = '' }) => {
  return (
    <ErrorBoundary>
      <div className={`${s.wrapper} ${className}`}>
        <ContentLoader
          speed={SKELETON_SPEED}
          backgroundColor={SKELETON_MAIN_COLOR}
          foregroundColor={SKELETON_FOREGROUND_COLOR}
          style={SKELETON_STYLES}
          className={s['svg-skeleton']}
          width={'533px'}
          height={'40px'}>
          <rect x='0' y='0' rx='16' ry='16' width='533px' height='40px' className={s['rect-skeleton']} />
        </ContentLoader>
      </div>
    </ErrorBoundary>
  )
}

export default Skeleton
