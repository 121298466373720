import React, { FC, ReactNode } from 'react'
import s from './BookingButton.module.sass'
import { ErrorBoundary } from '../../../../../../6_shared/lib/components/ErrorBoundary'

interface Props {
  className?: string
  color?: 'white' | 'red' | 'black'
  children: ReactNode
}

const BookingButton: FC<Props & React.ButtonHTMLAttributes<any>> = ({
  className = '',
  children,
  color = 'red',
  ...props
}) => {
  return (
    <ErrorBoundary>
      <button type='button' className={`${s.button} ${className}`} data-color={color} {...props}>
        {children}
      </button>
    </ErrorBoundary>
  )
}
export default BookingButton
