import React, { FC } from 'react'
import s from './NewsAuthor.module.sass'
import { useAppSelector } from '@app/model/store'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { Title } from '@shared/ui/Typography/Title'
import { Text } from '@shared/ui/Typography/Text'
import { Avatar } from '@shared/ui/Media/Avatar'
import AvatarMargo from '@images/special-margo-1.jpeg'
import { NewsAuthorSkeleton } from '@pages/NewsItem/ui/NewsAuthor/index'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'

interface Props {
  className?: string
}

const NewsAuthor: FC<Props> = ({ className = '' }) => {
  const { news } = useAppSelector((store) => store.pages.news_item)
  const { isMobile } = useClientResponsive()

  if (!news) return <NewsAuthorSkeleton className={className} />

  const isSpecialAuthor = !!news.author && news.author.id === 156 && news.author.slug === 'margarita-zamuraeva'
  const newsItemAvatarAuthorSrc = isSpecialAuthor ? AvatarMargo.src : news.author?.avatar

  if (!news.author) return

  return (
    <ErrorBoundary>
      <div className={`${s.author} ${className}`}>
        <Avatar
          sizes={'XL'}
          className={s['author-avatar']}
          userName={news.author?.name}
          src={newsItemAvatarAuthorSrc}
        />

        <div className={s['author-text']}>
          {isMobile ? (
            <Text sizes={'M'} medium className={s['author-name']}>
              {news.author?.name}
            </Text>
          ) : (
            <Title sizes={'h2'} tag={'h5'} className={s['author-name']}>
              {news.author?.name}
            </Title>
          )}
        </div>
      </div>
    </ErrorBoundary>
  )
}

export default NewsAuthor
