import React, { FC } from 'react'
import s from './RestaurantNews.module.sass'
import { NewsCardSkeleton } from '@widgets/News/NewsCard'
import { SectionCarousel } from '@shared/ui/Layout/SectionCarousel'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'

const Skeleton: FC = () => {
  return (
    <ErrorBoundary>
      <div className={s['news-wrap']}>
        <section>
          <SectionCarousel countSlidesOnContainer={3} title={'Материалы о ресторане'} skeleton={true}>
            <SectionCarousel.Skeletons>
              {Array.from(Array(4).keys()).map((i, idx) => (
                <NewsCardSkeleton key={idx} sizes={idx % 3 === 0 ? 'L2x1 M2x1 M2x1 1x1' : 'L1x1 M1x1 M1x1 1x1'} />
              ))}
            </SectionCarousel.Skeletons>
          </SectionCarousel>
        </section>
      </div>
    </ErrorBoundary>
  )
}

export default Skeleton
