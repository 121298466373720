import React, { useCallback, FC, useRef, useEffect } from 'react'
import l10n from '../../../lib/booking-localization'
import { getGuestCountString } from '../../../lib/helper'
import { useAppDispatch, useAppSelector } from '@app/model/store'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import s from './BookingScreenSuccess.module.sass'
import IconClose from '@icons/icon--x-thin.svg'
import { BookingButton } from '@widgets/Restaurant/Booking/ui/components/BookingButton'
import { closeBooking } from '@widgets/Restaurant/Booking'
import BookingSuccess from '@images/booking-success.png'
import { IconButton } from '@shared/ui/Actions/IconButton'
import { Title } from '@shared/ui/Typography/Title'
import Image from 'next/image'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { EGG } from '@shared/api/analytics'

declare const window: any

const BookingScreenSuccess: FC = () => {
  const dispatch = useAppDispatch()
  const { isDesktop, isMobile } = useClientResponsive()
  const storeBooking = useAppSelector((state) => state.booking)
  const { isOpenScreenConfirm, isOpenScreenSuccess, restaurantSource } = storeBooking
  const { dateField, timeField, guestsCountField, phoneField } = storeBooking.form
  const successImageRef = useRef()
  const bookingCheckoutId = null // TODO: AREST-5043, нужно отправлять id бронирования в любом формате

  // rcm rec-sys event
  useEffect(() => {
    window.rcm &&
      window.rcm('bookingDone', {
        itemId: 'Restaurant_' + restaurantSource.id,
        checkoutId: bookingCheckoutId,
      })
  }, [restaurantSource.name, restaurantSource.id])

  // egg click-house event
  useEffect(() => {
    EGG.entity.booking.appear_screen_success({
      count_guest: guestsCountField.value,
      phone: phoneField.value,
      date: dateField.selected?.itemText.toLowerCase(),
      time: timeField.selected?.value.toLowerCase(),
    })
  }, [restaurantSource.name, restaurantSource.id])

  const handleAbortBookingCallback = useCallback(
    (type: 'close' | 'confirm') => {
      !isOpenScreenConfirm && dispatch(closeBooking(null))

      const EGGMethod =
        type === 'confirm' ? EGG.entity.booking.click_success_ok : EGG.entity.booking.click_success_close

      EGGMethod()
    },
    [dispatch, isOpenScreenConfirm, restaurantSource.id, restaurantSource.name],
  )

  return (
    <ErrorBoundary>
      <header className={s['header-button']}>
        <IconButton
          sizes={'L M'}
          mode={'white'}
          icon={IconClose}
          onClick={() => handleAbortBookingCallback('close')}
          className={s['button-close']}
        />
      </header>
      <main className={s.wrapper}>
        <Title sizes={'h3'} tag={'h3'} className={s.title}>
          {l10n('form_header_title')}
        </Title>

        <p className={s.description}>{l10n('booking_complete_description')}</p>

        <div ref={successImageRef} className={s['img-wrapper']}>
          <Image layout={'fill'} className={s.img} src={BookingSuccess.src || ''} alt='успешное бронирование' />
        </div>

        <div className={s.row}>
          <p className={s['guest-info']}>
            {isMobile ? restaurantSource.name : l10n('form_complete_info_rest') + ' ' + restaurantSource.name}
            <br />
            {l10n('form_complete_info_on') + ' ' + dateField.selected?.itemText.toLowerCase()}
            <br />
            {l10n('form_complete_info_at') + ' ' + timeField.selected?.value.toLowerCase()},{' '}
            {getGuestCountString(guestsCountField.value)}
            {restaurantSource.alerts.map((el) => {
              if (!el.disabled && !!el.conclusion)
                return (
                  <>
                    <br />
                    <span>{el.conclusion}</span>
                  </>
                )
            })}
          </p>
        </div>
        <div className={s.row}>
          <BookingButton className={s.button} onClick={() => handleAbortBookingCallback('confirm')} color={'black'}>
            {l10n('form_close_confirm_close')}
          </BookingButton>
        </div>

        {/* // *** false - temporary disabled promo-block *** // */}
        {false && restaurantSource.couponCode && restaurantSource.couponValue && (
          <div className={s.row}>
            <section className={s['row-coupon-wrap']}>
              {/* // TODO: no english version for coupons */}
              {isDesktop ? (
                <p className={s['row-coupon-info']}>
                  назовите в ресторане кодовое слово
                  <br />и получите скидку {restaurantSource.couponValue}₽
                </p>
              ) : (
                <p className={s['row-coupon-info']}>
                  получите скидку {restaurantSource.couponValue}₽<br />
                  назвав в ресторане кодовое слово
                </p>
              )}

              <h3 className={s['row-coupon-keyword']}>{restaurantSource.couponCode}</h3>
            </section>
          </div>
        )}
      </main>
    </ErrorBoundary>
  )
}

export default BookingScreenSuccess
