import React, { createContext } from 'react'
import { PickAnalytic } from '@shared/api/analytics/types/types'

export const CarouselContext = createContext<TypeCarouselContext>(null)

type TypeCarouselContext = [TypeCarouselState, React.Dispatch<React.SetStateAction<TypeCarouselState>>] | null

type TypeCarouselState = {
  slides: object[] | null
  options: {
    [key: string]: any
  } | null
  sliderRef: any | null
  instanceRef: any | null
  slideMoveCount: number | null
  countSlidesOnContainer: number | null
  details: TypeCarouselDetails
  analytic: PickAnalytic<'section_name'>
}

export type TypeCarouselDetails = {
  nextIndex: number | null
  prevIndex: number | null
  currentIndex: number | null
  maxIndex: number | null
  minIndex: number | null
}
