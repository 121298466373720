// import { loadOwnerReviewsLists } from './createAsyncThunk'

import { combineReducers, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { OwnerPostsReducer } from '@pages/OwnerPersonalPage/model/OwnerPostsModel'
import { OwnerReviewsReducer } from '@pages/OwnerPersonalPage/model/OwnerReviewsModel'

const initialState = {
  data: null,
}

/**
 * @description OwnerPersonalPageReducer
 */
const OwnerPersonalPageReducer = createSlice({
  name: 'OwnerPersonalPageReducer',

  initialState,

  reducers: {
    setOwnerData(state, action: PayloadAction<any>) {
      state.data = action.payload
    },

    resetState: () => initialState,
  },
})

export const getState = (state: any) => state

export const { setOwnerData, resetState } = OwnerPersonalPageReducer.actions

/**
 * @function combineReducers
 * @todo можно переименовать posts в offers, аналогично названию на беке
 */
const OwnerPersonalPageReducers = combineReducers({
  main: OwnerPersonalPageReducer.reducer,
  posts: OwnerPostsReducer,
  reviews: OwnerReviewsReducer,
})

export default OwnerPersonalPageReducers
