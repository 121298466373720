import React, { FC, ReactNode, SVGProps } from 'react'
import s from './ChevronWithTooltip.module.sass'
import { Tooltip } from '@shared/ui/Overlays/Tooltip'
import { TooltipTrigger } from '@shared/ui/Overlays/Tooltip/ui/TooltipTrigger'
import { TooltipContent } from '@shared/ui/Overlays/Tooltip/ui/TooltipContent'
import { Text } from '@shared/ui/Typography/Text'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'

interface Props {
  icon: FC<SVGProps<SVGSVGElement>>
  className?: string
}

const ChevronWithTooltip: FC<Props> = ({ icon, className = '' }) => {
  const IconChevron = icon
  return (
    <ErrorBoundary>
      <div className={className}>
        <Tooltip>
          <TooltipTrigger>
            <IconChevron />
          </TooltipTrigger>
          <TooltipContent>
            <Text sizes={'S'} className={s['tooltip-text']}>
              Выбор Афиши
            </Text>
          </TooltipContent>
        </Tooltip>
      </div>
    </ErrorBoundary>
  )
}

export default ChevronWithTooltip
