import React, { FC, ReactNode } from 'react'
import s from './Content.module.sass'
import { ErrorBoundary } from '../../../../../lib/components/ErrorBoundary'

interface Props {
  children: ReactNode
  className?: string
}

const Content: FC<Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = ({
  children,
  className = '',
  ...props
}) => {
  return (
    <ErrorBoundary>
      <div className={`${s.content} ${className}`} {...props}>
        {children}
      </div>
    </ErrorBoundary>
  )
}

export default Content
