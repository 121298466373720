import React, { FC } from 'react'

import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import ContentLoader from 'react-content-loader'
import {
  SKELETON_FOREGROUND_COLOR,
  SKELETON_MAIN_COLOR,
  SKELETON_SPEED,
  SKELETON_STYLES,
} from '@shared/consts/skeleton'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'

const Skeleton: FC = () => {
  const { isMobile } = useClientResponsive()

  return (
    <ErrorBoundary>
      {isMobile ? (
        <ContentLoader
          speed={SKELETON_SPEED}
          backgroundColor={SKELETON_MAIN_COLOR}
          foregroundColor={SKELETON_FOREGROUND_COLOR}
          style={SKELETON_STYLES}
          width={'100px'}
          height={'128px'}>
          <circle cx='50' cy='50' r='50' />
          <rect x='18' y='110' rx='8' ry='8' width='64px' height='18px' />
        </ContentLoader>
      ) : (
        <ContentLoader
          speed={SKELETON_SPEED}
          backgroundColor={SKELETON_MAIN_COLOR}
          foregroundColor={SKELETON_FOREGROUND_COLOR}
          style={SKELETON_STYLES}
          width={'130px'}
          height={'164px'}>
          <circle cx='65' cy='65' r='65' />
          <rect x='33' y='146' rx='8' ry='8' width='64px' height='18px' />
        </ContentLoader>
      )}
    </ErrorBoundary>
  )
}

export default Skeleton
