import s from './ModalFoundMistake.module.sass'

import React, { FC } from 'react'

import { useAppDispatch, useAppSelector } from '@app/model/store'

import { closeModal } from '@widgets/Common/ModalFoundMistake/model/createActions'
import { sendRequest } from '@widgets/Common/ModalFoundMistake/model/createAsyncThunk'

import { Modal } from '@shared/ui/Overlays/Modal'
import { Text } from '@shared/ui/Typography/Text'
import { Button } from '@shared/ui/Actions/Button'
import { useField } from '@shared/lib/fields/hooks/useField/useField'
import { getDefaultField } from '@shared/lib/fields/getDefaultField'
import { BuildInput } from '@shared/ui/Form/Build/BuildInput'
import { BuildTextarea } from '@shared/ui/Form/Build/BuildTextarea'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'

/**
 * @function ModalFoundMistake
 * @todo remove ts-ignore
 */
const ModalFoundMistake: FC = () => {
  const { opened } = useAppSelector((store) => store.found_mistake)
  return opened && <ModalFoundMistakeInner />
}

const ModalFoundMistakeInner: FC = () => {
  const { prefilled_data } = useAppSelector((store) => store.found_mistake)
  const dispatch = useAppDispatch()

  const isVisibleFullName = !prefilled_data.full_name
  const isVisibleEmail = !prefilled_data.email

  const [fieldName, setFieldName] = useField(
    getDefaultField('input', {
      name: 'full_name',
      required: true,
      visible: isVisibleFullName,
      settings: {
        label: 'Имя',
        showRequired: false,
        placeholder: 'Укажите ваше имя',
      },
    }),
  )

  const [fieldEmail, setFieldEmail] = useField(
    getDefaultField('input_email', {
      name: 'email',
      required: true,
      visible: isVisibleEmail,
      settings: {
        label: 'E-mail',
        showRequired: false,
        placeholder: 'Укажите ваш e-mail',
      },
    }),
  )

  const [fieldComment, setFieldComment] = useField(
    getDefaultField('textarea', {
      name: 'comment',
      required: true,
      settings: {
        label: 'Комментарий',
        showRequired: false,
        placeholder: 'Расскажите об ошибке',
      },
    }),
  )

  const checkValidFields = () => {
    // @ts-ignore
    return [fieldName, fieldEmail, fieldComment].every((field) => field.valid)
  }

  const handleSend = () => {
    const isValidFields = checkValidFields()

    const data = {
      // @ts-ignore
      name: prefilled_data.full_name || fieldName.value,
      // @ts-ignore
      email: prefilled_data.email || fieldEmail.value,
      // @ts-ignore
      comment: fieldComment.value,
      url: document.location.href,
      type: 'complain',
    }

    isValidFields && dispatch(sendRequest(data))
  }

  return (
    <ErrorBoundary>
      <Modal
        data-testid={'modal-found-mistake'}
        data-modal={'Нашли ошибку?'}
        title={'Нашли ошибку?'}
        size={'M'}
        handleClose={() => dispatch(closeModal())}
        className={s.modal}>
        <div className={s.content}>
          <Text sizes={'M'} className={s.description}>
            Расскажите, какие неточности вы обнаружили на «Афише–Рестораны». Считается все: неточные адреса и время
            работы, закрывшиеся или сменившие название заведения, не те фотографии и даже орфографические ошибки.{' '}
          </Text>

          <div className={s.inputs}>
            {(isVisibleFullName || isVisibleEmail) && (
              <div className={s.row}>
                {/*@ts-ignore*/}
                <BuildInput field={fieldName} setField={setFieldName} data-testid={'input-name'} />
                {/*@ts-ignore*/}
                <BuildInput field={fieldEmail} setField={setFieldEmail} data-testid={'input-email'} />
              </div>
            )}

            <div className={s.row}>
              {/*@ts-ignore*/}
              <BuildTextarea field={fieldComment} setField={setFieldComment} data-testid={'input-comment'} />
            </div>
          </div>

          <Button
            sizes={'XL L'}
            mode={'black'}
            onClick={handleSend}
            disabled={!checkValidFields()}
            className={s.button}
            data-testid={'button-send'}>
            Отправить
          </Button>
        </div>
      </Modal>
    </ErrorBoundary>
  )
}

export default ModalFoundMistake
