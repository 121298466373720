import React, { FC, useState } from 'react'
import s from './PriceRange.module.sass'
import { Text } from '@shared/ui/Typography/Text'
import { ErrorBoundary } from '../../../lib/components/ErrorBoundary'
import { Tooltip } from '@shared/ui/Overlays/Tooltip'
import { TooltipTrigger } from '@shared/ui/Overlays/Tooltip/ui/TooltipTrigger'
import { TooltipContent } from '@shared/ui/Overlays/Tooltip/ui/TooltipContent'

interface Props {
  priceRange: number
  colorMode: string
  className?: string
  link?: string
}

const TOOLTIP_PRICES = ['до 700 ₽', '700 – 1700 ₽', '1700 – 3000 ₽ ', 'больше 3000 ₽ ']

const PriceRange: FC<Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>> = ({
  priceRange,
  colorMode = 'black',
  className = '',
  link,
  ...props
}) => {
  const classes = (...args: Array<string>) =>
    args
      .map((classNameArg: string) => !!classNameArg && s[classNameArg])
      .join(' ')
      .trim()

  const getRootClassName = () => {
    let output = classes('price-range-wrap')
    if (colorMode === 'white') {
      output += ' ' + classes('price-range-wrap--white')
    } else if (colorMode === 'black') {
      output += ' ' + classes('price-range-wrap--black')
    }
    return output
  }

  return (
    <ErrorBoundary>
      <Tooltip>
        <TooltipTrigger>
          <span className={`${getRootClassName()} ${link ? s.link : ''} ${className}`} {...props}>
            {Array(4)
              .fill(0)
              .map((el, index) => (
                <Text tag={'span'} sizes={'S'} key={index} data-active={priceRange >= index + 1} uppercase>
                  ₽
                </Text>
              ))}
          </span>
        </TooltipTrigger>
        <TooltipContent>
          <Text sizes={'S'} className={s['tooltip-text']}>
            {TOOLTIP_PRICES[priceRange - 1]}
          </Text>
        </TooltipContent>
      </Tooltip>
    </ErrorBoundary>
  )
}

export default PriceRange
