import { undefinedObjectToNull } from '@shared/api/middleware/mappingAPI/helpers'
import { Selection } from '@shared/api/types/swaggerTypes'
import { TypeMappingSelectionOutput } from '@shared/api/middleware/mappingAPI/selection/type'
import { TARGET_SELECTIONS } from '@shared/api/middleware/mappingAPI/patchStickersSelections'

export const mappingSelectionItem = (object: Selection): TypeMappingSelectionOutput => {
  try {
    return undefinedObjectToNull({
      id: object.id,
      url: {
        path: null, // TODO: Нужно добавить ссылку с городом
        slug: object.slug,
      },

      description: {
        title: object.title,
        description: object.description,
        restaurantsCount: object.restaurants_count,
      },

      categories: {
        selection: TARGET_SELECTIONS.includes(object.slug),
        advert: {
          active: object.sold_positions,
          erid: object.erid,
        },
      },

      img: {
        name: null,
        author: null,
        caption: null,
        alt: null,
        icon: object.icon_image,
        sizes: {
          desktop: {
            horizontal: object.cover_image_horizontal,
            vertical: object.cover_image_vertical,
          },
          mobile: {
            horizontal: object.cover_images['h594x594'],
            vertical: object.cover_images['v594x594'],
          },
        },
      },
    })
  } catch (error) {
    console.error(error)
    return null
  }
}
