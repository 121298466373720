import { useEffect } from 'react'

/**
 * @description отключение скроллинга страницы при отрисовки компонента (для модалок)
 */
export const useBlockScrollOverlay = () => {
  useEffect(() => {
    document.body.style.top = `-${window.scrollY}px`
    document.body.style.left = '0px'
    document.body.style.right = '0'
    document.body.style.position = 'fixed'
    // восстановление скроллинга
    return () => {
      const scrollY = document.body.style.top
      document.body.style.position = ''
      document.body.style.top = ''
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  }, [])
}
