import React, { FC, ReactNode } from 'react'

import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import s from '@features/PromoRoll/PromoRoll.module.sass'
import { Carousel } from '@shared/ui/Layout/Carousel'
import { PromoCardSkeleton } from '@entities/PromoCard'
import { ContainerLongCenter } from '@shared/ui/Layout/Containers/ContainerLongCenter'

interface Props {
  className?: string
}
const Skeleton: FC<Props> = ({ className = '' }) => {
  const arrSkeleton = Array.from(Array(3).keys())

  return (
    <ErrorBoundary>
      <ContainerLongCenter classNameContainer={`${className} ${s.wrapper}`}>
        <div className={s.promo}>
          <Carousel>
            <Carousel.Slider shadow={true}>
              {arrSkeleton.map((thisPromoItem, idx) => (
                <PromoCardSkeleton key={idx} />
              ))}
            </Carousel.Slider>
          </Carousel>
        </div>
      </ContainerLongCenter>
    </ErrorBoundary>
  )
}

export default Skeleton
