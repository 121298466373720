import { TypeRouteMapping } from '@app/service/routing/types'
import { AppHome } from '@pages/AppHome'
import NewsItem from '@pages/NewsItem'
import { RestItem } from '@pages/RestItem'
import RestReviews from '@pages/RestReviews'
import NewsGallery from '@pages/NewsGallery'
import SelectionsGallery from '@pages/SelectionsGallery'
import OwnerLanding from '@pages/OwnerLanding'
import Sitemap from '@pages/Usage'
import { Listing } from '@pages/Listing'
import SelectionItem from '@pages/SelectionItem'

export const isSameRoute = (currentRoute: string, nextRoute: string, arr: TypeRouteMapping) => {
  try {
    const currentRouteData = arr.find((el) => el.regexp.test(currentRoute))
    const nextRouteData = arr.find((el) => el.regexp.test(nextRoute))

    return currentRouteData.pageName === nextRouteData.pageName
  } catch (e) {
    return false
  }
}

export const getCurrentRouteData = (route: string, arr: TypeRouteMapping) => {
  const defaultData = {
    pageName: '',
    component: null,
    regexp: null,
  }
  try {
    const getData = arr.find((el) => el.regexp.test(route))
    return getData || defaultData
  } catch (e) {
    return defaultData
  }
}

// Порядок страниц важен так как путь '/[city_slug]/restaurants/tags' подходит под условие regexp для пути - '/[city_slug]/restaurants/[selection_slug]'
export const routeMapping: TypeRouteMapping = [
  {
    templateRoute: '/',
    pageName: 'app_home',
    component: AppHome,
    regexp: /^\/$/,
  },
  {
    templateRoute: '/404',
    pageName: 'not_found',
    component: null,
    regexp: /^\/404\/?$/,
  },
  {
    templateRoute: '/500',
    pageName: 'service_err',
    component: null,
    regexp: /^\/500\/?$/,
  },
  {
    templateRoute: '/_app',
    pageName: 'app_home',
    component: AppHome,
    regexp: /^\/_app\/?$/,
  },
  {
    templateRoute: '/_document',
    pageName: null,
    component: null,
    regexp: /^\/_document\/?$/,
  },
  {
    templateRoute: '/restaurant-news/[news_slug]',
    pageName: 'news_item',
    component: NewsItem,
    regexp: /^\/restaurant-news\/.+\/?$/,
  },
  {
    templateRoute: '/rests/lego',
    pageName: null,
    component: null,
    regexp: /^\/rests\/lego\/?$/,
  },
  {
    templateRoute: '/rests/online_reservation',
    pageName: null,
    component: null,
    regexp: /^\/rests\/online_reservation\/?$/,
  },
  {
    templateRoute: '/rests/usage',
    pageName: null,
    component: null,
    regexp: /^\/rests\/usage\/?$/,
  },
  {
    templateRoute: '/[city_slug]/restaurant/[rest_slug]',
    pageName: 'rest_item',
    component: RestItem,
    regexp: /^\/.+\/restaurant\/.+\/?$/,
  },
  {
    templateRoute: '/[city_slug]/restaurant/[rest_slug]/openpopup',
    pageName: 'rest_item',
    component: RestItem,
    regexp: /^\/.+\/restaurant\/.+\/openpopup\/?$/,
  },
  {
    templateRoute: '/[city_slug]/restaurant/[rest_slug]/owner',
    pageName: 'owner_marketing',
    component: null,
    regexp: /^\/.+\/restaurant\/.+\/owner\/?$/,
  },
  {
    templateRoute: '/[city_slug]/restaurant/[rest_slug]/owner/booking',
    pageName: 'owner_booking',
    component: null,
    regexp: /^\/.+\/restaurant\/.+\/owner\/booking\/?$/,
  },
  {
    templateRoute: '/[city_slug]/restaurant/[rest_slug]/reviews',
    pageName: 'rest_reviews',
    component: RestReviews,
    regexp: /^\/.+\/restaurant\/.+\/reviews\/?$/,
  },
  {
    templateRoute: '/[city_slug]/restaurant-news',
    pageName: 'news_gallery',
    component: NewsGallery,
    regexp: /^\/.+\/restaurant-news\/?$/,
  },
  {
    templateRoute: '/[city_slug]/restaurants',
    pageName: 'app_home',
    component: AppHome,
    regexp: /^\/.+\/restaurants\/?$/,
  },
  {
    templateRoute: '/[city_slug]/restaurants/afisha_selected',
    pageName: 'selections_gallery',
    component: SelectionsGallery,
    regexp: /^\/.+\/restaurants\/afisha_selected\/?$/,
  },
  {
    templateRoute: '/[city_slug]/restaurants/restorator',
    pageName: 'owner_landing',
    component: OwnerLanding,
    regexp: /^\/.+\/restaurants\/restorator\/?$/,
  },
  {
    templateRoute: '/[city_slug]/restaurants/tags',
    pageName: 'tag_list',
    component: Sitemap,
    regexp: /^\/.+\/restaurants\/tags\/?$/,
  },
  {
    templateRoute: '/[city_slug]/restaurants/restaurant_list/search',
    pageName: 'search_rests_listing',
    component: Listing,
    regexp: /^\/.+\/restaurants\/restaurant_list\/search\/?$/,
  },
  {
    templateRoute: '/[city_slug]/restaurants/restaurant_list/[[...rests_tags]]',
    pageName: 'rests_listing',
    component: Listing,
    regexp: /^\/.+\/restaurants\/restaurant_list\/?.+?\/?$/,
  },
  {
    templateRoute: '/[city_slug]/restaurants/[selection_slug]',
    pageName: 'selection_item',
    component: SelectionItem,
    regexp: /^\/.+\/restaurants\/.+\/?$/,
  },
]
