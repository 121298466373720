import React, { FC, useRef } from 'react'
import s from './NewsCardCover.module.sass'
import { useRouter } from 'next/router'

import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { MediaCardCover } from '@shared/ui/Layout/Cards/MediaCardCover'
import { TypeMappingNewsOutput } from '@shared/api/middleware/mappingAPI/news/type'
import { useIntersectionAnalytic } from '@shared/lib/hooks/useIntersectionAnalytic'
import { EGG } from '@shared/api/analytics'
import { PickAnalytic } from '@shared/api/analytics/types/types'
import { NewsCardCoverSkeleton } from '@widgets/News/NewsCardCover/index'

interface Props {
  data: TypeMappingNewsOutput
  subtitles?: string[]
  buttonText?: string
  analytic: PickAnalytic<'section_index' | 'section_name'>
  skeleton?: boolean
}

const NewsCardCoverWrapper: FC<Props> = (props) => {
  const { skeleton, ...data } = props
  return skeleton ? <NewsCardCoverSkeleton /> : <NewsCardCover {...data} />
}

const NewsCardCover: FC<Props> = ({
  data,
  subtitles = [data.type.name, '5 минут на чтение'],
  buttonText = 'Читать подборку',
  analytic,
}) => {
  const router = useRouter()
  const cardRef = useRef()

  useIntersectionAnalytic(cardRef, () => EGG.entity.news.card_view(data, analytic))

  const handleOpenNews = () => {
    EGG.entity.news.card_click(data, analytic)
    router.push(data.url.path, data.url.path, { scroll: false })
  }

  const defaultCoverImage =
    data.img.sizes.cover_2210x1244 ||
    data.img.sizes.cover_1220x594 ||
    data.img.sizes.cover_594x594 ||
    data.img.sizes.desktop.default1

  return (
    <ErrorBoundary>
      <MediaCardCover
        idObject={Number(data.id)}
        title={data.description.title}
        img={{ desktop: defaultCoverImage, mobile: defaultCoverImage }}
        subtitles={subtitles}
        handleClick={handleOpenNews}
        buttonText={buttonText}
        ref={cardRef}
        favorite={{
          category: 'news',
          analytic: {
            data: data,
            other: {
              section_name: analytic.section_name,
            },
          },
        }}
      />
    </ErrorBoundary>
  )
}

export default NewsCardCoverWrapper
