import { useEffect } from 'react'

export const useIntersection = (ref, callbackVisible, callbackInvisible, options?: IntersectionObserverInit) => {
  const optionsObserver: IntersectionObserverInit = {
    root: null,
    rootMargin: '5px',
    threshold: 1,
    ...options,
  }

  useEffect(() => {
    const target = ref.current
    const callbackObserver = (entries, observer) => {
      entries.forEach((entry) => (entry.isIntersecting ? callbackVisible() : callbackInvisible()))
    }

    const observer = new IntersectionObserver(callbackObserver, optionsObserver)
    observer.observe(target)

    return () => observer.unobserve(target)
  }, [])
}
