import s from './BookingDateTime.module.sass'
import React, { FC, useEffect, useState } from 'react'
import l10n from '@widgets/Restaurant/Booking/lib/booking-localization'
import { TypeExtendedScheduleTimeSlot } from '@shared/types/schedule/types'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { TypeTimeField } from '@widgets/Restaurant/Booking/model/types'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import IconDots from '@icons/icon-dots.svg'
import { useAppSelector } from '@app/model/store'

interface SectionTimeProps {
  handleSetTime: any
  isTimeErrorVisible: boolean
  timeField: TypeTimeField
}

/**
 * @function SectionTime - stateless presentational component for booking Time selection
 */
const SectionTime: FC<SectionTimeProps> = ({ handleSetTime, isTimeErrorVisible, timeField }) => {
  const { isDesktop, isMobile } = useClientResponsive()
  const { loading, options, selected } = timeField
  const bookingFormState = useAppSelector((state) => state.booking.form)
  const getIsShowAllTimeSlots = () => {
    if (selected) {
      const getIndexSelectedSlot = options.findIndex((slot) => slot.value === selected.value)
      if (getIndexSelectedSlot > 4) return true
    }
    return options?.length < 3
  }

  const [isAllSlotsVisible, setIsAllSlotsVisible] = useState<boolean>(getIsShowAllTimeSlots)

  useEffect(() => {
    setIsAllSlotsVisible(false)
  }, [bookingFormState.dateField.selected, bookingFormState.dateField.loading])

  const getClassButtonTime = (timeObj: TypeExtendedScheduleTimeSlot | null): string => {
    const selected = timeField.selected?.value === timeObj?.value
    return `${s.button} ${s['button--time']} ${selected ? s['button--selected'] : ''}`
  }

  const getTimeShowAllClassName = (): string => {
    let outputClassName = `${s.button} ${s['button__show-all-time']} `
    outputClassName += isDesktop ? s['booking-button-dots-icon--wide'] : s['booking-form-button--circle']

    return outputClassName
  }

  const isShowSlot = (index) => {
    if (isAllSlotsVisible) return true
    return isMobile ? index < 3 : index < 5
  }

  const isShowSlots = !loading && !!options?.length
  const isShowNotFound = !loading && !options?.length
  const isShowSkeleton = loading
  const isShowError = isTimeErrorVisible

  const skeletonCount = isMobile ? 4 : 6
  const skeletonArr = Array.from(Array(skeletonCount).keys())

  return (
    <ErrorBoundary>
      <div className={`${s.block} ${s['block-time']}`}>
        <label htmlFor='select_booking-time' className={s['booking-label']}>
          {l10n('booking_time_label')}
        </label>

        <div className={s.content}>
          {isShowSlots && (
            <>
              {options.map((slot, index) => {
                return isShowSlot(index) ? (
                  <button
                    onClick={() => handleSetTime(slot.value)}
                    className={getClassButtonTime(slot)}
                    key={slot.value}
                    type='button'>
                    {slot.value}
                  </button>
                ) : null
              })}

              {!isAllSlotsVisible && (
                <button className={getTimeShowAllClassName()} type='button' onClick={() => setIsAllSlotsVisible(true)}>
                  <IconDots />
                </button>
              )}
            </>
          )}

          {isShowSkeleton &&
            skeletonArr.map((el, idx) => (
              <button
                className={`${s['button']} ${s['button--placeholder']}`}
                key={idx + 1}
                type='button'
                disabled={true}
              />
            ))}

          {isShowNotFound && (
            <span className={s['no-slots-message']}>
              На выбранную дату нет свободных столиков, попробуйте выбрать другую
            </span>
          )}
        </div>

        {isShowError && <span className={s.error}>{l10n('booking_time_validation')}</span>}
      </div>
    </ErrorBoundary>
  )
}

export default SectionTime
