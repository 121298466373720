import React, { FC, ReactNode, useContext, useRef } from 'react'
import s from './Cover.module.sass'
import Image from 'next/image'
import { ImgContext } from '@shared/ui/Layout/Cover/Cover'
import { ErrorBoundary } from '../../../lib/components/ErrorBoundary'
import { useOnScreen } from '@widgets/Restaurant/Booking/lib/helper'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'

interface Props {
  children: ReactNode
  className?: string
  alt?: string
}

const CoverAside: FC<Props> = ({ children, className = '', alt = 'image cover' }) => {
  const { isMobile } = useClientResponsive()
  const imgSrc = useContext(ImgContext)
  const wrapper = useRef()
  const isVisible = useOnScreen(wrapper, { threshold: 0.15 })

  return (
    <ErrorBoundary>
      <aside className={s['aside-wrapper']} data-visible={isMobile ? isVisible : true} ref={wrapper}>
        <div className={s['aside-container']}>
          <div className={`${s.aside} ${className}`}>
            <div className={s['aside-inner']}>
              <Image layout={'fill'} className={s.img} src={imgSrc || ''} alt={alt} priority={true} />
            </div>
            {children}
          </div>
        </div>
      </aside>
    </ErrorBoundary>
  )
}

export default CoverAside
