import { globalStore } from '@app/model/store'
import { TypeClickHouseObjectTypes } from '@shared/api/analytics/types/types'

const api_key = 'iwKLN8NqoEeSDQOQ2VyjQ'
export const url = `https://egg.afisha.ru/v1/event/commonV2?api_key=${api_key}`

// получение id и типа текущей страницы (при наличии)
// для page_object_id и page_object_type из ClickHouse
export const getDataFromPageWithId = (): { id: number; type: TypeClickHouseObjectTypes } => {
  const { global, pages } = globalStore.getState()

  switch (global.currentPage) {
    case 'rest_item':
      return { id: pages.rest_item.data.id, type: 'restaurant' }
    case 'news_item':
      return { id: pages.news_item.news.id, type: 'article' }
    case 'selection_item':
      return { id: pages.selection_item.selection.data.id, type: 'selection' }
  }
}

export const pageNameMapping = {
  app_home: 'main',
  rest_item: 'rest_card',
  rest_reviews: 'rest_reviews',
  rests_listing: 'restaurant_list',
  search_rests_listing: 'search_result',
  news_gallery: 'main_news',
  news_item: 'article',
  selections_gallery: 'selection_list',
  selection_item: 'selection_card',
  reserve: 'reserve',
  success: 'success',
}
