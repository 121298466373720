import React, { FC } from 'react'
import s from './RestaurantSpecialOfferCard.module.sass'
import { CardService } from '@shared/ui/Layout/CardService'
import Image from 'next/image'
import { getPlaceholderImage } from '@shared/lib/helpers/getPlaceholderImage'
import { Text } from '@shared/ui/Typography/Text'
import { Title } from '@shared/ui/Typography/Title'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'

interface Props {
  title: string
  description: string
  img: string
  type_and_date: string
  onClick?: () => void
}

const RestaurantSpecialOfferCard: FC<Props> = ({ title, description, img, type_and_date, onClick }) => {
  const { isMobile } = useClientResponsive()

  return !isMobile ? (
    <div className={s.wrapper} onClick={onClick}>
      <div className={s.photo}>
        <Image layout={'fill'} src={img || getPlaceholderImage()} alt='rest-special' />
      </div>

      <div className={s.content}>
        <div className={s.content__inner}>
          <Text sizes={'S'} className={s.subtitle}>
            {type_and_date}
          </Text>

          <Title tag={'h2'} sizes={'h3 h4'} className={s.title}>
            {title}
          </Title>

          <Text sizes={'ML M M M'} className={s.description} paragraph>
            {description}
          </Text>
        </div>
      </div>
    </div>
  ) : (
    <CardService
      title={title}
      subtitle={type_and_date}
      description={description}
      img={img}
      maxLineDescription={5}
      handleClick={onClick}
    />
  )
}

export default RestaurantSpecialOfferCard
