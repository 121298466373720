import s from './Header.module.sass'
import React, { FC } from 'react'
import { useRouter } from 'next/router'

import { useAppSelector } from '@app/model/store'

import { City } from '@widgets/Common/Header/ui/City'
import { IconLogoRest, IconLogoText } from '@widgets/Common/Header/ui/Logo'
import { UserMenu } from '@widgets/Common/Header/ui/UserMenu'

import { ContainerLong } from '@shared/ui/Layout/Containers/ContainerLong'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { middlewareAuthorized } from '@shared/lib/helpers/auth'
import { HeaderSkeleton } from '@widgets/Common/Header/index'
import { IconButton } from '@shared/ui/Actions/IconButton'
import { Menu } from '@widgets/Common/Header/ui/Menu'
import { EGG } from '@shared/api/analytics'

import IconSearch from '@icons/icon-search.svg'
import IconLike from '@icons/icon-like.svg'
import AfishaOwnerLogo from '@icons/afisha-restaurants-owner-logo.svg'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'

const getRestCardLink = (router: any): string | null => {
  const citySlug = router?.query?.city_slug || null
  const restSlug = router?.query?.rest_slug || null
  return citySlug && restSlug ? `/${citySlug}/restaurant/${restSlug}/` : null
}

const Header: FC = () => {
  const { isMobile } = useClientResponsive()
  const router = useRouter()
  const stateGlobal = useAppSelector((state) => state.global)
  const user = stateGlobal.user.data

  const restCardLink = getRestCardLink(router)
  const isOwnerPage = stateGlobal.currentPage === 'owner_marketing' || stateGlobal.currentPage === 'owner_booking'

  const handleLike = () => {
    middlewareAuthorized(() => {
      EGG.header.button_favorite_click()

      router.push(
        `https://www.afisha.ru/personalpage/${user.afisha_id}/favorites/`,
        `https://www.afisha.ru/personalpage/${user.afisha_id}/favorites/`,
        {
          shallow: true,
        },
      )
    })
  }
  const openSearch = () => {
    // ! TODO: add real search
    EGG.header.button_search_click()

    router.push('https://www.afisha.ru/search/restaurant/?query=')
  }

  return (
    <ErrorBoundary>
      {stateGlobal.storeLoaded ? (
        <ContainerLong
          tag={'header'}
          classNameContent={`${s['header-content']} ${isOwnerPage ? s['header-content--owner'] : ''}`}
          classNameContainer={`${s['header-container']} ${isOwnerPage ? s['header-container--owner'] : ''}`}>
          <>
            {isOwnerPage ? (
              <AfishaOwnerLogo className={s['logo-owner']} />
            ) : (
              <div className={s['logo']}>
                <IconLogoRest />
                <div className={s['logo-column']}>
                  <IconLogoText />
                  <City />
                </div>
              </div>
            )}

            {!isOwnerPage && <Menu />}

            <div className={s.header_additional}>
              {isOwnerPage ? (
                restCardLink &&
                !isMobile && (
                  <a href={restCardLink} className={s['back-button']}>
                    Вернуться на сайт
                  </a>
                )
              ) : (
                <>
                  <IconButton
                    data-icon={'IconSearch'}
                    icon={IconSearch}
                    sizes='L M'
                    mode='white-border'
                    onClick={openSearch}
                  />

                  {!!user && (
                    <IconButton
                      data-icon={'IconLike'}
                      icon={IconLike}
                      sizes='L M'
                      mode='white-border'
                      onClick={handleLike}
                    />
                  )}
                </>
              )}

              <UserMenu />
            </div>
          </>
        </ContainerLong>
      ) : (
        <HeaderSkeleton />
      )}
    </ErrorBoundary>
  )
}

export default Header
