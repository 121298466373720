import React, { FC } from 'react'

import s from './QuickBookingDots.module.sass'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import ContentLoader from 'react-content-loader'
import {
  SKELETON_FOREGROUND_WHITE_COLOR,
  SKELETON_SPEED,
  SKELETON_STYLES,
  SKELETON_WHITE_COLOR,
} from '@shared/consts/skeleton'

interface Props {
  className?: string
}

const Skeleton: FC<Props> = ({ className = '' }) => {
  return (
    <ErrorBoundary>
      <ContentLoader
        speed={SKELETON_SPEED}
        backgroundColor={SKELETON_WHITE_COLOR}
        foregroundColor={SKELETON_FOREGROUND_WHITE_COLOR}
        style={SKELETON_STYLES}
        className={`skeleton ${s['dots-skeleton']} ${className}`}>
        <rect x='0' y='0' rx='24' ry='24' width='100%' height='100%' />
      </ContentLoader>
    </ErrorBoundary>
  )
}

export default Skeleton
