import React, { FC } from 'react'
import s from './TagsRoll.module.sass'
import { Carousel } from '@shared/ui/Layout/Carousel'
import CarouselSlider from '@shared/ui/Layout/Carousel/CarouselSlider'
import { Text } from '@shared/ui/Typography/Text'
import Link from 'next/link'
import { globalStore, useAppSelector } from '@app/model/store'
import { ErrorBoundary } from '../../../../6_shared/lib/components/ErrorBoundary'
import { EGG } from '@shared/api/analytics'
import { findDataTagForAnalyticBySlug } from '@features/Restaurant/RestaurantFilters/helpers/helper'
import { data } from 'browserslist'
import { TagsRollSkeleton } from '@pages/AppHome/ui/TagsRoll/index'

interface Props {
  tags: {
    id: number
    name: string
    slug: string
  }[]
  skeleton?: boolean
  className?: string
}

const TagsRoll: FC<Props> = ({ tags, skeleton, className = '' }) => {
  const { currentCity } = useAppSelector((store) => store.global)

  const handleClickTag = (tagSlug: string) => {
    const dataTag = findDataTagForAnalyticBySlug(tagSlug)
    EGG.common.tag_click({
      filter_apply_type: dataTag.type,
      filter_apply_value: tagSlug,
    })
  }

  if (!skeleton && !tags?.length) return

  return (
    <ErrorBoundary>
      {skeleton ? (
        <TagsRollSkeleton />
      ) : (
        <section className={`${s['tags-roll']} ${className}`}>
          <h2 className='visually-hidden'>app home tags-roll</h2>

          <ul className={s['tags-list']}>
            <Carousel>
              <CarouselSlider>
                {tags.map((tag, key) => {
                  const isLast = tags.length === key + 1
                  return (
                    <Text
                      sizes={'M S'}
                      key={key}
                      className={!isLast ? s['tags-item'] : `${s['tags-item']} ${s['tags-item--last']}`}>
                      <Link
                        href={`/${currentCity.slug}/restaurants/restaurant_list/${tag.slug}/`}
                        className={s['tags-link']}
                        onClick={() => handleClickTag(tag.slug)}
                        scroll={false}>
                        {tag.name}
                      </Link>
                    </Text>
                  )
                })}
              </CarouselSlider>
            </Carousel>
          </ul>
        </section>
      )}
    </ErrorBoundary>
  )
}

export default TagsRoll
