import { sendClickHouseAnalytic } from '@shared/api/analytics/template'

export const EGGAccountMenu = {
  button_profile_click: () =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      element_type: 'profile',
      section_name: 'profile',
    }),

  button_restaurateur_click: () =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      element_type: 'restaurateur_account',
      section_name: 'profile',
    }),

  button_reviews_click: () =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      element_type: 'marks_reviews',
      section_name: 'profile',
    }),

  button_editor_click: () =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      element_type: 'editor',
      section_name: 'profile',
    }),

  button_booking_click: () =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      element_type: 'booking',
      section_name: 'profile',
    }),

  button_settings_click: () =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      element_type: 'settings',
      section_name: 'profile',
    }),

  button_exit_click: () =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      element_type: 'exit',
      section_name: 'profile',
    }),

  button_add_event_click: () =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      element_type: 'add_event',
      section_name: 'profile',
    }),

  button_popup_close_click: () =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      element_type: 'close',
      section_name: 'profile',
    }),
}
