import React, { FC, useEffect, useRef, useState } from 'react'
import s from './QuestionAnswerItem.module.sass'
import { Text } from '@shared/ui/Typography/Text'
import { TypeQuestionAnswer } from '@widgets/Common/QuestionsAnswers/types/QuestionAnswer'

import IconArrow from '@icons/icon-arrow-slider.svg'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { PickAnalytic } from '@shared/api/analytics/types/types'
import { EGG } from '@shared/api/analytics'

interface Props {
  data: TypeQuestionAnswer
  className?: string
  analytic?: PickAnalytic<'section_index'>
}

const QuestionAnswerItem: FC<Props> = ({ data, analytic, className = '' }) => {
  const [isOpenAnswer, setIsOpenAnswer] = useState(false)
  const [heightAnswer, setHeightAnswer] = useState(0)

  const contentAnswer = useRef()
  const handleToggleAnswer = () => {
    if (!isOpenAnswer && analytic) {
      EGG.pages.RestItem.qa_click({ section_index: analytic.section_index })
    }
    setIsOpenAnswer(!isOpenAnswer)
  }

  useEffect(() => {
    // @ts-ignore
    setHeightAnswer(contentAnswer.current.offsetHeight)
  }, [])

  return (
    <ErrorBoundary>
      <div className={`${s.wrapper} ${className}`} data-open={isOpenAnswer}>
        <div className={s.question} onClick={handleToggleAnswer}>
          <Text sizes={'ML M'} className={s.question__title}>
            {data.question}
          </Text>

          <button className={s.arrow}>
            <IconArrow />
          </button>
        </div>

        <div className={s.answer} style={{ height: `${isOpenAnswer ? heightAnswer : '0'}px` }}>
          <div ref={contentAnswer}>
            <Text className={s.answer__text} sizes={'M S'} paragraph>
              {data.answer}
            </Text>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  )
}

export default QuestionAnswerItem
