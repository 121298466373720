import s from './FloatingMenu.module.sass'
import React, { FC, memo, useState } from 'react'

import { openBooking } from '@widgets/Restaurant/Booking'
import { useAppDispatch, useAppSelector } from '@app/model/store'

import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { Text } from '@shared/ui/Typography/Text'
import { EGG } from '@shared/api/analytics'
import { TypeMappingRestOutput } from '@shared/api/middleware/mappingAPI/restaurant/type'
import { getShortTextSchedule } from '@shared/lib/helpers/schedule/schedule'
import { useIntersection } from '@shared/lib/hooks/useIntersection'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { Button } from '@shared/ui/Actions/Button'
import { PromoBadgeCTA } from '@shared/ui/Feedback/PromoBadges/PromoBadgeCTA'
import { QuickBookingRestShortC } from '@widgets/Restaurant/QuickBooking/variants/QuickBookingRestShortC'
import { useNearestSlots } from '@widgets/Restaurant/QuickBooking/helpers/useNearestSlots'
import { QuickBookingRestShortB } from '@widgets/Restaurant/QuickBooking/variants/QuickBookingRestShortB'
import { useGetABTests } from '@shared/lib/hooks/useGetABTests'
import { QuickBookingRestShort } from '@widgets/Restaurant/QuickBooking/variants/QuickBookingRestShort'

interface Props {
  trackedRef: React.MutableRefObject<any>
  className?: string
}

const FloatingMenu: FC<Props> = ({ trackedRef, className = '' }) => {
  const [isFloatingMenuVisible, setIsFloatingMenuVisible] = useState(false)
  const { isMobile } = useClientResponsive()

  const stateRestaurant = useAppSelector((state) => state.pages.rest_item)
  const dispatch = useAppDispatch()
  const { id, name, booking, schedule, url, status, discounts, deposit } = stateRestaurant.data

  const whenOpens = getShortTextSchedule(stateRestaurant.data.schedule.week)
  const isShowBooking = [0].includes(status.code) && (booking.afisha.active || booking.tomesto.active)

  useIntersection(
    trackedRef,
    () => setIsFloatingMenuVisible(false),
    () => setIsFloatingMenuVisible(true),
  )

  const handleBooking = () => {
    if (booking.afisha.active) {
      const bookingAlert = booking.afisha.alert

      dispatch(openBooking(stateRestaurant.data as TypeMappingRestOutput))
    } else if (booking.tomesto.active) {
      window.open(booking.tomesto.url, '_blank')
    }

    EGG.entity.restaurant.click_booking(stateRestaurant.data as TypeMappingRestOutput, {
      section_name: 'page',
    })
  }

  let bookingInfoClass = null
  if (booking?.afisha?.active) {
    bookingInfoClass = 'booking-afisha'
  } else if (booking?.tomesto?.active) {
    bookingInfoClass = 'booking-tomesto'
  }

  const isVisibleQuickBooking = booking?.afisha?.active && !isMobile
  const titleBookingButton = isVisibleQuickBooking ? 'Забронировать' : 'Забронировать столик'

  // SLOTS
  const [paramsNearestSlots, setParamsNearestSlots] = useState({
    restData: stateRestaurant.data as TypeMappingRestOutput,
    guests: 2,
  })

  const slotsData = useNearestSlots(paramsNearestSlots)

  const ABTests = useGetABTests()
  const ABTestBooking = ABTests.find((test) => test.name === 'AB_OPEN_BOOKING_1')

  // AB enabled for group D
  if (ABTestBooking) {
    ABTestBooking.group = 'D'
  }

  const isABTestA = ABTestBooking?.group === 'A'
  const isABTestB = ABTestBooking?.group === 'B'
  const isABTestC = ABTestBooking?.group === 'C'
  const isABTestD = ABTestBooking?.group === 'D'

  // TITLE
  const getTitle = () => {
    if (!isVisibleQuickBooking || (isVisibleQuickBooking && !isABTestC)) return name
    if (!slotsData?.slots?.length) return `Столик в ${name}`
    const nearSlot = slotsData.slots[0]

    const nearSlotIsToday =
      new Date(nearSlot.day.value).toLocaleDateString() === new Date(Date.now()).toLocaleDateString()

    const tailDate = nearSlotIsToday ? `сегодня` : nearSlot.day.itemTextShort.toLowerCase()

    return `Cтолик${!nearSlotIsToday ? 'и' : ''} на ${tailDate} в ${name}`
  }

  return (
    <ErrorBoundary>
      <div
        className={`${s.sticky} ${className}`}
        data-visible={isFloatingMenuVisible}
        data-visible-quick-booking={isVisibleQuickBooking}>
        {isMobile ? (
          <div className={s['buttons-wrapper']}>
            <Button
              sizes={'XL'}
              mode={'black'}
              className={`${s['mobile-widget']} ${bookingInfoClass}`}
              onClick={() => handleBooking()}
              disabled={!isShowBooking}>
              Забронировать столик
            </Button>

            {discounts.remarked && (
              <PromoBadgeCTA sizes={'XL'} className={s['promo-badge']}>
                -{discounts.remarked}₽
              </PromoBadgeCTA>
            )}
          </div>
        ) : (
          <div className={s['desktop-widget']}>
            <div className={s['left-side']}>
              <Text tag={'span'} sizes={'L'}>
                {getTitle()}
              </Text>

              <Text tag={'span'} sizes={'M'} className={s['schedule-data']}>
                {whenOpens || 'Время работы не указано'}
              </Text>
            </div>

            <div className={s['buttons-wrapper']}>
              {(isABTestA || isABTestB || isABTestC || !ABTestBooking) && (
                <>
                  {/*!!!  Вариант со слотами !!! */}
                  {isVisibleQuickBooking && (
                    <QuickBookingRestShort slotsData={slotsData} className={s['quick-booking']} />
                  )}

                  {discounts.remarked && (
                    <PromoBadgeCTA sizes={'XL'} className={`${s['promo-badge']}`}>
                      -{discounts.remarked}₽
                    </PromoBadgeCTA>
                  )}

                  <button
                    className={`${s['button']} ${bookingInfoClass}`}
                    onClick={handleBooking}
                    disabled={!isShowBooking}
                    type='button'>
                    <Text sizes={'M'} tag={'span'}>
                      {titleBookingButton}
                    </Text>
                  </button>
                </>
              )}

              {/*!!! Вариант с селекторами !!!*/}
              {isABTestD && (
                <>
                  {isVisibleQuickBooking ? (
                    <QuickBookingRestShort slotsData={slotsData} className={s['quick-booking']} />
                  ) : (
                    <>
                      {discounts.remarked && (
                        <PromoBadgeCTA sizes={'XL'} className={`${s['promo-badge']}`}>
                          -{discounts.remarked}₽
                        </PromoBadgeCTA>
                      )}

                      <button
                        className={`${s['button']} ${bookingInfoClass}`}
                        onClick={handleBooking}
                        disabled={!isShowBooking}
                        type='button'>
                        <Text sizes={'M'} tag={'span'}>
                          {titleBookingButton}
                        </Text>
                      </button>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </ErrorBoundary>
  )
}

export default memo(FloatingMenu)
