import s from './StatisticItem.module.sass'

import React, { FC } from 'react'

import { Text } from '@shared/ui/Typography/Text'

interface Props {
  title: string
  value: number
  diff: number
}

const StatisticItem: FC<Props> = ({ title, value, diff }) => {
  return (
    <div className={s['stat-wrap']}>
      <Text sizes={'M'} className={s['stat-title']}>
        {title}
      </Text>
      <span className={s['stat-value-wrap']}>
        <Text sizes={'L'} className={s['stat-value-number']}>
          {value.toLocaleString()}
        </Text>
        {diff !== 0 && (
          <Text sizes={'M'} className={s['stat-value-percent']} data-is-up={diff > 0}>
            {diff > 0 && '+'}
            {diff}%
          </Text>
        )}
      </span>
    </div>
  )
}

export default StatisticItem
