import React, { FC } from 'react'

import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import ContentLoader from 'react-content-loader'
import {
  SKELETON_FOREGROUND_COLOR,
  SKELETON_MAIN_COLOR,
  SKELETON_SPEED,
  SKELETON_STYLES,
} from '@shared/consts/skeleton'

const Skeleton: FC = () => {
  return (
    <ErrorBoundary>
      <ContentLoader
        speed={SKELETON_SPEED}
        backgroundColor={SKELETON_MAIN_COLOR}
        foregroundColor={SKELETON_FOREGROUND_COLOR}
        style={SKELETON_STYLES}
        width={'847px'}
        height={'40px'}>
        <rect x='0' y='0' rx='24' ry='24' width='847px' height='40px' />
      </ContentLoader>
    </ErrorBoundary>
  )
}

export default Skeleton
