import { EGGAppHome } from '@shared/api/analytics/requests/pages/AppHome'
import { EGGListing } from '@shared/api/analytics/requests/pages/Listing'
import { EGGNewsGallery } from '@shared/api/analytics/requests/pages/NewsGallery'
import { EGGNewsItem } from '@shared/api/analytics/requests/pages/NewsItem'
import { EGGRestItem } from '@shared/api/analytics/requests/pages/RestItem'
import { EGGSelectionItem } from '@shared/api/analytics/requests/pages/SelectionItem'
import { EGGSelectionsGallery } from '@shared/api/analytics/requests/pages/SelectionsGallery'
import { EGGNews } from '@shared/api/analytics/requests/entity/news'
import { EGGSelection } from '@shared/api/analytics/requests/entity/selection'
import { EGGHeader } from '@shared/api/analytics/requests/header'
import { EGGCitySelect } from '@shared/api/analytics/requests/citySelect'
import { EGGAccountMenu } from '@shared/api/analytics/requests/accountMenu'
import { EGGFooter } from '@shared/api/analytics/requests/footer'
import { EGGCommon } from '@shared/api/analytics/requests/common'
import { EGGGlobal } from '@shared/api/analytics/requests/global'
import { EGGBooking } from '@shared/api/analytics/requests/entity/booking'
import { EGGPromoCard } from '@shared/api/analytics/requests/entity/promo_card'
import { EGGRestaurant } from '@shared/api/analytics/requests/entity/restaurant'
import { EGGReviews } from '@shared/api/analytics/requests/entity/reviews'

export const EGG = {
  pages: {
    AppHome: EGGAppHome,
    Listing: EGGListing,
    NewsGallery: EGGNewsGallery,
    NewsItem: EGGNewsItem,
    RestItem: EGGRestItem,
    SelectionItem: EGGSelectionItem,
    SelectionsGallery: EGGSelectionsGallery,
  },
  entity: {
    reviews: EGGReviews,
    restaurant: EGGRestaurant,
    news: EGGNews,
    selection: EGGSelection,
    booking: EGGBooking,
    promo_card: EGGPromoCard,
  },
  global: EGGGlobal,
  common: EGGCommon,
  header: EGGHeader,
  footer: EGGFooter,
  citySelect: EGGCitySelect,
  accountMenu: EGGAccountMenu,
}
