import React from 'react'

import { PopupNotice } from '@shared/ui/Overlays/PopupNotice'
import { removeNotice } from '../../model/reduce'
import s from './Notices.module.sass'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { useAppDispatch, useAppSelector } from '@app/model/store'
import { FloatingPortal } from '@floating-ui/react'
import { ErrorBoundary } from '../../../../../6_shared/lib/components/ErrorBoundary'

const Notices = () => {
  const { isMobile } = useClientResponsive()
  const dispatch = useAppDispatch()
  const notices = useAppSelector((state) => state.notices.notices)

  const closeNotice = (id) => dispatch(removeNotice(id))

  return (
    <ErrorBoundary>
      <FloatingPortal>
        <div
          className={`${s.notices} notices--${isMobile ? 'mobile' : 'desktop'}`}
          onClick={(e) => e.stopPropagation()}>
          {notices.map((el) => {
            return <PopupNotice key={el.id} text={el.text} id={el.id} handleClose={closeNotice} />
          })}
        </div>
      </FloatingPortal>
    </ErrorBoundary>
  )
}
export default Notices
