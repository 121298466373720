import { Dispatch } from 'react'
import { AnyAction } from '@reduxjs/toolkit'
import {
  getRestaurantsByFilters,
  loadCatalogTags,
  updateOutput,
} from '@features/Restaurant/RestaurantFilters/model/createAsyncThunk'
import { globalStore } from '@app/model/store'
import {
  applyPrecheckedListFilters,
  setReady,
  initCheckedFiltersItems,
  setVisible,
  setIsPreloaderResults,
} from '@features/Restaurant/RestaurantFilters/model/reducer'

const isCatalogFiltersLoaded = () => {
  const stateFilters = globalStore.getState().features.restaurant_filters.filters

  // @ts-ignore
  return Object.values(stateFilters).some((el) => !!el.list?.length)
}

export const initRestaurantFilters =
  (data: { tags?: string[]; preloading: boolean }) => async (dispatch: Dispatch<AnyAction>) => {
    if (!isCatalogFiltersLoaded()) {
      // @ts-ignore
      await dispatch(loadCatalogTags())
    }
    dispatch(setIsPreloaderResults(data.preloading))
    dispatch(initCheckedFiltersItems(data.tags || []))
    dispatch(setVisible(true))
    // @ts-ignore
    dispatch(getRestaurantsByFilters()).finally(() => {
      // @ts-ignore
      dispatch(updateOutput()).finally(() => {
        dispatch(setReady(true))
      })
    })
  }

export const applyFilters = () => async (dispatch: Dispatch<AnyAction>) => {
  dispatch(applyPrecheckedListFilters())
  // @ts-ignore
  await dispatch(updateOutput())
}
