import React, { FC, ReactNode, useContext } from 'react'

import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { CardTwoBlockSkeletonContext } from '@shared/ui/Layout/CardTwoBlock/Context'
import s from '@shared/ui/Layout/CardTwoBlock/ui/Img/Img.module.sass'

interface Props {
  children?: ReactNode
  className?: string
}

const Skeleton: FC<Props> = ({ children, className = '' }) => {
  const [context, setContext] = useContext(CardTwoBlockSkeletonContext)

  return (
    <ErrorBoundary>
      <rect x='0' y='0' rx='16' ry='16' className={`${s.block} ${className}`} data-size={context.size} />
    </ErrorBoundary>
  )
}

export default Skeleton
