import React, { FC } from 'react'

import s from './RestaurantQuickFilter.module.sass'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { FilterItemSkeleton } from '@widgets/Restaurant/RestaurantQuickFilter/ui/FilterItem'

interface Props {
  className?: string
}

const Skeleton: FC<Props> = ({ className = '' }) => {
  const arraySkeleton = Array.from(Array(8).keys())

  return (
    <ErrorBoundary>
      <div className={`${s['wrapper-skeleton']} ${className}`}>
        {arraySkeleton.map((el, index) => (
          <FilterItemSkeleton key={index} />
        ))}
      </div>
    </ErrorBoundary>
  )
}

export default Skeleton
