// COOKIE

// COOKIE_CITY_ID - только для корректных запросов к беку.
// Формат city_id: number
export const COOKIE_CITY_ID = 'city_id'

// COOKIE_CITY_USER - город который пользователь выбрал своим через попап "Ваш город ... ?" или о дефолтный город (msk) если пользователь закрыл попап.
// Формат city_user: 'city_id' + '-' + 'city_slug', Например: '2-msk'
export const COOKIE_CITY_USER = 'city_user'

export const COOKIE_SHOW_LOG_ANALYTIC = 'log_analytic'

// SESSION STORAGE

// COOKIE_CITY_USER - текущий город сессии
// Формат city_user: 'city_id' + '-' + 'city_slug', Например: '2-msk'
export const SESSION_STORAGE_CITY_CURRENT = 'city_current'
