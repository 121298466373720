import React, { FC } from 'react'
import s from './RestaurantSpecialOfferPopup.module.sass'
import { Text } from '@shared/ui/Typography/Text'
import Image from 'next/image'
import { getPlaceholderImage } from '@shared/lib/helpers/getPlaceholderImage'
import { Modal } from '@shared/ui/Overlays/Modal'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'

interface Props {
  title: string
  description: string
  img: string
  type_and_date: string
  handleClose: () => void
}

const RestaurantSpecialOfferPopup: FC<Props> = ({ title, description, img, type_and_date, handleClose }) => {
  const { isMobile } = useClientResponsive()

  return (
    <Modal
      size={'S'}
      mobileMode={'dropdown-image'}
      dropdownImage={img}
      title={title}
      subtitle={
        <Text sizes={'M'} className={s['modal-subtitle']}>
          {type_and_date}
        </Text>
      }
      titleSizes={'h2 h3'}
      handleClose={handleClose}>
      <div className={s.modal}>
        <div>
          {!isMobile && (
            <Image
              src={img || getPlaceholderImage()}
              sizes='100vw'
              style={{
                width: '100%',
                height: 'auto',
              }}
              width={500}
              height={300}
              alt='rest-special'
              className={s['modal-photo']}
            />
          )}
        </div>

        <Text sizes={'ML M M M'} className={s['modal-description']} paragraph>
          {description}
        </Text>
      </div>
    </Modal>
  )
}

export default RestaurantSpecialOfferPopup
