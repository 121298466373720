import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TypeInitialState } from '@widgets/Common/ModalFoundMistake/model/types'

const initialState: TypeInitialState = {
  opened: false,
  loading: false,
  prefilled_data: {
    full_name: null,
    email: null,
  },
}

const foundMistakeReduce = createSlice({
  name: 'foundMistake',
  initialState,
  reducers: {
    setOpened: (state, action: PayloadAction<boolean>) => {
      state.opened = action.payload
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setPrefilledData: (
      state,
      action: PayloadAction<{
        full_name?: string
        email?: string
      }>,
    ) => {
      state.prefilled_data = {
        ...state.prefilled_data,
        ...action.payload,
      }
    },
    setInitState: (state) => {
      return initialState
    },
  },
})

export const { setOpened, setLoading, setPrefilledData, setInitState } = foundMistakeReduce.actions

export default foundMistakeReduce.reducer
