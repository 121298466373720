const NEXT_PUBLIC_RESIZER_NODES =
  'img.rl0.ru, img01.rl0.ru, img02.rl0.ru, img03.rl0.ru, img04.rl0.ru, img05.rl0.ru, img06.rl0.ru, img07.rl0.ru, img08.rl0.ru, img09.rl0.ru'
const NEXT_PUBLIC_RESIZER_RESOURCE = 'afisha'
const NEXT_PUBLIC_MEDIA_ROOT = 'daily.afisha.ru'

/**
 * @function getResizedLink - Генерация URL для измененного изображения, с указанными параметрами размера
 */
export const getResizedLink = (
  { photoModification, width = '-', height = '-', resizerOptions, isExternal },
  removeSize?: boolean,
): string => {
  if (!photoModification) {
    return ''
  }

  const { photoPath, crop } =
    typeof photoModification === 'string' ? { photoPath: photoModification, crop: null } : photoModification

  if (!photoPath) {
    return ''
  }

  let size = `e${width}x${height}`

  if (hasGifExtension(photoPath) || removeSize) {
    size = '-x-'
  }

  const optionsString = getResizerOptionsString(resizerOptions)
  const urlWithoutHttp = photoPath.replace(/^https?:\/\//, '')
  const node = getNodeByUrl(urlWithoutHttp)
  const cropString = crop ? getCropString(crop) : ''
  const publicSitePath = isExternal ? '' : `${NEXT_PUBLIC_MEDIA_ROOT}/uploads/`

  return `https://${node}/${NEXT_PUBLIC_RESIZER_RESOURCE}/${size}${cropString}${optionsString}/${publicSitePath}${urlWithoutHttp}`
}

/**
 * @function getResizerOptionsString - Создание строки параметров ресайзера
 */
const getResizerOptionsString = (
  resizerOptions: { progressiveJpeg?: boolean; quality?: string; watermark?: { num: string; position: string } } = {
    progressiveJpeg: true,
  },
): string => {
  let optionsString = ''

  if (resizerOptions.quality) {
    optionsString += `q${resizerOptions.quality}`
  }

  if (resizerOptions.progressiveJpeg) {
    optionsString += 'i'
  }

  if (resizerOptions.watermark) {
    optionsString += `w${resizerOptions.watermark.num}${resizerOptions.watermark.position}`
  }

  return optionsString
}

/**
 * @function getNodeByUrl - Выборка ноды из списка для URL
 */
export const getNodeByUrl = (url: string) => {
  const nodes_array = NEXT_PUBLIC_RESIZER_NODES?.split(', ') || []
  const indexNode = Math.abs(getHashCode(url) % nodes_array.length)
  return nodes_array[indexNode]
}

/**
 * @function getHashCode - Хэширование строки URL
 */
const getHashCode = (url: string) => {
  let hash = 0

  url.split('').forEach((_, index) => {
    hash = (hash << 5) - hash + url.charCodeAt(index)
    hash |= 0
  })

  return hash
}

/**
 * @function getCropString - Генерация параметров кадрирования
 */
const getCropString = (crop): string => {
  const { offset, size } = crop
  const { x, y } = offset || {}
  const { width, height } = size || {}

  if ((x || x === 0) && (y || y === 0) && width && height) {
    return `p${x}x${y}f${width}x${height}`
  } else {
    return ''
  }
}

/**
 * @function hasGifExtension - GIF-валидация расширения файла
 */
export const hasGifExtension = (path: string): boolean => {
  const ext = path.split('.')[1]
  return ext === 'gif'
}

/**
 * @function getWebDavLink - Генерация WebDAV-ссылки ресайзера
 */
export const getWebDavLink = (imageUrl: string, width: number, height: number) => {
  const urlWithoutHttp = imageUrl.replace(/^https?:\/\//, '')
  const publicSitePath = `${NEXT_PUBLIC_MEDIA_ROOT}/uploads/`
  const node = getNodeByUrl(urlWithoutHttp)
  return `https://${node}/${NEXT_PUBLIC_RESIZER_RESOURCE}/e${width}x${height}i/${publicSitePath}${urlWithoutHttp}`
}

/**
 * @function getDailyUploadsCssLink - Создание ссылки на стили
 */
export const getDailyUploadsCssLink = (url: string) => {
  return `https://daily.afisha.ru/uploads/${url}`
}
