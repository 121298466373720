import { useEffect } from 'react'

// Для аналитики
// Хук срабатывает один раз, когда элемент полностью попадает в viewport.
export const useIntersectionAnalytic = (ref, callback, options?: object) => {
  const optionsObserver = {
    root: null,
    rootMargin: '5px',
    threshold: 0.9,
    ...options,
  }

  useEffect(() => {
    const target = ref.current

    const callbackObserver = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // TODO: раскомментировать callback для проверки аналитики и на проде
          observer.unobserve(target)
          callback()
        }
      })
    }

    const observer = new IntersectionObserver(callbackObserver, optionsObserver)

    observer.observe(target)

    return () => observer.unobserve(target)
  }, [])
}
