import s from './RestConcerts.module.sass'
import { TAG_CALENDAR, TAG_CALENDER_DAYS_AVAILABLE, TAG_PAYMENTS_LIST } from './gqlTags'
import { mappingPaymentItem, generateCalendarDates, generateRequestDate, filterDaysToSelect } from './helpers'

import { useLazyQuery } from '@apollo/client'
import React, { FC, useState } from 'react'

import { ButtonLoadMore } from '@shared/ui/Actions/ButtonLoadMore'
import { Carousel } from '@shared/ui/Layout/Carousel'
import { ContainerLongLeft } from '@shared/ui/Layout/Containers/ContainerLongLeft'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { Title } from '@shared/ui/Typography/Title'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { useEffectOnce } from '@shared/lib/hooks/useEffectOnce'
import CarouselSlider from '@shared/ui/Layout/Carousel/CarouselSlider'
import { EventCard } from '@widgets/Restaurant/RestConcerts/ui/EventCard'
import { CalendarSlider } from '@widgets/Restaurant/RestConcerts/ui/CalendarSlider'

interface Props {
  restId: number | string
}

const NOW_DATE = new Date().getTime()
const TODAY_STARTING_DATE = new Date(NOW_DATE - (NOW_DATE % 86400000))
const DEFAULT_DAYS_LOAD_INTERVAL = 365

/**
 * @function RestConcerts — компонент с событиями Афиши в ресторанах
 * @todo обновить работу с apollo/client, для получения данных availableDays
 */
const RestConcerts: FC<Props> = ({ restId }) => {
  const { isDesktop, isMobile, isTablet } = useClientResponsive()

  // TODO: need pagination for desktop; offset 8 -> default by design
  const cardsPerPage = isMobile ? 99 : 24
  const skeletonsTpl = !isTablet ? [0, 1, 2, 3] : [0, 1, 3]

  const [isSomePaymentExist, setIsSomePaymentExist] = useState(false)
  const [selectedDay, setSelectedDay] = useState(null)
  const [paymentItems, setPaymentItems] = useState(null)

  const [availableDays, setAvailableDays] = useState(null)
  const [calendarState, setCalendarState] = useState(null)

  const dateRangeFrom = generateRequestDate(selectedDay)
  const dateRangeTo = selectedDay
    ? generateRequestDate(TODAY_STARTING_DATE, selectedDay)
    : generateRequestDate(
        TODAY_STARTING_DATE,
        new Date().setDate(new Date(TODAY_STARTING_DATE).getDate() + DEFAULT_DAYS_LOAD_INTERVAL),
      )

  const calendarDatesArr = filterDaysToSelect(
    generateCalendarDates(TODAY_STARTING_DATE, DEFAULT_DAYS_LOAD_INTERVAL),
    availableDays,
  )

  const [cardsLoadOffset, setCardsLoadOffset] = useState(0)
  const [allTotalCards, setAllTotalCards] = useState(0)
  const [loadedCardsCount, setLoadedCardsCount] = useState(0)
  const [isShowMoreLoading, setIsShowMoreLoading] = useState(false)
  const needToShowMore = allTotalCards > loadedCardsCount

  // * единичный запрос информации о наличии расписания в данном ресторане, для отображения календаря *
  const [getScheduleGraphReq] = useLazyQuery(TAG_CALENDAR, {
    variables: {
      offset: 0,
      first: 99,
      query: `dateRange=${dateRangeFrom}--${dateRangeTo}`,
      restId: `Restaurant_${restId}`,
    },
    onCompleted(resData) {
      setIsSomePaymentExist(resData?.place?.scheduleMeta?.any)
    },
    onError({ message }) {
      console.error(message)
    },
  })

  // * единичный запрос на деактивацию ненужных дат в календаре *
  const [getCalendarInfoGraphReq] = useLazyQuery(TAG_CALENDER_DAYS_AVAILABLE, {
    variables: {
      offset: 0,
      first: 99,
      query: `dateRange=${dateRangeFrom}--${dateRangeTo}`,
      restId: `Restaurant_${restId}`,
    },
    onCompleted(resData) {
      if (!selectedDay) {
        const resItems =
          resData?.place?.scheduleConcerts?.items?.length > 0 ? resData.place.scheduleConcerts.items : null

        // TODO: get flattenUniqItems from resData / filters
        const flattenUniqItems =
          resItems?.length > 0
            ? resItems
                .reduce((acc, item) => [...acc, ...item.sessions], [])
                .map((item) => item?.dateTime?.split('T')[0])
                .reduce((acc, cur) => (acc.indexOf(cur) === -1 ? acc.concat(cur) : acc), [])
            : null

        if (flattenUniqItems?.length > 0) {
          setAvailableDays(flattenUniqItems)
        } else {
          setCalendarState('error')
        }
      }
    },
    onError({ message }) {
      console.error(message)
    },
  })

  // * запрос событий по ресторану на конкретные дни с информацией о концерте и разных сессиях *
  const [getPaymentGraphReq, { loading: paymentItemsLoading }] = useLazyQuery(TAG_PAYMENTS_LIST, {
    fetchPolicy: 'network-only',
    variables: {
      offset: cardsLoadOffset,
      first: cardsPerPage,
      query: `dateRange=${dateRangeFrom}--${dateRangeTo}`,
      restId: `Restaurant_${restId}`,
    },
    onCompleted(resData) {
      setIsShowMoreLoading(false)
      setLoadedCardsCount(cardsLoadOffset + cardsPerPage)
      const hasResult = resData?.place?.scheduleConcerts?.items?.length > 0
      const resultItems = hasResult ? mappingPaymentItem(resData.place.scheduleConcerts.items) : null
      const newPaymentItems = paymentItems ? [...paymentItems, ...resultItems] : resultItems
      setPaymentItems(newPaymentItems)
      setAllTotalCards(resData?.place?.scheduleConcerts?.totalCount || 0)
    },
    onError({ message }) {
      console.error(message)
    },
  })

  useEffectOnce(() => {
    getScheduleGraphReq()
    getCalendarInfoGraphReq()
    getPaymentsCallback()
  })

  const hasPaymentItems = paymentItems && paymentItems.length > 0

  const IS_ENABLED_ARROWS = true
  const hasButtonPrev = IS_ENABLED_ARROWS && !isMobile
  const hasButtonNext = IS_ENABLED_ARROWS && !isMobile
  const hasOverlayPrev = IS_ENABLED_ARROWS && !isMobile
  const hasOverlayNext = true

  const handleDaySelection = (thisDay) => {
    if (!paymentItemsLoading) {
      setPaymentItems(null)
      setSelectedDay(thisDay.value)
      setCardsLoadOffset(0)
      getPaymentsCallback()
    }
  }

  const handleDeselect = () => {
    if (!paymentItemsLoading) {
      setPaymentItems(null)
      setSelectedDay(null)
      setCardsLoadOffset(0)
      getPaymentsCallback()
    }
  }

  const getPaymentsCallback = () => {
    const needToMakeRequest = !paymentItemsLoading
    if (needToMakeRequest) getPaymentGraphReq()
  }

  const handleShowMore = (ev) => {
    setIsShowMoreLoading(true)
    setCardsLoadOffset(cardsLoadOffset + cardsPerPage)
  }

  return (
    <ErrorBoundary>
      {isSomePaymentExist && calendarState !== 'error' && (
        <section className={s['wrapper']}>
          <ContainerLongLeft>
            <Title tag='h2' sizes='h2 h2 h2 h4' className={s['title']}>
              Афиша событий
            </Title>

            <CalendarSlider
              hasButtonPrev={hasButtonPrev}
              hasButtonNext={hasButtonNext}
              hasOverlayPrev={hasOverlayPrev}
              hasOverlayNext={hasOverlayNext}
              daySelect={selectedDay}
              days={calendarDatesArr}
              handleDaySelection={handleDaySelection}
              handleDeselect={handleDeselect}
            />
          </ContainerLongLeft>

          <ContainerLongLeft>
            {hasPaymentItems ? (
              <>
                <Carousel>
                  <CarouselSlider
                    className={`${s['payment-list']}`}
                    spacingMobile={8}
                    spacingDesktop={0}
                    // @ts-ignore
                    countSlidesOnContainer={cardsPerPage}
                    disabled={!isMobile}>
                    {paymentItems.map((thisItem, index) => (
                      <EventCard
                        key={thisItem?.input?.concert?.id}
                        data={thisItem}
                        analytic={{ section_index: index, object_id: thisItem.id }}
                      />
                    ))}
                  </CarouselSlider>
                </Carousel>

                {!isMobile && needToShowMore && (
                  <div className={s['payment-show-more-wrap']}>
                    {/*TODO: Заменить на компонент Pagination*/}
                    <ButtonLoadMore
                      // @ts-ignore
                      onClick={(ev) => handleShowMore(ev)}
                      className={s['payment-show-more-button']}
                      loading={isShowMoreLoading}
                      // @ts-ignore
                      nextPage={needToShowMore}
                      analytic={{ section_name: 'События/концерты в ресторане' }}
                    />
                  </div>
                )}
              </>
            ) : (
              <ul className={`${s['payment-list']} ${s['payment-list--skeleton']}`}>
                {skeletonsTpl.map((skeleton, index) => {
                  return (
                    <li className={s['payment-item']} key={index}>
                      <div className={s['payment-item-wrap']}>
                        <div className={s['payment-item-picture']} style={{ width: '297px', height: '198px' }} />
                      </div>
                      <h3 className={s['payment-name']}>name</h3>
                      <span className={s['payment-genre']}>type</span>
                      <span className={s['payment-time-descr']}>timeDescr</span>
                    </li>
                  )
                })}
              </ul>
            )}
          </ContainerLongLeft>
        </section>
      )}
    </ErrorBoundary>
  )
}

export default RestConcerts
