import { undefinedObjectToNull } from '@shared/api/middleware/mappingAPI/helpers'
import { TypeRemarkedSlotBackend } from '@shared/api/middleware/mappingAPI/remarkedSlot/types'
import { TypeExtendedScheduleTimeSlot } from '@shared/types/schedule/types'

export const mappingRemarkedSlot = (object: TypeRemarkedSlotBackend): TypeExtendedScheduleTimeSlot => {
  try {
    const dateSlotStart = new Date(object.start_stamp * 1000)
    const localeSlotArr = dateSlotStart.toLocaleTimeString().split(':')
    const value = localeSlotArr[0] + ':' + localeSlotArr[1]
    const date = dateSlotStart.toJSON()

    return undefinedObjectToNull({
      value: value,
      date: date,
    })
  } catch (error) {
    console.error(error)
    return null
  }
}
