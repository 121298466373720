import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { globalState, TypePromoItem } from './types'

const initialState: globalState = {
  list: null,
  loading: true,
}

const promo = createSlice({
  name: 'promo',
  initialState,
  reducers: {
    setPromoList(state, action: PayloadAction<TypePromoItem[]>) {
      state.list = action.payload
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
  },
})

export const { setPromoList, setLoading } = promo.actions

export default promo.reducer
