import { sendClickHouseAnalytic } from '@shared/api/analytics/template'
import { globalStore } from '@app/model/store'
import { PickAnalytic } from '@shared/api/analytics/types/types'

export const EGGCitySelect = {
  button_right_city_click: () =>
    sendClickHouseAnalytic({
      section_name: 'city_selection',
      event_name: 'element_click',
      element_type: 'right',
      city_name: globalStore.getState().global.currentCity.name,
    }),

  button_city_another_click: (props: PickAnalytic<'city_name'>) =>
    sendClickHouseAnalytic({
      section_name: 'city_selection',
      event_name: 'element_click',
      element_type: 'city',
      ...props,
    }),

  show_cities_list_view: () =>
    sendClickHouseAnalytic({
      section_name: 'city_selection',
      event_name: 'element_view',
      element_type: 'search_result',
    }),

  button_popup_close_click: () =>
    sendClickHouseAnalytic({ section_name: 'city_selection', event_name: 'element_click', element_type: 'close' }),

  event_cities_not_found_view: () =>
    sendClickHouseAnalytic({ section_name: 'city_selection', event_name: 'element_view', element_type: 'not_found' }),
}
