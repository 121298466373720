import React, { FC } from 'react'

import { MediaCardCoverSkeleton } from '@shared/ui/Layout/Cards/MediaCardCover'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'

interface Props {
  className?: string
}

const Skeleton: FC<Props> = (props) => {
  return (
    <ErrorBoundary>
      <MediaCardCoverSkeleton {...props} />
    </ErrorBoundary>
  )
}

export default Skeleton
