import { API } from '@shared/api'
import { CityList, CityRetrieve } from '@shared/api/types/swaggerTypes'

type TypeGlobalProps = {
  city_id?: number
  city_slug?: string
  current_page: string
}

export type TypeGlobalOutput = {
  user: null
  this_city: {
    name: string | null // 'Москва'
    genitive_case: string | null // 'Москвы'
    highlight: boolean | null // true
    id: number | null // 2
    slug: string | null // 'msk'
  }
  city_read: CityRetrieve | null
  city_list: CityList[] | null
  topline: string | null
  current_page: string
}

/**
 * @function getGlobalProps - hi all bros
 * @param {string} - hello world
 */
const getGlobalProps = async ({ city_slug, city_id, current_page }: TypeGlobalProps): Promise<TypeGlobalOutput> => {
  const city_list = await API.city_list({ cache: true })

  const targetCity = city_list?.results
    ? city_list.results.find((city) => {
        if (city_slug) return city.slug === city_slug
        else if (city_id) return city.id === city_id
      })
    : null

  const requestedCity = targetCity ? targetCity.slug : 'msk'

  const city_read = await API.city_read({ path: requestedCity })

  return {
    user: null,
    this_city: {
      name: city_read?.name || null, // 'Москва'
      genitive_case: city_read?.genitive_case || null, // 'Москвы'
      highlight: city_read?.highlight || null, // true
      id: city_read?.id || null, // 2
      slug: requestedCity || null, // 'msk'
    },
    city_read: city_read || null,
    city_list: city_list?.results || null,
    topline: city_read?.topline || null,
    current_page: current_page,
  }
}

export default getGlobalProps
