import React, { FC } from 'react'
import s from './Input.module.sass'
import { FieldInput } from '@shared/types/fields/fields'
import { getErrorData } from '@shared/lib/fields/getErrorData'
import { ErrorBoundary } from '../../../../lib/components/ErrorBoundary'

interface Props {
  // eslint-disable-next-line
  handleValue: (value: string) => void
  field: FieldInput
  className?: string
}
const Input: FC<Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLInputElement>, HTMLInputElement>> = ({
  handleValue,
  field,
  className = '',
  ...props
}) => {
  const errorData = getErrorData(field)
  const handleValueInner = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value
    // @ts-ignore
    const maskValue = field?.settings?.mask ? field?.settings.mask(value, field) : value
    if (handleValue) handleValue(maskValue)
  }

  const placeholder = field?.settings?.placeholder ? field.settings.placeholder : ''

  return (
    <ErrorBoundary>
      <input
        className={`${s.input} ${errorData.class} ${className}`}
        id={field?.name}
        type={field?.type}
        name={field?.name}
        value={field?.value}
        placeholder={placeholder}
        disabled={field?.disabled}
        required={field?.required}
        onChange={handleValueInner}
        // for tests in stories
        data-testid={field?.name}
        {...props}
      />
    </ErrorBoundary>
  )
}
export default Input
