import React, { FC } from 'react'
import s from './QuickBookingSlots.module.sass'

import { TypeMappingRestOutput } from '@shared/api/middleware/mappingAPI/restaurant/type'
import {
  QuickBookingSlot,
  QuickBookingSlotSkeleton,
} from '@widgets/Restaurant/QuickBooking/components/QuickBookingSlots/ui/QuickBookingSlot'
import {
  QuickBookingDots,
  QuickBookingDotsSkeleton,
} from '@widgets/Restaurant/QuickBooking/components/QuickBookingSlots/ui/QuickBookingDots'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { Text } from '@shared/ui/Typography/Text'
import { useAppDispatch } from '@app/model/store'
import { openBooking } from '@widgets/Restaurant/Booking'
import { TypeDateTimeSlot } from '@shared/types/schedule/types'

interface Props {
  restData: TypeMappingRestOutput
  slotsData: {
    slots: TypeDateTimeSlot[]
    loading: boolean
    error: boolean
  }
  classNameWrapper?: string
  classNameSlots?: string
  classNameSlot?: string
  classNameDots?: string
  onClickSlot?: (data: TypeMappingRestOutput) => void
  onClickDots?: (data: TypeMappingRestOutput) => void
}

const QuickBookingSlots: FC<Props> = ({
  restData,
  slotsData,
  classNameWrapper = '',
  classNameSlots = '',
  classNameSlot = '',
  classNameDots = '',
  onClickSlot,
  onClickDots,
}) => {
  const dispatch = useAppDispatch()

  if ((!slotsData?.loading && !slotsData?.slots?.length) || slotsData?.error) {
    return (
      <ErrorBoundary>
        <Text sizes={'M'} className={s['slot-error']}>
          Не найдено время для бронирования
        </Text>
      </ErrorBoundary>
    )
  }

  const handleClickSlot = (data) => {
    dispatch(openBooking(restData, { time: data.time, date: data.day }))
    onClickSlot && onClickSlot(restData)
  }

  const handleClickDots = () => {
    dispatch(openBooking(restData))
    onClickDots && onClickDots(restData)
  }

  return (
    <ErrorBoundary>
      <div className={`${s.wrapper} ${classNameWrapper}`}>
        <div className={`${s.slots} ${classNameSlots}`}>
          {slotsData?.loading
            ? Array.from(Array(8).keys()).map((el, index) => (
                <QuickBookingSlotSkeleton className={classNameSlot} key={index} />
              ))
            : slotsData?.slots.map((el, index) => (
                <QuickBookingSlot onClick={handleClickSlot} dataTime={el} key={index} className={classNameSlot} />
              ))}
        </div>
        {slotsData?.loading ? (
          <QuickBookingDotsSkeleton className={classNameDots} />
        ) : (
          <QuickBookingDots onClick={handleClickDots} className={classNameDots} />
        )}
      </div>
    </ErrorBoundary>
  )
}

export default QuickBookingSlots
