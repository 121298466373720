import * as React from 'react'
import { FC } from 'react'
import { PopupOptions, usePopup } from '@shared/ui/Overlays/Popup/hooks'
import { PopupContext } from '@shared/ui/Overlays/Popup/context'

interface Props extends PopupOptions {
  children: React.ReactNode
  closeClickOutside?: boolean
}

// о modal параметре - https://floating-ui.com/docs/FloatingFocusManager
const Popup: FC<Props> = ({
  children,
  modal = false,
  arrow = false,
  offset,
  closeClickOutside,
  triggerRef,
  ...restOptions
}) => {
  // This can accept any props as options, e.g. `placement`,
  // or other positioning options.
  const popover = usePopup({ modal, arrow, triggerRef, closeClickOutside, offset, ...restOptions })
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <PopupContext.Provider value={popover}>{children}</PopupContext.Provider>
    </div>
  )
}

export default Popup
