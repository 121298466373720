import React, { FC, SVGProps, forwardRef } from 'react'
import s from './IconButton.module.sass'
import { useCurrentSize } from '@shared/lib/helpers/classes'
import { ErrorBoundary } from '../../../lib/components/ErrorBoundary'

interface Props {
  sizes: string
  mode: 'white' | 'white-border' | 'white-shadow' | 'black' | 'transparent'
  icon: FC<SVGProps<SVGSVGElement>>
  active?: boolean
  className?: string
}

const IconButton = forwardRef<HTMLButtonElement, React.ButtonHTMLAttributes<HTMLButtonElement> & Props>(
  ({ sizes, mode, active = false, className = '', icon, ...props }, ref) => {
    const [size] = useCurrentSize(sizes)
    const IconElem = icon

    const activeAttr = active ? { 'data-active': true } : {}

    return (
      <ErrorBoundary>
        <button
          ref={ref}
          className={`${s.button} ${className}`}
          data-size={size}
          data-mode={mode}
          {...activeAttr}
          {...props}>
          <IconElem className={s.icon} />
        </button>
      </ErrorBoundary>
    )
  },
)

export default IconButton
