import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  TypeFilterDate,
  TypeFilterType,
  TypeInitialState,
  TypeLoading,
  TypeParamsRequest,
  TypeRestaurant,
} from '@pages/OwnerPersonalPage/model/OwnerReviewsModel/types'
import {
  TypeMappingPaginationReviewOutput,
  TypeMappingReviewOutput,
} from '@shared/api/middleware/mappingAPI/review/type'

const initialState: TypeInitialState = {
  is_ready: false,
  loading: {
    full: true,
    addition: false,
  },
  restaurant: {
    id: null,
    name: null,
  },
  pagination: null,
  filters: {
    types: [
      {
        id: 1,
        type: 'all',
        title: 'Все',
        count: null,
        visible: true,
        select: true,
        paramsRequest: {
          is_positive_like: null,
          owner_comment_created_isnull: null,
        },
      },
      {
        id: 2,
        type: 'unanswered',
        title: 'Без ответа',
        count: null,
        visible: true,
        select: false,
        paramsRequest: {
          is_positive_like: null,
          owner_comment_created_isnull: true,
        },
      },
      {
        id: 3,
        type: 'positive',
        title: 'Положительные',
        count: null,
        visible: true,
        select: false,
        paramsRequest: {
          is_positive_like: true,
          owner_comment_created_isnull: null,
        },
      },
      {
        id: 4,
        type: 'negative',
        title: 'Отрицательные',
        count: null,
        visible: true,
        select: false,
        paramsRequest: {
          is_positive_like: false,
          owner_comment_created_isnull: null,
        },
      },
    ],
  },

  paramsRequest: {},
  reviewsByFilters: [],
  components: {
    create: {
      id_review: null,
      loading: false,
      body: '',
    },
    delete: {
      id_review: null,
      loading: false,
    },
    edit: {
      id_review: null,
      loading: false,
      body: '',
    },
  },
}

const OwnerReviews = createSlice({
  name: 'OwnerReviews',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<TypeLoading>) {
      state.loading = action.payload
    },

    // Виджет готов к отображению данных
    setReadyWidget(state, action: PayloadAction<boolean>) {
      state.is_ready = action.payload
    },

    setRestaurant(state, action: PayloadAction<TypeRestaurant>) {
      state.restaurant = action.payload
    },

    setPagination(state, action: PayloadAction<TypeMappingPaginationReviewOutput>) {
      state.pagination = action.payload
    },

    setPaginationItems(state, action: PayloadAction<number | null>) {
      state.pagination.total = action.payload
    },

    setPaginationPage(state, action: PayloadAction<TypeMappingPaginationReviewOutput>) {
      state.pagination.page = action.payload.page
    },

    setParamsRequest(state, action: PayloadAction<TypeParamsRequest | null>) {
      state.paramsRequest = action.payload ? action.payload : {}
    },

    setListReviewsAll(state, action: PayloadAction<TypeMappingReviewOutput[]>) {
      state.reviewsByFilters = action.payload
    },

    addListReviewsAll(state, action: PayloadAction<TypeMappingReviewOutput[]>) {
      state.reviewsByFilters = [...state.reviewsByFilters, ...action.payload]
    },

    initFilterRates(
      state,
      action: PayloadAction<{
        all?: number | null
        positive?: number | null
        negative?: number | null
        unanswered?: number | null
      }>,
    ) {
      state.filters.types = state.filters.types.map((filter) => ({
        ...filter,
        select: filter.type === 'all',
        count: action.payload[filter.type] || null,
        visible: !!action.payload[filter.type],
      }))
    },

    changeFilter(state, action: PayloadAction<{ type: 'types' | 'dates'; data: TypeFilterType | TypeFilterDate }>) {
      const { type, data } = action.payload
      state.filters[type] = state.filters[type].map((filter) => ({ ...filter, select: data.id === filter.id }))
    },

    changeComponentCreate(state, action: PayloadAction<{ body?: string | null; loading?: boolean }>) {
      state.components.create = { ...state.components.create, ...action.payload }
    },

    changeComponentEdit(state, action: PayloadAction<{ body?: string | null; loading?: boolean }>) {
      state.components.edit = { ...state.components.edit, ...action.payload }
    },

    changeComponentDelete(state, action: PayloadAction<{ loading?: boolean }>) {
      state.components.delete = { ...state.components.delete, ...action.payload }
    },

    // RESET
    resetStateReviews() {
      return initialState
    },
  },
})

export const {
  setReadyWidget,
  setLoading,
  setRestaurant,
  setPagination,
  setPaginationItems,
  setPaginationPage,
  setParamsRequest,
  setListReviewsAll,
  addListReviewsAll,

  // filters
  initFilterRates,
  changeFilter,

  // components
  changeComponentCreate,
  changeComponentEdit,
  changeComponentDelete,

  // reset
  resetStateReviews,
} = OwnerReviews.actions

export default OwnerReviews.reducer
