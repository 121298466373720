import { getDefaultField } from '@shared/lib/fields/getDefaultField'

export const getField = (nameField, data = null) => {
  const isMobile = data.designEnv === 'mobile'

  const FIELDS = {
    kitchen_type: getDefaultField('select', {
      name: 'kitchen_type',
      value: null,
      settings: {
        label: 'Тип кухни',
        placeholder: 'Выберите тип кухни',
        options: [{ value: 'loading', label: 'Загрузка...', disabled: true }],
      },
    }),
    place_type: getDefaultField('select', {
      name: 'kitchen_type',
      value: null,
      settings: {
        label: 'Тип заведения',
        placeholder: 'Выберите тип заведения',
        options: [{ value: 'loading', label: 'Загрузка...', disabled: true }],
      },
    }),
    city: getDefaultField('select', {
      name: 'city',
      value: null,
      settings: {
        label: 'Город',
        placeholder: 'Выберите город',
        options: [{ value: 'loading', label: 'Загрузка...', disabled: true }],
      },
    }),
    address: getDefaultField('input', {
      name: 'address',
      value: '',
      settings: {
        label: 'Адрес',
        placeholder: 'Укажите адрес',
      },
    }),
    web_site: getDefaultField('input', {
      name: 'web_site',
      value: '',
      settings: {
        label: 'Сайт',
        placeholder: 'https://',
      },
    }),
    telegram: getDefaultField('input', {
      name: 'telegram',
      value: '',
      settings: {
        label: 'Телеграм',
        placeholder: 'https://',
      },
    }),
    vk: getDefaultField('input', {
      name: 'vk',
      value: '',
      settings: {
        label: 'ВКонтакте',
        placeholder: 'https://',
      },
    }),
    open_hours: getDefaultField('input', {
      name: 'open_hours',
      value: '',
      settings: {
        label: 'Режим работы',
        placeholder: '',
      },
    }),
    description: getDefaultField('textarea', {
      name: 'description',
      value: '',
      settings: {
        label: 'Примечания',
        placeholder: 'Оставьте здесь комментарии или примечания при необходимости',
        height: data.isMobile ? '128px' : '108px',
      },
    }),

    // обязательные поля
    name_establishment: getDefaultField('input', {
      name: 'name_establishment',
      value: '',
      required: true,
      settings: {
        showRequired: true,
        label: 'Название заведения',
        placeholder: '',
      },
    }),
    name_organisation: getDefaultField('input', {
      name: 'name_organisation',
      value: '',
      required: true,
      settings: {
        showRequired: true,
        label: 'Название организации',
        placeholder: '',
        hint: 'Для ООО вводите название в кавычках',
      },
    }),
    inn: getDefaultField('input', {
      name: 'inn',
      value: '',
      required: true,
      settings: {
        showRequired: true,
        label: 'ИНН',
        placeholder: '',
      },
    }),
    phone_organisation: getDefaultField('input_tel', {
      name: 'phone_organisation',
      value: '',
      required: true,
      settings: {
        showRequired: true,
        label: 'Телефон заведения',
        placeholder: '+7 (000) 000-00-00',
        hint: !data.isLanding
          ? 'Проверьте, совпадает ли номер телефона на карточке заведения\n с указанным в этом поле'
          : '',
      },
    }),
    phone_contact: getDefaultField('input_tel', {
      name: 'phone_contact',
      value: '',
      required: true,
      settings: {
        showRequired: true,
        label: 'Телефон для связи',
        placeholder: '+7 (000) 000-00-00',
      },
    }),
    type_organisation: getDefaultField('radio', {
      name: 'type_organisation',
      value: 'OOO',
      required: true,
      settings: {
        label: 'Тип организации',
        showRequired: true,
        hint: '',
        options: [
          { id: 'OOO', name: 'OOO', label: 'OOO', disabled: false },
          { id: 'IP', name: 'IP', label: 'ИП', disabled: false },
        ],
      },
    }),
    email: getDefaultField('input_email', {
      name: 'email',
      value: '',
      required: true,
      settings: {
        showRequired: true,
        label: 'E-mail для связи',
        placeholder: 'example@rambler.ru',
      },
    }),
  }

  return FIELDS[nameField]
}
