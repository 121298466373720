import React, { FC, useRef } from 'react'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { MediaCardCompilation } from '@shared/ui/Layout/Cards/MediaCardCompilation'
import { TypeMappingNewsOutput } from '@shared/api/middleware/mappingAPI/news/type'
import { EGG } from '@shared/api/analytics'
import { useIntersectionAnalytic } from '@shared/lib/hooks/useIntersectionAnalytic'
import { PickAnalytic } from '@shared/api/analytics/types/types'

// D-desktop - M-mobile
// type size = 'D1x1 D2x1 M1x1'
interface Props {
  data: TypeMappingNewsOutput
  sizes: string
  className?: string
  analytic: PickAnalytic<'section_index' | 'section_name'>
}

const NewsCardCompilation: FC<Props> = ({ data, sizes, className = '', analytic, ...props }) => {
  const cardRef = useRef()

  const handleClick = () => EGG.entity.news.card_click(data, analytic)
  useIntersectionAnalytic(cardRef, () => EGG.entity.news.card_view(data, analytic))

  const defaultCoverImage =
    data.img.sizes.cover_2210x1244 ||
    data.img.sizes.cover_1220x594 ||
    data.img.sizes.cover_594x594 ||
    data.img.sizes.desktop.default1

  return (
    <ErrorBoundary>
      <MediaCardCompilation
        id={String(data.id)}
        title={data.description.title}
        sizes={sizes}
        img={{
          horizontal: defaultCoverImage,
          vertical: defaultCoverImage,
        }}
        link={data.url.path}
        chevron={data.categories.selection}
        className={className}
        onClick={handleClick}
        ref={cardRef}
        favorite={{
          category: 'news',
          analytic: {
            data: data,
            other: {
              section_name: analytic.section_name,
            },
          },
        }}
        {...props}
      />
    </ErrorBoundary>
  )
}

export default NewsCardCompilation
