import Head from 'next/head'
import React, { memo } from 'react'

const GITLAB_STATUS = process.env.GITLAB_STATUS || null
const IS_PROD = GITLAB_STATUS === '0'

const yaMetricsCounter = {
  common: '27079337', // window.yaCounter27079337
  legacy: '27508533', // window.yaCounter27508533
}

/**
 * @function awaitGlobalVariable — обертка, проверяющая глобальную переменной
 *    и выполняет соответствующий коллбэк при наличии, в ином случает делает повторные попытки вызова
 * @param {string} params.variableName — имя глобальной переменной для отслеживания.
 * @param {number} [params.maxAttempts] — максимальное количество попыток проверки наличия переменной.
 * @param {number} [params.attemptsIntervalMs] — интервал времени (в мс) между попытками.
 * @param {Function} params.onSuccessCallback — коллбэк, который выполняется при успешном обнаружении переменной.
 * @param {Function} params.onFailCallback — коллбэк, который выполняется, если переменная так и не была обнаружена.
 */
const awaitGlobalVariable = ({
  variableName,
  maxAttempts = 10,
  attemptsIntervalMs = 1000,
  onSuccessCallback,
  onFailCallback,
}) => {
  if (typeof window === 'undefined') return

  const isVariableExists = () => typeof window[variableName] !== 'undefined'

  const executeCallback = (callback) => {
    try {
      callback?.()
    } catch (error) {
      console.error(`Ошибка при выполнении ${callback.name}:`, error)
    }
  }

  let attemptsCounter = 0
  const checkAndExecuteCallback = () => {
    if (isVariableExists()) {
      executeCallback(() => onSuccessCallback(window[variableName]))
      clearInterval(awaiter)
    } else if (attemptsCounter++ >= maxAttempts) {
      executeCallback(() => onFailCallback())
      clearInterval(awaiter)
    }
  }

  const awaiter = setInterval(checkAndExecuteCallback, attemptsIntervalMs)

  checkAndExecuteCallback()
}

/**
 * @function sendYaMetricsEvent — отправляет "целевые" метрики и "параметры визита" в Яндекс
 * @todo — добавить awaitGlobalVariable при отправке reachGoal и params для стабильности работы метрик
 */
const sendYaMetricsEvent = (setting, id, params) => {
  const thisYa = setting && 'ya' in setting ? setting.ya : null

  const yaCommon = window && IS_PROD ? window[`yaCounter${yaMetricsCounter.common}`] : null
  const yaLegacy = window && IS_PROD ? window[`yaCounter${yaMetricsCounter.legacy}`] : null

  if ('set' in params && params.set) {
    console.warn('params', params)
  } else {
    if (thisYa) {
      const obj = {}

      for (const prop in thisYa) {
        if (prop in thisYa) {
          obj[prop] = thisYa[prop]
        }
      }

      if (params.ya.reachGoal) {
        // целевые метрики
        !!yaCommon && yaCommon.reachGoal(params.ya.goalName, thisYa || {})
        !!yaLegacy && yaLegacy.reachGoal(params.ya.goalName, thisYa || {})
      } else {
        // параметры визита
        !!yaCommon && yaCommon.params(thisYa || {})
        !!yaLegacy && yaLegacy.params(thisYa || {})
      }
    }
  }
}

/**
 * @function YaMetrics — добавляет счетчик Яндекса в window
 */
const YaMetrics = () => {
  return typeof window !== 'undefined' ? (
    <>
      <Head>
        <script
          dangerouslySetInnerHTML={{
            __html: `
                (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
                m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
                (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

                window.ym(${yaMetricsCounter.common}, "init", {
                  clickmap:true,
                  trackLinks:true,
                  accurateTrackBounce:true
                });

                window.ym(${yaMetricsCounter.legacy}, "init", {
                  clickmap:true,
                  trackLinks:true,
                  accurateTrackBounce:true
                });
              `,
          }}
        />
      </Head>
    </>
  ) : (
    <></>
  )
}

export { YaMetrics, sendYaMetricsEvent }
