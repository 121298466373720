/* eslint-disable */

import React, { FC, useEffect, useCallback, useState } from 'react'
import Script from 'next/script'

import { useLocalStorage } from '@widgets/Restaurant/Booking/lib/helper'

const THIS_TOP_100 = '3141186'
const THIS_RCM_ID = 'RCM-7BA1'

const GITLAB_STATUS = process.env.GITLAB_STATUS || null
const IS_PROD = true || GITLAB_STATUS === '0'

declare const window: Window &
  typeof globalThis & {
    rcm: any
    top100Counter: any
    _top100q: any
  }

interface Props {
  currentPage?: string
}

const getRestIdData = (pathname): string => {
  const slugWithId = pathname.split('/').filter(Boolean).pop()
  const restId = slugWithId.split('-').pop()
  return `Restaurant_${restId}`
}

const getNewsUrlData = (location): string => {
  const output = `${location.origin}` + `${location.pathname.slice(0, -1)}`
  return output
}

const RcmScript: FC<Props> = (props) => {
  const isDev = typeof window === 'object' && window.location.search.indexOf('is_dev=true') > 0

  const isNewsItemPage = props.currentPage === 'news_item'
  const isRestItemPage = props.currentPage === 'rest_item'

  const pathname = typeof window === 'object' && window.document?.location?.pathname
  const windowRcm = typeof window === 'object' && typeof window.rcm
  const windowTop100 = typeof window === 'object' && typeof window._top100q

  const [isRCM, setIsRCM] = useState(false)
  const [isTop100, setIsTop100] = useState(false)
  const [setupRCM, setSetupRCM] = useState(false)

  useEffect(() => {
    if (isTop100 === false && windowTop100 !== 'undefined') {
      setIsTop100(true)
    }
  }, [windowTop100])

  const restIdData = pathname && isRestItemPage ? getRestIdData(pathname) : null
  const newsUrlData = pathname && isNewsItemPage ? getNewsUrlData(window.location) : null

  const [sending, setSending] = useState(false)

  // * при переходе на новую страницу, один раз отправляет одно из событий rcm
  useEffect(() => {
    if (!sending && isRCM) {
      baseEventCallback()
      isRestItemPage && restCardPageCallback()
      isNewsItemPage && newsCardPageCallback()
    }
  }, [props.currentPage])

  const baseEventCallback = () => {
    isDev && console.log('rcm:restCardPageCallback / isRCM', isRCM)
    if (isRCM && !isNewsItemPage && !isRestItemPage && pathname) {
      isDev && console.info('rcm:hubpageview', pathname)
      window.rcm('hubpageview', {
        hubId: pathname,
      })
      setSending(true)
    }
  }

  const restCardPageCallback = () => {
    isDev && console.log('rcm:restCardPageCallback', isRCM, setupRCM, isRestItemPage, !!restIdData)
    if (isRCM && isRestItemPage && restIdData) {
      isDev && console.info('rcm:pageview', restIdData)
      window.rcm('pageview', {
        itemId: restIdData,
      })
      setSending(true)
    }
  }

  const newsCardPageCallback = () => {
    isDev && console.log('rcm:newsCardPageCallback', isRCM, isNewsItemPage, !!newsUrlData)
    if (isRCM && isNewsItemPage && newsUrlData) {
      isDev && console.info('rcm:pageview', newsUrlData)
      window.rcm('pageview', {
        itemId: newsUrlData,
      })
      setSending(true)
    }
  }

  const setupRCMCallback = () => {
    if (setupRCM === false) {
      isDev && console.info('rcm:setup', THIS_RCM_ID)

      setSetupRCM(true)

      window.rcm &&
        window.rcm('setup', {
          top100Counter: window.top100Counter,
          rcmId: THIS_RCM_ID,
        })

      if (!sending) {
        baseEventCallback()
        isRestItemPage && restCardPageCallback()
        isNewsItemPage && newsCardPageCallback()
      }
    }
  }

  useEffect(() => {
    isDev && console.log('rcm:setupRCMCallback', isRCM, isTop100)
    if (isRCM && isTop100) {
      setupRCMCallback()
    }
  }, [isRCM, isTop100])

  useEffect(() => {
    if (setupRCM === true || (isRCM === false && windowRcm !== 'undefined')) {
      setIsRCM(true)
    }
  }, [setupRCM, isRCM, windowRcm])

  return (
    IS_PROD &&
    pathname && (
      <>
        {isTop100 === false && (
          <Script
            id='top100-counter'
            strategy='afterInteractive'
            dangerouslySetInnerHTML={{
              __html: `
                var _top100q = _top100q || [];
                _top100q.push(['trackPageview']);

                window._top100q.push(() => {
                        try {
                            window.top100Counter = new top100({
                                project: '${THIS_TOP_100}',
                                custom_vars: {},
                            });
                        } catch (e) {}
                    });

                (function(){
                var pa = document.createElement('script');
                pa.async = true;
                pa.src = ('https:' == document.location.protocol ? 'https:' : 'http:') + '//st.top100.ru/top100/top100.js';
                var s = document.getElementsByTagName('script')[0];
                s.parentNode.insertBefore(pa, s);
                })();
              `,
            }}
          />
        )}

        {isRCM === false && (
          <Script
            id='recommender-script'
            strategy='afterInteractive'
            dangerouslySetInnerHTML={{
              __html: `
                (function (w, d, c) {
                    w[c] = w[c] || function () {
                        (w[c].q = w[c].q || []).push(arguments);
                    }, w[c].l = 1 * new Date();
                    var n = d.getElementsByTagName('script')[0],
                        s = d.createElement('script'),
                        f = function () { n.parentNode.insertBefore(s, n); };
                    s.type = 'text/javascript';
                    s.async = true;
                    s.src = 'https://rcmjs.rambler.ru/static/recommender.js';
                    if (w.opera == '[object Opera]') {
                        d.addEventListener('DOMContentLoaded', f, false);
                    } else { f(); }
                })(window, document, 'rcm');

                window.rcmw = window.rcmw || { cmd: [] };
              `,
            }}
          />
        )}

        <Script id='rcmw-script' strategy='afterInteractive' async src='https://rcmjs.rambler.ru/static/rcmw/rcmw.js' />
      </>
    )
  )
}

export default RcmScript
