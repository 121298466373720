import React, { FC } from 'react'
import s from './AnswerItem.module.sass'
import { Avatar } from '@shared/ui/Media/Avatar'
import { Text } from '@shared/ui/Typography/Text'
import { DiscloseText } from '@shared/ui/Actions/DiscloseText'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { TypeMappingReviewAnswerOutput } from '@shared/api/middleware/mappingAPI/review/type'

interface Props {
  data: TypeMappingReviewAnswerOutput
  ownerMode?: boolean
  className?: string
  showSettings?: boolean
  onClickDeleteAnswer?: (data: TypeMappingReviewAnswerOutput) => void
  onClickEditAnswer?: (data: TypeMappingReviewAnswerOutput) => void
  onClickFullAnswer?: () => void
}

const AnswerItem: FC<Props> = ({ data, ownerMode = false, className = '' }) => {
  const { isMobile } = useClientResponsive()

  return (
    <ErrorBoundary>
      {data && data.author && (
        <div className={`${s.answer} ${className}`}>
          <div className={s['photo-wrap']}>
            <Avatar src={data.author.avatar} sizes={'XL L'} userName={data.author.name} />
          </div>

          <div className={s.content}>
            <div className={s['content-head']}>
              <div className={s['head-main']}>
                <div className={s['head-info-wrap']}>
                  {data.author.name && (
                    <Text sizes={'M ML'} medium>
                      {data.author.name}
                    </Text>
                  )}

                  <div className={s['detailed-wrapper']}>
                    {ownerMode ? (
                      <Text tag={'span'} sizes={'S'} className={s.date}>
                        {data.date}
                      </Text>
                    ) : (
                      <Text tag={'span'} sizes={'S'} className={s.detailed}>
                        на отзыв {data.answerTo}
                      </Text>
                    )}
                  </div>
                </div>
              </div>

              {!isMobile && !ownerMode && (
                <Text tag={'span'} sizes={'S'} className={s.date}>
                  {data.date}
                </Text>
              )}
            </div>

            <DiscloseText maxHeight={118}>
              <Text sizes={'M'} paragraph>
                {data.body}
              </Text>
            </DiscloseText>
          </div>
          {isMobile && (
            <Text tag={'span'} sizes={'S'} className={s.date}>
              {data.date}
            </Text>
          )}
        </div>
      )}
    </ErrorBoundary>
  )
}

export default AnswerItem
