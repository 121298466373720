import React, { FC } from 'react'

import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import ContentLoader from 'react-content-loader'
import {
  SKELETON_FOREGROUND_COLOR,
  SKELETON_MAIN_COLOR,
  SKELETON_SPEED,
  SKELETON_STYLES,
} from '@shared/consts/skeleton'
import s from '@widgets/Common/QuestionsAnswers/QuestionsAnswers.module.sass'
import { SectionTitleSkeleton } from '@shared/ui/Layout/SectionTitle'

interface Props {
  className?: string
}

const Skeleton: FC<Props> = ({ className = '' }) => {
  const listSkeleton = Array.from(Array(4).keys())

  return (
    <ErrorBoundary>
      <div className={`${s.wrapper} ${className}`}>
        <SectionTitleSkeleton className={s.title} />

        <div className={s['items-skeleton']}>
          {listSkeleton.map((el, idx) => (
            <ContentLoader
              key={idx}
              speed={SKELETON_SPEED}
              backgroundColor={SKELETON_MAIN_COLOR}
              foregroundColor={SKELETON_FOREGROUND_COLOR}
              style={SKELETON_STYLES}
              className={s['item-skeleton']}>
              <rect x='0' y='0' rx='24' ry='24' className={s['item-skeleton']} />
            </ContentLoader>
          ))}
        </div>
      </div>
    </ErrorBoundary>
  )
}

export default Skeleton
