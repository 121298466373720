import React, { FC } from 'react'
import s from './Map.module.sass'
import { Template } from '@widgets/Restaurant/ResturantCards/RestCard/ui/CardTypes/Template'
import { TemplateCardProps } from '@widgets/Restaurant/ResturantCards/RestCard/type/TemplateCardProps'
import { TastyClub } from '@widgets/Restaurant/ResturantCards/RestCard/ui/Components/TastyClub'
import TypeAndPrice from '@widgets/Restaurant/ResturantCards/RestCard/ui/Components/TypeAndPrice/TypeAndPrice'
import { Place } from '@widgets/Restaurant/ResturantCards/RestCard/ui/Components/Place'
import { ScheduleAndPrice } from '@widgets/Restaurant/ResturantCards/RestCard/ui/Components/ScheduleAndPrice'
import { useCurrentSize } from '@shared/lib/helpers/classes'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'

const Map: FC<
  TemplateCardProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>
> = (props) => {
  const [size] = useCurrentSize(props.sizes)

  const price = props.data.price
  const address = props.data.address.address
  const metro = props.data.address.metro
  return (
    <ErrorBoundary>
      <Template {...props}>
        {/*<TastyClub tastyClub={props.data.discounts.tastyclub} />*/}
        <TypeAndPrice
          restType={props.data.type}
          tag={props.data?.tags?.main_tag}
          priceRange={size !== 'S' ? price.level : null}
          priceLink={price.tag.url}
        />
        {size !== 'S' && <Place address={address} metro={metro} />}
        <ScheduleAndPrice
          schedule={props.data.schedule}
          priceRange={size === 'S' ? price.level : null}
          priceLink={price.tag.url}
        />
        {size === 'S' && <Place address={address} metro={metro} />}
      </Template>
    </ErrorBoundary>
  )
}

export default Map
