import { createAsyncThunk } from '@reduxjs/toolkit'
import { API } from '@shared/api'
import { addTemporaryNotice } from '@widgets/Common/Notices'
import { setList, setLoading, setPaginationPage } from '@pages/SelectionsGallery/model/reducer'

export const loadMorePage = createAsyncThunk(
  'selectionsGallery/loadMorePage',
  async (action, { dispatch, getState }) => {
    // @ts-ignore
    const stateGlobal = getState().global
    // @ts-ignore
    const state = getState().pages.selections_gallery

    const { items_addition, items_full } = state.loading
    if (items_addition || items_full || !state.pagination.page.next.number) return

    dispatch(setLoading({ ...state.loading, items_addition: true }))

    const params = {
      page: state.pagination.page.next.number,
      page_size: state.pagination.page.size,
      city_id: stateGlobal.currentCity.id,
    }

    API.selection_list({ params })
      .then((response) => {
        const { results, pagination } = response
        dispatch(setList([...state.list, ...results]))
        dispatch(setPaginationPage(pagination))
      })
      .catch((error) => {
        // @ts-ignore
        dispatch(addTemporaryNotice('Ошибка загрузки подборок'))
      })
      .finally(() => {
        dispatch(setLoading({ ...state.loading, items_addition: false }))
      })
  },
)
