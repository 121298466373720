import React, { FC, ReactNode } from 'react'

import { SpecialOfferSkeleton } from '@widgets/Restaurant/ResturantCards/RestCard/ui/CardTypes/SpecialOffer'
import { GoThisWeekSkeleton } from '@widgets/Restaurant/ResturantCards/RestCard/ui/CardTypes/GoThisWeek'
import { NewDishesSkeleton } from '@widgets/Restaurant/ResturantCards/RestCard/ui/CardTypes/NewDishes'
import { SelectionSkeleton } from '@widgets/Restaurant/ResturantCards/RestCard/ui/CardTypes/Selection'
import { LookAlsoSkeleton } from '@widgets/Restaurant/ResturantCards/RestCard/ui/CardTypes/LookAlso'
import { ListSkeleton } from '@widgets/Restaurant/ResturantCards/RestCard/ui/CardTypes/List'
import { MapSkeleton } from '@widgets/Restaurant/ResturantCards/RestCard/ui/CardTypes/Map'

import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'

interface Props {
  type?: 'list' | 'special-offer' | 'new-dishes' | 'look-also' | 'selection' | 'map' | 'go-this-week'
  children?: ReactNode
  sizes: string
  className?: string
  animate?: boolean
}

const componentsByType = {
  list: ListSkeleton,
  'special-offer': SpecialOfferSkeleton,
  'new-dishes': NewDishesSkeleton,
  'look-also': LookAlsoSkeleton,
  selection: SelectionSkeleton,
  map: MapSkeleton,
  'go-this-week': GoThisWeekSkeleton,
}

const Skeleton: FC<Props> = ({ type = 'list', children, sizes = 'L', className = '', animate = true, ...props }) => {
  const Template = componentsByType[type]

  return (
    <ErrorBoundary>
      <Template sizes={sizes} className={className} animate={animate} {...props} />
    </ErrorBoundary>
  )
}

export default Skeleton
