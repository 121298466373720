import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TypeLoading, TypeState } from '@pages/Listing/model/types'
import { TypeMappingSelectionOutput } from '@shared/api/middleware/mappingAPI/selection/type'
import { TypeMappingPaginationOutput } from '@shared/api/middleware/mappingAPI/pagination/type'
import { TypeMappingRestOutput } from '@shared/api/middleware/mappingAPI/restaurant/type'

const initialState: TypeState = {
  isReady: false,
  loading: {
    page: true,
    items_full: false,
    items_addition: false,
  },
  title: null,
  description: null,
  pagination: null,
  paramsRequest: {},
  list: null,
  selections: {
    loading: true,
    list: null,
  },
}

const listing = createSlice({
  name: 'listing',
  initialState,

  reducers: {
    setReady(state, action: PayloadAction<boolean>) {
      state.isReady = action.payload
    },
    setLoadingPage(state, action: PayloadAction<boolean>) {
      state.loading.page = action.payload
    },
    setLoading(state, action: PayloadAction<TypeLoading>) {
      state.loading = action.payload
    },

    setTitle(state, action: PayloadAction<string>) {
      state.title = action.payload
    },
    setDescription(state, action: PayloadAction<string>) {
      state.description = action.payload
    },

    setPagination(state, action: PayloadAction<TypeMappingPaginationOutput | null>) {
      state.pagination = action.payload
    },
    setPaginationItems(state, action: PayloadAction<number | null>) {
      state.pagination.total = action.payload
    },
    setPaginationPage(state, action: PayloadAction<TypeMappingPaginationOutput>) {
      state.pagination.page = action.payload.page
    },
    setList(state, action: PayloadAction<TypeMappingRestOutput[]>) {
      state.list = action.payload
    },

    setSelectionsSection(state, action: PayloadAction<TypeMappingSelectionOutput[]>) {
      state.selections.list = action.payload
      state.selections.loading = false
    },

    setParamsRequest(state, action: PayloadAction<object | null>) {
      // @ts-ignore
      const { page_size, page, city, ...params } = action.payload
      state.paramsRequest = params
    },

    clearStateListing() {
      return initialState
    },
  },
})

export const getState = (state) => state
export const {
  setLoading,
  setTitle,
  setDescription,
  setPagination,
  setPaginationItems,
  setPaginationPage,
  setList,
  setSelectionsSection,
  setLoadingPage,
  clearStateListing,
  setParamsRequest,
  setReady,
} = listing.actions
export default listing.reducer
