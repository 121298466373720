import React, { FC, useEffect, useState } from 'react'
import merge from 'lodash/merge'

import s from './ImagePreview.module.sass'

import { TypeRulesImageUpload } from '@widgets/Common/ImageCropping/ui/ImagePreview/types'
import { getImageError } from '@widgets/Common/ImageCropping/ui/ImagePreview/helpers'
import { defaultRules } from '@widgets/Common/ImageCropping/ui/ImagePreview/const'

import { LoadingSpinner } from '@shared/ui/Feedback/LoadingSpinner'
import { IconButton } from '@shared/ui/Actions/IconButton'
import { Text } from '@shared/ui/Typography/Text'

import IconTrash from '@icons/icon-trash.svg'
import IconPlus from '@icons/icon-cropper-add-image-plus.svg'
import { InputHint } from '@shared/ui/Form/Single/InputHint'

interface Props {
  id: string
  style?: object
  image?: string
  handleDelete: () => void
  handleSelectImage: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleChangeCrop: () => void
  rules?: TypeRulesImageUpload
}

const ImagePreview: FC<Props> = ({
  id,
  style,
  image: imageProps,
  rules: rulesProps,
  handleSelectImage,
  handleChangeCrop,
  handleDelete,
}) => {
  const rules = merge({}, defaultRules, rulesProps)
  const [loading, setLoading] = useState(!imageProps)
  const [image, setImage] = useState(null)
  const [errors, setErrors] = useState(null)
  const formatsWithPrefix = rules.formats.map((format) => `image/${format}`)

  const handleSelectImageInner = (e) => {
    setErrors(() => null)
    if (!e.target.files.length) return

    // Проверка на ограничения изображений (вес, ширина, высота, форматы)
    try {
      const errorsBeforeLoad = []
      const file = e.target.files[0]
      const _URL = window.URL || window.webkitURL

      const minWeightIsValid = file.size >= rules.weight.min * 1024 * 1024
      const maxWeightIsValid = file.size <= rules.weight.max * 1024 * 1024
      const formatIsValid = formatsWithPrefix.some((format) => file.type === format)
      const img = new Image()

      if (!minWeightIsValid || !maxWeightIsValid) {
        errorsBeforeLoad.push(
          getImageError({
            category: 'weight',
            type: !minWeightIsValid ? 'min' : 'max',
            sourceValue: !minWeightIsValid ? rules.weight.min : rules.weight.max,
            currentValue: (file.size / 1024 / 1024).toFixed(1),
          }),
        )
      }

      if (!formatIsValid) {
        errorsBeforeLoad.push(
          getImageError({
            category: 'formats',
            sourceValue: rules.formats.join(','),
            currentValue: file.type,
          }),
        )
      }

      if (errorsBeforeLoad.length) {
        setErrors(() => errorsBeforeLoad)
        return
      }

      img.onload = function () {
        const errorsAfterLoad = []
        // @ts-ignore
        const minWidthIsValid = this.width > rules.width.min
        // @ts-ignore
        const maxWidthIsValid = this.width < rules.width.max
        // @ts-ignore
        const minHeightIsValid = this.height > rules.height.min
        // @ts-ignore
        const maxHeightIsValid = this.height < rules.height.max

        if (!minWidthIsValid || !maxWidthIsValid) {
          errorsAfterLoad.push(
            getImageError({
              category: 'width',
              type: !minWidthIsValid ? 'min' : 'max',
              sourceValue: !minWidthIsValid ? rules.width.min : rules.width.max,
              // @ts-ignore
              currentValue: this.width,
            }),
          )
        }

        if (!minHeightIsValid || !maxHeightIsValid) {
          errorsAfterLoad.push(
            getImageError({
              category: 'height',
              type: !minHeightIsValid ? 'min' : 'max',
              sourceValue: !minHeightIsValid ? rules.height.min : rules.height.max,
              // @ts-ignore
              currentValue: this.height,
            }),
          )
        }

        if (errorsAfterLoad.length) setErrors(() => errorsAfterLoad)
        else {
          handleSelectImage(e)
          e.target.value = ''
        }
      }

      img.src = _URL.createObjectURL(file)
    } catch (e) {
      console.error('неизвестная ошибка загрузки изображения, попробуйте позже', e)
      setErrors(() => ['неизвестная ошибка загрузки изображения, попробуйте позже'])
    }
  }

  useEffect(() => {
    if (!imageProps) {
      setImage(() => null)
      setLoading(false)
      return
    }
    setLoading(true)

    const imgTest = new Image()

    imgTest.onload = function () {
      setImage(() => imageProps)
      setLoading(false)
    }
    imgTest.onerror = function () {
      setErrors(() => ['Ошибка загрузки изображения'])
      setLoading(false)
    }

    imgTest.src = imageProps
  }, [imageProps])

  return (
    <>
      <input
        id={id}
        type='file'
        accept={formatsWithPrefix.join(', ')}
        onChange={handleSelectImageInner}
        data-testid={'image-cropping-preview-select'}
        hidden
      />

      {image ? (
        <div className={s.wrapper} data-image={!!image} data-loading={loading} style={style}>
          {loading ? (
            <LoadingSpinner loading={loading} />
          ) : (
            <div className={s['img-wrapper']}>
              <img
                className={s['img-wrapper-img']}
                src={image}
                alt={'Ваша фотография'}
                onClick={handleChangeCrop}
                data-testid={'image-cropping-preview-change'}
              />
              <IconButton
                sizes={'S'}
                mode={'white'}
                icon={IconTrash}
                className={s.delete}
                onClick={handleDelete}
                data-testid={'image-cropping-preview-delete'}
              />
            </div>
          )}
        </div>
      ) : (
        <label htmlFor={id} className={s.wrapper} data-image={!!image} data-loading={loading} style={style}>
          {loading ? (
            <LoadingSpinner loading={loading} size={25} />
          ) : (
            <>
              <div className={s.title}>
                <IconPlus />
                <Text sizes={'L'}>Добавить</Text>
              </div>

              <Text sizes={'S'} className={s.description}>
                Ширина и высота фотографии должны быть не меньше {rules.width.min}x{rules.height.min}. <br />
                Размер файла не должен превышать {rules.weight.max} МБ.
              </Text>
            </>
          )}
        </label>
      )}

      {errors?.length && (
        <div className={s.errors}>
          {errors.map((el) => (
            <InputHint status={'error'} key={el} sizes={'S'} text={el} />
          ))}
        </div>
      )}
    </>
  )
}

export default ImagePreview
