import React, { FC, useState } from 'react'
import s from './FilterButtonsTemplate.module.sass'
import { BtnFilterDropdown } from '@shared/ui/Form/Single/Filters/Dropdown'
import { Checkbox } from '@shared/ui/Form/Single/Checkbox'
import { Text } from '@shared/ui/Typography/Text'
import FilterButtons from '../FilterButtons/FilterButtons'
import { useAppDispatch, useAppSelector } from '@app/model/store'
import { CheckboxOption } from '@shared/types/fields/fields'
import {
  closeModalFilterList,
  resetFilterAsync,
  setCheckedFilterItemAsync,
} from '@features/Restaurant/RestaurantFilters/model/createAsyncThunk'
import { TypeFiltersByKeyListButtons } from '@features/Restaurant/RestaurantFilters/model/types'
import { EGG } from '@shared/api/analytics'
import { getFilterByType, getFilterSelected } from '@features/Restaurant/RestaurantFilters/helpers/helper'

interface Props {
  typeFilter: TypeFiltersByKeyListButtons
  handleSave: () => void
  handleRemove: () => void
}

const title = {
  rating: ['Рейтинг', 'Рейтинг'],
  price: ['Цена', 'Средний счет'],
}

const FilterButtonsTemplate: FC<Props> = ({ typeFilter, handleSave, handleRemove }) => {
  const dispatch = useAppDispatch()
  const stateFilters = useAppSelector((store) => store.features.restaurant_filters)
  const { list, checkedList } = stateFilters.filters[typeFilter]
  const [isOpenedPopup, setIsOpenedPopup] = useState(false)

  const handleRemoveInner = async () => {
    setIsOpenedPopup(false)
    await dispatch(resetFilterAsync(typeFilter))
    handleRemove()
  }

  const changeChecked = (option: CheckboxOption, checked) => {
    dispatch(
      setCheckedFilterItemAsync({
        filterType: typeFilter,
        itemId: Number(option.id),
        checked: checked,
      }),
    )
  }

  const handleSaveInner = () => {
    EGG.common.filter_use_click({
      filter_apply_type: typeFilter,
      filters_apply: getFilterSelected(),
      filter_apply_value: getFilterByType(typeFilter),
    })
    setIsOpenedPopup(false)
    handleSave()
  }

  const handleOpenPopup = () => {
    setIsOpenedPopup(() => true)
  }
  const handleClosePopup = () => {
    // @ts-ignore
    dispatch(closeModalFilterList())
    setIsOpenedPopup(() => false)
  }

  return (
    <BtnFilterDropdown openedPopup={isOpenedPopup}>
      {/*@ts-ignore*/}
      <BtnFilterDropdown.Button
        handleOpen={handleOpenPopup}
        handleClose={handleClosePopup}
        count={checkedList?.length || 0}
        handleRemove={handleRemoveInner}>
        {title[typeFilter][0]}
        {/*@ts-ignore*/}
      </BtnFilterDropdown.Button>
      {/*@ts-ignore*/}
      <BtnFilterDropdown.Popup
        title={title[typeFilter][1]}
        width={420}
        className={s.popup}
        handleClose={handleClosePopup}>
        <div className={s.list}>
          {list.length ? (
            list.map((tag) => (
              <Checkbox
                key={`checkbox-${tag.id}`}
                option={{
                  id: tag.id,
                  name: tag.slug,
                  label: tag.name,
                  disabled: tag.disabled,
                }}
                checked={tag.checked}
                onChange={changeChecked}
                mode={'button'}
                className={s.button}
              />
            ))
          ) : (
            <Text sizes={'M'}>Ничего не найдено</Text>
          )}
        </div>
        {/*@ts-ignore*/}
        <FilterButtons handleRemove={handleRemoveInner} handleSave={handleSaveInner} />
        {/*@ts-ignore*/}
      </BtnFilterDropdown.Popup>
    </BtnFilterDropdown>
  )
}

export default FilterButtonsTemplate
