/* eslint-disable */
interface FieldProp {
  value: string
  visible: boolean
  disabled: boolean
  required: boolean
}

export const checkFieldErrorByType = (type: string, field: FieldProp) => {
  const { value, visible, disabled, required } = field

  if (!visible || disabled) return
  if (!value && !required) return

  const typeWithoutNumbers = type.replace(/[0-9]/g, '')

  switch (typeWithoutNumbers) {
    case 'required':
      return !value.trim() ? type : false

    case 'email':
      const regExpEmail =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return regExpEmail.test(String(value).toLowerCase()) ? false : type

    case 'phone':
      const regExpPhone = /^(\+7|7|8|\+8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/gm
      return regExpPhone.test(String(value).toLowerCase()) ? false : type

    case 'max-symbols-':
      const NumberMax = type.match(/\d+/)
      if (NumberMax !== null) {
        return value.trim().length > parseInt(NumberMax.toString()) ? type : false
      } else {
        console.error('Ошибка указания типа ошибки max-symbols-[numbers]. Указано - ', type)
        return false
      }

    case 'min-symbols-':
      const NumberMin = type.match(/\d+/)
      if (NumberMin !== null) {
        return value.trim().length < parseInt(NumberMin.toString()) ? type : false
      } else {
        console.error('Ошибка указания типа ошибки min-symbols-[numbers]. Указано - ', type)
        return false
      }
  }
}
