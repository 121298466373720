import React, { FC } from 'react'

import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import ContentLoader from 'react-content-loader'
import {
  SKELETON_FOREGROUND_COLOR,
  SKELETON_MAIN_COLOR,
  SKELETON_SPEED,
  SKELETON_STYLES,
} from '@shared/consts/skeleton'
import s from './MediaCard.module.sass'
import { useCurrentSize } from '@shared/lib/helpers/classes'
// ЗАГЛУШКА/ПРИМЕР КОМПОНЕНТА (УДАЛИТЬ КОММЕНТАРИЙ ПОСЛЕ ЗАВЕРШЕНИЯ РАБОТЫ)
interface Props {
  className?: string
  sizes: string
}

const Skeleton: FC<Props> = ({ sizes, className = '' }) => {
  const [size] = useCurrentSize(sizes)

  const defaultAttributes = {
    rx: '8',
    ry: '8',
    'data-size': size,
  }

  return (
    <ErrorBoundary>
      <ContentLoader
        speed={SKELETON_SPEED}
        backgroundColor={SKELETON_MAIN_COLOR}
        foregroundColor={SKELETON_FOREGROUND_COLOR}
        style={SKELETON_STYLES}
        className={` ${s['skeleton-card']} ${className}`}
        data-size={size}>
        <rect x='0' y='0' rx='16' ry='16' className={`${s['skeleton-image']} ${className}`} data-size={size} />
        <rect className={`${s['skeleton-text']} ${s['skeleton-text--1']}`} {...defaultAttributes} />
        <rect className={`${s['skeleton-text']} ${s['skeleton-text--2']}`} {...defaultAttributes} />
        <rect className={`${s['skeleton-text']} ${s['skeleton-text--3']}`} {...defaultAttributes} />
      </ContentLoader>
    </ErrorBoundary>
  )
}

export default Skeleton
