import React, { FC, ReactNode, useEffect, useState } from 'react'

import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import ContentLoader from 'react-content-loader'
import {
  SKELETON_FOREGROUND_COLOR,
  SKELETON_MAIN_COLOR,
  SKELETON_SPEED,
  SKELETON_STYLES,
} from '@shared/consts/skeleton'
import s from '@shared/ui/Layout/CardTwoBlock/CardTwoBlock.module.sass'
import { useCurrentSize } from '@shared/lib/helpers/classes'
import { ImgSkeleton } from '@shared/ui/Layout/CardTwoBlock/ui/Img'
import { ContentSkeleton } from '@shared/ui/Layout/CardTwoBlock/ui/Content'
import { CardTwoBlockSkeletonContext } from '@shared/ui/Layout/CardTwoBlock/Context'

interface Props {
  children?: ReactNode
  sizes: string
  className?: string
  animate?: boolean
}

type SkeletonExtensions = {
  Img: typeof ImgSkeleton
  Content: typeof ContentSkeleton
}

const Skeleton: FC<Props> & SkeletonExtensions = ({ children, sizes = 'L ML', className = '', animate = true }) => {
  const [size] = useCurrentSize(sizes)

  const [context, setContext] = useState({
    size: size,
  })

  useEffect(() => {
    setContext((prev) => ({
      ...prev,
      size,
    }))
  }, [size])

  return (
    <ErrorBoundary>
      <CardTwoBlockSkeletonContext.Provider value={[context, setContext]}>
        <ContentLoader
          speed={SKELETON_SPEED}
          backgroundColor={SKELETON_MAIN_COLOR}
          foregroundColor={animate ? SKELETON_FOREGROUND_COLOR : SKELETON_MAIN_COLOR}
          style={SKELETON_STYLES}
          className={`${s.card} ${s['skeleton-card']} ${className}`}
          data-size={size}>
          {children}
        </ContentLoader>
      </CardTwoBlockSkeletonContext.Provider>
    </ErrorBoundary>
  )
}

Skeleton.Img = ImgSkeleton
Skeleton.Content = ContentSkeleton

export default Skeleton
