import React, { cloneElement, FC, isValidElement, ReactNode } from 'react'
import s from './PopupHeader.module.sass'
import { usePopupContext } from '@shared/ui/Overlays/Popup/hooks'
import { Title } from '@shared/ui/Typography/Title'

interface Props {
  children?: ReactNode
  asChild?: boolean
  headerSizes?: string
}

export const PopupHeader = React.forwardRef<HTMLButtonElement, React.ButtonHTMLAttributes<HTMLButtonElement> & Props>(
  ({ children, asChild = false, headerSizes = 'h3 h5', ...props }, ref) => {
    if (asChild && isValidElement(children)) {
      return children
    }

    return (
      <div className={s.head}>
        <Title tag='h4' sizes={headerSizes} className={`${s.header} popup-title`}>
          {children}
        </Title>
      </div>
    )
  },
)

export default PopupHeader
