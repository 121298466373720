import React, { FC, memo } from 'react'
import s from './InputHint.module.sass'
import { Text } from '@shared/ui/Typography/Text'
import { ErrorBoundary } from '../../../../lib/components/ErrorBoundary'

interface Props {
  status: 'default' | 'error'
  text?: string
  sizes?: string
  className?: string
}

const InputHint: FC<Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>> = ({
  text,
  status,
  sizes = 'S',
  className = '',
  ...props
}) => {
  const classes: { [key: string]: string } = {
    default: s.default,
    error: s.error,
  }
  return (
    <ErrorBoundary>
      {!!text && (
        <Text tag='span' sizes={sizes} className={`${s.hint} ${classes[status]} ${className}`} {...props}>
          {text}
        </Text>
      )}
    </ErrorBoundary>
  )
}

export default memo(InputHint)
