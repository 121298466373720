import { TypeMappingSEOOutput } from '@shared/api/middleware/mappingAPI/seo/type'

export const mappingSEO = (object: any): TypeMappingSEOOutput | null => {
  const data = object.seo
  try {
    const result = {
      amp_link: data.amp_link,
      canonical: data.canonical,
      content_type: data.content_type,
      description: data.description,
      image: {
        choice_pic: data.image_choice_pic,
        static: data.image_static,
        title: data.image_title,
        type: data.image_type,
        url: data.image_url,
      },
      ios_url: data.ios_url,
      is_noindex_nofollow: data.is_noindex_nofollow,
      keywords: data.keywords,
      next_link: data.next_link,
      prev_link: data.prev_link,
      request: data.request,
      title: data.title ? `${data.title} — Афиша–Рестораны` : 'Афиша–Рестораны',
    }
    return result
  } catch (error) {
    console.error(error)
    return null
  }
}
