import React from 'react'

const IconHasNoReviews = () => {
  return (
    <>
      <svg xmlns='http://www.w3.org/2000/svg' width='207' height='182' fill='none' viewBox='0 0 207 182'>
        <path
          fill='url(#paint0_linear_130_3108)'
          d='M65.5 31.13L93 74.63l3.5-1h10l11.5 1.5-42.5-67.5-3-3.5c-1.333-.5-4.3-1.6-5.5-2-1.5-.5-3.5.5-7 2-2.8 1.2-3.833 4.5-4 6v5.5l9.5 15.5z'></path>
        <path
          fill='url(#paint1_linear_130_3108)'
          d='M135.5 146.131l-5-8.5 4-2 6.5-7.5 2.5-8.5 11 16 6.5 30-25.5-19.5z'></path>
        <path
          stroke='#000'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit='10'
          strokeWidth='2.5'
          d='M161.749 166.346c24.616.576 43.331 3.5 43.331 7.027 0 3.936-23.411 7.131-52.287 7.131-28.875 0-52.287-3.195-52.287-7.131 0-1.816 4.958-3.466 13.129-4.723 5.578-.855 12.657-1.536 20.67-1.946M103.5 106c4.251-1.013 8.525-.097 12 4 2.636 3.107 4.664 7.114 5.459 10.929.794 3.815 1.405 7.734 3.334 11.225 1.074 1.938 2.61 3.771 4.775 4.775 1.623.76 3.5.995 5.333 1.039 3.099.079 6.277-.41 8.956-1.781 0 0 7.324-3.072 8.275-12.543 1.502-14.962-2.697-30.893-15.607-40.695-11.522-8.747-27.959-12.081-42.703-8.965'></path>
        <path
          stroke='#000'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit='10'
          strokeWidth='2.5'
          d='M130.997 137.933l5.805 9.139 24.72 19.169-6.834-29.486-4.766-7.132M93 74L57.08 17.813a10.216 10.216 0 01-1.476-6.957 10.154 10.154 0 015.037-7.333l.34-.192a10.15 10.15 0 018.205-.82l.262.087a10.157 10.157 0 015.404 4.207l42.353 66.952M88.006 66.557c-13.311.218-28.753 2.924-40.206 9.357-12.805 7.193-23.839 19.326-29.155 34.663-1.536 4.426-2.592 9.113-2.653 13.88-.166 12.386 6.625 23.961 15.668 30.525s19.178 8.694 29.845 8.607l9.846-.471c4.627 4.006 11.304 3.736 15.171 2.749 4.95-1.257 7.123-4.129 8.843-6.241 2.924-3.596 3.44-8.685 2.462-13.207-.987-4.531-3.98-10.685-9.384-16.097'></path>
        <path
          stroke='#000'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit='10'
          strokeWidth='2.5'
          d='M98.315 103.41c11.051 5.159 15.748 14.106 17.712 21.788 1.021 3.998.872 8.284-.315 12.238-2.409 7.987-9.279 14.473-17.397 16.419M111.496 63.309c2.846-1.17 5.735-2.357 8.782-2.793 3.046-.446 6.328-.07 8.877 1.658 4.199 2.854 5.368 8.476 5.761 13.539.123 1.606.201 3.273-.384 4.766M45.435 77.31s-11.322-2.288-22.39 4.957C11.4 89.897 6.285 99.603 3.849 108.341c-2.435 8.738-5.996 33.773 15.477 31.442'></path>
        <defs>
          <linearGradient
            id='paint0_linear_130_3108'
            x1='74'
            x2='118.5'
            y1='11.63'
            y2='70.13'
            gradientUnits='userSpaceOnUse'>
            <stop stopColor='#AFA7E3'></stop>
            <stop offset='1' stopColor='#FFFDFC' stopOpacity='0'></stop>
          </linearGradient>
          <linearGradient
            id='paint1_linear_130_3108'
            x1='158.5'
            x2='137.5'
            y1='160.631'
            y2='129.631'
            gradientUnits='userSpaceOnUse'>
            <stop stopColor='#B0A7E4'></stop>
            <stop offset='1' stopColor='#fff' stopOpacity='0'></stop>
          </linearGradient>
        </defs>
      </svg>
    </>
  )
}
export default IconHasNoReviews
