import s from './CalendarArrow.module.sass'
import React, { FC } from 'react'
import IconArrow from '@icons/icon-calendar-arrow.svg'

interface Props {
  state: 'prev' | 'next'
  hasOverlay: boolean
  hasButton: boolean
}

const CalendarArrow: FC<Props> = ({ state, hasOverlay, hasButton }) => {
  let wrapClassName = null
  let buttonClassName = null

  if (state === 'prev') {
    wrapClassName = `${s['calendar-wrap-prev']}`
    buttonClassName = `${s['calendar-button-prev']}`
    if (hasOverlay) {
      wrapClassName += ` ${s['calendar-wrap-prev--has-overlay']}`
    }
    if (!hasButton) {
      wrapClassName += ` ${s['calendar-wrap-prev--without-button']}`
    }
  } else if (state === 'next') {
    wrapClassName = `${s['calendar-wrap-next']}`
    buttonClassName = `${s['calendar-button-next']}`
    if (hasOverlay) {
      wrapClassName += ` ${s['calendar-wrap-next--has-overlay']}`
    }
    if (!hasButton) {
      wrapClassName += ` ${s['calendar-wrap-next--without-button']}`
    }
  }

  return (
    <div className={wrapClassName}>
      {hasButton && (
        <div className={buttonClassName} aria-label={`Календарь-${state}`}>
          <span hidden>{state}</span>
          <IconArrow />
        </div>
      )}
    </div>
  )
}

export default CalendarArrow
