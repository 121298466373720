import React, { FC } from 'react'
import ContentLoader from 'react-content-loader'
import s from './IconButton.module.sass'

import { ErrorBoundary } from '../../../lib/components/ErrorBoundary'
import { useCurrentSize } from '@shared/lib/helpers/classes'
import { SKELETON_FOREGROUND_COLOR, SKELETON_MAIN_COLOR, SKELETON_SPEED } from '@shared/consts/skeleton'

interface Props {
  sizes: string
  className?: string
}

const Skeleton: FC<Props> = ({ sizes, className = '' }) => {
  const [size] = useCurrentSize(sizes)

  const sizesStyle = {
    XS: 28,
    S: 32,
    M: 40,
    L: 48,
    XL: 56,
  }

  const currentSize = sizesStyle[size]

  return (
    <ErrorBoundary>
      <ContentLoader
        speed={SKELETON_SPEED}
        backgroundColor={SKELETON_MAIN_COLOR}
        foregroundColor={SKELETON_FOREGROUND_COLOR}
        viewBox={`0 0 ${currentSize} ${currentSize}`}
        data-size={size}
        className={`${s.button} ${s.skeleton} ${className}`}>
        <rect x='0' y='0' rx='100' ry='100' width='100%' height='100%' />
      </ContentLoader>
    </ErrorBoundary>
  )
}

export default Skeleton
