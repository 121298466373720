import s from './OwnerAccessRestricted.module.sass'

import React from 'react'

import { IconAccessRestricted } from '@pages/OwnerPersonalPage/ui/components/IconAccessRestricted'

import { Title } from '@shared/ui/Typography/Title'
import { Button } from '@shared/ui/Actions/Button'
import { Text } from '@shared/ui/Typography/Text'

/**
 * @function OwnerAccessRestricted
 */
const OwnerAccessRestricted = (props) => {
  return (
    <section className={`${s['access-restricted-section']} ${s['access-restricted-section--centered']}`}>
      <div className={s['access-restricted']}>
        <IconAccessRestricted />

        <Title sizes={'h2'} tag={'h3'} className={s['access-restricted-title']}>
          Нет доступа к личному кабинету
        </Title>

        <Text className={s['access-restricted-description']} sizes={'M'}>
          <span>
            Для подключения напишите нам:{' '}
            <a className={s['access-restricted-mail-link']} href={'mailto:restaurants@afisha.ru'}>
              restaurants@afisha.ru
            </a>
            , и&nbsp;вам станут доступны статистика, объявления и&nbsp;работа с&nbsp;отзывами
          </span>
        </Text>

        <Button
          className={s['access-restricted-button']}
          sizes={'L'}
          mode={'black'}
          onClick={(ev) => props?.handleInfoButton(ev)}>
          Подробнее о личном кабинете
        </Button>
      </div>
    </section>
  )
}

export default OwnerAccessRestricted
