import React, { ReactNode, FC, useState, useEffect } from 'react'

// @ts-ignore
import s from './SectionCarousel.module.sass'

import { Carousel } from '@shared/ui/Layout/Carousel'
import { SectionTitle } from '@shared/ui/Layout/SectionTitle'
import { ErrorBoundary } from '../../../lib/components/ErrorBoundary'
import { SectionCarouselContext } from '@shared/ui/Layout/SectionCarousel/SectionCarouselContext'
import {
  SectionCarouselItems,
  SectionCarouselSkeletons,
} from '@shared/ui/Layout/SectionCarousel/ui/SectionCarouselItems'

interface Props {
  children: ReactNode[] | ReactNode
  countSlidesOnContainer?: 2 | 3 | 6
  title: string
  handleBeforeClickButtonMore?: () => boolean
  link?: string | object
  className?: string
  borderRadius?: string
  skeleton?: boolean
}

type CarouselExtensions = {
  Items: typeof SectionCarouselItems
  Skeletons: typeof SectionCarouselSkeletons
}

const SectionCarousel: FC<Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> &
  CarouselExtensions = ({
  children,
  countSlidesOnContainer,
  title,
  handleBeforeClickButtonMore,
  link,
  className = '',
  borderRadius = '16px 16px 0 0',
  skeleton = false,
  ...props
}) => {
  const [context, setContext] = useState({
    borderRadius: null,
    countSlidesOnContainer: null,
    skeleton: null,
  })

  useEffect(() => {
    setContext(() => ({
      borderRadius,
      countSlidesOnContainer,
      skeleton,
    }))
  }, [borderRadius, countSlidesOnContainer, skeleton])

  return (
    <ErrorBoundary>
      <SectionCarouselContext.Provider value={[context, setContext]}>
        <div className={`${s.container} ${className}`} {...props}>
          <Carousel analytic={{ section_name: title }}>
            <div className={s.header}>
              <SectionTitle
                className={s.address}
                title={title}
                link={link}
                handleBeforeAction={handleBeforeClickButtonMore}
                skeleton={skeleton}
              />

              {/* @ts-ignore */}
              {!skeleton && <Carousel.Controls />}
            </div>

            {children}
          </Carousel>
        </div>
      </SectionCarouselContext.Provider>
    </ErrorBoundary>
  )
}

SectionCarousel.Items = SectionCarouselItems
SectionCarousel.Skeletons = SectionCarouselSkeletons

export default SectionCarousel
