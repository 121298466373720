import React, { FC, useEffect, useMemo, useState } from 'react'
import s from './CreateReviewBlock.module.sass'
import { Title } from '@shared/ui/Typography/Title'
import { IconButton } from '@shared/ui/Actions/IconButton'
import { middlewareAuthorized } from '@shared/lib/helpers/auth'
import { InputHint } from '@shared/ui/Form/Single/InputHint'
import { BuildTextareaSend } from '@shared/ui/Form/Build/BuildTextareaSend'
import { useAppSelector } from '@app/model/store'
import { FieldBase, FieldTextarea } from '@shared/types/fields/fields'
// @ts-ignore
import iconThumb from '@icons/icon-thumb.svg'
import { ErrorBoundary } from '../../../../../../../6_shared/lib/components/ErrorBoundary'

interface Props {
  rateError: boolean
  handleClickDislike: () => void
  handleClickLike: () => void
  handleSubmit: () => void
  comment: FieldTextarea
  setComment: React.Dispatch<React.SetStateAction<FieldBase>>
}

const CreateReviewBlock: FC<Props> = ({
  rateError,
  handleClickDislike,
  handleClickLike,
  handleSubmit,
  comment,
  setComment,
}) => {
  const stateReviews = useAppSelector((state) => state.entities.restaurant_reviews)
  const stateReviewCreate = stateReviews.components.create

  const isLike = stateReviewCreate.is_like === true
  const isDislike = stateReviewCreate.is_like === false

  const classRateLike = useMemo(() => {
    return isLike ? `${s['rate-like']} ${s['active']}` : s['rate-like']
  }, [isLike])

  const classRateDislike = useMemo(() => {
    return isDislike ? `${s['rate-dislike']} ${s['active']}` : s['rate-dislike']
  }, [isDislike])

  return (
    <ErrorBoundary>
      <div className={s.wrapper}>
        <div className={s.header}>
          <Title tag='h3' sizes='h2 h2 h2 h4'>
            Оцените {stateReviews.restaurant.name}?
          </Title>

          <div className={s['rate-group']}>
            <IconButton
              icon={iconThumb}
              mode='white-border'
              sizes='XL L'
              active={isDislike}
              onClick={() => middlewareAuthorized(handleClickDislike)}
              className={classRateDislike}
            />
            <IconButton
              icon={iconThumb}
              mode='white-border'
              sizes='XL L'
              active={isLike}
              onClick={() => middlewareAuthorized(handleClickLike)}
              className={classRateLike}
            />
          </div>

          {rateError && (
            <InputHint status='error' text='Поставьте «Нравится» или «Не нравится»' sizes={'M S'} className={s.error} />
          )}
        </div>

        <form className={s.wrapper}>
          {/*// @ts-ignore*/}
          <BuildTextareaSend
            field={comment}
            setField={setComment}
            handleSubmit={() => middlewareAuthorized(handleSubmit)}
          />
        </form>
      </div>
    </ErrorBoundary>
  )
}

export default CreateReviewBlock
