import React, { FC } from 'react'

import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import ContentLoader from 'react-content-loader'
import {
  SKELETON_FOREGROUND_COLOR,
  SKELETON_MAIN_COLOR,
  SKELETON_SPEED,
  SKELETON_STYLES,
} from '@shared/consts/skeleton'
import s from '@widgets/Restaurant/RestaurantDescription/RestaurantDescription.module.sass'
import { SectionTitleSkeleton } from '@shared/ui/Layout/SectionTitle'

const Skeleton: FC = () => {
  return (
    <ErrorBoundary>
      <div>
        <div className={s.content}>
          <div className={s.buttons}>
            <ContentLoader
              speed={SKELETON_SPEED}
              backgroundColor={SKELETON_MAIN_COLOR}
              foregroundColor={SKELETON_FOREGROUND_COLOR}
              style={SKELETON_STYLES}
              className={s['button-skeleton']}>
              <rect x='0' y='0' rx='8' ry='8' className={s['button-skeleton']} />
            </ContentLoader>
          </div>

          <SectionTitleSkeleton />

          <div className={s['description-wrapper-skeleton']}>
            <ContentLoader
              speed={SKELETON_SPEED}
              backgroundColor={SKELETON_MAIN_COLOR}
              foregroundColor={SKELETON_FOREGROUND_COLOR}
              style={SKELETON_STYLES}
              width={'100%'}
              height={'104px'}>
              <rect x='0' y='0' rx='12' ry='12' className={s['description-text-skeleton--1']} />
              <rect x='0' y='0' rx='12' ry='12' className={s['description-text-skeleton--2']} />
              <rect x='0' y='0' rx='12' ry='12' className={s['description-text-skeleton--3']} />
              <rect x='0' y='0' rx='12' ry='12' className={s['description-text-skeleton--4']} />
            </ContentLoader>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  )
}

export default Skeleton
