import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  TypeInitialState,
  TypeLoading,
  TypeParamsRequest,
  TypeRestaurant,
} from '@entities/Restaurant/Reviews/model/types'
import {
  TypeMappingPaginationReviewOutput,
  TypeMappingReviewOutput,
} from '@shared/api/middleware/mappingAPI/review/type'

const initialState: TypeInitialState = {
  is_ready: false,
  loading: {
    full: true,
    addition: false,
  },
  restaurant: {
    id: null,
    name: null,
  },
  pagination: null,
  filters: {
    rates: [
      {
        id: 1,
        type: 'all',
        title: 'Все',
        count: null,
        visible: true,
        select: true,
        paramsRequest: {
          is_positive_like: undefined,
        },
      },
      {
        id: 2,
        type: 'positive',
        title: 'Положительные',
        count: null,
        visible: true,
        select: false,
        paramsRequest: {
          is_positive_like: true,
        },
      },
      {
        id: 3,
        type: 'negative',
        title: 'Отрицательные',
        count: null,
        visible: true,
        select: false,
        paramsRequest: {
          is_positive_like: false,
        },
      },
    ],
  },

  paramsRequest: {},
  lists: {
    user_reviews: [],
    other_reviews: [],
  },
  components: {
    create: {
      loading: false,
      visible: false,
      id: null,
      body: '',
      is_like: null,
    },
    delete: {
      loading: false,
      visible: false,
      id: null,
    },
    edit: {
      loading: false,
      visible: false,
      id: null,
      body: '',
      is_like: null,
    },
  },
}

const restaurantReviews = createSlice({
  name: 'restaurantReviews',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<TypeLoading>) {
      state.loading = action.payload
    },

    // Виджет готов к отображению данных
    setReadyWidget(state, action: PayloadAction<boolean>) {
      state.is_ready = action.payload
    },

    setRestaurant(state, action: PayloadAction<TypeRestaurant>) {
      state.restaurant = action.payload
    },

    setPagination(state, action: PayloadAction<TypeMappingPaginationReviewOutput>) {
      state.pagination = action.payload
    },

    setPaginationItems(state, action: PayloadAction<number | null>) {
      state.pagination.total = action.payload
    },

    setPaginationPage(state, action: PayloadAction<TypeMappingPaginationReviewOutput>) {
      state.pagination.page = action.payload.page
    },

    setParamsRequest(state, action: PayloadAction<TypeParamsRequest | null>) {
      state.paramsRequest = action.payload ? action.payload : {}
    },

    setListOtherReviews(state, action: PayloadAction<TypeMappingReviewOutput[]>) {
      state.lists.other_reviews = action.payload
    },
    addListOtherReviews(state, action: PayloadAction<TypeMappingReviewOutput[]>) {
      state.lists.other_reviews = [...state.lists.other_reviews, ...action.payload]
    },

    setListUserReviews(state, action: PayloadAction<TypeMappingReviewOutput[]>) {
      state.lists.user_reviews = action.payload
    },

    initFilterRates(
      state,
      action: PayloadAction<{ all?: number | null; positive?: number | null; negative?: number | null }>,
    ) {
      state.filters.rates = state.filters.rates.map((filter) => ({
        ...filter,
        select: filter.type === 'all',
        count: action.payload[filter.type] || null,
        visible: !!action.payload[filter.type],
      }))
    },

    changeFilter(state, action: PayloadAction<{ type: 'rates' | 'date'; id: number }>) {
      const { type, id } = action.payload
      state.filters[type] = state.filters[type].map((filter) => ({ ...filter, select: id === filter.id }))
    },

    // COMPONENTS
    showComponentCreate(
      state,
      action: PayloadAction<{
        id: number | null
        body: string | null
        is_like: boolean
      }>,
    ) {
      const { id, body, is_like } = action.payload
      state.components.create = { ...state.components.create, id, body, is_like, visible: true }
    },

    showComponentEdit(
      state,
      action: PayloadAction<{
        id: number | null
        body: string | null
        is_like: boolean
      }>,
    ) {
      const { id, body, is_like } = action.payload
      state.components.edit = { ...state.components.edit, id, body, is_like, visible: true }
    },

    changeComponentCreate(
      state,
      action: PayloadAction<{ body?: string | null; is_like?: boolean; loading?: boolean }>,
    ) {
      state.components.create = { ...state.components.create, ...action.payload }
    },

    changeComponentEdit(state, action: PayloadAction<{ body?: string | null; is_like?: boolean; loading?: boolean }>) {
      state.components.edit = { ...state.components.edit, ...action.payload }
    },

    changeComponentDelete(state, action: PayloadAction<{ loading?: boolean }>) {
      state.components.delete = { ...state.components.delete, ...action.payload }
    },

    showComponentDelete(state, action: PayloadAction<number>) {
      state.components.delete.id = action.payload
      state.components.delete.visible = true
    },

    hideComponentCreate(state) {
      state.components.create = initialState.components.create
    },

    hideComponentDelete(state) {
      state.components.delete = initialState.components.delete
    },

    hideComponentEdit(state) {
      state.components.edit = initialState.components.edit
    },

    // RESET
    resetStateReviews() {
      return initialState
    },
  },
})

export const {
  setReadyWidget,
  setLoading,
  setRestaurant,
  setPagination,
  setPaginationItems,
  setPaginationPage,
  setParamsRequest,
  setListOtherReviews,
  addListOtherReviews,
  setListUserReviews,

  // filters
  initFilterRates,
  changeFilter,

  // components
  showComponentCreate,
  showComponentDelete,
  showComponentEdit,
  changeComponentCreate,
  changeComponentEdit,
  changeComponentDelete,
  hideComponentCreate,
  hideComponentDelete,
  hideComponentEdit,

  // reset
  resetStateReviews,
} = restaurantReviews.actions

export default restaurantReviews.reducer
