import { getGuestCountString } from '@widgets/Restaurant/Booking/lib/helper'
import { getDefaultField } from '@shared/lib/fields/getDefaultField'

export const getField = (nameField) => {
  const optionsGuests = Array.from(Array(10).keys()).map((el, index) => {
    return {
      value: index + 1,
      label: getGuestCountString(index + 1),
      disabled: false,
    }
  })

  const FIELDS = {
    guest_count: getDefaultField('select', {
      name: 'guest_count',
      value: optionsGuests[1],
      settings: {
        label: null,
        placeholder: 'Гостей',
        options: optionsGuests,
      },
    }),
    date: getDefaultField('select', {
      name: 'date',
      value: null,
      settings: {
        label: null,
        placeholder: 'Дата',
        options: [],
      },
    }),
    time: getDefaultField('select', {
      name: 'time',
      value: null,
      settings: {
        label: null,
        placeholder: 'Время',
        options: [],
      },
    }),
  }

  return FIELDS[nameField]
}
