import React, { FC } from 'react'
import s from './Booking.module.sass'
import { Template } from '@widgets/Restaurant/ResturantCards/RestCard/ui/CardTypes/Template'
import { TemplateCardProps } from '@widgets/Restaurant/ResturantCards/RestCard/type/TemplateCardProps'
import TypeAndPrice from '@widgets/Restaurant/ResturantCards/RestCard/ui/Components/TypeAndPrice/TypeAndPrice'
import { useCurrentSize } from '@shared/lib/helpers/classes'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { PromoBadgeLabel } from '@shared/ui/Feedback/PromoBadges/PromoBadgeLabel'
import { QuickBookingRestCard } from '@widgets/Restaurant/QuickBooking/variants/QuickBookingRestCard'
import { EGG } from '@shared/api/analytics'

const Booking: FC<
  TemplateCardProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>
> = (props) => {
  const [size] = useCurrentSize(props.sizes)

  const price = props.data.price

  const handleClickSlot = (data) => {
    EGG.entity.booking.slots_quick_booking_slot_click(props.data, { section_index: props.analytic?.section_index || 0 })
  }

  const handleClickDots = (data) => {
    EGG.entity.booking.slots_quick_booking_dots_click(props.data, { section_index: props.analytic?.section_index || 0 })
  }

  return (
    <ErrorBoundary>
      <Template {...props}>
        {!!props.data.discounts.remarked && (
          <PromoBadgeLabel label={'Скидка'} className={s['promo-label']}>
            -{props.data.discounts.remarked}₽
          </PromoBadgeLabel>
        )}

        <TypeAndPrice
          restType={props.data.type}
          tag={props.data?.tags?.main_tag}
          priceRange={size !== 'S' ? price.level : null}
          priceLink={price.tag.url}
        />

        <QuickBookingRestCard restData={props.data} onClickDots={handleClickDots} onClickSlot={handleClickSlot} />
      </Template>
    </ErrorBoundary>
  )
}

export default Booking
