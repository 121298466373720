import { undefinedObjectToNull } from '@shared/api/middleware/mappingAPI/helpers'
import { UserMe } from '@shared/api/types/swaggerTypes'

export const mappingUser = (object: UserMe): UserMe => {
  try {
    // @ts-ignore
    const { favoritesGraph, ...other } = object
    return undefinedObjectToNull({
      ...other,
      favorites_objects: favoritesGraph || [],
    })
  } catch (error) {
    console.error(error)
    return null
  }
}
