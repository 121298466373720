import React, { FC } from 'react'
import s from './CarouselShadow.module.sass'

export type TypeShadowData =
  | {
      color?: string
      width?: string
    }
  | boolean

interface Props {
  mode: 'start' | 'end'
  shadow: TypeShadowData
  isShow: boolean
}
const CarouselShadow: FC<Props> = ({ shadow, mode, isShow }) => {
  if (!shadow) return

  const useColor = typeof shadow !== 'boolean' ? shadow.color : '#fff'
  const useWidth = typeof shadow !== 'boolean' ? shadow.width : '80px'

  const styles = {
    background: '',
    width: '',
  }

  if (mode === 'start') {
    styles.background = `linear-gradient(to right, ${useColor}, rgba(255, 255, 255, 0))`
    styles.width = useWidth
  } else if (mode === 'end') {
    styles.background = `linear-gradient(to left, ${useColor}, rgba(255, 255, 255, 0))`
    styles.width = useWidth
  }

  return <div className={`${s.shadow} ${s[`shadow-${mode}`]}`} style={styles} data-is-show={isShow}></div>
}

export default CarouselShadow
