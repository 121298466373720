import s from './Template.module.sass'
import React, { FC, ReactNode } from 'react'
import { CardTwoBlockSkeleton } from '@shared/ui/Layout/CardTwoBlock'
import { useCurrentSize } from '@shared/lib/helpers/classes'

// @ts-ignore
import IconLike from '@icons/icon-like.svg'
// @ts-ignore
import IconChevron from '@icons/icon-chevron-medium.svg'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'

export interface TemplateCardProps {
  sizes: string // L ML M S
  children?: ReactNode
  className?: string
  animate?: boolean
}

const Skeleton: FC<
  TemplateCardProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>
> = ({ sizes, children, className = '', animate = true }) => {
  const [size] = useCurrentSize(sizes)

  const defaultAttributes = {
    rx: '8',
    ry: '8',
    'data-size': size,
  }
  return (
    <ErrorBoundary>
      <CardTwoBlockSkeleton sizes={sizes} className={`${s.card} ${className}`} animate={animate}>
        <CardTwoBlockSkeleton.Img />

        <CardTwoBlockSkeleton.Content className={s.content}>
          {children || (
            <>
              <rect className={`${s['skeleton-item']} ${s['skeleton-item--1']}`} {...defaultAttributes} />
              <rect className={`${s['skeleton-item']} ${s['skeleton-item--2']}`} {...defaultAttributes} />
              <rect className={`${s['skeleton-item']} ${s['skeleton-item--3']}`} {...defaultAttributes} />
            </>
          )}
        </CardTwoBlockSkeleton.Content>
      </CardTwoBlockSkeleton>
    </ErrorBoundary>
  )
}

export default Skeleton
