import { useEffect } from 'react'
import * as Sentry from '@sentry/browser'

const DSN_PROD = 'https://df56b2e931664449bfe0b1df422bc5d5@sentry-rests.afisha.ru/107'
const DSN_DEV = 'https://d6575b6239684ab6b1dba341bc8ab854@sentry-rests.afisha.ru/106'

const GITLAB_STATUS = process.env.GITLAB_STATUS || null
const IS_PROD = GITLAB_STATUS === '0'
const IS_STAGE = process.env.IS_STAGE || null

// Подключение и настройка Sentry
export const useErrorCatcher = () => {
  useEffect(() => {
    if (IS_PROD || IS_STAGE) {
      Sentry.init({ dsn: IS_PROD ? DSN_PROD : DSN_DEV })
    }
  }, [])
}
