import React, { FC, useMemo } from 'react'
import s from './TypeAndPrice.module.sass'
import { Text } from '@shared/ui/Typography/Text'
import { Range } from '@widgets/Restaurant/ResturantCards/RestCard/type/type'
import { Price } from '@widgets/Restaurant/ResturantCards/RestCard/ui/Components/Price'
import { useRouter } from 'next/router'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'

interface Props {
  restType: string
  tag?: {
    id: number
    name: string
    url: string
  }
  priceRange?: Range<1, 5>
  priceLink?: string
}

const TypeAndPrice: FC<Props> = ({ tag, restType, priceRange = 3, priceLink }) => {
  const router = useRouter()

  const goToUrlType = () => {
    if (getTag?.url) router.push(getTag?.url, getTag?.url, { scroll: false })
  }

  // в приоритете вывести main_tag,
  // если его нет то тип ресторана,
  const getTag = {
    id: tag?.id || 0,
    name: tag?.name || restType,
    url: tag?.url || '',
  }

  return (
    <ErrorBoundary>
      <div className={s.wrapper}>
        <Text tag={'span'} sizes={'S'} className={getTag?.url ? s['type-url'] : ''} onClick={goToUrlType}>
          {getTag?.name}
        </Text>
        <Price priceRange={priceRange} priceLink={priceLink} />
      </div>
    </ErrorBoundary>
  )
}

export default TypeAndPrice
