/**
 * @function generateDataString - TODO описание
 * @returns - ХХ месяц ХХХХ
 */
export const generateDataString = (createdDate: string | null) => {
  if (!createdDate) return null

  const reviewTimestamp = Date.parse(createdDate.split(' ')[0])
  const dateOptions = {
    timeZone: 'Europe/Moscow',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }
  // @ts-ignore
  const reviewConvertedDate = new Date(reviewTimestamp).toLocaleString('ru-RU', dateOptions).replace('г.', '').trim()
  return reviewConvertedDate
}
