import React, { FC, useState } from 'react'

import { useAppDispatch, useAppSelector } from '@app/model/store'
import SectionDate from './BookingSectionDate'
import SectionTime from './BookingSectionTime'

import { setSelectedTime } from '@widgets/Restaurant/Booking/model/reduce'
import { setDateAC } from '@widgets/Restaurant/Booking/model/createActions'
import { TypeExtendedScheduleTimeSlot } from '@shared/types/schedule/types'

import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { EGG } from '@shared/api/analytics'

const BookingDateTime: FC = () => {
  const { isDesktop, isMobile } = useClientResponsive()
  const { restaurantSource, form } = useAppSelector((store) => store.booking)
  const { dateField, timeField, isErrorsVisible } = form
  const { schedule } = restaurantSource
  const dispatch = useAppDispatch()

  // * --------------- Section. Date --------------- *

  const isDateErrorVisible = isErrorsVisible && !dateField.valid
  const isTimeErrorVisible = isErrorsVisible && !timeField.valid

  const handleSetDate = (date: Date) => {
    dispatch(setDateAC(date))
  }

  // * --------------- Section. Time --------------- *

  const handleSetTime = (timeString: string) => {
    const selectedTimeObj =
      timeField.options.find((field: TypeExtendedScheduleTimeSlot | null) => field?.value === timeString) || null
    dispatch(setSelectedTime(selectedTimeObj || undefined))

    EGG.entity.booking.click_reserve_time({
      input: timeString,
    })
  }

  return (
    <ErrorBoundary>
      <SectionDate dateField={dateField} onSelectDate={handleSetDate} error={isDateErrorVisible} schedule={schedule} />
      <SectionTime handleSetTime={handleSetTime} isTimeErrorVisible={isTimeErrorVisible} timeField={timeField} />
    </ErrorBoundary>
  )
}

export default BookingDateTime
