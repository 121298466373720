/**
 * @usage import { useEffectOnce } from '@shared/lib/hooks/useEffectOnce'
 * @usage useEffectOnce(() => { console.log('single-call fn') }, [])
 */

import { useEffect, useRef } from 'react'

const useEffectOnce = (thisEffect) => {
  const destroyFunc = useRef()
  const calledOnce = useRef(false)
  const renderAfterCalled = useRef(false)
  if (calledOnce.current) {
    renderAfterCalled.current = true
  }
  useEffect(() => {
    if (calledOnce.current) {
      return
    }
    calledOnce.current = true
    destroyFunc.current = thisEffect()
    return () => {
      if (!renderAfterCalled.current) {
        return
      }
      if (destroyFunc.current && typeof destroyFunc.current != 'boolean') {
        // @ts-ignore
        destroyFunc.current()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export { useEffectOnce }
