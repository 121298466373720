import s from './SelectionsSection.module.sass'

import React, { FC } from 'react'
import { useAppSelector } from '@app/model/store'
import { CompilationGrid } from '@widgets/News/CompilationGrid'
import { SectionTitle } from '@shared/ui/Layout/SectionTitle'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { TypeMappingSelectionOutput } from '@shared/api/middleware/mappingAPI/selection/type'

interface Props {
  selectionsList: TypeMappingSelectionOutput[]
  skeleton?: boolean
}

const SelectionsSection: FC<Props> = ({ selectionsList, skeleton = false }) => {
  const storeGlobal = useAppSelector((store) => store.global)

  return (
    <ErrorBoundary>
      <div className={s['selections-wrap']}>
        <SectionTitle
          title={'Подборки Афиши Рестораны'}
          link={`/${storeGlobal.currentCity.slug}/restaurants/afisha_selected/`}
          skeleton={skeleton}
        />

        <CompilationGrid
          type={'selections'}
          counts={'6 6 4'}
          list={selectionsList}
          analytic={{
            section_name: 'Подборки Афиши Рестораны',
          }}
          skeleton={skeleton}
        />
      </div>
    </ErrorBoundary>
  )
}
export default SelectionsSection
