import { sendClickHouseAnalytic } from '@shared/api/analytics/template'
import { PickAnalytic } from '@shared/api/analytics/types/types'

export const EGGFooter = {
  menu_item_click: (props: PickAnalytic<'button_name'>) =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      section_name: 'footer',
      element_type: 'info_button',
      ...props,
    }),

  social_network_click: (props: PickAnalytic<'social_network'>) =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      section_name: 'footer',
      element_type: 'social_network',
      ...props,
    }),
}
