import React, { FC } from 'react'
import s from './DefaultTag.module.sass'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import ContentLoader from 'react-content-loader'
import {
  SKELETON_FOREGROUND_COLOR,
  SKELETON_MAIN_COLOR,
  SKELETON_SPEED,
  SKELETON_STYLES,
} from '@shared/consts/skeleton'
import { useCurrentSize } from '@shared/lib/helpers/classes'
// ЗАГЛУШКА/ПРИМЕР КОМПОНЕНТА (УДАЛИТЬ КОММЕНТАРИЙ ПОСЛЕ ЗАВЕРШЕНИЯ РАБОТЫ)

interface Props {
  className?: string
}

const Skeleton: FC<Props> = ({ className = '' }) => {
  return (
    <ErrorBoundary>
      <ContentLoader
        speed={SKELETON_SPEED}
        backgroundColor={SKELETON_MAIN_COLOR}
        foregroundColor={SKELETON_FOREGROUND_COLOR}
        style={SKELETON_STYLES}
        className={`${s.skeleton} ${className}`}>
        <rect x='0' y='0' rx='24' ry='24' className={s['skeleton-rect']} />
      </ContentLoader>
    </ErrorBoundary>
  )
}

export default Skeleton
