import React, { FC } from 'react'
import s from './Metro.module.sass'
import { Text } from '@shared/ui/Typography/Text'
import { useRouter } from 'next/router'
import { useAppSelector } from '@app/model/store'
import { ErrorBoundary } from '../../../lib/components/ErrorBoundary'

interface Props {
  colors: any
  name: string
  link?: string
  className?: string
}

/**
 * @function prepareMetroColors - подготовка цветов
 * @param colorValue с бека приходит значение в HEX формате, без хештега
 * @description временно приходит в строковом формате, в будущем может должно приходить в ввиде массива
 * @returns массив состоящий из 1-4 цветов метро, каждый из которых при отсуствии заменяется на черный
 */
const prepareMetroColors = (colorValue) => {
  const defaultMetroColor = '262626'
  return Array.isArray(colorValue)
    ? [...colorValue.map((thisColor) => `#${thisColor.replace('#', '') || defaultMetroColor}`)]
    : [`#${colorValue.replace('#', '') || defaultMetroColor}`]
}

const Metro: FC<Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLLIElement>, HTMLLIElement>> = ({
  colors,
  name,
  link,
  className = '',
  ...props
}) => {
  const router = useRouter()

  const goToLink = () => {
    if (link) router.push(link, link, { scroll: false })
  }

  const thisColors = colors ? prepareMetroColors(colors) : null

  return (
    <ErrorBoundary>
      <li className={`${s.metro} ${link ? s['metro-link'] : ''} ${className}`} onClick={() => goToLink()} {...props}>
        {thisColors && (
          <div className={s.dots}>
            {thisColors.map((colorItem) => {
              return <i className={s.dot} style={{ backgroundColor: colorItem }} key={name + colorItem} />
            })}
          </div>
        )}

        <Text tag={'span'} sizes={'S'}>
          {name}
        </Text>
      </li>
    </ErrorBoundary>
  )
}

export default Metro
