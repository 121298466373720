import axios from 'axios'
import { throwError } from 'react-multi-carousel/lib/utils'

const IS_DEV = process.env.NODE_ENV === 'development'
// const BASE_URL = IS_DEV
//   ? 'http://artemis.dev.rests.afisha.ru/rests/api/public/v1'
//   : 'https://www.afisha.ru/rests/api/public/v1'

const instance = axios.create({
  withCredentials: true,
  timeout: 20000,

  // baseURL: BASE_URL,
})

const cacheData = {}

type typeParamsGet = {
  url: string
  params?: object
  cache?: boolean
  repeated?: boolean
  headers?: object
  config?: object
}
type typeParamsPost = {
  url: string
  body?: object
  headers?: object
  repeated?: boolean
  config?: object
}
type typeParamsDelete = {
  url: string
  body?: object
  repeated?: boolean
  headers?: object
  config?: object
}

// Функция для повторной отправки запроса в случае ошибки сервера.
// При значении repeated = 1, функция просто отправит запрос и вернет ответ
const repeatedRequest = async (callback, count = 0) => {
  if (count > 0 && IS_DEV) console.log(`🔃 Еще одна попытка запроса - ${count}  ⬇`)

  if (count === 0) {
    try {
      return await callback()
    } catch {
      return await repeatedRequest(callback, count + 1)
    }
  } else if (count > 0 && count < 3) {
    try {
      return await new Promise((resolve, reject) => {
        setTimeout(async () => {
          callback()
            .then((response) => resolve(response))
            .catch((err) => reject(err))
        }, 1000)
      })
    } catch {
      const isLastCount = count === 2
      if (isLastCount) throw Error()
      else await repeatedRequest(callback, count + 1)
    }
  } else return await callback()
}

const baseApiRequest = {
  get: ({ url, headers = null, config = {} }) => {
    IS_DEV && console.info('🚀 GET', url)

    return instance
      .get(url, { ...config, headers })
      .then((response) => {
        IS_DEV && console.info('✅ GET', url)
        return response.data
      })
      .catch((error) => {
        if (error.response) {
          console.error(`❌ GET ${url}, response: ${JSON.stringify(error.response.data)}.`)
        } else console.error(`❌ GET ${url}, return: ${error}.`)

        throw Error
      })
  },

  post: ({ url, body = null, headers, config = {} }) => {
    IS_DEV && console.info('🚀 POST', url)
    return instance
      .post(url, body, {
        ...config,
        headers: {
          ContentType: 'application/json',
          ...headers,
        },
      })
      .then((res) => {
        IS_DEV && console.info('✅ POST', url)
        return res.data
      })
      .catch((error) => {
        if (error.response) {
          console.error(`❌ POST ${url}, response: ${JSON.stringify(error.response.data)}.`)
        } else {
          console.error(`❌ POST ${url}, return: ${error}.`)
        }
        const serverErrorMsg = error?.response?.data?.non_field_errors

        throw new Error(serverErrorMsg)
      })
  },

  put: ({ url, body, headers, config = {} }) => {
    IS_DEV && console.info('🚀 PUT', url, body)

    return instance
      .put(url, body, {
        ...config,
        headers: {
          ContentType: 'application/json',
          ...headers,
        },
      })
      .then((res) => {
        IS_DEV && console.info('✅ PUT', url)
        return res.data
      })
      .catch((error) => {
        if (error.response) {
          console.error(`❌ PUT ${url}, response: ${JSON.stringify(error.response.data)}.`)
        } else console.error(`❌ PUT ${url}, return: ${error}.`)
        throw Error
      })
  },

  patch: ({ url, body, headers, config = {} }) => {
    IS_DEV && console.info('🚀 PATCH', url)

    return instance
      .patch(url, body, {
        ...config,
        headers: {
          ContentType: 'application/json',
          ...headers,
        },
      })
      .then((res) => {
        IS_DEV && console.info('✅ PATCH', url)
        return res.data
      })
      .catch((error) => {
        if (error.response) {
          console.error(`❌ PATCH ${url}, response: ${JSON.stringify(error.response.data)}.`)
        } else console.error(`❌ PATCH ${url}, return: ${error}.`)
        throw Error
      })
  },

  delete: ({ url, body, config = {} }) => {
    IS_DEV && console.info('🚀 DELETE', url)

    return instance
      .delete(url, {
        data: body,
        ...config,
      })
      .then((res) => {
        IS_DEV && console.info('✅ DELETE', url)
        return res.data
      })
      .catch((error) => {
        if (error.response) {
          console.error(`❌ DELETE ${url}, response: ${JSON.stringify(error.response.data)}.`)
        } else console.error(`❌ DELETE ${url}, return: ${error}.`)
        throw Error
      })
  },
}

const IS_CACHE_FEATURE_ACTIVE = false

export const apiRequest = {
  get: async ({ url, params, cache = false, repeated = false, headers, config }: typeParamsGet) => {
    let urlParams = ''

    params &&
      Object.entries(params).forEach(([key, value]) => {
        urlParams += `${!urlParams.length ? '?' : '&'}`

        // TODO: TEMP use value
        // urlParams += `${typeof value === 'boolean' ? key : `${key}=${value}`}`
        urlParams += `${key}=${value}`
      })
    const fullUrl = url + urlParams
    const cacheKey = url + urlParams + (headers ? JSON.stringify(headers) : '')
    if (IS_CACHE_FEATURE_ACTIVE && cache && cacheData[cacheKey]) {
      console.info(`💾  USE CACHE ${cacheKey}`)
      return cacheData[cacheKey]
    }

    // try {
    const response = repeated
      ? await repeatedRequest(() => baseApiRequest.get({ url: fullUrl, headers, config }))
      : await baseApiRequest.get({ url: fullUrl, headers, config })

    if (IS_CACHE_FEATURE_ACTIVE && response && cache) cacheData[cacheKey] = response

    return response
    // } catch (error) {
    //   console.error(`❌ SOME ERR: ${error}.`)
    // }
  },

  post: async ({ url, body, headers, repeated = false, config }: typeParamsPost) => {
    return repeated
      ? await repeatedRequest(() => baseApiRequest.post({ url, body, headers, config }))
      : await baseApiRequest.post({ url, body, headers, config })
  },

  put: async ({ url, body, headers, repeated = false, config }: typeParamsPost) => {
    return repeated
      ? await repeatedRequest(() => baseApiRequest.put({ url, body, headers, config }))
      : await baseApiRequest.put({ url, body, headers, config })
  },

  patch: async ({ url, body, headers, repeated = false, config }: typeParamsPost) => {
    return repeated
      ? await repeatedRequest(() => baseApiRequest.patch({ url, body, headers, config }))
      : await baseApiRequest.patch({ url, body, headers, config })
  },

  delete: async ({ url, body, headers, repeated = false, config }: typeParamsDelete) => {
    return repeated
      ? await repeatedRequest(() => baseApiRequest.delete({ url, body, config }))
      : await baseApiRequest.delete({ url, body, config })
  },
}
