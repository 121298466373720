import React, { FC, memo, useMemo } from 'react'
import s from './Text.module.sass'
import { TextTag } from './types'
import { useCurrentSize } from '@shared/lib/helpers/classes'
import { ErrorBoundary } from '../../../lib/components/ErrorBoundary'

interface Props {
  className?: string
  children: React.ReactNode
  /** Размеры для разрешений large-desktop, desktop, tablet, mobile в формате строки -
   * 'M'(для всех разрешений M), 'M S'(для десктопа и мобилки), 'XL M S'(для десктопа, планшета и мобилки)*/
  sizes: string
  /** Для параграфов. ( только при size - XL, ML, M ) */
  paragraph?: boolean
  /** Шрифт с увеличенной жирностью. ( только при size - XL, L, M ) */
  medium?: boolean
  /** Для чисел. ( только при size - XL, M, S) */
  numbers?: boolean
  /** Текст в верхнем регистре. ( только при size - M, S ) */
  uppercase?: boolean
  tag?: TextTag
}
const Text: FC<Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>> =
  React.forwardRef(
    (
      {
        className = '',
        tag = 'p',
        children,
        sizes,

        // modifier
        paragraph = false,
        medium = false,
        numbers = false,
        uppercase = false,

        ...props
      },
      ref,
    ) => {
      const [size] = useCurrentSize(sizes)
      const TagText = tag

      const modifiersAttr = useMemo(() => {
        const objectModifiers: { [key: string]: boolean } = {
          paragraph,
          medium,
          numbers,
          uppercase,
        }

        const result = {}

        Object.keys(objectModifiers).forEach((key) => {
          if (objectModifiers[key]) {
            result[`data-${key}`] = objectModifiers[key]
          }
        })

        return result
      }, [paragraph, medium, numbers, uppercase])

      return (
        <ErrorBoundary>
          {/*// @ts-ignore*/}
          <TagText className={`${s.text} ${className}`} data-size={size} {...modifiersAttr} {...props}>
            {children}
          </TagText>
        </ErrorBoundary>
      )
    },
  )

export default memo(Text)
