import { createAsyncThunk } from '@reduxjs/toolkit'
import { API } from '@shared/api'
import { generateDateOption } from '@shared/lib/helpers/schedule/schedule'
import { TypeExtendedScheduleDay } from '@shared/types/schedule/types'
import { TypeMappingScheduleWeek } from '@shared/api/middleware/mappingAPI/restaurant/type'

type TypeFetchRemarkedSlots = {
  guests: number
  date: TypeExtendedScheduleDay
  restSlug: string
}
export const fetchRemarkedSlots = createAsyncThunk(
  'booking/fetchRemarkedSlots',
  async (action: TypeFetchRemarkedSlots, { dispatch, getState }) => {
    const { guests, date, restSlug } = action

    return new Promise((resolve, reject) => {
      API.booking_get_slots({ numOfPlaces: guests, date: date.value, rest: restSlug })
        .then((res) => {
          // @ts-ignore
          res.slots?.length ? resolve({ date: date, slots: res.slots }) : reject({ date: date, slots: null })
        })
        .catch((er) => reject({ date: date, slots: null }))
    })
  },
)

type TypeInitFetchRemarkedSlotsNearTwoDays = {
  guests: number
  restSlug: string
  schedule: TypeMappingScheduleWeek
}
export const initFetchRemarkedSlotsNearTwoDays = createAsyncThunk(
  'booking/initFetchRemarkedSlotsNearTwoDays',
  async (action: TypeInitFetchRemarkedSlotsNearTwoDays, { dispatch, getState }) => {
    const { guests, restSlug, schedule } = action
    const addZero = (number: number): string => `${`00${number}`.slice(-2)}`

    const fetchArr = Array.from(Array(2).keys()).map((el, index) => {
      const fetchDate = new Date(Date.now())
      fetchDate.setDate(fetchDate.getDate() + index)

      const currentYear = fetchDate.getFullYear()
      const currentMonth = addZero(fetchDate.getMonth() + 1)
      const currentDay = addZero(fetchDate.getDate())
      const currentDateFormatted = `${currentYear}-${currentMonth}-${currentDay}`

      return API.booking_get_slots({ numOfPlaces: guests, date: currentDateFormatted, rest: restSlug })
    })

    const response = await Promise.all(fetchArr)

    const result = []
    if (response?.length) {
      response.forEach((daySlots) => {
        if (!daySlots.slots?.length) return
        const firstSlot = daySlots.slots[0]

        const dateOption = generateDateOption(0, schedule, new Date(firstSlot.date))
        result.push({ dateOption: dateOption, timeOptions: daySlots.slots })
      })
    }

    if (result.length) return result
    else throw Error()
  },
)
