import React, { FC, useState, useEffect } from 'react'
import s from './RestaurantNews.module.sass'
import { NewsCard, NewsCardSkeleton } from '@widgets/News/NewsCard'
import { API } from '@shared/api'
import { SectionCarousel } from '@shared/ui/Layout/SectionCarousel'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { useAppSelector } from '@app/model/store'
import { RestCardSkeleton } from '@widgets/Restaurant/ResturantCards/RestCard'

const startFetchNews = async (stateRestId, responseCallback, callbackLoading) => {
  callbackLoading(true)
  const newsResponse = await API.news_list({
    params: {
      rid_in: stateRestId,
      page: 1,
      page_size: 4,
    },
  })
  callbackLoading(false)

  const newsArray = newsResponse.results || []
  const preparedNewsArray = [...newsArray]

  responseCallback(preparedNewsArray)
}

interface Props {
  restId: number | string
}

const RestaurantNews: FC<Props> = ({ restId }) => {
  const stateGlobal = useAppSelector((state) => state.global)
  const [newsData, setNewsData] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    setNewsData([])
    if (restId) startFetchNews(restId, setNewsData, setLoading)

    return () => {
      setLoading(true)
      setNewsData([])
    }
  }, [restId])

  const isShowComponent = (newsData.length === 0 && loading) || (newsData.length > 0 && !loading)

  return (
    isShowComponent && (
      <ErrorBoundary>
        <div className={s['news-wrap']}>
          <section>
            <SectionCarousel countSlidesOnContainer={3} title={'Материалы о ресторане'} skeleton={loading}>
              <SectionCarousel.Items>
                {newsData.map((news, index) => (
                  <NewsCard
                    sizes={index % 3 === 0 ? 'L2x1 M2x1 M2x1 1x1' : 'L1x1 M1x1 M1x1 1x1'}
                    key={news.id}
                    data={news}
                    analytic={{
                      section_name: `Материалы о ресторане`,
                      section_index: index,
                    }}
                  />
                ))}
              </SectionCarousel.Items>
              <SectionCarousel.Skeletons>
                {Array.from(Array(4).keys()).map((i, idx) => (
                  <NewsCardSkeleton key={idx} sizes={idx % 3 === 0 ? 'L2x1 M2x1 M2x1 1x1' : 'L1x1 M1x1 M1x1 1x1'} />
                ))}
              </SectionCarousel.Skeletons>
            </SectionCarousel>
          </section>
        </div>
      </ErrorBoundary>
    )
  )
}

export default RestaurantNews
