import React, { FC } from 'react'

import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import ContentLoader from 'react-content-loader'
import { SKELETON_FOREGROUND_COLOR, SKELETON_MAIN_COLOR, SKELETON_SPEED } from '@shared/consts/skeleton'
import s from '@shared/ui/Layout/Cards/MainCard/MainCard.module.sass'
import { useCurrentSize } from '@shared/lib/helpers/classes'

interface Props {
  sizes?: string
  classNameWrapper?: string
}

const Skeleton: FC<Props> = ({ sizes = 'XL S', classNameWrapper = '' }) => {
  const [size] = useCurrentSize(sizes)

  return (
    <ErrorBoundary>
      <ContentLoader
        speed={SKELETON_SPEED}
        backgroundColor={SKELETON_MAIN_COLOR}
        foregroundColor={SKELETON_FOREGROUND_COLOR}
        className={`${s.card} ${classNameWrapper}`}
        data-cover-size={size}>
        <rect x='0' y='0' rx='32' ry='32' width={'100%'} height={'100%'} />
      </ContentLoader>
    </ErrorBoundary>
  )
}

export default Skeleton
