import React, { FC } from 'react'
import s from './DailyCard.module.sass'
import CardTwoBlock from '@shared/ui/Layout/CardTwoBlock/CardTwoBlock'
import { Text } from '@shared/ui/Typography/Text'
import IconDaily from '@icons/icon-afisha-daily-white.svg'

interface Props {
  title: string
  url: string
  img: string
}

const DailyCard: FC<Props> = ({ title, url, img }) => {
  return (
    <CardTwoBlock sizes={'L ML'} href={url}>
      {/*// @ts-ignore*/}
      <CardTwoBlock.Img img={img} classNameWrapper={s.img}>
        <IconDaily className={s.logo} />
        {/*// @ts-ignore*/}
      </CardTwoBlock.Img>
      {/*// @ts-ignore*/}
      <CardTwoBlock.Content>
        <Text sizes={'ML M'}>{title}</Text>
        {/*// @ts-ignore*/}
      </CardTwoBlock.Content>
    </CardTwoBlock>
  )
}

export default DailyCard
