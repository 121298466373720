export const defaultRules = {
  weight: {
    max: 20, // Mb
    min: 0, // Mb
  },
  width: {
    max: 7000,
    min: 800,
  },
  height: {
    max: 7000,
    min: 600,
  },
  formats: ['jpeg', 'png'],
}
