/**
 * @function parseCookie - TODO описание
 * @usage - import { parseCookie } from '@shared/lib/helpers/parseCookie.js'
 */

function parseCookie(name) {
  let cookieValue = null

  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';')

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim()

      if (cookie.substring(0, name.length + 1) === name + '=') {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1))
        break
      }
    }
  }
  return cookieValue
}

export default parseCookie
