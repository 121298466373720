import s from './OwnerRestInfoTextarea.module.sass'

import React, { useEffect, useRef } from 'react'

import { Text } from '@shared/ui/Typography/Text'
import { Button } from '@shared/ui/Actions/Button'

import IconArrow from '@icons/icon-submit-arrow.svg'
import IconDone from '@icons/icon-submit-done.svg'
import { IconButton } from '@shared/ui/Actions/IconButton'

const OwnerRestInfoTextarea = (props) => {
  const isResize = props?.resize
  const fieldHeight = props?.height ? props.height : 129

  const textarea = useRef()
  const wrapper = useRef()

  const handleResize = () => {
    if (textarea.current && wrapper.current && isResize) {
      // @ts-ignore
      textarea.current.style.height = '0'
      // @ts-ignore
      textarea.current.style.height = textarea.current.scrollHeight + 'px'

      setTimeout(() => {
        if (textarea.current && wrapper.current) {
          // @ts-ignore
          wrapper.current.style.height = textarea.current.scrollHeight + 'px'
        }
      }, 0)
    }
  }

  useEffect(() => {
    handleResize()
  }, [handleResize])

  const handleSubmitAboutText = (ev) => {
    !props.isDescrLoad && props.handleSubmitAboutText(ev)
  }

  return (
    <>
      <div className={s['restaurant-info-textarea']} ref={wrapper} data-textarea-wrapper>
        <textarea
          className={s['restaurant-info-textarea-object']}
          ref={textarea}
          onChange={(ev) => props.onChange(ev)}
          onBlur={(ev) => props.onBlur(ev)}
          onFocus={(ev) => props.onFocus(ev)}
          name={props.name}
          id={props.name}
          inputMode='text'
          autoComplete='off'
          autoCorrect='off'
          spellCheck={false}
          maxLength={14000}
          value={props.value}
          data-is-valid={props.isValid}
          data-is-empty={props.isEmpty}
          placeholder={props.placeholder}
          style={isResize ? { minHeight: fieldHeight, overflow: 'hidden' } : { height: fieldHeight }}
        />

        <IconButton
          mode='black'
          sizes='M'
          onClick={(ev) => {
            return !props.isDescrDone ? handleSubmitAboutText(ev) : false
          }}
          icon={!props.isDescrDone ? IconArrow : IconDone}
          id={s.button}
          disabled={props.isSubmitInfoDisabled}
        />

        {props?.value && (
          <Text sizes={'S'} className={s['restaurant-info-textarea-counter']}>
            {props.value.length} / 4000
          </Text>
        )}
      </div>
    </>
  )
}
export default OwnerRestInfoTextarea
