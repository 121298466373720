import React, { ReactNode, FC, useState, useMemo, useEffect, useContext, memo } from 'react'
import { KeenSliderOptions, useKeenSlider } from 'keen-slider/react'
import s from './Carousel.module.sass'
import { CarouselContext } from './CarouselContext'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { TypeShadowData } from '@shared/ui/Layout/Carousel/ui/CarouselShadow/CarouselShadow'
import { CarouselShadow } from '@shared/ui/Layout/Carousel/ui/CarouselShadow'
import { WheelControls } from '@shared/ui/Layout/Carousel/helpers/pluginForSlider'
import { useRouter } from 'next/router'

interface Props {
  children: ReactNode[]
  // Видимые на десктопе слайды в контейнере. Для определения показа элементов навигации
  countSlidesOnContainer?: 2 | 3 | 6
  // Сколько слайдов перелистывать по клику на стрелку
  slideMoveCount?: number
  optionsKeenSlider?: object
  className?: string
  spacingMobile?: number
  spacingDesktop?: number
  disabled?: boolean
  shadow?: TypeShadowData // Адаптивный градиент\тень по бокам слайдера
  slideChanged?: (data: KeenSliderOptions) => void
  animationStarted?: (data: KeenSliderOptions) => void
  animationStopped?: (data: KeenSliderOptions) => void
  animationEnded?: (data: KeenSliderOptions) => void
  dragStarted?: (data: KeenSliderOptions) => void
  dragEnded?: (data: KeenSliderOptions) => void
  draggedDirection?: (data: KeenSliderOptions, direction: 'next' | 'prev') => void
}

const CarouselSlider: FC<Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = ({
  children,
  countSlidesOnContainer,
  optionsKeenSlider,
  spacingMobile = 8,
  spacingDesktop = 16,
  slideMoveCount = 1,
  disabled = false,
  shadow,
  className = '',

  animationStarted,
  animationStopped,
  animationEnded,
  dragStarted,
  dragEnded,
  draggedDirection,
  ...props
}) => {
  const { isDesktop, isMobile } = useClientResponsive()
  const [loading, setLoading] = useState(true)
  const [context, setContext] = useContext(CarouselContext)
  const [draggedStateDirection, setDraggedStateDirection] = useState(null)
  const gapWidth = isMobile ? spacingMobile : spacingDesktop
  const router = useRouter()
  const [isSliderAnimation, setIsSliderAnimation] = useState(false)
  const [isSliderDraggable, setIsSliderDraggable] = useState(false)

  const hasVelocitySlider = isSliderAnimation || isSliderDraggable
  const isShowShadowStart = hasVelocitySlider || !!context.details?.prevIndex
  const isShowShadowEnd = hasVelocitySlider || !!context.details?.nextIndex

  const sliderOptions = {
    rubberband: false,
    disabled: disabled,
    slides: {
      perView: 'auto',
      spacing: gapWidth,
    },
    loop: false,
    selector: `.${s.slide}`,
    slideChanged: (data) => {
      setContext((prev) => ({ ...prev, details: getDetailsData(data) }))
    },
    animationStarted: (data) => {
      const details = data.track.details
      // Данное событие либы вызывается даже когда юзер пытается перетащить слайдер в стену, т.е. в ту сторону в которую он уже упирается
      // Если начало анимации начинается в этих позициях, то перехода на новый слайд не будет и событие отправлять не будем
      if (details.position === details.min || details.position === details.max) return
      setIsSliderAnimation(() => true)
      animationStarted && animationStarted(data)
    },
    animationStopped: (data) => animationStopped && animationStopped(data),
    animationEnded: (data) => {
      setIsSliderAnimation(() => false)
      animationEnded && animationEnded(data)
    },
    dragEnded: (data) => {
      setIsSliderDraggable(() => false)
      dragEnded && dragEnded(data)
    },
    dragged: (data) => {
      const velocity = data.track.velocity()

      if (velocity > 0) setDraggedStateDirection('next')
      else if (velocity < 0) setDraggedStateDirection('prev')
      else if (velocity === 0) setDraggedStateDirection(null)
    },
    ...optionsKeenSlider,
  }
  // @ts-ignore
  const [sliderRef, instanceRef] = useKeenSlider(sliderOptions, [WheelControls])

  useEffect(() => {
    if (draggedStateDirection) {
      draggedDirection && draggedDirection(instanceRef.current, draggedStateDirection)
    }
  }, [draggedStateDirection])

  useEffect(() => {
    if (draggedStateDirection) {
      setIsSliderDraggable(() => true)
      dragStarted && dragStarted(instanceRef.current)
    } else {
      setIsSliderDraggable(() => false)
      dragEnded && dragEnded(instanceRef.current)
    }
  }, [!!draggedStateDirection])

  useEffect(() => {
    instanceRef.current.update(sliderOptions, 0)
    // @ts-ignore
    setContext((prev) => ({
      ...prev,
      slideMoveCount: slideMoveCount,
      slides: children,
      countSlidesOnContainer: getCountSlidesOnContainer,
      details: getDetailsData(instanceRef.current),
      options: sliderOptions,
    }))
  }, [isMobile, children?.length, router.asPath])

  useEffect(() => {
    setContext((prev) => ({ ...prev, sliderRef, instanceRef }))
  }, [sliderRef, instanceRef])

  const getDetailsData = (slider) => {
    if (!slider.track.details) return

    const { rel, maxIdx, minIdx } = slider.track.details

    return {
      nextIndex: rel < maxIdx ? rel + 1 : null,
      prevIndex: rel > minIdx ? rel + 1 : null,
      currentIndex: rel,
      maxIndex: maxIdx,
      minIndex: minIdx,
    }
  }

  const getCountSlidesOnContainer = useMemo(() => {
    if (!countSlidesOnContainer) return null
    if (isDesktop) return countSlidesOnContainer
    if (isMobile) {
      if (countSlidesOnContainer === 2 || countSlidesOnContainer === 3) return 1
      if (countSlidesOnContainer === 6) return 2
    }
  }, [countSlidesOnContainer, isDesktop, isMobile])

  useEffect(() => {
    instanceRef.current && loading && setLoading(false)
  }, [instanceRef.current, loading])

  useEffect(() => {
    instanceRef.current.update()
  }, [children])

  return (
    <ErrorBoundary>
      <div ref={sliderRef} className={`keen-slider ${s.slider} ${className}`} {...props} hidden={loading}>
        <CarouselShadow shadow={shadow} mode={'start'} isShow={isShowShadowStart} />

        {children?.length &&
          children.map((childrenItem, index) => (
            <div
              key={index}
              className={`slider-item ${s.slide}`}
              data-active-slide={instanceRef.current?.track?.details?.rel === index}>
              {childrenItem}
            </div>
          ))}

        <CarouselShadow shadow={shadow} mode={'end'} isShow={isShowShadowEnd} />
      </div>
    </ErrorBoundary>
  )
}

export default memo(CarouselSlider)
