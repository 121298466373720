type TypeGetImageError = {
  category: 'weight' | 'width' | 'height' | 'formats'
  type?: 'max' | 'min'
  sourceValue: string | number
  currentValue: string | number
}

export const getImageError = ({ category, type, sourceValue, currentValue }: TypeGetImageError) => {
  try {
    const methods = {
      weight: {
        max: (sourceValue, currentValue) =>
          `максимальный вес изображения - ${sourceValue}Mb, вес вашего изображения - ${currentValue}Mb`,
        min: (sourceValue, currentValue) =>
          `минимальный вес изображения - ${sourceValue}Mb, вес вашего изображения - ${currentValue}Mb`,
      },
      width: {
        max: (sourceValue, currentValue) =>
          `макс. ширина изображения - ${sourceValue}px, ширина вашего изображения - ${currentValue}px`,
        min: (sourceValue, currentValue) =>
          `мин. ширина изображения - ${sourceValue}px, ширина вашего изображения - ${currentValue}px`,
      },
      height: {
        max: (sourceValue, currentValue) =>
          `макс. высота изображения - ${sourceValue}px, высота вашего изображения - ${currentValue}px`,
        min: (sourceValue, currentValue) =>
          `мин. высота изображения - ${sourceValue}px, высота вашего изображения - ${currentValue}px`,
      },
      formats: (sourceValue, currentValue) =>
        `допустимые форматы изображений - ${sourceValue}. формат вашего изображения - ${currentValue}`,
    }

    // @ts-ignore
    return type ? methods[category][type](sourceValue, currentValue) : methods[category](sourceValue, currentValue)
  } catch (e) {
    console.error(e)
    return
  }
}
