import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { StateSelectionItem, TypeLoading } from '@pages/SelectionItem/model/types'
import { TypeMappingSelectionOutput } from '@shared/api/middleware/mappingAPI/selection/type'
import { TypeMappingRestOutput } from '@shared/api/middleware/mappingAPI/restaurant/type'
import { PropsSelectionRestaurants } from '@shared/api/types/apiPublicParameters'
import { TypeMappingPaginationOutput } from '@shared/api/middleware/mappingAPI/pagination/type'

const initialState: StateSelectionItem = {
  selection: {
    loading: {
      page: true,
      items_full: false,
      items_addition: false,
    },
    data: null,
    restaurants: null,
    pagination: null,
    paramsRequest: null,
  },
  recommended: null,
  afishaSelected: null,
}

const selectionItem = createSlice({
  name: 'selectionItem',
  initialState,

  reducers: {
    setSelection(state, action: PayloadAction<TypeMappingSelectionOutput>) {
      state.selection.data = action.payload
    },

    setSelectionList(state, action: PayloadAction<TypeMappingRestOutput[] | null>) {
      state.selection.restaurants = action.payload
    },
    setParamsRequest(state, action: PayloadAction<PropsSelectionRestaurants | null>) {
      state.selection.paramsRequest = action.payload
    },
    setPagination(state, action: PayloadAction<TypeMappingPaginationOutput | null>) {
      state.selection.pagination = action.payload
    },
    setPaginationPage(state, action: PayloadAction<TypeMappingPaginationOutput>) {
      state.selection.pagination.page = action.payload.page
    },

    setLoading(state, action: PayloadAction<TypeLoading>) {
      state.selection.loading = action.payload
    },

    setRecommendationList(state, action: PayloadAction<TypeMappingRestOutput[] | null>) {
      state.recommended = action.payload?.length ? action.payload : null
    },
    setAfishaSelectedList(state, action: PayloadAction<TypeMappingSelectionOutput[]>) {
      state.afishaSelected = action.payload?.length ? action.payload : null
    },

    resetState() {
      return initialState
    },
  },
})

export const getState = (state) => state

export const {
  setSelection,
  setSelectionList,
  setParamsRequest,
  setPagination,
  setPaginationPage,
  setLoading,
  setRecommendationList,
  setAfishaSelectedList,
  resetState,
} = selectionItem.actions
export default selectionItem.reducer
