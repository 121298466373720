import React, { FC, useMemo } from 'react'
import s from './ScheduleAndPrice.module.sass'
import { Text } from '@shared/ui/Typography/Text'
import Price from '../Price/Price'
import { Range } from '@widgets/Restaurant/ResturantCards/RestCard/type/type'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { TypeMappingRestSchedule } from '@shared/api/middleware/mappingAPI/restaurant/type'

interface Props {
  schedule: TypeMappingRestSchedule
  priceRange?: Range<1, 5>
  priceLink?: string
}

const ScheduleAndPrice: FC<Props> = ({ schedule, priceRange, priceLink }) => {
  const timeClose = useMemo(() => {
    const getFirstWorkDay = Object.values(schedule.week).find((day) => day.open)
    if (getFirstWorkDay) return getFirstWorkDay.close
  }, [schedule.week])

  return schedule ? (
    <ErrorBoundary>
      <Text tag={'p'} sizes={'S'} className={s.schedule}>
        {timeClose ? `Открыто до ${timeClose}` : ''}
        <Price priceRange={priceRange} priceLink={priceLink} />
      </Text>
    </ErrorBoundary>
  ) : null
}

export default ScheduleAndPrice
