import s from './Header.module.sass'
import React, { FC } from 'react'
import { useRouter } from 'next/router'

import { useAppSelector } from '@app/model/store'
import { City } from '@widgets/Common/Header/ui/City'
import { IconLogoRest, IconLogoText } from '@widgets/Common/Header/ui/Logo'
import { UserMenu } from '@widgets/Common/Header/ui/UserMenu'

import { ContainerLong } from '@shared/ui/Layout/Containers/ContainerLong'
import { IconButtonSkeleton } from '@shared/ui/Actions/IconButton'

import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import ContentLoader from 'react-content-loader'
import { SKELETON_FOREGROUND_COLOR, SKELETON_MAIN_COLOR, SKELETON_SPEED } from '@shared/consts/skeleton'
import { MenuSkeleton } from '@widgets/Common/Header/ui/Menu'

const Skeleton: FC = () => {
  return (
    <ErrorBoundary>
      <ContainerLong tag={'header'} classNameContent={`${s.header}`}>
        {/*Logo and city*/}
        <div className={s.logo}>
          <ContentLoader
            speed={SKELETON_SPEED}
            backgroundColor={SKELETON_MAIN_COLOR}
            foregroundColor={SKELETON_FOREGROUND_COLOR}
            width={'158'}
            height={'48'}>
            <rect x='0' y='3' rx='8' ry='8' width='42' height='42' />
            <rect x='58' y='4' rx='3' ry='3' width='100' height='17' />
            <rect x='58' y='29' rx='3' ry='3' width='60' height='13' />
          </ContentLoader>
        </div>
        {/*Menu*/}
        <MenuSkeleton />

        {/*Like, User*/}
        <div className={s.header_additional}>
          <IconButtonSkeleton sizes='L M' />
          <IconButtonSkeleton sizes='L M' />
          <IconButtonSkeleton sizes='L M' />
        </div>
      </ContainerLong>
    </ErrorBoundary>
  )
}

export default Skeleton
