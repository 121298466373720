import React, { createContext } from 'react'

// MAIN
export const CardTwoBlockContext = createContext<TypeCardTwoBlockContext>(null)

type TypeCardTwoBlockContext = [TypeState, React.Dispatch<React.SetStateAction<TypeState>>] | null

type TypeState = {
  href: string
  size: string
}

// SKELETON
export const CardTwoBlockSkeletonContext = createContext<TypeCardTwoBlockSkeletonContext>(null)

type TypeCardTwoBlockSkeletonContext =
  | [TypeStateSkeleton, React.Dispatch<React.SetStateAction<TypeStateSkeleton>>]
  | null

type TypeStateSkeleton = {
  size: string
}
