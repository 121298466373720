import { useContext, useMemo, useRef, useState } from 'react'
import {
  arrow,
  autoUpdate,
  flip,
  offset,
  Placement,
  shift,
  useClick,
  useDismiss,
  useFloating,
  UseFloatingOptions,
  useInteractions,
  useRole,
  VirtualElement,
} from '@floating-ui/react'
import { PopupContext } from '@shared/ui/Overlays/Popup/context'

export interface PopupOptions {
  initialOpen?: boolean
  placement?: Placement
  arrow?: boolean
  offset?: number
  modal?: boolean
  open?: boolean
  flip?: boolean
  triggerRef?: VirtualElement
  closeClickOutside?: boolean
  onOpenChange?: (open: boolean) => void
}

export function usePopup({
  initialOpen = false,
  placement = 'bottom-start',
  modal,
  triggerRef,
  arrow: visibleArrow,
  offset: offsetProp = 18,
  flip: flipProp = true,
  open: controlledOpen,
  closeClickOutside = true,
  onOpenChange: setControlledOpen,
}: PopupOptions = {}) {
  const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen)
  const [labelId, setLabelId] = useState<string | undefined>()
  const [descriptionId, setDescriptionId] = useState<string | undefined>()

  const open = controlledOpen ?? uncontrolledOpen
  const setOpen = setControlledOpen ?? setUncontrolledOpen
  const arrowRef = useRef(null)

  const optionsFloating: UseFloatingOptions = {
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [offset(offsetProp), shift({ padding: 5 }), arrow({ element: arrowRef })],
  }

  if (triggerRef) {
    optionsFloating.elements = { reference: triggerRef }
  }

  // Включение функции автоматического перемещения попапа если не хватает места для всего контента
  if (flipProp) {
    optionsFloating.middleware.push(
      flip({
        fallbackPlacements: ['top-start', placement],
        padding: 5,
      }),
    )
  }

  const data = useFloating(optionsFloating)

  const context = data.context

  const click = useClick(context, {
    enabled: controlledOpen == null,
  })
  const dismiss = useDismiss(context, {
    outsidePressEvent: 'click',
    outsidePress: closeClickOutside,
  })
  const role = useRole(context)

  const interactions = useInteractions([click, dismiss, role])

  return useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data,
      modal,
      labelId,
      descriptionId,
      arrowRef,
      visibleArrow,
      setLabelId,
      setDescriptionId,
    }),
    [open, setOpen, interactions, data, modal, labelId, descriptionId, arrowRef, visibleArrow],
  )
}

export const usePopupContext = () => {
  const context = useContext(PopupContext)

  if (context == null) {
    throw new Error('Popup components must be wrapped in <Popup />')
  }

  return context
}
