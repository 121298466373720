import dynamic from 'next/dynamic'
import Skeleton from '@features/PromoRoll/Skeleton'

const PromoRoll = dynamic(() => import('./PromoRoll').then((module) => module), {
  loading: () => {
    return <Skeleton />
  },
  ssr: false,
})

export { PromoRoll }

// export { default as PromoRoll } from './PromoRoll'
