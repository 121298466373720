// @ts-nocheck

import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useField } from '@shared/lib/fields/hooks/useField/useField'
import { API } from '@shared/api'

import { Title } from '@shared/ui/Typography/Title'
import { Modal } from '@shared/ui/Overlays/Modal'
import { Button } from '@shared/ui/Actions/Button'
import { Text } from '@shared/ui/Typography/Text'
import s from './ModalAddEstablishment.module.sass'
import { useAppDispatch, useAppSelector } from '@app/model/store'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { BuildInput } from '@shared/ui/Form/Build/BuildInput'
import { BuildSelect } from '@shared/ui/Form/Build/BuildSelect'
import { BuildTextarea } from '@shared/ui/Form/Build/BuildTextarea'
import { BuildRadio } from '@shared/ui/Form/Build/BuildRadio'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { addTemporaryNotice } from '@widgets/Common/Notices'
import { getField } from '@widgets/Common/ModalAddEstablishment/lib/fields'

const ModalAddEstablishment = ({ handleSendStatus, handleClose, extended }) => {
  const stateGlobal = useAppSelector((store) => store.global)
  const { isMobile } = useClientResponsive()
  const dispatch = useAppDispatch()

  const isExtendedForm = extended
  const [isLoading, setIsLoading] = useState(false)

  const [fieldNameEstablish, setFieldNameEstablish] = useField(getField('name_establishment', { isExtendedForm }))
  const [fieldNameOrg, setFieldNameOrg] = useField(getField('name_organisation', { isExtendedForm }))
  const [fieldINN, setFieldINN] = useField(getField('inn', { isExtendedForm }))
  const [fieldPhoneOrg, setFieldPhoneOrg] = useField(getField('phone_organisation', { isExtendedForm }))
  const [fieldPhoneContact, setFieldPhoneContact] = useField(getField('phone_contact', { isExtendedForm }))

  const [fieldWebSite, setFieldWebSite] = useField(getField('web_site', { isExtendedForm }))
  const [fieldTelegram, setFieldTelegram] = useField(getField('telegram', { isExtendedForm }))
  const [fieldVk, setFieldVk] = useField(getField('vk', { isExtendedForm }))
  const [fieldOpenHours, setFieldOpenHours] = useField(getField('open_hours', { isExtendedForm }))
  const [fieldEmail, setFieldEmail] = useField(getField('email', { isExtendedForm }))
  const [fieldDescription, setFieldDescription] = useField(getField('description', { isExtendedForm, isMobile }))

  const [fieldAddress, setFieldAddress] = useField(getField('address', { isExtendedForm }))
  const [fieldCity, setFieldCity] = useField(getField('city', { isExtendedForm }))
  const [fieldPlaceType, setFieldPlaceType] = useField(getField('place_type', { isExtendedForm }))
  const [fieldKitchenType, setFieldKitchenType] = useField(getField('kitchen_type', { isExtendedForm }))

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [fieldOrgType, setFieldOrgType] = useField(getField('type_organisation', { isExtendedForm }))

  const getDataForSelectors = useCallback(async () => {
    const placeTypes = await API.tag_list({
      params: {
        catalogs: '1174',
        page_size: 1000,
      },
    })

    const kitchenTypes = await API.tag_list({
      params: {
        catalogs: '1173',
        page_size: 1000,
      },
    })

    const dataSetState = [
      {
        data: stateGlobal.cityList,
        state: fieldCity,
        setState: setFieldCity,
      },
      {
        data: placeTypes ? placeTypes.results : [],
        state: fieldPlaceType,
        setState: setFieldPlaceType,
      },
      {
        data: kitchenTypes ? kitchenTypes.results : [],
        state: fieldKitchenType,
        setState: setFieldKitchenType,
      },
    ]

    // eslint-disable-next-line
    dataSetState.forEach(({ data, state, setState }) => {
      if (!data) return

      const arr = data?.map((item) => ({
        value: item.name,
        label: item.name,
        disabled: false,
      }))

      setState((state) => ({
        ...state,
        settings: { ...state.settings, options: [...arr] },
      }))
    })
  }, [fieldCity, fieldKitchenType, fieldPlaceType, setFieldCity, setFieldPlaceType, setFieldKitchenType])

  useEffect(() => {
    getDataForSelectors()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getCheckedOrgType = useMemo(() => {
    return fieldOrgType.settings.options.find((item) => fieldOrgType.value === item.id)
  }, [fieldOrgType])

  let isFormValid = false
  if (isExtendedForm) {
    isFormValid = [
      fieldNameEstablish,
      fieldOrgType,
      fieldINN,
      fieldNameOrg,
      fieldPhoneOrg,
      fieldPhoneContact,
      fieldEmail,
    ].every((field) => field.valid === true)
  } else {
    isFormValid = [fieldOrgType, fieldINN, fieldNameOrg, fieldPhoneOrg, fieldPhoneContact, fieldEmail].every(
      (field) => field.valid === true,
    )
  }

  const apiRequestText = () => {
    const fieldRequestBody = [
      fieldPlaceType.value ? `Тип заведения — ${fieldPlaceType.value.value}\n` : '',
      fieldKitchenType.value ? `Тип кухни — ${fieldKitchenType.value.value}\n` : '',
      fieldNameEstablish.value ? `Название заведения — ${fieldNameEstablish.value}\n` : '',
      fieldCity.value ? `Город — ${fieldCity.value.value}\n` : '',
      fieldAddress.value ? `Адрес — ${fieldAddress.value}\n` : '',
      getCheckedOrgType ? `Тип организации — ${getCheckedOrgType.label}\n` : '',
      fieldINN.value ? `ИНН — ${fieldINN.value}\n` : '',
      fieldNameOrg.value ? `Название организации — ${fieldNameOrg.value}\n` : '',
      fieldPhoneOrg.value ? `Номер телефона заведения — ${fieldPhoneOrg.value}\n` : '',
      fieldPhoneContact.value ? `Номер телефона для связи — ${fieldPhoneContact.value}\n` : '',
      fieldOpenHours.value ? `Режим работы — ${fieldOpenHours.value}\n` : '',
      fieldWebSite.value ? `Сайт — ${fieldWebSite.value}\n` : '',
      fieldEmail.value ? `E-mail — ${fieldEmail.value}\n` : '',
      fieldVk.value ? `Вконтакте — ${fieldVk.value}\n` : '',
      fieldTelegram.value ? `Telegram — ${fieldTelegram.value}\n` : '',
      fieldDescription.value ? `Примечание — ${fieldDescription.value}\n` : '',
    ]

    const textStart = '🤩 Заявка на подключение нового ресторана: '
    const textBody = fieldRequestBody.join('')

    return `${textStart}\n\n` + textBody
  }

  const fetchData = async () => {
    const requestData = {
      name: '-',
      email: fieldEmail.value || '',
      url: document.location.href,
      comment: apiRequestText(),
      type: 'landing-restorator',
    }

    setIsLoading(true)

    API.send_new_rest({ body: requestData, repeated: true, error: { showUser: true } })
      .then(() => {
        // @ts-ignore
        dispatch(addTemporaryNotice('Спасибо! Ваша заявка отправлена.'))
        handleSendStatus(true)
        handleClose()
      })
      .catch(() => handleSendStatus(false))
      .finally(() => {
        setIsLoading(false)
      })
  }

  const escFunction = useCallback((event) => event.key === 'Escape' && handleClose(), [handleClose])

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false)
    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
  }, [escFunction])

  const handleClickSubmit = () => !isLoading && fetchData()

  const defaultPropsField = {}

  return (
    <ErrorBoundary>
      <Modal
        size='L'
        title={isExtendedForm ? 'Добавить заведение' : 'Подключить бронирование'}
        handleClose={handleClose}>
        <header className={s.header}>
          {/* Комментарий */}
          {isExtendedForm && (
            <Text className={s.description} sizes='M M'>
              Создайте уникальную страницу своего заведения на Афиша Рестораны, чтобы получить возможность рассказать о
              нем многомиллионной аудитории и увеличить посещаемость.
            </Text>
          )}
        </header>

        <main>
          {/* Подзаголовок — О заведении */}
          {isExtendedForm && <Title className={s['section-title']}>О заведении</Title>}

          {/* Тип заведения & Тип кухни */}
          {isExtendedForm && (
            <div className={s.row}>
              <BuildSelect field={fieldPlaceType} setField={setFieldPlaceType} {...defaultPropsField} />
              <BuildSelect field={fieldKitchenType} setField={setFieldKitchenType} {...defaultPropsField} />
            </div>
          )}

          {/* Название заведения */}
          {isExtendedForm && (
            <div className={s.row}>
              <BuildInput field={fieldNameEstablish} setField={setFieldNameEstablish} {...defaultPropsField} />
            </div>
          )}

          {/* Город & Адрес */}
          {isExtendedForm && (
            <div className={s.row}>
              <BuildSelect field={fieldCity} setField={setFieldCity} {...defaultPropsField} />
              <BuildInput field={fieldAddress} setField={setFieldAddress} {...defaultPropsField} />
            </div>
          )}

          {/* Телефон заведения & Сайт */}
          {isExtendedForm && (
            <div className={s.row}>
              <BuildInput field={fieldPhoneOrg} setField={setFieldPhoneOrg} {...defaultPropsField} />
              <BuildInput field={fieldWebSite} setField={setFieldWebSite} {...defaultPropsField} />
            </div>
          )}

          {/* ВКонтакте & Телеграм */}
          {isExtendedForm && (
            <div className={s.row}>
              <BuildInput field={fieldVk} setField={setFieldVk} {...defaultPropsField} />
              <BuildInput field={fieldTelegram} setField={setFieldTelegram} {...defaultPropsField} />
            </div>
          )}

          {/* Режим работы */}
          {isExtendedForm && (
            <div className={s.row}>
              <BuildInput field={fieldOpenHours} setField={setFieldOpenHours} {...defaultPropsField} />
            </div>
          )}

          {/* Подзаголовок — Об организации */}
          {isExtendedForm && (
            <Title className={`${s['section-title']} ${s['section-top-margin']}`}>Об организации</Title>
          )}

          {/* Тип организации */}
          <div className={s.row}>
            <BuildRadio field={fieldOrgType} setField={setFieldOrgType} className='rest-modal__select-buttons' />
          </div>

          {/* ИНН & Название организации */}
          <div className={s.row}>
            <BuildInput field={fieldINN} setField={setFieldINN} {...defaultPropsField} />
            <BuildInput field={fieldNameOrg} setField={setFieldNameOrg} {...defaultPropsField} />
          </div>

          {/* Телефона для связи & E-mail для связи */}
          {isExtendedForm && (
            <div className={s.row}>
              <BuildInput
                field={fieldPhoneContact}
                setField={setFieldPhoneContact}
                autoValidation
                {...defaultPropsField}
              />
              <BuildInput field={fieldEmail} setField={setFieldEmail} autoValidation {...defaultPropsField} />
            </div>
          )}

          {/* Телефон заведения + rest-modal__form-description */}
          {!isExtendedForm && (
            <div className={s.row}>
              <BuildInput field={fieldPhoneOrg} setField={setFieldPhoneOrg} {...defaultPropsField} />
            </div>
          )}

          {/* Примечания */}
          <div className={s.row}>
            <BuildTextarea field={fieldDescription} setField={setFieldDescription} {...defaultPropsField} />
          </div>

          <footer className={s.footer}>
            <Button sizes='XL' mode='black' onClick={handleClickSubmit} disabled={!isFormValid} className={s.button}>
              Отправить заявку
            </Button>
          </footer>
        </main>
      </Modal>
    </ErrorBoundary>
  )
}

export default ModalAddEstablishment
