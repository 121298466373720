import { TypeClientResponsive } from '@app/model/types'

const SHOW_ANY_ADV = process.env.SHOW_ANY_ADV

/**
 * @function getListSection - TODO описание
 */

export const getListSection = (list: object[], responsive: TypeClientResponsive, isLastPage) => {
  const result = []
  let listFormatted = list
  if (!listFormatted) {
    listFormatted = Array.from(Array(20).keys()).map((el) => ({ skeleton: true }))
  }

  const config = {
    desktopLarge: {
      slider: 4, // количество элементов в секции slider
      grid: 6, // количество элементов в секции grid
      cards: 8, // количество элементов в секции cards
    },
    desktopMedium: {
      slider: 4, // количество элементов в секции slider
      grid: 4, // количество элементов в секции grid
      cards: 6, // количество элементов в секции cards
    },
    tablet: {
      slider: 4, // количество элементов в секции slider
      grid: 4, // количество элементов в секции grid
      cards: 4, // количество элементов в секции cards
    },
    mobile: {
      slider: 3, // количество элементов в секции slider
      grid: null, // количество элементов в секции grid
      cards: 'all', // количество элементов в секции cards
    },
  }

  let currentConfig = null

  if (responsive.isDesktopLarge) currentConfig = config.desktopLarge
  else if (responsive.isDesktop) currentConfig = config.desktopMedium
  else if (responsive.isTablet) currentConfig = config.tablet
  else if (responsive.isMobile) currentConfig = config.mobile

  // Слайдер показываем только когда два полностью заполнена секция grid и cards.
  // На мобилках есть только cards поэтому показывем слайдер если элементов больше 11
  const isShowSlider = responsive.isMobile
    ? listFormatted.length >= 8 + 3
    : listFormatted.length >= currentConfig.grid + currentConfig.cards + 3

  let selectType = isShowSlider ? 'slider' : responsive.isMobile ? 'cards' : 'grid'
  let counter = 0

  listFormatted.forEach((item, index) => {
    if (currentConfig[selectType] === 'all') {
      result.push({
        skeleton: !list,
        type: selectType,
        list: [item],
      })
      return
    }

    if (counter === 0) {
      result.push({
        skeleton: !list,
        type: selectType,
        list: [item],
      })
      counter++
    } else if (counter > 0 && counter < currentConfig[selectType] - 1) {
      result[result.length - 1].list.push(item)
      counter++
    } else if (counter === currentConfig[selectType] - 1) {
      result[result.length - 1].list.push(item)

      if (selectType === 'slider') {
        selectType = responsive.isMobile ? 'cards' : 'grid'
      } else {
        selectType = selectType === 'grid' ? 'cards' : 'grid'
      }

      counter = 0
    }
  })

  const lastSectionType = result[result.length - 1].type
  const lastSectionListLength = result[result.length - 1].list.length
  const isSectionCards = lastSectionType === 'cards'
  const isFullRowCards = isSectionCards && lastSectionListLength === currentConfig.cards

  if (!isFullRowCards) {
    if (isLastPage) {
      result[result.length - 1].type = 'grid'
      if (lastSectionListLength > 6) {
        result.push({
          skeleton: !list,
          type: 'grid',
          list: result[result.length - 1].list.splice(5, 2),
        })
      }
    } else {
      result.pop()
    }
  }

  // Добавление секций для рекламы
  if (SHOW_ANY_ADV) {
    let countAdvert = 0
    const types = ['100%x240 Listing1', 'Parallax Listing1']
    let currentTypeIndex = 0
    let alternationCount = 0

    if (responsive.isDesktopLarge) alternationCount = 3
    else if (responsive.isDesktop) alternationCount = 4
    else if (responsive.isTablet) alternationCount = 4
    else if (responsive.isMobile) {
      // на этом разрешении карточки выстраиваются в две колонки
      if (window.innerWidth <= 500) {
        alternationCount = 11
        // в три колонки
      } else if (window.innerWidth <= 650) {
        alternationCount = 13
        // в четыре колонки
      } else if (window.innerWidth <= 757) {
        alternationCount = 13
      }
      // const hasSlider = result.some((section) => section.type === 'slider')
    }

    for (let i = 0; i <= result.length; i++) {
      if (i !== 0 && i % alternationCount === 0) {
        result.splice(i, 0, { type: 'advert', count: countAdvert, componentType: types[currentTypeIndex] })
        countAdvert++
        currentTypeIndex = currentTypeIndex === 0 ? 1 : 0
      }
    }

    const hasAdvertSection = result.some((section) => section.type === 'advert')

    if (!hasAdvertSection) result.push({ type: 'advert', count: countAdvert, componentType: types[currentTypeIndex] })
  }

  // console.log(result)

  return result
}
