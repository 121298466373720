import React, { FC } from 'react'
import s from './QuickBookingSlot.module.sass'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { TypeDateTimeSlot } from '@shared/types/schedule/types'

export interface PropsQuickBookingSlot {
  dataTime: TypeDateTimeSlot
  onClick: (data: TypeDateTimeSlot) => void
  className?: string
}

const QuickBookingSlot: FC<PropsQuickBookingSlot> = ({ dataTime, onClick, className = '' }) => {
  const handleClick = () => {
    onClick(dataTime)
  }

  return (
    <ErrorBoundary>
      <button className={`${s.slot} ${className}`} onClick={handleClick}>
        {dataTime.time.value}
      </button>
    </ErrorBoundary>
  )
}

export default QuickBookingSlot
