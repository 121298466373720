import React, { FC, memo, useEffect, useRef } from 'react'
import s from './Textarea.module.sass'
import { FieldTextarea } from '@shared/types/fields/fields'
import { getErrorData } from '@shared/lib/fields/getErrorData'
import { ErrorBoundary } from '../../../../lib/components/ErrorBoundary'

interface Props {
  // eslint-disable-next-line
  handleValue: (value: string) => void
  field: FieldTextarea
  className?: string
}

const Textarea: FC<Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>> = ({
  handleValue,
  field,
  className = '',
  ...props
}) => {
  const errorData = getErrorData(field)
  const isResize = field?.settings?.resize
  const textarea = useRef<HTMLTextAreaElement>()
  const wrapper = useRef<HTMLDivElement>()

  const handleResize = () => {
    if (textarea.current && wrapper.current && isResize) {
      textarea.current.style.height = '0'
      textarea.current.style.height = textarea.current.scrollHeight + 'px'

      setTimeout(() => {
        if (textarea.current && wrapper.current) {
          wrapper.current.style.height = textarea.current.scrollHeight + 'px'
        }
      }, 0)
    }
  }

  useEffect(() => {
    handleResize()
  }, [handleResize])

  const placeholder = field?.settings?.placeholder ? field.settings.placeholder : ''
  const fieldHeight = field?.settings?.height ? field.settings.height : 100

  return (
    <ErrorBoundary>
      <div ref={wrapper} data-textarea-wrapper>
        <textarea
          ref={textarea}
          className={`${s.textarea} ${errorData.class} ${className}`}
          id={field.name}
          name={field.name}
          value={field.value}
          placeholder={placeholder}
          disabled={field.disabled}
          required={field.required}
          onChange={(e) => handleValue(e.target.value)}
          style={isResize ? { minHeight: fieldHeight, overflow: 'hidden' } : { height: fieldHeight }}
          {...props}
        />
      </div>
    </ErrorBoundary>
  )
}

export default memo(Textarea)
