import React, { FC } from 'react'
import s from './MenuDesktop.module.sass'
import MenuLinkSkeleton from '@widgets/Common/Header/ui/MenuLink/MenuLinkSkeleton'

const Skeleton: FC = () => {
  return (
    <div className={s['nav-wrapper']}>
      <nav className={s.nav} data-opened={false} role='navigation' aria-label='Разделы сайта'>
        <div className={s['nav-top']}>
          {Array.from(Array(8).keys())?.map((el, index) => (
            <MenuLinkSkeleton type={'link'} key={index} />
          ))}
        </div>

        <MenuLinkSkeleton type={'link'} />

        <MenuLinkSkeleton type={'dots'} />
      </nav>
    </div>
  )
}

export default Skeleton
