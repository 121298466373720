import React, { FC } from 'react'
import s from './CardService.module.sass'
import { Text } from '@shared/ui/Typography/Text'
import Image from 'next/image'
import { ErrorBoundary } from '../../../lib/components/ErrorBoundary'

interface Props {
  title: string
  subtitle: string
  description: string
  img: string
  maxLineDescription?: number
  className?: string
  handleClick?: () => void
}

const CardService: FC<Props> = ({
  title,
  subtitle,
  description,
  img,
  maxLineDescription,
  className = '',
  handleClick,
}) => {
  const handleClickInner = () => {
    handleClick && handleClick()
  }

  return (
    <ErrorBoundary>
      <div className={`${s.card} ${className}`} onClick={handleClickInner}>
        <div className={s.img}>
          <Image layout={'fill'} src={img || ''} alt={title} />
        </div>

        <div className={s.content}>
          <Text sizes={'L ML ML ML'} className={s.title}>
            {title}
          </Text>

          {subtitle && (
            <Text sizes={'M S S S'} className={s.subtitle}>
              {subtitle}
            </Text>
          )}

          <div
            className={s['description-wrapper']}
            style={{ WebkitLineClamp: maxLineDescription ? maxLineDescription : 'initial' }}>
            <Text sizes={'M S S S'} className={s.description}>
              {description}
            </Text>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  )
}

export default CardService
