import { API_PUBLIC } from './api.public'
import { API_OWNER } from './api.owner'
import { API_LK } from './api.lk'

/**
 * @function checkUniqueKeys - аккумулирует объекты из массива объектов и проверяет наличие дублирующихся ключей;
 *    если ключ найден повторно, функция выбрасывает ошибку с соответствующим сообщением;
 */
const checkUniqueKeys = (objectsArray) => {
  return objectsArray.reduce((acc, obj) => {
    Object.keys(obj).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(acc, key)) {
        throw new Error(`Duplicate key found: ${key}`)
      } else {
        acc[key] = obj[key]
      }
    })
    return acc
  }, {})
}

// Итоговый объект API содержит уникальные ключи из всех переданных объектов
const API = checkUniqueKeys([API_PUBLIC, API_OWNER, API_LK])

export { API }
