import React, { cloneElement, FC, forwardRef, HTMLProps, isValidElement, ReactNode } from 'react'
import s from './PopupTrigger.module.sass'
import { useMergeRefs } from '@floating-ui/react'
import { usePopupContext } from '@shared/ui/Overlays/Popup/hooks'

interface Props {
  children: ReactNode
  asChild?: boolean
}

// children не должен иметь position absolute.
// Eсли children - компонент, то он должен иметь возможность прокинуть в него ref
export const PopupTrigger = forwardRef<HTMLElement, HTMLProps<HTMLElement> & Props>(
  ({ children, asChild = true, ...props }, propRef) => {
    const context = usePopupContext()
    const childrenRef = (children as any).ref
    const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef])

    // `asChild` allows the user to pass any element as the anchor
    if (asChild && isValidElement(children)) {
      return cloneElement(
        children,
        context.getReferenceProps({
          ref,
          ...props,
          ...children.props,
          'data-state': context.open ? 'open' : 'closed',
        }),
      )
    }

    return (
      <button
        ref={ref}
        type='button'
        // The user can style the trigger based on the state
        data-state={context.open ? 'open' : 'closed'}
        {...context.getReferenceProps(props)}>
        {children}
      </button>
    )
  },
)

export default PopupTrigger
