import React, { FC } from 'react'

import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'

import { MainCardSkeleton } from '@shared/ui/Layout/Cards/MainCard'
import { SectionMainCarousel } from '@shared/ui/Layout/SectionMainCarousel'

const Skeleton: FC = () => {
  const listSkeleton = Array.from(Array(3).keys())
  return (
    <ErrorBoundary>
      <SectionMainCarousel>
        {listSkeleton.map((el, index) => (
          <MainCardSkeleton key={index} />
        ))}
      </SectionMainCarousel>
    </ErrorBoundary>
  )
}

export default Skeleton
