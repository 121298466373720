import { sendClickHouseAnalytic } from '@shared/api/analytics/template'
import { PickAnalytic } from '@shared/api/analytics/types/types'

export const EGGCommon = {
  button_all_click: (props: PickAnalytic<null, 'section_name'>) =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      element_type: 'all',
      ...props,
    }),

  slider_arrow_button_click: (props: PickAnalytic<null, 'section_name'>, direction: 'forward' | 'back') =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      element_type: direction,
      ...props,
    }),

  page_number_click: (props?: PickAnalytic<null, 'section_name'>) =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      element_type: 'page_number',
      ...props,
    }),

  button_load_more_click: (props: PickAnalytic<null, 'section_name'>) =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      element_type: 'more',
      ...props,
    }),

  filter_use_click: (
    props: PickAnalytic<'filter_apply_type' | 'filter_apply_value' | 'filters_apply', 'section_name'>,
  ) =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      element_type: 'filter',
      ...props,
    }),

  tag_click: (props: PickAnalytic<'filter_apply_type' | 'filter_apply_value'>) =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      element_type: 'filter',
      section_name: 'tag',
      filter_apply_type: props.filter_apply_type || 'special',
      filter_apply_value: props.filter_apply_value,
    }),

  quick_filter_click: (props: PickAnalytic<'filter_apply_type' | 'filter_apply_value'>) => {
    sendClickHouseAnalytic({
      event_name: 'element_click',
      element_type: 'filter',
      section_name: 'image_filter',
      filter_apply_type: props.filter_apply_type || 'special',
      filter_apply_value: props.filter_apply_value,
    })
  },
}
