import React, { FC, useEffect, useState } from 'react'
import s from './ReviewContent.module.sass'

import { AnswerItem } from '@entities/Restaurant/ReviewsNew/ui/ReviewItem/ui/AnswerItem'

import { TypeMappingReviewOutput } from '@shared/api/middleware/mappingAPI/review/type'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { BuildTextareaSend } from '@shared/ui/Form/Build/BuildTextareaSend'
import { PopupContent } from '@shared/ui/Overlays/Popup/ui/PopupContent'
import { PopupTrigger } from '@shared/ui/Overlays/Popup/ui/PopupTrigger'
import { getDefaultField } from '@shared/lib/fields/getDefaultField'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { PopupMenuItem } from '@shared/ui/Actions/PopupMenuItem'
import { DiscloseText } from '@shared/ui/Actions/DiscloseText'
import { useField } from '@shared/lib/fields/hooks/useField/useField'
import { IconButton } from '@shared/ui/Actions/IconButton'
import { Button } from '@shared/ui/Actions/Button'
import { Text } from '@shared/ui/Typography/Text'
import { Modal } from '@shared/ui/Overlays/Modal'
import { Popup } from '@shared/ui/Overlays/Popup'
import { Avatar } from '@shared/ui/Media/Avatar'

// @ts-ignore
import IconThumb from '@icons/icon-thumb.svg'
// @ts-ignore
import IconDots from '@icons/icon-dots.svg'
import merge from 'lodash/merge'

interface ReviewContentProps {
  sourceData: {
    restName: string
    reviewData: TypeMappingReviewOutput
  }
  commonProps: {
    options: {
      loading: boolean // показатель загрузки в модалках (при отправке или редактировании отзыва)
      showSettings: boolean // Возможность открыть настройки (редактирование, удаление) отзыва
      style?: 'card' | 'row'
      isModal?: boolean
      disclose: boolean
    }
    events?: {
      onClickDeleteReview?: (data: TypeMappingReviewOutput) => void
      onClickEditReview?: (data: TypeMappingReviewOutput) => void
      onClickFullReview?: () => void
      onClickFullAnswer?: () => void
      onInputValueReview?: (value: string) => void
      onInputRateReview?: (value: boolean | null) => void
    }
  }
  ownerProps?: {
    options: {
      loading: boolean // показатель загрузки в модалках (при отправке или редактировании отзыва)
      answerPossible: boolean // возможность ответить на отзыв (кнопка "ответить" и поле для ответа)
      showSettings: boolean // Возможность открыть настройки (редактирование, удаление) ответа на отзыв
    }

    events: {
      onClickOpenSendAnswer?: () => void
      onClickSendAnswer?: (data: TypeMappingReviewOutput, body: string) => void
      onClickDeleteAnswer?: (data: TypeMappingReviewOutput) => void
      onClickEditAnswer?: (data: TypeMappingReviewOutput) => void
      onInputValueAnswer?: (data: TypeMappingReviewOutput, body: string) => void
    }
  }
}

const defaultProps = {
  sourceData: {
    restName: '',
    reviewData: null,
  },
  commonProps: {
    options: {
      loading: false,
      showSettings: false,
      style: 'row',
      disclose: true,
      isModal: false,
    },
    events: {},
  },
  ownerProps: null,
}

const getInitialField = () =>
  getDefaultField('textarea', {
    name: 'comment',
    required: true,
    validations: {
      'min-symbols-10': 'Отзыв должен содержать более 10 символов',
      'max-symbols-1500': 'Отзыв должен содержать не более 1500 символов',
    },
    settings: {
      placeholder: 'Ваш ответ',
      height: '72px',
      resize: true,
      label: '',
    },
  })

const ReviewContent: FC<ReviewContentProps> = (props) => {
  const readyProps: ReviewContentProps = merge({}, defaultProps, props)
  const { sourceData, commonProps, ownerProps } = readyProps
  const { restName, reviewData } = sourceData
  const { isMobile } = useClientResponsive()
  const [isOpenSettingsMenu, setIsOpenSettingsMenu] = useState(false)
  const [settingsButton, setSettingsButton] = useState()
  const [isActiveAnswerField, setActiveAnswerField] = useState(false)
  const [comment, setComment, validComment] = useField(getInitialField())

  useEffect(() => {
    ownerProps.events.onInputValueAnswer(reviewData, comment.value)
  }, [comment.value])

  const handleChangeOpenSettings = () => setIsOpenSettingsMenu(true)
  const handleChangeCloseSettings = () => setIsOpenSettingsMenu(false)
  const handleOpenModalDelete = () => {
    commonProps.events.onClickDeleteReview(reviewData)
    handleChangeCloseSettings()
  }
  const handleOpenModalEdit = () => {
    commonProps.events.onClickEditReview(reviewData)
    handleChangeCloseSettings()
  }

  const handleClickFullReview = () => {
    if (commonProps.options.disclose) commonProps.events.onClickFullReview && commonProps.events.onClickFullReview()
  }

  const handleOpenAnswerModalEdit = () => {
    ownerProps.events.onClickEditAnswer(reviewData)
  }

  const handleOpenAnswerModalDelete = () => {
    ownerProps.events.onClickDeleteAnswer(reviewData)
  }

  const handleClickFullAnswer = () => {
    commonProps.events.onClickFullAnswer()
  }

  const handleSubmitAnswer = () => {
    // @ts-ignore
    const isValidComment = validComment().error === ''

    if (isValidComment) {
      // @ts-ignore
      setActiveAnswerField(false)
      setComment(() => getInitialField())
      ownerProps.events.onClickSendAnswer && ownerProps.events.onClickSendAnswer(reviewData, comment.value)
    }
  }

  return (
    <ErrorBoundary>
      <a href={'https://www.afisha.ru/personalpage/' + reviewData.author.afishaId} className={s['photo-wrap']}>
        <Avatar src={reviewData.author.avatar} sizes={'XL L'} userName={reviewData.author.name} />
      </a>

      <div className={s['content-head']}>
        <div className={s['head-main']}>
          {!ownerProps && (
            <span className={`${s['like-type']} ${s[`like-type--${reviewData.like ? 'positive' : 'negative'}`]}`}>
              <IconThumb />
            </span>
          )}

          <div className={s['head-info-wrap']}>
            <a href={'https://www.afisha.ru/personalpage/' + reviewData.author.afishaId}>
              <Text sizes={'M ML'} medium className={s['user-name']}>
                {reviewData.author.name}
              </Text>
            </a>

            {ownerProps && (
              <Text tag={'span'} sizes={'S'} className={s.date}>
                {reviewData.date}
              </Text>
            )}
          </div>
        </div>

        {!isMobile && (
          <div className={s['date-action-wrapper']}>
            {ownerProps ? (
              <span className={`${s['like-type']} ${s[`like-type--${reviewData.like ? 'positive' : 'negative'}`]}`}>
                <IconThumb />
              </span>
            ) : (
              <Text tag={'span'} sizes={'S'} className={s.date}>
                {reviewData.date}
              </Text>
            )}

            {commonProps.options.showSettings && (
              <div onClick={(e) => e.stopPropagation()}>
                <Popup
                  open={isOpenSettingsMenu}
                  onOpenChange={(value) => setIsOpenSettingsMenu(value)}
                  offset={0}
                  placement={'bottom-end'}
                  flip={false}>
                  <PopupTrigger>
                    <IconButton
                      icon={IconDots}
                      sizes={'S'}
                      mode={'white'}
                      onClick={() => setIsOpenSettingsMenu(!isOpenSettingsMenu)}
                    />
                  </PopupTrigger>
                  <PopupContent className={s.settings} style={{ width: '240px' }}>
                    <PopupMenuItem onClick={handleOpenModalEdit}>Редактировать</PopupMenuItem>
                    <PopupMenuItem onClick={handleOpenModalDelete}>Удалить</PopupMenuItem>
                  </PopupContent>
                </Popup>
              </div>
            )}
          </div>
        )}
      </div>

      <div className={s['content']} onClick={() => commonProps.options.isModal && handleClickFullReview()}>
        {commonProps.options.isModal ? (
          <Text sizes={'M'} paragraph>
            {reviewData.body}
          </Text>
        ) : (
          <DiscloseText
            maxHeight={ownerProps ? 70 : 184}
            className={s['disclose-comment']}
            colorShadow={commonProps.options.style === 'row' ? '#FFFFFF' : isMobile ? '#F2F2F2' : null}
            staticShadow={isMobile}
            isTextMode={!!ownerProps}
            handleClick={handleClickFullReview}>
            <Text sizes={'M'} paragraph>
              {reviewData.body}
            </Text>
          </DiscloseText>
        )}

        {isMobile && (
          <div className={s['date-action-wrapper']}>
            <Text tag={'span'} sizes={'S'} className={s.date}>
              {reviewData.date}
            </Text>

            {commonProps.options.showSettings && (
              <div onClick={(e) => e.stopPropagation()}>
                <IconButton
                  // @ts-ignore
                  ref={setSettingsButton}
                  icon={IconDots}
                  sizes={'S'}
                  mode={'white'}
                  onClick={handleChangeOpenSettings}
                  className={s['settings-button']}
                />

                {isMobile && isOpenSettingsMenu ? (
                  <Modal
                    size={'S'}
                    buttonClose={false}
                    handleClose={() => setIsOpenSettingsMenu(false)}
                    className={s['settings-modal']}>
                    <div className={s['settings-modal-content']}>
                      <PopupMenuItem onClick={handleOpenModalEdit}>Редактировать</PopupMenuItem>
                      <PopupMenuItem onClick={handleOpenModalDelete}>Удалить</PopupMenuItem>
                      <PopupMenuItem onClick={() => setIsOpenSettingsMenu(false)} className={s['setting-cancel']}>
                        Отмена
                      </PopupMenuItem>
                    </div>
                  </Modal>
                ) : (
                  <Popup
                    triggerRef={settingsButton}
                    open={isOpenSettingsMenu}
                    onOpenChange={(value) => setIsOpenSettingsMenu(value)}
                    offset={0}
                    placement={'bottom-end'}
                    flip={false}>
                    <PopupContent className={s.settings} style={{ width: '240px' }}>
                      <PopupMenuItem onClick={handleOpenModalEdit}>Редактировать</PopupMenuItem>
                      <PopupMenuItem onClick={handleOpenModalDelete}>Удалить</PopupMenuItem>
                    </PopupContent>
                  </Popup>
                )}
              </div>
            )}
          </div>
        )}

        {ownerProps.options.answerPossible && !reviewData.answer && (
          <div className={s['create-answer-wrapper']} onClick={(e) => e.stopPropagation()}>
            {isActiveAnswerField ? (
              <form className={s['create-answer']}>
                {/*// @ts-ignore*/}
                <BuildTextareaSend field={comment} setField={setComment} handleSubmit={handleSubmitAnswer} />
              </form>
            ) : (
              <Button sizes={'S'} mode={'white-border'} onClick={() => setActiveAnswerField(true)}>
                Ответить
              </Button>
            )}
          </div>
        )}
      </div>

      {(!isMobile || commonProps.options.style === 'row') && (
        <AnswerItem
          ownerMode={!!ownerProps}
          data={reviewData.answer}
          className={s.answer}
          showSettings={ownerProps.options.showSettings}
          onClickDeleteAnswer={handleOpenAnswerModalDelete}
          onClickEditAnswer={handleOpenAnswerModalEdit}
          onClickFullAnswer={handleClickFullAnswer}
        />
      )}
    </ErrorBoundary>
  )
}

export default ReviewContent
