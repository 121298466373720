import gql from 'graphql-tag'

// Запрос на проверку будет ли хоть какое нибудь мероприятие в этом ресторане

const TAG_CALENDAR = gql`
  query Calendar($restId: ID!) {
    place(id: $restId) {
      name
      scheduleMeta {
        any
      }
    }
  }
`

// Запрос на проверку доступных дат в календаре

const TAG_CALENDER_DAYS_AVAILABLE = gql`
  query CalendarAvailable($restId: ID!, $offset: Int!, $first: Int!, $query: String!) {
    place(id: $restId) {
      scheduleConcerts(offset: $offset, first: $first, query: $query) {
        filters {
          ... on DateRangeFilter {
            __typename
            availableDays {
              value
            }
          }
        }
        items {
          sessions {
            dateTime
          }
        }
      }
    }
  }
`

// Запрос событий по ресторану на конкретные дни с информацией о концерте и разных сессиях

const TAG_PAYMENTS_LIST = gql`
  query PaymentsList($restId: ID!, $offset: Int!, $first: Int!, $query: String!) {
    place(id: $restId) {
      scheduleConcerts(offset: $offset, first: $first, query: $query) {
        filters {
          ... on DateRangeFilter {
            name
            title
            availableDays {
              value
            }
          }
        }
        totalCount
        items {
          concert {
            id
            name
            afishaChoice
            url
            image {
              url
            }
            genres {
              name
            }
          }
          sessions {
            dateTime
            sessionSourceID
            minPrice
          }
        }
      }
    }
  }
`

export { TAG_CALENDAR, TAG_CALENDER_DAYS_AVAILABLE, TAG_PAYMENTS_LIST }
