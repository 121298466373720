/**
 * @description маска для номера телефона. Формируется на основе количества введенных символов
 * @param      {String}  numberSource   Текущее значение поля
 * @param      {String}  defaultValue   Дефолтное значение
 * @param      {String}  type           Режим установки или удаления элементов (set, remove)
 */
export const handlePhoneFormatting = (numberSource: string, type = 'set', defaultValue = '+7') => {
  const number = numberSource.replace(/\D/g, '')
  const isSet = type === 'set'

  switch (number.length) {
    case 0:
      return isSet ? defaultValue : number
    case 1:
      return isSet ? `+${number} ` : '+ '
    case 2:
    case 3:
      return `+${number.slice(0, 1)} (${number.slice(1)}`
    case 4:
      return `+${number.slice(0, 1)} (${number.slice(1, 4)}`
    case 5:
    case 6:
    case 7:
      return `+${number.slice(0, 1)} (${number.slice(1, 4)}) ` + `${number.slice(4)}`
    case 8:
    case 9:
      return `+${number.slice(0, 1)} (${number.slice(1, 4)}) ` + `${number.slice(4, 7)}-${number.slice(7)}`
    case 10:
    case 11:
      return (
        `+${number.slice(0, 1)} (${number.slice(1, 4)}) ` +
        `${number.slice(4, 7)}-${number.slice(7, 9)}-${number.slice(9)}`
      )
    default:
      return (
        `+${number.slice(0, 1)} (${number.slice(1, 4)}) ` +
        `${number.slice(4, 7)}-${number.slice(7, 9)}-${number.slice(9, 11)}` +
        `-${number.slice(11, 15)}`
      )
  }
}
