import * as React from 'react'
import { TooltipOptions } from '@shared/ui/Overlays/Tooltip/types/type'
import { useTooltip } from '@shared/ui/Overlays/Tooltip/hooks'
import { TooltipContext } from '@shared/ui/Overlays/Tooltip/context'

function Tooltip({ children, ...options }: { children: React.ReactNode } & TooltipOptions) {
  // This can accept any props as options, e.g. `placement`,
  // or other positioning options.
  const tooltip = useTooltip(options)
  return <TooltipContext.Provider value={tooltip}>{children}</TooltipContext.Provider>
}

export default Tooltip
