import React, { FC } from 'react'
import s from './NewsContent.module.sass'

import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import ContentLoader from 'react-content-loader'
import {
  SKELETON_FOREGROUND_COLOR,
  SKELETON_MAIN_COLOR,
  SKELETON_SPEED,
  SKELETON_STYLES,
} from '@shared/consts/skeleton'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'

interface Props {
  className?: string
}

const Skeleton: FC<Props> = ({ className = '' }) => {
  const { isMobile } = useClientResponsive()

  const TemplateWithImage = () => (
    <div>
      <ContentLoader
        speed={SKELETON_SPEED}
        backgroundColor={SKELETON_MAIN_COLOR}
        foregroundColor={SKELETON_FOREGROUND_COLOR}
        style={SKELETON_STYLES}
        className={s['content-skeleton-template-image']}>
        <rect rx='12' ry='12' className={s['content-skeleton-template-image__title']} />
        <rect rx='12' ry='12' className={s['content-skeleton-template-image__image']} />

        <rect rx='12' ry='12' className={s['content-skeleton-template-image__text-1']} />
        <rect rx='12' ry='12' className={s['content-skeleton-template-image__text-2']} />
        <rect rx='12' ry='12' className={s['content-skeleton-template-image__text-3']} />
        <rect rx='12' ry='12' className={s['content-skeleton-template-image__text-4']} />
        <rect rx='12' ry='12' className={s['content-skeleton-template-image__text-5']} />
        {isMobile && <rect rx='12' ry='12' className={s['content-skeleton-template-image__text-6']} />}
        {isMobile && <rect rx='12' ry='12' className={s['content-skeleton-template-image__text-7']} />}
        {isMobile && <rect rx='12' ry='12' className={s['content-skeleton-template-image__text-8']} />}
      </ContentLoader>
    </div>
  )

  const TemplateTexts: FC<{ lineCount: 3 | 4 | 5 }> = ({ lineCount = 5 }) => (
    <div>
      <ContentLoader
        speed={SKELETON_SPEED}
        backgroundColor={SKELETON_MAIN_COLOR}
        foregroundColor={SKELETON_FOREGROUND_COLOR}
        style={SKELETON_STYLES}
        className={s['content-skeleton-template-text']}
        data-lines={lineCount}></ContentLoader>
    </div>
  )

  return (
    <ErrorBoundary>
      <div className={`${s['content-skeleton']} ${className}`}>
        <div>
          <ContentLoader
            speed={SKELETON_SPEED}
            backgroundColor={SKELETON_MAIN_COLOR}
            foregroundColor={SKELETON_FOREGROUND_COLOR}
            style={SKELETON_STYLES}
            className={s['content-skeleton-main-text']}>
            <rect rx='16' ry='16' className={s['content-skeleton-main-text__1']} />
            <rect rx='16' ry='16' className={s['content-skeleton-main-text__2']} />
            <rect rx='16' ry='16' className={s['content-skeleton-main-text__3']} />
            {isMobile && <rect rx='16' ry='16' className={s['content-skeleton-main-text__4']} />}
          </ContentLoader>
        </div>
        <TemplateWithImage />
        <TemplateWithImage />
        <div>
          <ContentLoader
            speed={SKELETON_SPEED}
            backgroundColor={SKELETON_MAIN_COLOR}
            foregroundColor={SKELETON_FOREGROUND_COLOR}
            style={SKELETON_STYLES}
            className={s['content-skeleton-social']}>
            <rect x='0' y='0' rx='16' ry='16' width='100%' height='100%' />
          </ContentLoader>
        </div>
      </div>
    </ErrorBoundary>
  )
}

export default Skeleton
