import s from './OwnerAsideNav.module.sass'

import React from 'react'

import { useAppDispatch } from '@app/model/store'

import { openCreateForm } from '@pages/OwnerPersonalPage/model/OwnerPostsModel/reducer'

// @ts-ignore
import IconOwnerHome from '@icons/icon-owner-home.svg'
// @ts-ignore
import IconOwnerMegaphone from '@icons/icon-owner-megaphone.svg'
// @ts-ignore
import IconOwnerRestaurant from '@icons/icon-owner-restaurant.svg'
// @ts-ignore
import IconOwnerChatbubbles from '@icons/icon-owner-chatbubbles.svg'
// @ts-ignore
import IconOwnerPlus from '@icons/icon-owner-plus.svg'

/**
 * @function getStatusText - TODO описание
 */
const getStatusText = (thisCount) => {
  // TODO: проверить склонение
  return 1 === parseInt(thisCount, 10) ? `${thisCount} новый` : `${thisCount} новых`
}

/**
 * @function OwnerAsideNav. stateless component
 * @description боковое меню личного кабинета ресторатора
 */
const OwnerAsideNav = ({ tabs, activeTab, handleActiveTab }) => {
  const dispatch = useAppDispatch()

  const handleClickOpenCreatePost = () => {
    dispatch(openCreateForm())
  }

  return (
    <aside className={s['container']}>
      {tabs.length > 0 && (
        <ul className={s['list-wrap']}>
          {tabs.map((thisTab, index) => {
            const isFirst = index === 0
            const isActive = thisTab.content === activeTab

            return (
              <li
                className={s['list-item']}
                data-is-first={isFirst}
                data-has-side-button={thisTab.has_side_button}
                key={index}>
                <button
                  className={s['item-action-button']}
                  data-with-status={thisTab.has_status}
                  data-selected={isActive}
                  onClick={(ev) => handleActiveTab(thisTab.content, ev)}
                  type='button'>
                  <>
                    {thisTab.icon === 'Home' && <IconOwnerHome className={s['icon']} />}
                    {thisTab.icon === 'Megaphone' && <IconOwnerMegaphone className={s['icon']} />}
                    {thisTab.icon === 'Restaurant' && <IconOwnerRestaurant className={s['icon']} />}
                    {thisTab.icon === 'Chatbubbles' && <IconOwnerChatbubbles className={s['icon']} />}
                  </>

                  {thisTab.label && <span className={s['text']}>{thisTab.label}</span>}

                  {thisTab.has_status && thisTab.status_count > 0 && (
                    <span className={s['text-status']}>{getStatusText(thisTab.status_count)}</span>
                  )}
                </button>

                {thisTab.has_side_button && (
                  <button className={s['item-button-add-news']} type='button' onClick={handleClickOpenCreatePost}>
                    <IconOwnerPlus className={s['item-button-add-news-icon']} />
                  </button>
                )}
              </li>
            )
          })}
        </ul>
      )}

      <p className={s['description']}>
        <span className={s['description-text']}>Связаться с нами: </span>
        <a className={s['description-mail-link']} href={'mailto:restaurants@afisha.ru'}>
          restaurants@afisha.ru
        </a>
      </p>
    </aside>
  )
}

export default OwnerAsideNav
