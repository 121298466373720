import React, { FC, useId } from 'react'
import s from './CalendarSliderCell.module.sass'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import IconClose from '@icons/icon--x.svg'
interface Props {
  daySelect: string
  dayData: any
  handleDaySelection: (data: any) => void
  handleDeselect: () => void
}

const CalendarSliderCell: FC<Props> = ({ daySelect, dayData, handleDaySelection, handleDeselect }) => {
  const id = useId()
  const handleDaySelectionInner = (data, e) => {
    e.stopPropagation()
    handleDaySelection(data)
  }
  const handleDeselectInner = (e) => {
    e.stopPropagation()
    handleDeselect()
  }

  return (
    <ErrorBoundary>
      <div
        className={s['calendar-item']}
        key={id}
        // @ts-ignore
        disabled={dayData.isDisabled}>
        {dayData.hasMonthLabel && <div className={s['calendar-month-label']}>{dayData.monthAbr}</div>}

        <button
          data-value={dayData.value}
          data-date-month={`${dayData.dayOfMonth} ${dayData.monthAbr}`}
          data-selected={daySelect === dayData.value}
          onClick={(e) => handleDaySelectionInner(dayData, e)}
          className={`${s['calendar-item-button']} ${dayData.isHoliday ? 'holiday' : ''}`}
          aria-label={`${dayData.dayOfMonth} ${dayData.monthAbr}`}
          key={id}>
          <div
            className={`${s['calendar-item-day-of-week']} ${
              dayData.isHoliday ? s['calendar-item-day-of-week--holiday'] : ''
            }`}>
            {dayData.dayOfWeek}
          </div>

          <div className={s['calendar-item-day-of-month']}>{dayData.dayOfMonth}</div>
        </button>

        {daySelect === dayData.value && (
          <button
            onClick={(ev) => handleDeselectInner(ev)}
            type='button'
            className={`button-deselect ${s['calendar-deselect']}`}>
            <IconClose />
          </button>
        )}
      </div>
    </ErrorBoundary>
  )
}

export default CalendarSliderCell
