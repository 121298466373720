import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TypeLoading, TypeState, TypeVarietyItem } from '@pages/NewsGallery/model/types'
import { TypeMappingNewsOutput } from '@shared/api/middleware/mappingAPI/news/type'
import { TypeMappingPaginationOutput } from '@shared/api/middleware/mappingAPI/pagination/type'

const initialState: TypeState = {
  loading: {
    page: true,
    items_full: false,
    items_addition: false,
  },
  pagination: null,
  sort: {
    selected: [],
    list: [],
  },
  list: null,
  special: null,
}

const newsGallery = createSlice({
  name: 'newsGallery',
  initialState,

  reducers: {
    setLoading(state, action: PayloadAction<TypeLoading>) {
      state.loading = action.payload
    },

    setPagination(state, action: PayloadAction<TypeMappingPaginationOutput>) {
      state.pagination = action.payload
    },
    setPaginationItems(state, action: PayloadAction<number | null>) {
      state.pagination.total = action.payload
    },
    setPaginationPage(state, action: PayloadAction<TypeMappingPaginationOutput>) {
      state.pagination.page = action.payload.page
    },
    setSortList(state, action: PayloadAction<TypeVarietyItem[] | []>) {
      state.sort.list = action.payload
    },
    changeSortSelected(state, action: PayloadAction<number>) {
      const { items_addition, items_full } = state.loading
      if (items_addition || items_full) return

      const objectSort = state.sort.list.find((item: TypeVarietyItem) => item.id === action.payload)

      const hasInArraySelected = state.sort.selected.find((item) => item.id === action.payload)
      if (!hasInArraySelected) {
        state.sort.selected.push(objectSort)
      } else {
        state.sort.selected = state.sort.selected.filter((item) => item.id !== action.payload)
      }
    },
    removeSelectedSort(state) {
      const { items_addition, items_full } = state.loading
      if (items_addition || items_full) return

      state.sort.selected = []
    },
    setList(state, action: PayloadAction<TypeMappingNewsOutput[]>) {
      state.list = action.payload
    },

    setSpecialSection(state, action: PayloadAction<TypeMappingNewsOutput>) {
      state.special = action.payload
    },

    setLoadingPage(state, action: PayloadAction<boolean>) {
      state.loading.page = action.payload
    },
    clearStateNewsGallery: () => initialState,
  },
})

export const getState = (state) => state

export const {
  setPagination,
  setPaginationItems,
  setPaginationPage,
  setSortList,
  setList,
  setLoading,
  setSpecialSection,
  changeSortSelected,
  removeSelectedSort,
  setLoadingPage,
  clearStateNewsGallery,
} = newsGallery.actions

export default newsGallery.reducer
