import React, { FC, ReactNode } from 'react'
import s from './ContainerLongCenter.module.sass'
import { ErrorBoundary } from '../../../../lib/components/ErrorBoundary'

interface Props {
  children: ReactNode
  classNameContainer?: string
  classNameContent?: string
}

const ContainerLongCenter: FC<
  Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = ({ children, classNameContainer = '', classNameContent = '', ...props }) => {
  return (
    <ErrorBoundary>
      <div className={`${s.container} ${classNameContainer}`} {...props}>
        <div className={`${s.content} ${classNameContent}`}>{children}</div>
      </div>
    </ErrorBoundary>
  )
}

export default ContainerLongCenter
