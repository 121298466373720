import React, { FC } from 'react'
import s from './InputTitle.module.sass'
import { Text } from '@shared/ui/Typography/Text'
import { ErrorBoundary } from '../../../../lib/components/ErrorBoundary'

interface Props {
  nameField: string
  title?: string
  showRequired?: boolean
}
// eslint-disable-next-line
const InputTitle: FC<Props> = ({ title, nameField, showRequired = true }) => {
  return (
    <ErrorBoundary>
      {!!title && (
        // @ts-ignore
        <Text tag='label' sizes='M M' htmlFor={nameField} className={s.title} data-required={showRequired}>
          {title}
        </Text>
      )}
    </ErrorBoundary>
  )
}

export default InputTitle
