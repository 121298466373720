import React, { FC, ReactNode } from 'react'
import s from './ContainerLong.module.sass'
import { ErrorBoundary } from '../../../../lib/components/ErrorBoundary'

interface Props {
  children: ReactNode
  classNameContainer?: string
  classNameContent?: string
  tag?: 'div' | 'aside' | 'header' | 'footer' | 'section'
}

const ContainerLong: FC<Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = ({
  children,
  classNameContainer = '',
  classNameContent = '',
  tag = 'div',
  ...props
}) => {
  const Tag = tag
  return (
    <ErrorBoundary>
      <Tag className={`${s.container} ${classNameContainer}`} {...props}>
        <div className={`${s.content} ${classNameContent}`}>{children}</div>
      </Tag>
    </ErrorBoundary>
  )
}

export default ContainerLong
