/**
 * @fsd_page Sitemap - карта сайта, список тегов
 */
import s from './Usage.module.sass'

import React from 'react'

import { ContainerLongCenter } from '@shared/ui/Layout/Containers/ContainerLongCenter'
import { Title } from '@shared/ui/Typography/Title'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { TypeURL } from '@shared/consts/page-urls'
import { TypeMappingTagOutput } from '@shared/api/middleware/mappingAPI/tag/type'

interface SiteMapProps {
  URLS: TypeURL[]
  data: TypeMappingTagOutput[]
}

const Sitemap = ({ data }: SiteMapProps) => {
  return (
    <ErrorBoundary>
      <ContainerLongCenter>
        <main className={s['layout']}>
          <Title tag={'h1'} className={s['title']} sizes={'h1 h3'}>
            Пользовательское соглашение сервиса &laquo;Афиша Рестораны&raquo;
          </Title>

          <div className={s['usage-doc']}>
            <div className={s['start-text']}>
              Пользовательское соглашение определяет условия, на&nbsp;которых Компания предоставляет сервис &laquo;Афиша
              Рестораны&raquo;.
            </div>

            <section className={s['section-wrap']}>
              <p className={s['section-title']}>1. Термины</p>

              <p className={s['sub-text']}>
                <span>1.</span> Заведение&nbsp;&mdash; юридическое лицо или индивидуальный предприниматель, являющийся
                владельцем и/или управляющей организацией общественного питания. Информация о&nbsp;Заведении, в&nbsp;том
                числе название и&nbsp;адрес, размещены на&nbsp;Сервисе.
              </p>
              <p className={s['sub-text']}>
                <span>2.</span> Пользователь&nbsp;&mdash; физическое лицо, осуществляющее Бронирование на&nbsp;Сервисе
                и&nbsp;обладающее необходимой дееспособностью для заключения и&nbsp;исполнения настоящего
                пользовательского соглашения Сервиса (Соглашение).
              </p>
              <p className={s['sub-text']}>
                <span>3.</span> Сервис&nbsp;&mdash; сервис &laquo;Афиша Рестораны&raquo;, предоставляющий функционал
                Бронирования для Пользователей.
              </p>
              <p className={s['sub-text']}>
                <span>4.</span> Бронирование&nbsp;&mdash; прием запроса от&nbsp;Пользователя на&nbsp;резервирование
                столика/места в&nbsp;Заведении в&nbsp;желаемое им&nbsp;время и&nbsp;дату с&nbsp;дальнейшим
                подтверждением наличия места в&nbsp;Заведении и&nbsp;уведомлением Пользователя об&nbsp;этом.
              </p>
              <p className={s['sub-text']}>
                <span>5.</span> Компания&nbsp;&mdash; ООО &laquo;Компания Афиша&raquo;, являющееся владельцем Сервиса.
              </p>
            </section>

            <section className={s['section-wrap']}>
              <p className={s['section-title']}>2. Действие соглашения</p>

              <p className={s['sub-text']}>
                <span>2.1.</span> Соглашение заключается в&nbsp;соответствии с&nbsp;нормами законодательства&nbsp;РФ,
                регулирующими принятие публичной оферты конклюдентными действиями.
              </p>
              <p className={s['sub-text']}>
                <span>2.2.</span> Соглашение считается заключенным с&nbsp;момента его принятия Пользователем.
                Использование Пользователем Сервиса, в&nbsp;том числе осуществление Бронирования означает, что
                Пользователь полностью и&nbsp;безоговорочно принял условия Соглашения. Если Пользователь
                не&nbsp;согласен с&nbsp;условиями Соглашения, он&nbsp;не&nbsp;вправе использовать Сервис, в&nbsp;том
                числе осуществлять Бронирование.
              </p>
              <p className={s['sub-text']}>
                <span>2.3.</span> Соглашение может быть изменено Компанией. Используя Сервис, в&nbsp;том числе
                осуществляя Бронирование после изменения Соглашения, Пользователь принимает условия Соглашения полностью
                и&nbsp;безоговорочно в&nbsp;измененном виде.
              </p>
            </section>

            <section className={s['section-wrap']}>
              <p className={s['section-title']}>3. Осуществление бронирования</p>

              <p className={s['sub-text']}>
                <span>3.1.</span> Для осуществления Бронирования Пользователю необходимо заполнить форму Бронирования
                на&nbsp;Сервисе и&nbsp;предоставить информацию, предусмотренную формой Бронирования.
              </p>
              <p className={s['sub-text']}>
                <span>3.2.</span> Пользователь подтверждает достоверность вводимых им&nbsp;при осуществлении
                Бронирования данных и&nbsp;принимает на&nbsp;себя всю ответственность за&nbsp;их&nbsp;точность, полноту
                и&nbsp;достоверность.
              </p>
              <p className={s['sub-text']}>
                <span>3.3.</span> Бронирование считается осуществлённым после получения Пользователем подтверждения
                об&nbsp;осуществлении Бронирования.
              </p>
              <p className={s['sub-text']}>
                <span>3.4</span> В&nbsp;случае неполучения Пользователем подтверждения Бронирования, Бронирование
                считаетс.я не&nbsp;осуществлённым.
              </p>
              <p className={s['sub-text']}>
                <span>3.5.</span> Отмена созданного/подтвержденного Бронирования осуществляется Пользователем
                самостоятельно по&nbsp;телефону Заведения в&nbsp;котором осуществлено Бронирование.
              </p>
              <p className={s['sub-text']}>
                <span>3.6.</span> Все вопросы и&nbsp;претензии, связанные с&nbsp;подтверждением Заведением Бронирования,
                посещением Заведения, получением услуг Заведения, качеством предоставления услуг Заведением,
                Пользователь обязуется самостоятельно и&nbsp;за&nbsp;свой счет урегулировать непосредственно
                с&nbsp;Заведением.
              </p>
              <p className={s['sub-text']}>
                <span>3.7.</span> Пользователю могут направляться информационные сообщения, связанные
                с&nbsp;Бронированием и/или работой Сервиса, по&nbsp;адресу электронной почты и/или номеру телефона,
                указанному Пользователем при Бронировании.
              </p>
              <p className={s['sub-text']}>
                <span>3.8.</span> Пользователь самостоятельно несет ответственность за&nbsp;все действия, совершаемые
                им&nbsp;на&nbsp;Сервисе, за&nbsp;нарушение законодательства&nbsp;РФ и&nbsp;Соглашения.
              </p>
              <p className={s['sub-text']}>
                <span>3.9.</span> Сервис предоставляется &laquo;как есть&raquo;. Компания не&nbsp;гарантирует, что
                Сервис и&nbsp;результат его использования будут удовлетворять ожиданиям Пользователя. Пользователь
                принимает все последствия и&nbsp;самостоятельно несёт все возможные риски, связанные
                с&nbsp;использованием Сервиса.
              </p>
              <p className={s['sub-text']}>
                <span>3.10.</span> Компания обрабатывает персональные данные Пользователя и&nbsp;иную пользовательскую
                информацию (Данные) с&nbsp;целью предоставления Сервиса, включая предоставление определенного
                функционала Сервиса, в&nbsp;том числе осуществления Бронирования. Указанная обработка осуществляется
                в&nbsp;соответствии с&nbsp;
                <a className={s['text-link']} href={'https://www.afisha.ru/article/privacy-policy/'} target='_blank'>
                  Политикой конфиденциальности
                </a>
                .
              </p>
              <p className={s['sub-text']}>
                <span>3.11.</span>Для исполнения Соглашения необходимо предоставление Компанией Заведениям Данных
                и&nbsp;их&nbsp;последующая обработка Заведениями (в&nbsp;целях осуществления и&nbsp;подтверждения
                Бронирования, предоставления услуг Заведений, в&nbsp;том числе предоставления столика/места
                в&nbsp;Заведении).
              </p>
              <p className={s['sub-text']}>
                <span>3.12.</span> Для исполнения Соглашения необходима обработка Данных ООО &laquo;Астра Пейдж&raquo;
                по&nbsp;поручению Компании (в&nbsp;целях осуществления и&nbsp;подтверждения Бронирования, в&nbsp;том
                числе посредством телефонного вызова, направления смс-сообщений, электронных сообщений или иных видов
                сообщений&nbsp;&mdash; на&nbsp;номер телефона и/или адрес электронной почты, указанный пользователем
                в&nbsp;форме Бронирования), ООО &laquo;РИХ&raquo; (хостинг-провайдер).
              </p>
            </section>

            <section className={s['section-wrap']}>
              <p className={s['section-title']}>4. Иное</p>

              <p className={s['sub-text']}>
                <span>4.1.</span> Отношения Компании и&nbsp;Пользователя, в&nbsp;том числе предусмотренные Соглашением,
                регулируются законодательством&nbsp;РФ.
              </p>
              <p className={s['sub-text']}>
                <span>4.2.</span> Все споры, возникающие из&nbsp;Соглашения и/или связанные с&nbsp;Сервисом
                и&nbsp;Бронированием, могут быть переданы на&nbsp;разрешение суда после принятия Сторонами мер
                по&nbsp;досудебному урегулированию спора по&nbsp;истечении 30&nbsp;календарных дней со&nbsp;дня
                направления первой претензии. Если спор не&nbsp;урегулирован в&nbsp;досудебном порядке согласно
                настоящему пункту, он&nbsp;может быть передан на&nbsp;разрешение суда по&nbsp;месту нахождения Компании.
              </p>
              <p className={s['sub-text']}>
                <span>4.3.</span> По&nbsp;Соглашению Компания предоставляет Сервис и/или Бронирование безвозмездно,
                поэтому нормы о&nbsp;защите прав потребителей не&nbsp;применяются.
              </p>
              <p className={s['sub-text']}>
                <span>4.4.</span> Юридически значимые сообщения в&nbsp;адрес Компании направляются по&nbsp;адресу:
                117105, г. Москва, Варшавское&nbsp;ш., д.&nbsp;9, стр.&nbsp;1.
              </p>
            </section>

            <div className={s['text-date']}>Редакция от&nbsp;01&nbsp;февраля 2024&nbsp;г.</div>
          </div>
        </main>
      </ContainerLongCenter>
    </ErrorBoundary>
  )
}

export default Sitemap
