import React, { FC, useState } from 'react'
import s from './QuickBookingRestCard.module.sass'
import { QuickBookingSlots } from '@widgets/Restaurant/QuickBooking/components/QuickBookingSlots'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { Text } from '@shared/ui/Typography/Text'
import { TypeMappingRestOutput } from '@shared/api/middleware/mappingAPI/restaurant/type'
import PopupArrow from '@icons/icon--popup-arrow.svg'
import { useNearestSlots } from '@widgets/Restaurant/QuickBooking/helpers/useNearestSlots'

interface Props {
  restData: TypeMappingRestOutput
  className?: string
  onClickSlot?: (data: TypeMappingRestOutput) => void
  onClickDots?: (data: TypeMappingRestOutput) => void
}

const QuickBookingRestCard: FC<Props> = ({ restData, className = '', onClickSlot, onClickDots }) => {
  const [paramsNearestSlots, setParamsNearestSlots] = useState({
    restData: restData,
    guests: 2,
  })

  const slotsData = useNearestSlots(paramsNearestSlots)

  const getTitle = () => {
    if (!slotsData?.slots?.length) return `Столики на ... :`
    const nearSlot = slotsData.slots[0]

    const nearSlotIsToday =
      new Date(nearSlot.day.value).toLocaleDateString() === new Date(Date.now()).toLocaleDateString()

    const tailDate = nearSlotIsToday ? `сегодня` : nearSlot.day.itemTextShort.toLowerCase()

    return `Cтолики на ${tailDate}:`
  }

  const hasBookingAfisha = restData.booking.afisha.active
  if (!hasBookingAfisha) return

  const handleClickComponent = (e) => {
    e.stopPropagation()
  }

  return (
    <ErrorBoundary>
      <div className={`${s.wrapper} ${className}`} onClick={handleClickComponent}>
        <PopupArrow className={s.arrow} />
        <Text sizes={'S'} className={s.title}>
          {getTitle()}
        </Text>
        <QuickBookingSlots
          slotsData={slotsData}
          classNameWrapper={`${s.schedule} ${className}`}
          restData={restData}
          classNameSlot={s.slot}
          classNameDots={s.dots}
          onClickSlot={onClickSlot}
          onClickDots={onClickDots}
        />
      </div>
    </ErrorBoundary>
  )
}

export default QuickBookingRestCard
