import React, { useEffect, useState } from 'react'
import Input from './Input'
import { getArgsStories, setArgsStories, setArgsTypesStories } from '@shared/lib/stories/help'
import { getDefaultField } from '@shared/lib/fields/getDefaultField'

export default {
  title: 'Component/Shared/Form/Single/Input',
  tags: ['autodocs'],
  component: Input,
}

// TODO: Нужно типизоровать
const Template = (args) => {
  const [field, setField] = useState(getArgsStories(args).field)
  useEffect(() => setField(getArgsStories(args).field), [args])

  const handleValue = (value) =>
    setField((prev) => ({
      ...prev,
      value: value,
    }))
  return <Input field={field} handleValue={handleValue} />
}

export const Primary = Template.bind({})
Primary.args = setArgsStories({
  field: {
    ...getDefaultField('input', {
      validations: {
        'max-symbols-900': 'Максимальное количество символов - 900',
        required: 'Обязательное поле',
        other: 'Тестовая ошибка',
      },
    }),
  },
})

Primary.argTypes = setArgsTypesStories(Primary.args, {
  field: {
    if: { arg: 'unvisible' },
  },
  field__type: {
    description: 'Тип поля. Для данного поля возможны типы - text, tel, password и др.',
    table: { type: { summary: 'string' } },
  },
  field__name: {
    description: 'id поля. Нужен для совместного использования с компонентом InputTitle',
    table: { type: { summary: 'string' } },
  },
  field__value: {
    description: 'Данные в текстовом поле',
    table: { type: { summary: 'string' } },
  },
  field__required: {
    description:
      'Обязательное поле (при true рекомендуется также прописывать в errorsTypes свйоство required с текстом ошибки)',
    table: { type: { summary: 'boolean' } },
  },
  field__disabled: {
    description: 'Блокирование поля',
    table: { type: { summary: 'boolean' } },
  },
  field__visible: {
    description: 'Видимость поля',
    table: { type: { summary: 'boolean' } },
  },
  field__error: {
    description:
      'Поле id ошибки. Строка может иметь любое наполнение и input будет окрашен в красный, ' +
      'но рекомендуется использовать один из ключей из errorsTypes',
    table: { type: { summary: 'string' } },
    control: 'select',
    options: ['max-symbols-900', 'required', 'other'],
  },

  field__settings__title: {
    description: 'label/Заголовок над инпутом. (для компонента InputTitle)',
    table: { type: { summary: 'string' } },
  },
  field__settings__showRequired: {
    description: 'Флаг для показа звездочки у поля. (для компонента InputTitle)',
    table: { type: { summary: 'boolean' } },
  },
  field__settings__showError: {
    description: 'Флаг для визуального индикатора ошибки после валидации',
    table: { type: { summary: 'boolean' } },
  },
  field__settings__hint: {
    description:
      'Подсказка и Текст ошибки. При пустом error указывается подсказка для поля, ' +
      'иначе указывается текст ошибки из errorsTypes (для компонента InputHint)',
    table: { type: { summary: 'string' } },
  },
  field__settings__mask: {
    description: 'Маска для поля. Применяется при вводе текста. Полностью кастомная',
    table: { type: { summary: 'Function' } },
  },
  field__settings__placeholder: {
    description: 'Текст-подсказка когда в поле ничего не введено',
    table: { type: { summary: 'string' } },
  },
})

// interactions example
export const Disabled = Template.bind({})
Disabled.args = setArgsStories({
  ...Primary.args,
  field: { ...Primary.args.field, disabled: true },
})
Disabled.argTypes = setArgsTypesStories(Disabled.args, { ...Primary.argTypes })
