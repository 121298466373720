import merge from 'lodash/merge'
import { flatten, unflatten } from 'flat'

export const delimiterStories = '__'

export const getControlInfoStories = (nameArg) => {
  const NameSplit = nameArg.split(delimiterStories)
  const name = NameSplit.pop()
  const [category, subcategory] = NameSplit
  return {
    table: {
      category: category,
      subcategory: subcategory,
    },
    name,
  }
}

// Приводим все вложенные объекты аргументов в вид - field__value,
// field__settings__placeholder чтобы в дальнейшем их описать в ArgTypes
export const setArgsStories = (args) => {
  return flatten(args, { delimiter: delimiterStories, safe: true })
}

export const getArgsStories = (argsFlatten) => {
  return unflatten(argsFlatten, { delimiter: delimiterStories })
}

export const setArgsTypesStories = (args, customArgTypes) => {
  return merge({}, getArgsTypeFromArg(args), customArgTypes)
}

export const getArgsTypeFromArg = (args) => {
  const argTypes = {}
  Object.keys(args).forEach((key) => {
    argTypes[key] = getControlInfoStories(key)
  })
  return argTypes
}
