import { createAsyncThunk } from '@reduxjs/toolkit'

import { API } from '@shared/api'

import {
  addListReviewsAll,
  changeComponentDelete,
  changeComponentEdit,
  changeFilter,
  initFilterRates,
  setListReviewsAll,
  setLoading,
  setPagination,
  setPaginationPage,
  setParamsRequest,
  changeComponentCreate,
} from '@pages/OwnerPersonalPage/model/OwnerReviewsModel/reducer'

import { addTemporaryNotice } from '@widgets/Common/Notices'
import { TypeFilterDate, TypeFilterType } from '@pages/OwnerPersonalPage/model/OwnerReviewsModel/types'

// Скопировано с Reviews/model/createAsyncThunk.ts (можно удалить)

// Первая загрузка отзывов для страницы отзывов
export const loadListReviewsByFilter = createAsyncThunk(
  'OwnerReviews/loadListReviewsByFilter',
  async (props, { dispatch, getState }) => {
    // @ts-ignore
    const state = getState().pages.owner_personal.reviews

    dispatch(setLoading({ ...state.loading, full: true }))

    const params = { ...state.paramsRequest, page: 1 }

    // Загрузка отзывов для ЛК и запись в state всех отзывов
    await API.owner_reviews_list({ params: { ...params } })
      .then((response) => {
        const { results, pagination } = response
        dispatch(setListReviewsAll(results))

        dispatch(setPagination(pagination))

        dispatch(
          initFilterRates({
            all: pagination.total,
            positive: pagination.positive,
            negative: pagination.negative,
            unanswered: pagination.unanswered,
          }),
        )
      })
      // @ts-ignore
      .catch(() => dispatch(addTemporaryNotice('Ошибка загрузки отзывов')))
      .finally((res) => {
        dispatch(setLoading({ ...state.loading, full: false }))
      })
  },
)

/**
 * @function updateReviewFilter - Получение списка отзывов по фильтрам
 */
export const updateListReviewsByFilter = createAsyncThunk(
  'OwnerReviews/updateListReviewsByFilter',
  async (action: { type: 'types' | 'dates'; data: TypeFilterType | TypeFilterDate }, { dispatch, getState }) => {
    // @ts-ignore
    const state = getState().pages.owner_personal.reviews
    const getFilter = state.filters[action.type].find((filter) => filter.id === action.data.id)

    const { addition, full } = state.loading
    if (addition || full || !getFilter) return

    dispatch(setLoading({ ...state.loading, full: true }))
    dispatch(setParamsRequest({ ...state.paramsRequest, ...getFilter.paramsRequest }))
    dispatch(changeFilter(action))

    const params = {
      ...state.paramsRequest,
      ...getFilter.paramsRequest,
      page: 1,
    }

    // Тут будет запрос для ЛК
    API.owner_reviews_list({ params })
      .then((response) => {
        dispatch(setListReviewsAll(response.results))
        dispatch(setPagination(response.pagination))
      })
      .catch(() => {
        // @ts-ignore
        dispatch(addTemporaryNotice('Ошибка загрузки отзывов'))
      })
      .finally(() => {
        dispatch(setLoading({ ...state.loading, full: false }))
      })
  },
)

/**
 * @function loadMoreReviews - Подгрузка дополнительных отзывов с текущими фильтрами
 */
export const loadMoreReviews = createAsyncThunk(
  'OwnerReviews/loadMoreReviews',
  async (action, { dispatch, getState }) => {
    // @ts-ignore
    const state = getState().pages.owner_personal.reviews

    const { addition, full } = state.loading
    if (addition || full || !state.pagination.page.next.number) return

    dispatch(setLoading({ ...state.loading, addition: true }))

    const params = {
      ...state.paramsRequest,
      page: state.pagination.page.next.number,
    }

    // Тут будет запрос для ЛК
    API.owner_reviews_list({ params })
      .then((response) => {
        dispatch(addListReviewsAll(response.results))
        dispatch(setPaginationPage(response.pagination))
        dispatch(setParamsRequest(params))
      })
      .catch((error) => {
        console.error(error)
        // @ts-ignore
        dispatch(addTemporaryNotice('Ошибка загрузки отзывов'))
      })
      .finally(() => {
        dispatch(setLoading({ ...state.loading, addition: false }))
      })
  },
)

/**
 * @function sendCreateAnswer
 */
export const sendCreateAnswer = createAsyncThunk(
  'OwnerReviews/sendCreateAnswer',
  async (action, { dispatch, getState }) => {
    // @ts-ignore
    const state = getState().pages.owner_personal.reviews
    const { id_review, body, loading } = state.components.create

    const { addition, full } = state.loading
    if (addition || full) return

    dispatch(changeComponentCreate({ loading: true }))

    const params = {
      // @ts-ignore
      id: action.id,
      // @ts-ignore
      restaurant: action.restaurantId,
      body: {
        owner_comment_text: body,
      },
    }

    // Тут будет запрос для ЛК
    API.owner_reviews_partial_update(params)
      .then(() => {
        // @ts-ignore
        dispatch(addTemporaryNotice('Ответ обновлен'))
        dispatch(changeComponentCreate({ body: '' }))
        dispatch(loadListReviewsByFilter())
      })
      .catch((error) => {
        console.error('!!!sendCreateAnswer', error)
        // @ts-ignore
        dispatch(addTemporaryNotice('Ошибка обновления ответа'))
      })
      .finally(() => {
        dispatch(changeComponentEdit({ loading: false }))
      })
  },
)

/**
 * @function sendEditAnswer
 */
export const sendEditAnswer = createAsyncThunk(
  'OwnerReviews/sendEditAnswer',
  async (action, { dispatch, getState }) => {
    // @ts-ignore
    const state = getState().pages.owner_personal.reviews
    const { id_review, body } = state.components.edit

    const { addition, full } = state.loading
    if (addition || full) return

    dispatch(changeComponentEdit({ loading: true }))

    // const params = {
    //   path: id_review,
    //   body: { body },
    //   repeated: true,
    // }

    // Тут будет запрос для ЛК
    // API.owner_reviews_partial_update(params)
    //   .then(() => {
    //     dispatch(hideComponentEdit())
    //     // @ts-ignore
    //     dispatch(addTemporaryNotice('Ответ обновлен'))
    //     // @ts-ignore
    //     dispatch(loadReviewsLists())
    //   })
    //   .catch((error) => {
    //     // @ts-ignore
    //     dispatch(addTemporaryNotice('Ошибка обновления ответа'))
    //   })
    //   .finally(() => {
    //     dispatch(changeComponentEdit({ loading: false }))
    //   })
  },
)

/**
 * @function sendDeleteAnswer - Обработка удаления ответа
 */
export const sendDeleteAnswer = createAsyncThunk(
  'OwnerReviews/sendDeleteAnswer',
  async (action, { dispatch, getState }) => {
    // @ts-ignore
    const state = getState().pages.owner_personal.reviews

    const { addition, full } = state.loading
    if (addition || full) return

    dispatch(changeComponentDelete({ loading: true }))

    // const params = {
    //   path: state.components.delete.id_review,
    //   repeated: true,
    // }

    // Тут будет запрос для ЛК
    // API.reviews_delete(params)
    //   .then(() => {
    //     dispatch(hideComponentDelete())
    //     // @ts-ignore
    //     dispatch(addTemporaryNotice('Ответ успешно удален'))
    //     // @ts-ignore
    //     dispatch(loadReviewsLists())
    //   })
    //   .catch((error) => {
    //     // @ts-ignore
    //     dispatch(addTemporaryNotice('Ошибка удаления ответа'))
    //   })
    //   .finally(() => {
    //     dispatch(changeComponentDelete({ loading: false }))
    //   })
  },
)
