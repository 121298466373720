import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

const storeName = createSlice({
  name: 'test',
  initialState,

  reducers: {
    test(state, action) {
      console.log('test')
    },
  },
})

export const getState = (state) => state

export const { test } = storeName.actions

export default storeName.reducer
