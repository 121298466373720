/**
 * @description analytics.ts - Данный модуль отвечает за отслеживание аналитики
 *    информации о том, как пользователи взаимодействуют с приложением,
 *    в аналитическую базу данных для последующего анализа.
 */

import {
  ClickHouseMappingParams,
  TypeAnalyticParams,
  TypeClickHouseParams,
  TypeYandexMetricParams,
} from '@shared/api/analytics/types/types'
import { pageNameMapping } from '@shared/api/analytics/const'
import { globalStore } from '@app/model/store'
import { getFilterSelected } from '@features/Restaurant/RestaurantFilters/helpers/helper'
import parseCookie from '@shared/lib/helpers/parseCookie'
import { COOKIE_SHOW_LOG_ANALYTIC } from '@shared/consts/storage'

const GITLAB_STATUS = process.env.GITLAB_STATUS || null
const IS_PROD = GITLAB_STATUS === '0'
let SHOW_LOG_STATUS = null

const checkShowLogAnalytic = () => {
  if (SHOW_LOG_STATUS === null) {
    const hasCookie = !!parseCookie(COOKIE_SHOW_LOG_ANALYTIC)
    SHOW_LOG_STATUS = hasCookie
  }

  return SHOW_LOG_STATUS
}

/**
 * @function sendClickHouseAnalyticTimeout - Отправляет данные о событиях в ClickHouse через EGG библиотеку.
 *    Если не получается отправить (бывает из-за отстутствия скрипта EGG),
 *    функция делает повторные попытки и отправляет информацию
 * @param params
 * @param attemptCounter
 * @returns
 */
const sendClickHouseAnalyticTimeout = (params: Partial<TypeClickHouseParams>, attemptCounter = 0) => {
  if (!params.name) return
  if (attemptCounter === 10) return

  const { name, ...properties } = params

  if (checkShowLogAnalytic() && !IS_PROD) {
    console.log('EGG Analytic;', `Элемент - ${params.element || 'нет'};`, `Событие - ${name};`, '\nПодробности - ', {
      name: name,
      ...properties,
    })
  }

  if (!IS_PROD) return

  // @ts-ignore
  if (window.EGG) {
    // @ts-ignore
    window.EGG.send('event_v2', {
      name: params.name,
      props: properties,
    })

    if (checkShowLogAnalytic()) {
      console.log('EGG Analytic;', `Элемент - ${params.element || 'нет'};`, `Событие - ${name};`, '\nПодробности - ', {
        name: name,
        ...properties,
      })
    }
  } else {
    setTimeout(() => {
      sendClickHouseAnalyticTimeout(params, ++attemptCounter)
    }, 500)
  }
}

/**
 * @function sendClickHouseAnalytic - Подготовка данных аналитики и вызов их отправки в ClickHouse,
 * @param params
 */
export const sendClickHouseAnalytic = (params: Partial<TypeAnalyticParams>) => {
  const { global, pages } = globalStore.getState()

  if (!global.storeLoaded) {
    setTimeout(() => sendClickHouseAnalytic(params), 1000)
    return
  }

  const mappingData: Partial<TypeClickHouseParams> = {
    tab: 'restaurant',
    city: global.currentCity.name,
    page: params.page_name ? pageNameMapping[params.page_name] : pageNameMapping[global.currentPage],
    auth: global.user.data ? 1 : 0,
  }

  if (global.user.data) {
    mappingData.role = global.user.data.owner_rests.length ? 'restaurateur' : 'user'
  }

  Object.entries(params).forEach(([key, value]) => {
    const keyClickHouse = ClickHouseMappingParams[key]

    // Если переданного ключа нет в списке ClickHouseMappingParams для ClickHouse,
    // то данный параметр добавлен в итоговый объект добавлен не будет
    // P.S. Эта проверка необходима, ибо params может содержать параметры как для ClickHouse, так и для YandexMetric
    if (!keyClickHouse) return

    let formattedValue = typeof value == 'boolean' ? Number(value) : value

    if (keyClickHouse === 'position') {
      formattedValue = Number(formattedValue) + 1
    }

    mappingData[keyClickHouse] = formattedValue
  })

  switch (global.currentPage) {
    case 'rest_item':
      mappingData.page_object_id = pages.rest_item.data?.id
      mappingData.page_object_type = 'restaurant'
      break
    case 'news_item':
      mappingData.page_object_id = pages.news_item.news?.id
      mappingData.page_object_type = 'article'
      break
    case 'selection_item':
      mappingData.page_object_id = pages.selection_item.selection.data?.id
      mappingData.page_object_type = 'selection'
      break
    case 'rests_listing':
      if (!mappingData.filters) {
        mappingData.filters = getFilterSelected()
      }
      mappingData.list_name = pages.listing.title
  }

  sendClickHouseAnalyticTimeout(mappingData)
}

export const sendYandexMetricAnalytic = (params: TypeYandexMetricParams) => {
  if (checkShowLogAnalytic()) {
    console.log(params.event, params.id)
  }

  if (!IS_PROD) return

  // @ts-ignore
  if (window?.ym) {
    // @ts-ignore
    window.ym(params.id, params.type, params.event)
  }
}
