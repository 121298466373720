import { useEffect } from 'react'
import { EGG } from '@shared/api/analytics'
import { useAppSelector } from '@app/model/store'
import { useEffectOnce } from '@shared/lib/hooks/useEffectOnce'
import { COOKIE_SHOW_LOG_ANALYTIC } from '@shared/consts/storage'

// Работа с аналитикой
export const useGlobalAnalytic = () => {
  const globalState = useAppSelector((state) => state.global)

  // Отправка аналитики о новой сессии, срабатывает только один раз за сессию
  useEffect(() => {
    const hasSessionStorageRedesign = sessionStorage.getItem('redesign-afisha-restaurant')
    if (!hasSessionStorageRedesign) {
      sessionStorage.setItem('redesign-afisha-restaurant', 'true')
      EGG.global.new_session_experiment()
    }
  }, [])

  useEffectOnce(() => {
    // @ts-ignore
    window.showLogAnalytic = (value) => {
      let date: Date | string = new Date(Date.now())
      date.setDate(date.getDate() + (value ? 30 : -1))
      date = date.toUTCString()

      document.cookie = `${COOKIE_SHOW_LOG_ANALYTIC}=true; expires=${date}; domain=.afisha.ru; path=/`

      window.location.reload()
    }
  })

  // Отправка аналитики при переходе на страницу
  useEffect(() => {
    EGG.global.send_global_user_data()
  }, [globalState.currentPage])
}
