import s from './Template.module.sass'
import React, { FC } from 'react'
import { useCurrentSize } from '@shared/lib/helpers/classes'

import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { TemplateSkeleton } from '@widgets/Restaurant/ResturantCards/RestCard/ui/CardTypes/Template'

export interface TemplateCardProps {
  sizes: string // L ML M S
  className?: string
}

const Skeleton: FC<TemplateCardProps> = (props) => {
  const [size] = useCurrentSize(props.sizes)

  return (
    <ErrorBoundary>
      <TemplateSkeleton {...props} />
    </ErrorBoundary>
  )
}

export default Skeleton
