import React, { FC, ReactNode } from 'react'
import s from './SectionMainCarousel.module.sass'
import { Carousel } from '@shared/ui/Layout/Carousel'
import IconArrow from '@icons/icon-arrow-cover.svg'
import { ContainerLong } from '@shared/ui/Layout/Containers/ContainerLong'

interface Props {
  children: ReactNode
  className?: string
}

const SectionMainCarousel: FC<Props> = ({ children, className = '', ...props }) => {
  return (
    <ContainerLong {...props}>
      <Carousel analytic={{ section_name: 'Главный слайдер' }}>
        <div className={s.wrapper}>
          <div className={s.controls}>
            <Carousel.Arrow direction={'prev'} className={s['arrow-prev']}>
              <IconArrow />
            </Carousel.Arrow>
            {/*// @ts-ignore*/}
            <Carousel.Arrow direction={'next'} className={s['arrow-next']}>
              <IconArrow />
              {/*// @ts-ignore*/}
            </Carousel.Arrow>
          </div>
          {/*// @ts-ignore*/}
          <Carousel.Slider className={`${s.slider} ${className}`} spacingMobile={8} spacingDesktop={16}>
            {children}
            {/*// @ts-ignore*/}
          </Carousel.Slider>
        </div>
      </Carousel>
    </ContainerLong>
  )
}

export default SectionMainCarousel
