import s from './Template.module.sass'
import React, { FC } from 'react'

import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { TemplateSkeleton } from '@widgets/Restaurant/ResturantCards/RestCard/ui/CardTypes/Template'

export interface TemplateCardProps {
  sizes: string // L ML M S
  className?: string
  animate?: boolean
}

const Skeleton: FC<TemplateCardProps> = (props) => {
  return (
    <ErrorBoundary>
      <TemplateSkeleton {...props} />
    </ErrorBoundary>
  )
}

export default Skeleton
