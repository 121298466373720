import { undefinedObjectToNull } from '@shared/api/middleware/mappingAPI/helpers'
import { TypeMappingPaginationOutput } from '@shared/api/middleware/mappingAPI/pagination/type'

export const mappingPagination = (object): TypeMappingPaginationOutput => {
  try {
    return undefinedObjectToNull({
      total: object.count,
      page: {
        count: object.num_pages,
        size: object.page_size,
        next: {
          link: object.next,
          number: object.next_page_number,
        },
        previous: {
          link: object.previous,
          number: object.previous_page_number,
        },
        current: {
          number: object.current_page_number,
        },
      },
    })
  } catch (error) {
    console.error(error)
    return null
  }
}
