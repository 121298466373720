// First, create the thunk
import {
  openModal,
  setCheckDiscountLoading,
  setDatesOptions,
  setDisabledAlertItem,
  setGuestsCountValue,
  setIsErrorsVisible,
  setIsOpenScreenSuccess,
  setNameFieldValue,
  setPhoneFieldValue,
  setRestaurantData,
  setSelectedDate,
  setSelectedTime,
  setTimesOptions,
} from '@widgets/Restaurant/Booking/model/reduce'
import { TypeExtendedScheduleDay, TypeExtendedScheduleTimeSlot } from '@shared/types/schedule/types'
import { AnyAction, createAsyncThunk } from '@reduxjs/toolkit'
import { Dispatch } from 'react'
import { API } from '@shared/api'
import { addTemporaryNotice } from '@widgets/Common/Notices'
import { EGG } from '@shared/api/analytics'
import { TypeMappingRestOutput } from '@shared/api/middleware/mappingAPI/restaurant/type'
import { isPhonePatternValid } from '@widgets/Restaurant/Booking/lib/helper'
import {
  fetchRemarkedSlots,
  initFetchRemarkedSlotsNearTwoDays,
} from '@widgets/Restaurant/Booking/model/createAsyncThunk'
import { generateDatesOptions } from '@shared/lib/helpers/schedule/schedule'
import { globalStore } from '@app/model/store'

export const submitBooking = createAsyncThunk('booking/submitBooking', async (action, { dispatch, getState }) => {
  // check valid form

  // @ts-ignore
  const { form, restaurantSource } = getState().booking
  const { guestsCountField, nameField, phoneField, commentField, dateField, timeField } = form
  const arrayValidFields = [
    guestsCountField.valid,
    nameField.valid,
    phoneField.valid,
    commentField.valid,
    dateField.valid,
    timeField.valid,
  ]

  const isValidAllFields = arrayValidFields.every((fieldValid) => fieldValid)
  if (!isValidAllFields) {
    // @ts-ignore
    dispatch(setIsErrorsVisible())
    return
  }

  EGG.entity.booking.click_reserve_book({
    count_guest: String(guestsCountField.value),
    phone: phoneField.value,
    date: String(dateField.selected.value),
    time: String(timeField.selected.value),
  })

  const date = dateField.selected.value
  const time = timeField.selected.value

  API.booking_create({
    body: {
      restaurant: Number(restaurantSource.id),
      guest_phone: phoneField.value,
      num_of_places: Number(guestsCountField.value),
      reservation_at: `${date} ${time}`,
      guest_name: nameField.value,
      comment: commentField.value,
    },
    // repeated: true,
  })
    .then(() => {
      dispatch(setIsOpenScreenSuccess(true))
      window.localStorage.setItem('booking-guest-name', JSON.stringify(nameField.value))
      window.localStorage.setItem('booking-guest-phone', JSON.stringify(phoneField.value))
    })
    .catch((error) => {
      const noticeText = error?.message || 'Ошибка отправки формы бронирования'
      // @ts-ignore
      dispatch(addTemporaryNotice(noticeText))
    })
})

export const handleSubmitForm = () => (dispatch: Dispatch<AnyAction>) => {
  // @ts-ignore
  dispatch(setIsErrorsVisible())
}

export const checkDiscountByNumber = createAsyncThunk(
  'booking/checkDiscountByNumber',
  async (action: { phone: string; restId: number }, { dispatch, getState }) => {
    // @ts-ignore
    const stateDiscounts = getState().booking.restaurantSource.discounts
    const hasDiscounts = !!stateDiscounts.tastyclub || !!stateDiscounts.remarked

    if (!hasDiscounts) return
    if (!isPhonePatternValid(action.phone)) return

    const numbers = parseInt(action.phone.replace(/[^\d]/g, ''))

    dispatch(setCheckDiscountLoading(true))
    dispatch(setDisabledAlertItem({ type: 'discount', disabled: true }))

    await API.booking_discount({ body: { phone: numbers, rest_id: action.restId } })
      .then((res) => {
        dispatch(setDisabledAlertItem({ type: 'discount', disabled: !res.discount }))
      })
      .finally(() => {
        dispatch(setCheckDiscountLoading(false))
      })
  },
)

type TypeOpenBookingInitial = {
  date?: TypeExtendedScheduleDay
  time?: TypeExtendedScheduleTimeSlot
  guests?: number
}

export const openBooking =
  (restaurantData: TypeMappingRestOutput, initial?: TypeOpenBookingInitial) =>
  async (dispatch: Dispatch<AnyAction>) => {
    const remarkedActive = restaurantData.booking.afisha.remarked.active
    const {
      date: initialDate,
      time: initialTime,
      guests: initialGuests,
    } = { date: null, time: null, guests: 2, ...initial }

    dispatch(setRestaurantData(restaurantData))
    dispatch(setGuestsCountValue(initialGuests))
    dispatch(openModal(null))
    // @ts-ignore
    dispatch(setFieldsValuesFromLocalStorage())

    if (remarkedActive) {
      // 1. Делаем запрос на ближайшие два дня (параллельно), чтобы отображать кнопки "сегодня", "завтра" или нет
      // 2.1 Если слоты будут, то отображаются кнопки
      // 2.2 Если слотов нет, то отображается только календарь, и в слотах будет надпись "Выберите дату"
      await dispatch(
        // @ts-ignore
        initFetchRemarkedSlotsNearTwoDays({
          guests: initialGuests,
          restSlug: restaurantData.url.slug,
          schedule: restaurantData.schedule.week,
        }),
      )

      if (initialDate) {
        // Если передан параметр на предустановленный слот, делаем еще делаем еще один запрос на данный слот (с учетом кол-ва гостей).
        // Это нужно, чтобы получить список всех доступных слотов на выбранный день
        await dispatch(
          // @ts-ignore
          fetchRemarkedSlots({
            guests: initialGuests,
            restSlug: restaurantData.url.slug,
            date: initialDate,
          }),
        )

        dispatch(setSelectedTime(initialTime))
      }
    } else {
      dispatch(setDatesOptions(null))
      dispatch(setSelectedDate(initialDate))
      dispatch(setTimesOptions(null))
      dispatch(setSelectedTime(initialTime))
    }
  }

export const setFieldsValuesFromLocalStorage = () => (dispatch: Dispatch<AnyAction>) => {
  const isWindowAvailable = typeof window !== 'undefined'
  if (isWindowAvailable) {
    const storedName = window.localStorage.getItem('booking-guest-name')
    const storedPhone = window.localStorage.getItem('booking-guest-phone')

    storedName && dispatch(setNameFieldValue(JSON.parse(storedName)))
    storedPhone && dispatch(setPhoneFieldValue(JSON.parse(storedPhone)))
  }
}

/**
 * @Function updateTimeSlotsFromBehindDateAC
 * Обновление слотов времени при изменении выбранной даты. Необходим для ресторанов с ремаркедом
 * */
export const updateTimeSlotsFromBehindDateAC =
  (date: TypeExtendedScheduleDay) => async (dispatch: Dispatch<AnyAction>) => {
    const stateBooking = globalStore.getState().booking
    const { remarked, slug } = stateBooking.restaurantSource

    if (remarked) {
      await dispatch(
        // @ts-ignore
        fetchRemarkedSlots({
          guests: stateBooking.form.guestsCountField.value,
          restSlug: slug,
          date: date,
        }),
      )
    } else {
      dispatch(setTimesOptions(date))
    }
  }

/**
 * @Function updateTimeSlotsFromBehindGuestsAC.
 * Обновление слотов времени при изменении кол-ва гостей. Необходим для ресторана с ремаркедом
 * */
export const updateTimeSlotsFromBehindGuestsAC = () => async (dispatch: Dispatch<AnyAction>) => {
  const stateBooking = globalStore.getState().booking
  const restData = stateBooking.restaurantSource

  const formattedDate = stateBooking.form.dateField.selected

  if (restData.remarked) {
    await dispatch(
      // @ts-ignore
      fetchRemarkedSlots({
        guests: stateBooking.form.guestsCountField.value,
        restSlug: restData.slug,
        date: formattedDate,
      }),
    )
  }
}

/**
 * @Function setGuestsAC. Для использования в UI
 * Установка данных (счетчик гостей, слотов времени) для нового кол-ва гостей.
 * */
export const setGuestsAC = (value: number) => (dispatch: Dispatch<AnyAction>) => {
  dispatch(setGuestsCountValue(value))
  // @ts-ignore
  dispatch(updateTimeSlotsFromBehindGuestsAC())
}

/**
 * @Function setDateAC. Для использования в UI
 * Установка данных (слотов времени) для нового выбранного дня.
 * */
export const setDateAC = (date: Date) => (dispatch: Dispatch<AnyAction>) => {
  const stateBooking = globalStore.getState().booking
  const restData = stateBooking.restaurantSource

  dispatch(setSelectedTime(null))

  const formattedDateResult = generateDatesOptions(restData.schedule, { preferredDay: date })[0]

  dispatch(setSelectedDate(formattedDateResult))
  // @ts-ignore
  dispatch(updateTimeSlotsFromBehindDateAC(formattedDateResult))
}
