import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { TypeState } from '@pages/RestItem/model/types'
import {
  fetchNearestRests,
  fetchPopularRests,
  fetchSelectionList,
  fetchSimilarRests,
} from '@pages/RestItem/model/createAsyncThunk'

import { TypeMappingRestOutput } from '@shared/api/middleware/mappingAPI/restaurant/type'

import { generateSeoAnswers } from '@pages/RestItem/model/helpers'

import { TypeMappingSelectionOutput } from '@shared/api/middleware/mappingAPI/selection/type'

const initialState: TypeState = {
  data: null,

  similarRests: {
    loading: true,
    list: null,
  },
  nearestRests: {
    loading: true,
    list: null,
  },
  popularRests: {
    loading: true,
    list: null,
  },
  selectionList: {
    loading: true,
    list: null,
  },
}

const restItem = createSlice({
  name: 'restItem',
  initialState,

  reducers: {
    setData(state, action: PayloadAction<{ data: TypeMappingRestOutput; cityName: string | null }>) {
      state.data = {
        ...action.payload.data,
        questionsAnswers: generateSeoAnswers({
          restData: action.payload.data,
          city: action.payload.cityName,
        }),
      }
    },

    resetState() {
      return initialState
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchSimilarRests.pending, (state) => {
      state.similarRests.loading = true
      state.similarRests.list = null
    })
    builder.addCase(fetchSimilarRests.fulfilled, (state, action) => {
      state.similarRests.loading = false
      state.similarRests.list = action.payload
    })
    builder.addCase(fetchSimilarRests.rejected, (state, action) => {
      state.similarRests.loading = false
    })

    builder.addCase(fetchNearestRests.pending, (state) => {
      state.nearestRests.loading = true
      state.nearestRests.list = null
    })
    builder.addCase(fetchNearestRests.fulfilled, (state, action) => {
      state.nearestRests.loading = false
      state.nearestRests.list = action.payload
    })
    builder.addCase(fetchNearestRests.rejected, (state, action) => {
      state.nearestRests.loading = false
    })

    builder.addCase(fetchPopularRests.pending, (state) => {
      state.popularRests.loading = true
      state.popularRests.list = null
    })
    builder.addCase(fetchPopularRests.fulfilled, (state, action) => {
      state.popularRests.list = action.payload
      state.popularRests.loading = false
    })
    builder.addCase(fetchPopularRests.rejected, (state, action) => {
      state.popularRests.loading = false
    })

    builder.addCase(fetchSelectionList.pending, (state) => {
      state.selectionList.loading = true
      state.selectionList.list = null
    })
    builder.addCase(fetchSelectionList.fulfilled, (state, action: PayloadAction<TypeMappingSelectionOutput[]>) => {
      state.selectionList.list = action.payload
      state.selectionList.loading = false
    })
    builder.addCase(fetchSelectionList.rejected, (state) => {
      state.selectionList.loading = false
    })
  },
})

export const getState = (state) => state

export const { setData, resetState } = restItem.actions

export default restItem.reducer
