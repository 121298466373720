import { getDefaultField } from '@shared/lib/fields/getDefaultField'

export const getField = (nameField, data = null) => {
  const index = data?.index ? `-${data.index}` : ''

  const FIELDS = {
    special_title: getDefaultField('input', {
      name: 'special_title',
      value: data?.value || '',
      required: true,
      autoValid: true,
      validations: {
        'max-symbols-70': 'Максимальное количество символов - 70',
      },
      settings: {
        showError: true,
        showRequired: false,
        label: 'Заголовок спецпредложения',
        placeholder: 'Введите заголовок',
        hint: 'Не более 70 символов',
      },
    }),

    special_description: getDefaultField('textarea', {
      name: 'special_description',
      value: data?.value || '',
      required: true,
      autoValid: true,
      validations: {
        'max-symbols-450': 'Максимальное количество символов - 450',
      },
      settings: {
        showError: true,
        showRequired: false,
        label: 'Описание спецпредложения',
        placeholder: 'Расскажите подробнее о спецпредложении',
        hint: 'Не более 450 символов',
        resize: true,
        height: '108px',
      },
    }),

    news_title: getDefaultField('input', {
      name: 'news_title',
      value: data?.value || '',
      required: true,
      autoValid: true,
      validations: {
        'max-symbols-300': 'Максимальное количество символов - 300',
      },
      settings: {
        showError: true,
        showRequired: false,
        label: 'Заголовок новости',
        placeholder: 'Введите заголовок',
        hint: 'Не более 300 символов',
      },
    }),

    news_description: getDefaultField('textarea', {
      name: 'news_description',
      value: data?.value || '',
      required: true,
      autoValid: true,
      validations: {
        'max-symbols-1000': 'Максимальное количество символов - 1000',
      },
      settings: {
        showError: true,
        showRequired: false,
        label: 'Описание новости',
        placeholder: 'Расскажите подробнее о событии',
        hint: 'Не более 1000 символов',
        resize: true,
        height: '108px',
      },
    }),

    dish_title: getDefaultField('input', {
      name: `dish_title${index}`,
      value: data?.value || '',
      required: true,
      autoValid: true,
      validations: {
        'max-symbols-50': 'Максимальное количество символов - 50',
      },
      settings: {
        showError: true,
        showRequired: false,
        label: 'Название блюда',
        placeholder: 'Введите название',
      },
    }),

    dish_price: getDefaultField('input', {
      name: `dish_price${index}`,
      value: data?.value || '',
      required: true,
      autoValid: true,
      validations: {
        'max-symbols-50': 'Максимальное количество символов - 50',
      },
      settings: {
        showError: true,
        showRequired: false,
        label: 'Цена',
        placeholder: 'Укажите цену (только цифры)',
      },
    }),

    dish_description: getDefaultField('textarea', {
      name: `dish_description${index}`,
      value: data?.value || '',
      required: true,
      autoValid: true,
      validations: {
        'max-symbols-140': 'Максимальное количество символов - 140',
      },
      settings: {
        showError: true,
        showRequired: false,
        label: 'Описание блюда',
        placeholder: 'Расскажите подробнее о новом блюде',
        hint: 'Не более 140 символов',
        resize: true,
        height: '108px',
      },
    }),
  }

  return FIELDS[nameField]
}
