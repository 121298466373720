import coverPink from '@images/placeholder-with-logo--pink.png'
import coverGreen from '@images/placeholder-with-logo--green.png'
import coverBlue from '@images/placeholder-with-logo--blue.png'

import squarePink from '@images/placeholder-with-logo-square--pink.png'
import squareGreen from '@images/placeholder-with-logo-square--green.png'
import squareBlue from '@images/placeholder-with-logo-square--blue.png'

import bgPink from '@images/placeholder-bg--pink.png'
import bgGreen from '@images/placeholder-bg--green.png'
import bgBlue from '@images/placeholder-bg--blue.png'

const placeholderImagesCover = {
  pink: coverPink.src,
  green: coverGreen.src,
  blue: coverBlue.src,
}
const placeholderSquareImages = {
  pink: squarePink.src,
  green: squareGreen.src,
  blue: squareBlue.src,
}
const placeholderImagesBg = {
  pink: bgPink.src,
  green: bgGreen.src,
  blue: bgBlue.src,
}

/**
 * @function randomProperty - TODO описание
 */
const randomProperty = (obj) => {
  const keys = Object.keys(obj)
  return obj[keys[(keys.length * Math.random()) << 0]]
}

/**
 * @function shuffleChars - TODO описание
 */
const shuffleChars = (input) => {
  const output = input
    .toString()
    .split('')
    .sort(() => Math.random() - 0.5)
    .join('')
  return output
}

/**
 * @function - getPropertyFromID - перемешиваем символы в ID строке,
 *    чтобы избежать группирования  по цвету "соседних" данных в базе,
 * @description делим полученное на три цветовые группы
 *
 */
const getPropertyFromID = (ID: string, imgType = null) => {
  let output

  // Вычисляем сумму численных значений всех символов строки ID
  const sum = ID.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0)

  // Определяем остаток от деления на 3 для выбора цвета
  const controlSeed = shuffleChars(sum) % 3

  // Определяем значение
  switch (controlSeed) {
    case 0:
      output =
        imgType === 'square'
          ? placeholderSquareImages.pink
          : imgType === 'bg'
          ? placeholderImagesBg.pink
          : placeholderImagesCover.pink
      break
    case 1:
      output =
        imgType === 'square'
          ? placeholderSquareImages.green
          : imgType === 'bg'
          ? placeholderImagesBg.green
          : placeholderImagesCover.green
      break
    case 2:
      output =
        imgType === 'square'
          ? placeholderSquareImages.blue
          : imgType === 'bg'
          ? placeholderImagesBg.blue
          : placeholderImagesCover.blue
      break
  }

  return output
}

/**
 * @function getPlaceholderImage
 * @param inputID используется для фиксации цвета по id, чтобы не менялось агрессивно;
 *    чистим данные, чтобы в них были только цифры
 * @description при отсутствии ID берется случайная картинка
 */

export const getPlaceholderImage = (inputID?: number | string, imgType?: string) => {
  return inputID
    ? getPropertyFromID(`${inputID}`.replaceAll('[^\\d-]', ''), imgType)
    : randomProperty(placeholderImagesCover)
}
