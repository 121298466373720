import { FieldSelect } from '@shared/types/fields/fields'

/**
 * @function getSelectLabel - TODO описание
 */
export const getSelectLabel = (field: FieldSelect) => {
  const { placeholder, defaultOption } = field.settings
  const hasValue = !!field.value

  if (defaultOption) {
    return hasValue ? field?.value?.label : defaultOption.label
  } else if (placeholder) {
    return hasValue ? field?.value?.label : placeholder
  }
}

/**
 * @function isClassPlaceholder - TODO описание
 */
export const isClassPlaceholder = (field: FieldSelect) => {
  const { placeholder, defaultOption } = field.settings

  if (defaultOption) {
    return field.value?.value === defaultOption.value
  } else if (placeholder) {
    return !field.value
  }
}

/**
 * @function getOptions - TODO описание
 */
export const getOptions = (field: FieldSelect) => {
  const { defaultOption, options } = field.settings

  return defaultOption ? [defaultOption, ...options] : options
}
