import React, { FC, memo } from 'react'
import s from './Radio.module.sass'
import { RadioOption } from '@shared/types/fields/fields'
import { ErrorBoundary } from '../../../../lib/components/ErrorBoundary'
import { Text } from '@shared/ui/Typography/Text'

interface Props {
  option: RadioOption
  checked: boolean
  // eslint-disable-next-line
  handleClick: (value: RadioOption) => void
  mode?: 'checkbox' | 'button'
}

const Radio: FC<Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLLabelElement>, HTMLLabelElement>> = ({
  option,
  checked,
  handleClick,
  mode = 'checkbox',
  ...props
}) => {
  // eslint-disable-next-line
  const handleChangeInner = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleClick && handleClick(option)
  }

  return (
    <ErrorBoundary>
      <label className={`${s.button} ${s[mode]}`} data-disabled={option.disabled} data-checked={checked} {...props}>
        <input
          type='radio'
          id={option.id}
          name={option.name}
          value={option.value}
          disabled={option.disabled}
          checked={checked}
          onChange={handleChangeInner}
          hidden
        />
        <Text sizes={'M'}>{option.label}</Text>
      </label>
    </ErrorBoundary>
  )
}

export default memo(Radio)
