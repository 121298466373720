import React, { FC } from 'react'
import s from './SectionTitle.module.sass'
import { Title } from '@shared/ui/Typography/Title'
import { ButtonLinkMore } from '@shared/ui/Actions/ButtonLinkMore'
import Link from 'next/link'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { PickAnalytic } from '@shared/api/analytics/types/types'
import { SectionTitleSkeleton } from '@shared/ui/Layout/SectionTitle/index'

interface Props {
  title: string
  link?: string | object
  handleBeforeAction?: () => boolean
  skeleton?: boolean
  className?: string
  analytic?: PickAnalytic<'section_name'>
}

const SectionTitle: FC<Props> = ({ title, link, handleBeforeAction, className, analytic, skeleton, ...props }) => {
  const Header = () => (
    <Title tag='h2' sizes='h2 h2 h2 h4' className={s.title}>
      {title}
    </Title>
  )

  if (skeleton) return <SectionTitleSkeleton />

  return (
    <ErrorBoundary>
      <div className={`${s.wrapper} ${className}`} {...props}>
        {link ? (
          <Link href={link} className={s['title-link']} scroll={false}>
            <Header />
          </Link>
        ) : (
          <Header />
        )}

        {link && (
          <ButtonLinkMore
            link={link}
            handleBeforeAction={handleBeforeAction}
            analytic={{ section_name: analytic?.section_name || title }}
            className={s.button}
          />
        )}
      </div>
    </ErrorBoundary>
  )
}

export default SectionTitle
