import React, { FC, useEffect, useRef, useState } from 'react'
import { Title } from '@shared/ui/Typography/Title'
import { Text } from '@shared/ui/Typography/Text'
import { useAppSelector } from '@app/model/store'
import { DiscloseText } from '@shared/ui/Actions/DiscloseText'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import s from './RestaurantDescription.module.sass'
import { useIntersectionAnalytic } from '@shared/lib/hooks/useIntersectionAnalytic'
import { EGG } from '@shared/api/analytics'
import { TypeMappingRestOutput } from '@shared/api/middleware/mappingAPI/restaurant/type'
import { RestaurantDescriptionSkeleton } from '@widgets/Restaurant/RestaurantDescription/index'

const RestaurantDescriptionWrapper: FC = () => {
  const restaurantData = useAppSelector((state) => state.pages.rest_item.data)
  const isLoading = !restaurantData
  const hasContent =
    restaurantData?.description.description.afisha ||
    restaurantData?.description.description.owner ||
    restaurantData?.description.title
  if (isLoading) return <RestaurantDescriptionSkeleton />
  else if (!isLoading && hasContent) return <RestaurantDescription />
  else return
}

const RestaurantDescription: FC = () => {
  const stateRestaurant = useAppSelector((state) => state.pages.rest_item)
  const { description } = stateRestaurant.data

  const descriptionByAfisha = description.description.afisha
  const descriptionByOwner = description.description.owner

  const getDescriptionType = () => {
    return descriptionByAfisha ? 'afisha' : 'owner'
  }
  const [selectedDescriptionType, setSelectedDescriptionType] = useState(descriptionByAfisha ? 'afisha' : 'owner')
  const wrapper = useRef()

  useEffect(() => {
    setSelectedDescriptionType(() => getDescriptionType())
  }, [stateRestaurant.data.id])

  const handleChangeVisibleText = (type) => {
    switch (type) {
      case 'afisha':
        setSelectedDescriptionType('afisha')
        handleSendAnalytic('afisha')
        break
      case 'owner':
        setSelectedDescriptionType('owner')
        handleSendAnalytic('owner')
        break
    }
  }

  const handleSendAnalytic = (type) => {
    EGG.pages.RestItem.description_view({
      description: type === 'afisha' ? 'afisha' : 'own',
    })
  }

  useIntersectionAnalytic(wrapper, () => handleSendAnalytic(selectedDescriptionType))

  return (
    <ErrorBoundary>
      <div className={`${s.wrapper}`} ref={wrapper}>
        {/*{state.avatar && <Image layout={'fill'} src={state.avatar} alt='rest-description' className={s.avatar} />}*/}

        <div className={s.content}>
          <div className={s.buttons}>
            {descriptionByAfisha && (
              <button
                className={s.button}
                type='button'
                onClick={() => handleChangeVisibleText('afisha')}
                data-active={selectedDescriptionType === 'afisha'}>
                <Text tag='span' sizes='S'>
                  Описание Афиши
                </Text>
              </button>
            )}

            {descriptionByOwner && (
              <button
                className={s.button}
                type='button'
                onClick={() => handleChangeVisibleText('owner')}
                data-active={selectedDescriptionType === 'owner'}>
                <Text tag='span' sizes='S'>
                  Описание от ресторана
                </Text>
              </button>
            )}
          </div>

          <Title tag='h3' sizes='h1 h2 h2 h4' className={s.title}>
            {description.title}
          </Title>

          {selectedDescriptionType === 'afisha' ? (
            <DiscloseText maxHeight={116}>
              <Text tag='p' sizes='ML M M M' className={s.text} paragraph>
                {descriptionByAfisha}
              </Text>
            </DiscloseText>
          ) : (
            <DiscloseText maxHeight={116}>
              <Text tag='p' sizes='ML M M M' className={s.text} paragraph>
                {descriptionByOwner}
              </Text>
            </DiscloseText>
          )}
        </div>
      </div>
    </ErrorBoundary>
  )
}

export default RestaurantDescriptionWrapper
