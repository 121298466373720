import React, { FC, useCallback, useMemo } from 'react'
import s from './RestaurantReviews.module.sass'
import { Reviews } from '@entities/Restaurant/Reviews'
import { useAppSelector } from '@app/model/store'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { Carousel } from '@shared/ui/Layout/Carousel'
import { SectionTitle } from '@shared/ui/Layout/SectionTitle'
import { useRouter } from 'next/router'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { RestaurantReviewsSkeleton } from '@widgets/Restaurant/RestaurantReviews/index'

type Props = {
  restaurant: {
    name: string
    id: number
  }
}

const RestaurantReviews: FC<Props> = ({ restaurant }) => {
  const stateReviews = useAppSelector((state) => state.entities.restaurant_reviews)
  const { user_reviews, other_reviews } = stateReviews.lists
  const { isMobile } = useClientResponsive()
  const router = useRouter()

  const mainList = isMobile ? [...user_reviews, ...other_reviews] : other_reviews

  const optionsSlider = {
    breakpoints: {
      '(min-width: 768px)': {
        disabled: true,
      },
    },
  }

  const TARGET_QA_KEY = 'is_test_runner'
  const getReviewsUrl = (thisQuery) => {
    const isQA = window?.location?.search?.indexOf(TARGET_QA_KEY) > 0
    return !isQA
      ? `/${thisQuery.city_slug}/restaurant/${thisQuery.rest_slug}/reviews`
      : `/${thisQuery.city_slug}/restaurant/${thisQuery.rest_slug}/reviews?${TARGET_QA_KEY}=true`
  }

  const getAnalyticData = useCallback(
    (type, index) => {
      if (type === 'user') return { section_index: index }
      if (type === 'main') return { section_index: (user_reviews.length || 0) + index }
    },
    [user_reviews.length, other_reviews.length],
  )

  if (!restaurant.id) return <RestaurantReviewsSkeleton />

  return (
    <ErrorBoundary>
      <div className={s.wrapper} id={'reviews'}>
        <Reviews restaurant={restaurant}>
          {stateReviews.loading.full || !stateReviews.is_ready ? (
            <RestaurantReviewsSkeleton />
          ) : (
            <>
              <div className={s['wrapper-user-path']}>
                {!!user_reviews.length && !isMobile && (
                  <div>
                    <SectionTitle title={'Ваш отзыв'} />
                    {user_reviews.map((item, index) => (
                      // @ts-ignore
                      <Reviews.Item
                        key={item.id}
                        data={item}
                        mode={isMobile ? 'card' : 'row'}
                        analytic={getAnalyticData('user', index)}
                      />
                    ))}
                  </div>
                )}
                {/*// @ts-ignore*/}
                <Reviews.Create />
              </div>

              {!!other_reviews.length && (
                <div>
                  <SectionTitle
                    analytic={{ section_name: 'reviews' }}
                    title={isMobile ? 'Отзывы' : `Отзывы о ${restaurant.name}`}
                    link={isMobile ? getReviewsUrl(router.query) : null}
                  />

                  {/*// @ts-ignore*/}
                  {!isMobile && <Reviews.Filters />}

                  <Carousel>
                    {/*// @ts-ignore*/}
                    <Carousel.Slider className={s.list} optionsKeenSlider={optionsSlider}>
                      {mainList.map((item, index) => (
                        // @ts-ignore
                        <Reviews.Item
                          key={item.id}
                          data={item}
                          mode={isMobile ? 'card' : 'row'}
                          analytic={getAnalyticData('main', index)}
                        />
                      ))}
                      {/*@ts-ignore*/}
                    </Carousel.Slider>
                  </Carousel>

                  {/*// @ts-ignore*/}
                  {!isMobile && <Reviews.Pagination className={s.pagination} />}
                </div>
              )}
            </>
          )}
        </Reviews>
      </div>
    </ErrorBoundary>
  )
}

export default RestaurantReviews
