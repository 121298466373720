import { createContext } from 'react'

export const DropdownContext = createContext<TypeContext>({
  floatingRelative: null,
  opened: false,
})

type TypeContext = {
  floatingRelative: HTMLDivElement | null
  opened: boolean
}
