import React, { FC, ReactNode, useContext } from 'react'
import s from './Cover.module.sass'
import { ImgContext } from '@shared/ui/Layout/Cover/Cover'
import Image from 'next/image'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'

interface Props {
  children: ReactNode
  className?: string
  alt?: string
}

const CoverMain: FC<Props> = ({ children, className = '', alt = 'image cover blur' }) => {
  const imgSrc = useContext(ImgContext)

  return (
    <ErrorBoundary>
      <main className={`${s.main} ${className}`}>
        <div className={s['main-blur-wrapper']}>
          <div className={s['main-blur']}>
            <Image src={imgSrc} layout={'fill'} alt={alt} priority={true} />
          </div>
        </div>

        {children}
      </main>
    </ErrorBoundary>
  )
}

export default CoverMain
