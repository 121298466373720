import s from './HeaderAdvert.module.sass'

import React, { FC } from 'react'
import { useRouter } from 'next/router'
import Image from 'next/image'

import { AdvertTemplate } from '@shared/ui/Layout/AdvertTemplate'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'

import PicnicAfishaDesktopSvg from '@images/picnic/picnic-afishi.svg'
import MskDesktopSvg from '@images/picnic/msk.svg'
import SmileDesktopSvg from '@images/picnic/smile.svg'
import SpbDesktopSvg from '@images/picnic/spb.svg'
import SponsorDesktopSvg from '@images/picnic/sponsor.svg'

import Slide1MobileSvg from '@images/picnic/slide-1.svg'
import Slide2MobileSvg from '@images/picnic/slide-2.svg'
import Slide3MobileSvg from '@images/picnic/slide-3.svg'
import Slide4MobileSvg from '@images/picnic/slide-4.svg'

const SHOW_ANY_ADV = process.env.SHOW_ANY_ADV
const IS_STATIC_PICNIC_BILLBOARD = true

// adv-header Billboard TopBanner
const HeaderAdvert: FC = () => {
  const router = useRouter()
  const { designEnv, isMobile } = useClientResponsive()

  // Лендинг ресторатора со своей шапкой
  const isShowHeader = router.pathname !== '/[city_slug]/restaurants/restorator'

  // основная логика для ADFox баннеров
  // отображение плоскости для верхней рекламы билборда
  const isShowAdv = SHOW_ANY_ADV && isShowHeader && designEnv !== 'default'

  return isShowAdv ? (
    <div className={s['adv-header']}>
      <div className={s['adv-wrapper']}>
        {IS_STATIC_PICNIC_BILLBOARD ? (
          <div className={`${s['adv-container']} ${isMobile ? s['adv-container--mobile'] : ''}`}>
            <AdvertTemplate
              type={'Billboard TopBanner'}
              className={`${s['adv-place']} ${isMobile ? s['adv-place--mobile'] : ''}`}
            />
          </div>
        ) : (
          <div
            className={`${s['adv-container']} ${s['adv-container--picnic']} ${
              isMobile ? s['adv-container--mobile'] : ''
            }`}>
            {/* Пикник Афиши */}
            <a
              className={`${s['adv-picnic__banner']} ${isMobile ? s['adv-picnic__banner--mobile'] : ''}`}
              href='https://picnic.afisha.ru/'
              target={isMobile ? '_blank' : '_self'}>
              <div className={`${s['adv-picnic__content']} ${isMobile ? s['adv-picnic__content--mobile'] : ''}`}>
                <>
                  {/* slide-1 desktop */}
                  {/* slide-1 mobile */}
                  <div
                    className={`${s['adv-picnic__animate']} ${isMobile ? s['adv-picnic__animate--mobile'] : ''} ${
                      s['adv-picnic__slide']
                    } ${isMobile ? s['adv-picnic__slide--mobile'] : ''} ${s['adv-picnic__slide-1']} ${
                      isMobile ? s['adv-picnic__slide-1--mobile'] : ''
                    }`}>
                    {!isMobile ? (
                      <PicnicAfishaDesktopSvg alt='Пикник Афиши 2024' width='1024' height='66' />
                    ) : (
                      <Slide1MobileSvg alt='Пикник Афиши 2024' width='360' height='60' />
                    )}
                  </div>
                </>

                <>
                  {!isMobile ? (
                    <>
                      {/* slide-2 desktop */}
                      <div
                        className={`${s['adv-picnic__animate']} ${isMobile ? s['adv-picnic__animate--mobile'] : ''} ${
                          s['adv-picnic__slide']
                        } ${isMobile ? s['adv-picnic__slide--mobile'] : ''} ${s['adv-picnic__slide-2']} ${
                          isMobile ? s['adv-picnic__slide-2--mobile'] : ''
                        }`}>
                        <MskDesktopSvg alt='МСК, СК Лужники, 3–4.08' width='404' height='66' />
                        <SmileDesktopSvg alt='=)' width='98' height='66' />
                        <SpbDesktopSvg alt='СПБ, Елагин остров, 10–11.08' width='412' height='66' />
                      </div>
                    </>
                  ) : (
                    <>
                      <>
                        {/* slide-2 mobile */}
                        <div
                          className={`${s['adv-picnic__animate']} ${isMobile ? s['adv-picnic__animate--mobile'] : ''} ${
                            s['adv-picnic__slide']
                          } ${isMobile ? s['adv-picnic__slide--mobile'] : ''} ${s['adv-picnic__slide-2']} ${
                            isMobile ? s['adv-picnic__slide-2--mobile'] : ''
                          }`}>
                          <Slide2MobileSvg alt='МСК, СК Лужники, 3–4.08' width='360' height='60' />
                        </div>
                      </>

                      <>
                        {/* slide-3 mobile */}
                        <div
                          className={`${s['adv-picnic__animate']} ${isMobile ? s['adv-picnic__animate--mobile'] : ''} ${
                            s['adv-picnic__slide']
                          } ${isMobile ? s['adv-picnic__slide--mobile'] : ''} ${s['adv-picnic__slide-3']} ${
                            isMobile ? s['adv-picnic__slide-3--mobile'] : ''
                          }`}>
                          <Slide3MobileSvg alt='СПБ, Елагин остров, 10–11.08' width='360' height='60' />
                        </div>
                      </>
                    </>
                  )}
                </>

                <>
                  {!isMobile ? (
                    <>
                      {/* slide-3 desktop */}
                      <div
                        className={`${s['adv-picnic__animate']} ${isMobile ? s['adv-picnic__animate--mobile'] : ''} ${
                          s['adv-picnic__slide']
                        } ${isMobile ? s['adv-picnic__slide--mobile'] : ''} ${s['adv-picnic__slide-3']} ${
                          isMobile ? s['adv-picnic__slide-3--mobile'] : ''
                        }`}>
                        <SponsorDesktopSvg alt='Афиша | СБЕР — генеральный партнёр' width='480' height='45' />
                      </div>
                    </>
                  ) : (
                    <>
                      {/* slide-4 mobile */}
                      <div
                        className={`${s['adv-picnic__animate']} ${isMobile ? s['adv-picnic__animate--mobile'] : ''} ${
                          s['adv-picnic__slide']
                        } ${isMobile ? s['adv-picnic__slide--mobile'] : ''} ${s['adv-picnic__slide-4']} ${
                          isMobile ? s['adv-picnic__slide-4--mobile'] : ''
                        }`}>
                        <Slide4MobileSvg alt='Афиша | СБЕР — генеральный партнёр' width='356' height='35' />
                      </div>
                    </>
                  )}
                </>
              </div>
            </a>
          </div>
        )}
      </div>
    </div>
  ) : (
    <div className={s.plug}></div>
  )
}

export default HeaderAdvert
