import s from './OwnerCreatePostForm.module.sass'

import React, { FC, memo, useState } from 'react'

import { useAppDispatch, useAppSelector } from '@app/model/store'

import { OwnerCreatePostFormSpecial } from '@pages/OwnerPersonalPage/ui/components/OwnerPostForms/OwnerCreatePostForm/ui/SubformSpecial'
import { OwnerCreatePostFormNews } from '@pages/OwnerPersonalPage/ui/components/OwnerPostForms/OwnerCreatePostForm/ui/SubformNews'
import { OwnerCreatePostFormMenu } from '@pages/OwnerPersonalPage/ui/components/OwnerPostForms/OwnerCreatePostForm/ui/SubformMenu'
import { closeForm, setCategoryCreateForm } from '@pages/OwnerPersonalPage/model/OwnerPostsModel/reducer'

import { Modal } from '@shared/ui/Overlays/Modal'
import { Text } from '@shared/ui/Typography/Text'
import { Radio } from '@shared/ui/Form/Single/Radio'
import { Button } from '@shared/ui/Actions/Button'
import { postCreate } from '@pages/OwnerPersonalPage/model/OwnerPostsModel/createActions'

interface Props {
  className?: string
}

const mappingTypeCategories = {
  special: 'Спецпредложение',
  news: 'Новость',
  menu: 'Обновление меню',
}

const OwnerCreatePostFormContainer: FC<Props> = (props) => {
  const stateCreatePost = useAppSelector((store) => store?.pages?.owner_personal?.posts?.forms?.create)

  return stateCreatePost?.opened && <OwnerCreatePostForm {...props} />
}

const OwnerCreatePostForm: FC<Props> = ({ className = '' }) => {
  const dispatch = useAppDispatch()
  const statePosts = useAppSelector((store) => store?.pages?.owner_personal?.posts)
  const stateCreatePost = statePosts?.forms?.create
  const { loading, selectedCategory, categories } = stateCreatePost

  const handleChangeFormCategory = (data) => {
    dispatch(setCategoryCreateForm(data.name))
  }

  const handleCloseForm = () => {
    dispatch(closeForm())
  }

  const handlePublic = () => {
    dispatch(postCreate())
  }

  return (
    <Modal title={'Новое объявление'} size={'S'} handleClose={handleCloseForm} className={s['owner-edit-form']}>
      <>
        <div className={s['select-type-wrap']} data-testid={'modal-create-post'}>
          <Text className={s['select-type-label']} sizes={'M'}>
            Тип объявления
          </Text>

          <div className={s['select-type-list']}>
            {Object.keys(categories).map((category) => (
              <Radio
                key={mappingTypeCategories[category]}
                option={{ id: category, name: category, disabled: false, label: mappingTypeCategories[category] }}
                handleClick={handleChangeFormCategory}
                checked={category === selectedCategory}
                data-testid={`category-${category}`}
                mode={'button'}
              />
            ))}
          </div>
        </div>

        <div>
          {selectedCategory === 'special' && <OwnerCreatePostFormSpecial />}
          {selectedCategory === 'news' && <OwnerCreatePostFormNews />}
          {selectedCategory === 'menu' && <OwnerCreatePostFormMenu />}
        </div>

        <div className={s.footer}>
          {!statePosts.canPublished && (
            <Text sizes={'S'} className={s['footer-warning']}>
              Взаимодействовать с объявлением можно не чаще раза в минуту
            </Text>
          )}
          <div className={s['data-submit-wrap']}>
            {/*<Button*/}
            {/*  onClick={(ev) => console.log('click')}*/}
            {/*  className={s['data-submit-btn-secondary']}*/}
            {/*  sizes='XL'*/}
            {/*  mode='white-border'>*/}
            {/*  Сохранить*/}
            {/*</Button>*/}

            <Button
              data-testid={'create-post-send'}
              onClick={handlePublic}
              className={s['data-submit-btn-primary']}
              sizes='XL'
              mode='black'
              disabled={!statePosts.canPublished}>
              Опубликовать
            </Button>
          </div>
        </div>
      </>
    </Modal>
  )
}

export default memo(OwnerCreatePostFormContainer)
